import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import { simpleGetCall, simpleGetCallWithToken, simplePostAuthCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";

const EditSubscription = () => {
    const params = useParams()?.id;
    const [paramId, setParamId] = useState(params);
    const navigate = useNavigate();
    const { sidebar, setSidebar } = useContext(AppContext);
    const [entitiesData, setEntitiesData] = useState([]);
    const [subsData, setSubsData] = useState({
        subscription_id: paramId,
        subscription_name: "",
        subscription_description: "",
        subscription_price: null,
        duration_months: null,
        entity_id: null,
        renewal_price: null,
        subscription_type: ""
    });
    const changeVal = (e) => {
        setSubsData({
            ...subsData,
            [e.target.name]: e.target.value
        })
    }
    const getEntitiesDropDn = () => {
        simpleGetCall(ApiConfig.GET_ENTITIES_DROPDOWN)
            .then((res) => {
                if (res.success) {
                    setEntitiesData(res?.data);
                } else {
                    setEntitiesData([]);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const getSubsDetailsById = () => {
        simpleGetCall(ApiConfig.GET_SUBSCRIPTIONBY_ID + `?subscription_id=${paramId}`)
            .then((res) => {
                if (res.success) {
                    let data = res?.data;
                    setSubsData({
                        ...subsData,
                        subscription_name: data[0]?.subscription_name,
                        subscription_description: data[0]?.subscription_description,
                        duration_months: data[0]?.duration_months,
                        subscription_price: data[0]?.subscription_price,
                        entity_id: data[0]?.entity_id,
                        subscription_type: data[0]?.subscription_type,
                        renewal_price: data[0]?.renewal_price
                    })
                } else {
                    console.log("NO DATA")
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const updateOnCLick = () => {
        simplePostAuthCall(ApiConfig.UPDATE_SUBSCRIPTIONBY_ID, JSON.stringify(subsData))
            .then((res) => {
                if (res?.success) {
                    notifySuccess(res?.message);
                    setSubsData({
                        subscription_name: "",
                        subscription_description: "",
                        subscription_price: null,
                        duration_months: null,
                        entity_id: null,
                        renewal_price: null,
                        subscription_type: ""
                    });
                    navigate("/SubscriptionPlan");
                } else {
                    notifyError(res?.message)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getEntitiesDropDn();
        getSubsDetailsById();
    }, []);

    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };
    return (
        <>
            <motion.div
                variants={aninations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
                className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
                id="cx-main">
                <div className="addEditSubsBox">
                    <div className="row">
                        <div className="col-md-6 colSix">
                            <div className="formBox">
                                <label className="formLabel">Plan Id</label>
                                <input
                                    value={subsData.subscription_id}
                                    type="text" className="form-control" />
                            </div>
                            <div className="formBox">
                                <label className="formLabel">Plan Name</label>
                                <input
                                    value={subsData.subscription_name}
                                    name="subscription_name"
                                    onChange={changeVal}
                                    type="text" className="form-control" />
                            </div>
                            <div className="formBox">
                                <label className="formLabel">Description</label>
                                <textarea
                                    value={subsData.subscription_description}
                                    name="subscription_description"
                                    onChange={changeVal}
                                    className="form-textArea"></textarea>
                            </div>
                            <div className="formBox">
                                <label className="formLabel">Plan Duration</label>
                                <input
                                    value={subsData.duration_months}
                                    name="duration_months"
                                    onChange={changeVal}
                                    type="text" className="form-control" />
                            </div>
                            <div className="formBox">
                                <label className="formLabel">Plan Price</label>
                                <input
                                    value={subsData.subscription_price}
                                    name="subscription_price"
                                    onChange={changeVal}
                                    type="number" className="form-control" />
                            </div>
                            <div className="formBox">
                                <label className="formLabel">Entity Key</label>
                                <select
                                    value={subsData.entity_id}
                                    onChange={changeVal}
                                    name="entity_id"
                                    className="form-select">
                                    <option value="">Select Entity Key</option>
                                    {
                                        entitiesData && entitiesData.map((data, index) => {
                                            return (
                                                <>
                                                    <option value={data?.entity_type_id} key={index}>{data?.entity_type}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="formBox">
                                <label className="formLabel">Subscription Type</label>
                                <select
                                    value={subsData.subscription_type}
                                    onChange={changeVal}
                                    name="subscription_type"
                                    className="form-select">
                                    <option value="">Select Subscription Type</option>
                                    <option value="basic">Basic</option>
                                    <option value="standard">Standard</option>
                                    <option value="premiuim">Premiuim</option>
                                </select>
                            </div>
                            <div className="formBox">
                                <label className="formLabel">Renewal</label>
                                <input
                                    value={subsData.renewal_price}
                                    onChange={changeVal}
                                    name="renewal_price"
                                    type="text" className="form-control" />
                            </div>
                            <div className="saveBox">
                                <button className="innerBtn" onClick={updateOnCLick}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div >
        </>
    )
}

export default EditSubscription
