import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import Star from "../../assets/images/icons/ratingStar.svg";
import NoData from "../../assets/images/icons/noDataImg.svg";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import noData from "../../assets/images/icons/noDataImg.svg";
import emptyBox from "../../assets/images/icons/emptyBoxes.svg";
import Form from "react-bootstrap/Form";
import AddIcon from "../../assets/images/icons/addsquare.svg";
import {
  PutCallWithErrorResponse,
  postMultipartWithAuthCall,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "./../../api/ApiConfig";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { notifySuccess } from "../../sharedComponent/Notify";
import CustomInfiniteScroll from "../../customeHooks/CustomInfiniteScroll";

const MasterProductList = () => {
  const {
    sidebar,
    pharmacuEventKey,
    setPharmacuEventKey,
    navigate,
    setBkdropLdr,
    slideBelowRef,
  } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  // STATES
  const [productList, setProductList] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoreLoader, setIsMoreLoader] = useState(false);
  const [currentPageData, setCurrentPageData] = useState({ last_page: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageLimit: 10,
    product_category: "",
    search: "",
  });
  const [categorySearch, setCategorySearch] = useState("");
  const [selectedTab, setSelectedTab] = useState("products");
  // STATES

  useEffect(() => {
    fetchProductCategory();
    fetchLookupData();
  }, []);

  const fetchProductCategory = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.PHARMACY_PRODUCT_CATEGORY_LIST}?productFor=pharmacy&page_limit=10&page=1&product_type=`
      );

      const { success, data } = res?.json;
      if (success && data) {
        setProductCategory(data);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };
  const [categories, setCategories] = useState([]);
  const fetchLookupData = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_LOOKUP_DATA_PHARMACY}?productFor=pharmacy`
      );
      // console.log("res,", res);
      const { success, lookupData } = res?.json;
      if (lookupData) {
        lookupData?.productTypes && setCategories(lookupData?.productTypes);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, [filterData]);

  const fetchProductList = async () => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.INVENTRY_PHARMACY_PRODUCT_LIST}?productFor=pharmacy&page_limit=${filterData.pageLimit}&page=${filterData.page}&filter=${filterData?.search}&product_category=${filterData?.product_category}`
      );

      const { success, data, last_page, page } = res?.json;
      if (success && data) {
        setCurrentPageData({ last_page });
        setProductList(data);
        setCurrentPage(prev => prev + 1)
      } else {
        setProductList([]);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  const disabledPetProduct = async (productId, active) => {
    try {
      setBkdropLdr(true);
      const payLoad = {
        is_active: active,
      };
      const res = await PutCallWithErrorResponse(
        `${ApiConfig.PRODUCT_DISABLED}/${productId}`,
        payLoad
      );
      console.log("disabledPetProduct res,", res);
      const { success, message } = res?.json;
      if (success) {
        notifySuccess(
          active
            ? "Product enabled successfully"
            : "Product disabled successfully"
        );
        fetchProductList();
        setCurrentPage(1);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };
  // console.log("selectedTab,", selectedTab)
  // useEffect(() => {
  //   if (currentPage > 1 && selectedTab === "products") {
  //     fetchMoreProductList();
  //   } else if (currentPage > 1 && selectedTab === "categories") {
  //     fetchMoreProductCategory();
  //   }
  // }, [currentPage]);

  const fetchMoreProductList = async (pageNumber) => {
    try {
      // setIsMoreLoader(true);
      setBkdropLdr(true)
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.INVENTRY_PHARMACY_PRODUCT_LIST}?productFor=pharmacy&page_limit=${filterData.pageLimit}&page=${pageNumber}&filter=${filterData?.search}&product_category=${filterData?.product_category}`
      );
      const { success, data, last_page } = res?.json;
      if (success && data) {
        const mergedData = [...productList, ...data];
        setProductList(mergedData);
        setCurrentPageData({ last_page });
        setCurrentPage(pageNumber + 1);
        //  !last_page && setCurrentPage(pageNumber + 1);
      }
      // else {
      //   setProductList([])
      // }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsMoreLoader(false);
      setBkdropLdr(false)
    }
  };

  const fetchMoreProductCategory = async (pageNumber) => {
    try {
      setIsMoreLoader(true);
      const res = await simpleGetCallWithErrorResponse(
        `${
          ApiConfig.PHARMACY_PRODUCT_CATEGORY_LIST
        }?productFor=pharmacy&page_limit=10&page=${pageNumber}&product_type=${""}`
      );
      const { success, data, last_page } = res?.json;
      if (success && data) {
        setProductCategory((prev) => [...prev, ...data]);
        setCurrentPageData({ last_page });
        setCurrentPage(pageNumber + 1);
        // !last_page && setCurrentPage(pageNumber + 1);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsMoreLoader(false);
    }
  };

  // const handleInfiniteScroll = () => {
  //   const slideBelow = slideBelowRef.current;

  //   if (
  //     slideBelow &&
  //     slideBelow.clientHeight + slideBelow.scrollTop + 1 >=
  //       slideBelow.scrollHeight
  //   ) {
  //     if (!currentPageData.last_page) {
  //       setCurrentPage((prevPage) => prevPage + 1);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const slideBelow = slideBelowRef.current;
  //   if (slideBelow) {
  //     slideBelow.addEventListener("scroll", handleInfiniteScroll);
  //   }
  // }, []);

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="masterProductList-wrapper">
          <div className="MasterInvenTabs">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={pharmacuEventKey}
            >
              <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                <Nav.Item
                  onClick={() => {
                    setSelectedTab("products");
                    setCurrentPage(1);
                    setCurrentPageData({ last_page: false });
                  }}
                >
                  <Nav.Link eventKey="ProductList">Product List</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  onClick={() => {
                    setSelectedTab("categories");
                    setCurrentPage(1);
                    setCurrentPageData({ last_page: false });
                  }}
                >
                  <Nav.Link eventKey="ProductCategory">
                    Product Category
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="ProductList">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="searchBox">
                        <img src={SearchIcon} className="searchImg" alt="" />
                        <input
                          type="text"
                          className="form-control searchDoc"
                          placeholder="Search by name..."
                          onChange={(e) => {
                            setFilterData({
                              ...filterData,
                              search: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="selectBox">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            setFilterData({
                              ...filterData,
                              product_category: e.target.value
                                ? e.target.value
                                : "",
                            });
                          }}
                          value={
                            filterData?.product_category
                              ? filterData?.product_category
                              : ""
                          }
                        >
                          <option value="">All Categories...</option>
                          {categories.map((item, idx) => {
                            return (
                              <option
                                value={item?.product_type}
                                key={item?.product_type_id}
                              >
                                {item?.product_type}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-between">
                      <div
                        className="topFilter"
                        style={{ visibility: "hidden" }}
                      >
                        {/* <img src={FilterIcon} className="filterInner" alt="" /> */}
                      </div>
                      <div className="topFilter">
                        <Link to="/AddProduct">
                          <div className="addBox">
                            <img src={AddIcon} alt="" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* Cards section */}
                    {isLoading ? (
                      <RotatingLoader />
                    ) : productList.length > 0 ? (
                      // slideBelow
                      <CustomInfiniteScroll
                        fetchMoreData={fetchMoreProductList}
                        hasMore={!currentPageData.last_page}
                        currentPage={currentPage}
                      >
                        <div className=" row p-0 m-0" ref={slideBelowRef}>
                          {productList.map((item, idx) => {
                            return (
                              <div
                                className="col-lg-3 cardsResponsive"
                                key={"productList" + idx}
                              >
                                <div className="productCards">
                                  <img
                                    src={
                                      item?.product_image_path
                                        ? `${item?.product_image_path}`
                                        : ""
                                    }
                                    onError={(e) => {
                                      e.target.src = noData;
                                    }}
                                    className="cardImg"
                                    alt=""
                                  />
                                  {item?.avg_rating && (
                                    <div className="starFlex">
                                      <img
                                        src={Star}
                                        className="innerStar"
                                        alt=""
                                      />
                                      <label className="innerRate">
                                        {item?.avg_rating}
                                      </label>
                                    </div>
                                  )}
                                  <div className="switchFlex">
                                    <div className="cardDetails">
                                      <p>{item?.product}</p>
                                      <p>
                                        {item?.product_brand} |{" "}
                                        {item?.product_type}
                                      </p>
                                    </div>
                                    <div className="checkSwitch">
                                      <Form.Check
                                        type="switch"
                                        checked={item?.is_active}
                                        onChange={(e) => {
                                          disabledPetProduct(
                                            item?.product_id,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {item?.product_desc && (
                                    <p className="paraTXt">
                                      {item?.product_desc}
                                    </p>
                                  )}
                                  <div className="text-center cardBtmBtn">
                                    {/*
                                  <Link to="/EditProduct" className="routButton">
                                  */}
                                    <button
                                      className="innerBtn"
                                      onClick={() => {
                                        navigate(
                                          `/EditProduct/${item.product_id}`
                                        );
                                      }}
                                    >
                                      Edit Product
                                    </button>
                                    {/* </Link> */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </CustomInfiniteScroll>
                    ) : (
                      <NoDataMsg />
                    )}
                  </div>
                  {isMoreLoader && <RotatingLoader />}
                </Tab.Pane>
                <Tab.Pane eventKey="ProductCategory">
                  <div className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                      <div className="searchBox">
                        <img src={SearchIcon} className="searchImg" alt="" />
                        <input
                          type="text"
                          className="form-control searchDoc"
                          placeholder="Search by name..."
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12 d-flex justify-content-between">
                      <div
                        className="topFilter"
                        style={{ visibility: "hidden" }}
                      >
                        <img src={FilterIcon} className="filterInner" alt="" />
                      </div>
                      <div className="topFilter">
                        <Link to="/AddCategory">
                          <div className="addBox">
                            <img src={AddIcon} alt="" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* Cards section */}

                    {productCategory && productCategory.length > 0 ? (
                       <CustomInfiniteScroll
                       fetchMoreData={fetchMoreProductCategory}
                       hasMore={!currentPageData.last_page}
                       currentPage={currentPage + 1}
                     >
                      <div className=" row p-0 m-0">
                        {productCategory.map((item, idx) => {
                          return (
                            <div
                              className="col-lg-3 cardsResponsive"
                              key={"productCategory" + idx}
                            >
                              <div className="productCards">
                                <img
                                  src={emptyBox}
                                  className="cardImg"
                                  alt=""
                                />
                                <div className="switchFlex">
                                  <div className="cardDetails">
                                    <p className="productName">
                                      {item?.category}
                                    </p>
                                  </div>
                                  {/*
                                  <div className="checkSwitch">
                                  <Form.Check type="switch" />
                                </div>
                                */}
                                </div>
                                <div className="quantityAnPrice">
                                  <div className="row">
                                    <div className="col-lg-6 text-center p-0">
                                      <div className="quant">
                                        <p className="greyTxt">Products</p>
                                        <label className="Amnt">
                                          {item?.total_products}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 text-center p-0">
                                      <div className="price">
                                        <p className="greyTxt">Brands</p>
                                        <label className="Amnt">
                                          {item?.total_brands}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-center cardBtmBtn">
                                  <Link
                                    to={`/EditCategory/${item?.product_type_id}`}
                                    className="routButton"
                                  >
                                    <button className="innerBtn">
                                      Edit Category
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      </CustomInfiniteScroll>
                    ) : (
                      <NoDataMsg MSG={"No Categories founds"} />
                    )}
                    {/* {isMoreLoader && <RotatingLoader />} */}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default MasterProductList;
