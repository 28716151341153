import React, { useContext } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { AppContext } from '../context/AppContext';

const MapComponent = () => {
    const { pickup, setPickup } = useContext(AppContext);
    console.log("pickup=>", pickup)
    return (
        <div className="map-container">
            <MapContainer center={[pickup?.x, pickup?.y]} zoom={10} style={{ height: '300px', width: '40%' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={[pickup?.x, pickup?.y]}>
                    <Popup>Pickup Location</Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};
export default MapComponent;
