import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import { Form } from "react-bootstrap";
import { notifySuccess } from "../../sharedComponent/Notify";
import ApiConfig from "../../api/ApiConfig";
import {
  PostCallWithErrorResponse,
  PutCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { useNavigate, useParams } from "react-router-dom";

const EditCategory = () => {
  const { sidebar, setBkdropLdr, setPharmacuEventKey } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const navigate = useNavigate();
  const params = useParams();
  const category_id = Number(params.id);
  // STATES
  const [validated, setValidated] = useState(false);
  // const [pharmacuEventKey, setPharmacuEventKey] = useState();
  const [categoryPayLoad, setCategoryPayLoad] = useState({
    category_id: "",
    category_name: "",
  });
  console.log("category Payload,", categoryPayLoad);
  // STATES

  // GET_CATEGORY_DETAILS
  useEffect(() => {
    fetchCategoryDetails();
  }, []);

  const fetchCategoryDetails = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_CATEGORY_DETAILS}/${category_id}`
      );
      console.log("res,", res);
      const { success, data } = res?.json;
      if (success && data) {
        setCategoryPayLoad({
          category_id: data?.product_type_id,
          category_name: data?.product_type,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  // UPDATED_CATEGORY === products
  const updatePharmacyProductCategory = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      } else {
        setBkdropLdr(true);
        const res = await PutCallWithErrorResponse(
          ApiConfig.UPDATED_CATEGORY,
          categoryPayLoad
        );
        const { success } = res?.json;
        if (success === true) {
          setPharmacuEventKey("ProductCategory");
          navigate("/MasterInventory");
          notifySuccess("Category updated successfully");
        }
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={updatePharmacyProductCategory}
        >
          <div className="addProduct-wrapper">
            <div className="addCardWraper">
              <div className="cardHeader">
                <label className="headTxt">Edit Product Category</label>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Category Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Category Name"
                      value={categoryPayLoad?.category_name}
                      required
                      onChange={(e) =>
                        setCategoryPayLoad({
                          ...categoryPayLoad,
                          category_name: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Category name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="rejectBtn me-2"
                onClick={() => {
                  navigate("/MasterInventory");
                  setPharmacuEventKey("ProductCategory");
                }}
              >
                Cancel
              </button>
              <button className="AcceptBtn">Save</button>
            </div>
          </div>
        </Form>
      </motion.div>
    </>
  );
};

export default EditCategory;
