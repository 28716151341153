import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import PetShop from "../../assets/images/icons/petShopImg.svg";
import Star from "../../assets/images/icons/ratingStar.svg";
import dummypetshop from "../../assets/images/dummypetshop.jpg";
import Clock from "../../assets/images/icons/clock.svg";
import { Link } from "react-router-dom";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";

const VetPetShops = () => {
  const { sidebar, setSidebar } = useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  // STATES
  const [isMoreLoader, setIsMoreLoader] = useState(false);
  const [vetShopList, setVetShopList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState({ last_page: false });
  const [filterData, setFilterData] = useState({
    page: 1,
    search: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // STATES

  useEffect(() => {
    fetchVetShopList();
  }, [filterData]);

  const fetchVetShopList = async () => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.PET_SHOP_LIST}?page=${filterData?.page}${
          filterData?.search ? `&search=${filterData?.search}` : ""
        }`
      );

      const { success, petVetShopList, last_page } = res?.json;
      if (success) {
        petVetShopList && setVetShopList(petVetShopList);
        setCurrentPageData({ last_page });
        // !last_page && setCurrentPage(currentPage + 1);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchMoreVetShopList();
    }
  }, [currentPage]);

  const fetchMoreVetShopList = async () => {
    try {
      // alert("fetch More")
      setIsMoreLoader(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.PET_SHOP_LIST}?page=${currentPage}${
          filterData?.search ? `&search=${filterData?.search}` : ""
        }`
      );

      const { success, petVetShopList, last_page } = res?.json;
      if (success) {
        petVetShopList &&
          setVetShopList((prev) => [...prev, ...petVetShopList]);
        setCurrentPageData({ last_page : last_page ? last_page :false });
        if (last_page){
          !last_page && setCurrentPage(currentPage + 1);
        }
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsMoreLoader(false);
    }
  };
  const slideBelowRef = useRef(null);
  
  
  
  // const handleInfiniteScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop + 1 >=
  //     document.documentElement.scrollHeight
  //   ) {
  //     alert("scroll")
  //     if (!currentPageData.last_page) {
  //       setCurrentPage(currentPage + 1);
  //     }
  //   }
  // };
  
  const handleInfiniteScroll = () => {
    const slideBelow = slideBelowRef.current;

    if (
      slideBelow &&
      slideBelow.clientHeight + slideBelow.scrollTop + 1 >=
        slideBelow.scrollHeight
    ) {
      if (!currentPageData.last_page) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };


  useEffect(() => {
    const slideBelow = slideBelowRef.current;
    // console.log("slideBelow,", slideBelow)
    if (slideBelow) {
      slideBelow.addEventListener('scroll', handleInfiniteScroll);
    }
    // return () => {
    //   if (slideBelow) {
    //     slideBelow.removeEventListener('slideBelow', handleInfiniteScroll);
    //   }
    // };
    // window.addEventListener("scroll", handleInfiniteScroll);
    // return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, [vetShopList]);

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="pharmacyShop-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="innerSearchItem">
                <div className="leftFilterTop">
                  <div className="topSearch">
                    <input
                      type="text"
                      className="form-control searchDoc"
                      placeholder="Search by name..."
                      onChange={(e) =>
                        setFilterData({ ...filterData, search: e.target.value })
                      }
                    />
                    <img src={SearchIcon} className="searchInner" alt="" />
                  </div>
                  {/* <div className="topFilter">
                    <img src={FilterIcon} className="filterInner" alt="" />
                  </div> */}
                </div>
                <Link to="/AddPetShops" className="routButton">
                  <button className="innerBtn">Add Pet Shop</button>
                </Link>
              </div>
            </div>
            {/* Cards Content */}
            {isLoading ? (
              <RotatingLoader />
            ) : vetShopList && vetShopList.length > 0 ? (
              <div className="slideBelow row p-0 m-0"
              ref={slideBelowRef}
              >
                {vetShopList.map((item, idx) => {
                  return (
                    <div
                      className="col-lg-3 cardsResponsive"
                      key={"vetShopList" + idx}
                    >
                      <div className="doctorsDetailsCard">
                        <img
                          src={
                            item?.pet_shop_image_path
                              ? item?.pet_shop_image_path
                              : PetShop
                          }
                          onError={(ev) => {
                            ev.target.src = dummypetshop;
                          }}
                          className="docInsideImg"
                          alt=""
                        />
                        <div iv className="medicalbelowDetails">
                          <label className="headTxt">
                            {item?.pet_shop_name !== "null"
                              ? item?.pet_shop_name
                              : ""}
                          </label>
                          {item?.rating && (
                            <>
                              <div className="starFlex">
                                <img src={Star} className="star" alt="" />
                              </div>
                              <label className="numTxt">
                                {parseFloat(item?.rating).toFixed(2)}
                              </label>
                            </>
                          )}
                        </div>
                        {/* <div className='clockDet'>
                        <img src={Clock} className='clock' alt='' />
                        <label htmlFor='' className='timeDet'>
                          Mon- Sat : 10:00 AM - 06:00 PM
                        </label>
                      </div> */}

                        <div className="belowBtns">
                          <Link to={`/PetShopDetails`}>
                            <button
                              className="rejectBtn"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "petShopId",
                                  item?.pet_shop_id
                                );
                                sessionStorage.setItem(
                                  "pet_shop_registration_id",
                                  item?.pet_shop_registration_id
                                );
                              }}
                            >
                              Shop Details
                            </button>
                          </Link>
                          <Link to={`/PetShopInventory/${item.pet_shop_id}`}>
                            <button className="AcceptBtn">Inventory</button>
                          </Link>
                        </div>
                        {item?.is_disabled && (
                            <div className="disable_img">
                              {" "}
                              <img src={disabled} />{" "}
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
                {/* <div className='col-lg-3 cardsResponsive'>
                    <div className='doctorsDetailsCard'>
                      <img src={PetShop} className='docInsideImg' alt='' />
                      <div iv className='medicalbelowDetails'>
                        <label className='headTxt'>David Pharmacy</label>
                        <div className='starFlex'>
                          <img src={Star} className='star' alt='' />
                        </div>
                        <label className='numTxt'>4.3</label>
                      </div>
                      <div className='clockDet'>
                        <img src={Clock} className='clock' alt='' />
                        <label htmlFor='' className='timeDet'>
                          Mon- Sat : 10:00 AM - 06:00 PM
                        </label>
                      </div>
                      <div className='belowBtns'>
                        <Link to='/PetShopDetails'>
                          <button className='rejectBtn'>Shop Details</button>
                        </Link>
                        <Link to='/PetShopInventory'>
                          <button className='AcceptBtn'>Inventory</button>
                        </Link>
                      </div>
                    </div>
                  </div> */}
              </div>
            ) : (
              <NoDataMsg />
            )}
            {isMoreLoader && <RotatingLoader />}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default VetPetShops;
