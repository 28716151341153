import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import { Link } from "react-router-dom";
import { simplePostAuthCall, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";
import dummydoctor from "../../assets/images/icons/dummydoctor.jpg";
import { useDebounce } from "../../customeHooks/useDebounce";
import CustomInfiniteScroll from "../../customeHooks/CustomInfiniteScroll";
import NoDataMsg from "../../sharedComponent/NoDataMsg";

const Doctors = () => {
  const { sidebar, slideBelowRef, setBkdropLdr } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [last_page, setlast_page] = useState(false);
  const [TotalData, setTotalData] = useState(0);
  const [doctorsList, setDoctorsList] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [loading, setLoading] = useState(false);
  const localData = JSON.parse(localStorage.getItem("user_loggedIn_data"));
  const debouncedValue = useDebounce(searchTxt, 500);

  // Get All Doc cards List
  const getAllVErDocsList = (currentPage) => {
    if (currentPage == 1) {
      setLoading(true);
    } else {
      setBkdropLdr(true)
    }
    let payload = {
      country_id: localData?.country_id,
      state_id: localData?.state_id,
      city_id: localData?.city_id,
      admin_role_id: localData?.admin_role_id,
      is_super_admin: localData?.is_super_admin,
      keyword: debouncedValue,
      page_limit: 10,
      page: currentPage ? currentPage : 1,
    };
    simplePostAuthCall(ApiConfig.VERIFIED_DOCS_LIST, JSON.stringify(payload))
      .then((res) => {
        const { success, data, last_page, total, total_pages, current_page } =
          res;
        if (success === true) {
          let total = res.total ? res.total : 0;
          setTotalData(total);
          setlast_page(last_page);
          setPage(current_page + 1);
          let doctorsDefArr = data ? data : [];
          let finalArr = doctorsDefArr.map((innerData, index) => {
            return {
              ...innerData,
              doctor_name: innerData?.doctor_name || "",
            };
          });

          if (currentPage === 1) {
            setDoctorsList(data);
          } else {
            setDoctorsList([...doctorsList, ...finalArr]);
          }
        } else {
          setDoctorsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setBkdropLdr(false)
      });
  };
  useEffect(() => {
    getAllVErDocsList(page);
  }, [debouncedValue]);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const onPageLoad = () => {
    setPage(page + 1);
    if (last_page === false) {
      getAllVErDocsList(page + 1);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="doctor-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="innerSearchItem">
                <div className="leftFilterTop">
                  <div className="topSearch">
                    <input
                      type="text"
                      autoFocus
                      className="form-control searchDoc"
                      value={searchTxt}
                      onChange={(e) => {
                        setSearchTxt(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Search by name..."
                    />
                    <img src={SearchIcon} className="searchInner" alt="" />
                  </div>
                  {/* <div className="topFilter">
                        <img src={FilterIcon} className="filterInner" alt="" />
                      </div> */}
                </div>
                <Link to="/AddDoctor" className="routButton">
                  <button className="innerBtn">Add Doctor’s</button>
                </Link>
              </div>
            </div>

            {/* Cards Content */}
            {loading ? (
              <Loader />
            ) : (
              <CustomInfiniteScroll
                fetchMoreData={getAllVErDocsList}
                hasMore={!last_page}
                currentPage={page}
              >
                <div className=" row p-0 m-0" ref={slideBelowRef}>
                  {doctorsList && doctorsList.length > 0 ? (
                    doctorsList.map((data, index) => {
                      return (
                        <div className="col-lg-3 cardsResponsive" key={index}>
                          <Link
                            to={`/DoctorDetails/${data.doctor_id}`}
                            className="cardLink"
                          >
                            <div className="doctorsDetailsCard">
                              <img
                                src={
                                  data?.doctor_profile_image_path
                                    ? data?.doctor_profile_image_path
                                    : dummydoctor
                                }
                                className="docInsideImg"
                                alt=""
                              />
                              <div className="ratingDet">
                                {/* <img src={Star} className="innerRate" alt="" /> */}
                                {/* <label className="rateTxt">4.5</label> */}
                              </div>
                              <div className="docDetailsTxt">
                                <h1>{data.doctor_name}</h1>
                                <label>{data.specializations}</label>
                              </div>
                              {data?.is_disabled && (
                                <div className="disable_img">
                                  <img src={disabled} />{" "}
                                </div>
                              )}
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <NoDataMsg MSG={"No doctors found"} />
                  )}
                </div>
              </CustomInfiniteScroll>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Doctors;
