import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import DoctorSahab from "../../assets/images/icons/doctorSahab.svg";
import dummyprofile from "../../assets/images/dummyprofile.jpeg";
import ThreeDots from "../../assets/images/icons/ThreeDots.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import Patient from "../../assets/images/icons/patientImg.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import DogImg from "../../assets/images/icons/dogImg.svg";
import catImg from "../../assets/images/icons/catImg.svg";
import Chart from "react-apexcharts";
import Star from "../../assets/images/icons/ratingStar.svg";
import scanner from "../../assets/images/icons/scanner.svg";
import scannerWrapper from "../../assets/images/icons/wrapperScanner.svg";
import Print from "../../assets/images/icons/PrintImg.svg";
import Dog from "../../assets/images/icons/DoggyImg.svg";
import CallIcon from "../../assets/images/icons/callPurple.svg";
import EmailIcon from "../../assets/images/icons/mailPurple.svg";
import Location from "../../assets/images/icons/locationPurple.svg";
import Signature from "../../assets/images/icons/Signature.svg";
import ic_star_deactive from "../../assets/images/icons/ic_star_deactive.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import {
  PutCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifySuccess } from "../../sharedComponent/Notify";
import moment from "moment";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";

const PetShopDetails = () => {
  const { sidebar, setBkdropLdr, navigate } = useContext(AppContext);
  const [stateChaneg, setStateChaneg] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#4675F1"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#34B53A"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "₹" +
            "</div>"
          );
        },
      },
    },
  });
  const [lossState, setLossState] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#D0182E"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#D0182E"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "₹" +
            "</div>"
          );
        },
      },
    },
  });
  const [clickedOrder, setClickedOrder] = useState(false);
  const [clickedTransaction, setClickedTransaction] = useState(false);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const petShopId = sessionStorage.petShopId && sessionStorage.petShopId;
  const pet_shop_registration_id =
    sessionStorage.pet_shop_registration_id &&
    sessionStorage.pet_shop_registration_id;

  const [profileInfo, setProfileInfo] = useState({});
  const [allOrders, setAllOrders] = useState([]);
  const [allTransaction, setTransaction] = useState([]);
  const [payMentInfo, setPayMentInfo] = useState([]);
  console.log("payMentInfo", payMentInfo);
  const currentMonth = payMentInfo[0];
  // const totalRevenue = payMentInfo[1];
  const bankDetails = payMentInfo[2];
  const [ratingAndReviews, setRatingAndReviews] = useState({});
  const [currentRevenue, setCurrentRevenue] = useState({});
  const [totalRevenue, setTotalRevenue] = useState({});
  useEffect(() => {
    fetchVetShopDetails();
  }, []);

  const fetchVetShopDetails = async () => {
    const profileInfo = simpleGetCallWithErrorResponse(
      `${ApiConfig.PET_VET_SHOP_PROFILE}?petShopId=${petShopId}`
    );
    const orders = simpleGetCallWithErrorResponse(
      `${ApiConfig.PET_VET_SHOP_ORDERS}?petVetShopId=${petShopId}`
    );
    const transactions = simpleGetCallWithErrorResponse(
      `${ApiConfig.PET_VET_SHOP_TRANSACTIONS}?petVetShopId=${petShopId}`
    );
    const payMentInfo = simpleGetCallWithErrorResponse(
      `${ApiConfig.PET_VET_SHOP_PAYMENTS}?petVetShopUserId=${pet_shop_registration_id}&petVetShopId=${petShopId}`
    );
    const ratingAndReviews = simpleGetCallWithErrorResponse(
      `${ApiConfig.PET_VET_SHOP_RATINGS}?petShopId=${petShopId}`
    );

    try {
      setBkdropLdr(true);
      const responses = await Promise.all([
        profileInfo,
        orders,
        transactions,
        payMentInfo,
        ratingAndReviews,
      ]);
      const [
        profileDetails,
        allOrders,
        allTransactions,
        payMentdetails,
        ratAndRevies,
      ] = await Promise.all(responses.map((response) => response.json));
      if (profileDetails?.success) {
        profileDetails?.data && setProfileInfo(profileDetails?.data[0]);
      }
      if (allOrders?.success) {
        allOrders?.data && setAllOrders(allOrders?.data);
      }
      if (allTransactions?.success) {
        allTransactions?.data && setTransaction(allTransactions?.data);
      }
      if (payMentdetails?.success) {
        payMentdetails?.data && setPayMentInfo(payMentdetails?.data);
        payMentdetails?.monthlyRevenue &&
          setLossState({
            ...lossState,
            series: [
              { data: payMentdetails?.monthlyRevenue?.perDay.splice(10) },
            ],
          });
        setCurrentRevenue(payMentdetails?.totalRevenue);
        setTotalRevenue(payMentdetails?.monthlyRevenue);
        payMentdetails?.totalRevenue &&
          setStateChaneg({
            ...stateChaneg,
            series: [{ data: payMentdetails?.totalRevenue?.perMonth }],
          });
      }
      if (ratAndRevies?.success) {
        ratAndRevies?.data && setRatingAndReviews(ratAndRevies?.data[0]);
      }
    } catch (error) {
      console.log("Catch error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };
  //   PET_VET_SHOP_DISABLE: BASE_URL + ADMIN_PATH + 'petVetShopEnableDisable',
  //   PET_VET_SHOP_REJECT: BASE_URL + ADMIN_PATH + 'petVetShopReaddRemove',

  const disablePharmacyShop = async (is_disabled) => {
    try {
      const payLoad = {
        petVetShopId: pet_shop_registration_id,
        disable: is_disabled,
      };
      setBkdropLdr(true);
      const res = await PutCallWithErrorResponse(
        ApiConfig.PET_VET_SHOP_DISABLE,
        payLoad
      );
      const { success } = res?.json;
      if (success) {
        notifySuccess("Disabled successfully");
        navigate("/vetPetShops");
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const removePharmacyShop = async () => {
    try {
      const payLoad = {
        petVetShopId: pet_shop_registration_id,
        remove: true,
      };
      setBkdropLdr(true);
      const res = await PutCallWithErrorResponse(
        ApiConfig.PET_VET_SHOP_REJECT,
        payLoad
      );
      const { success } = res?.json;
      if (success) {
        notifySuccess("Removed successfully");
        navigate("/vetPetShops");
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [prescriptionData, setPrescriptionData] = useState({});
  const subTotal =
    prescriptionData?.order_details &&
    prescriptionData?.order_details.reduce((acc, innerItem) => {
      return acc + innerItem.amount;
    }, 0);

  // ORDER_PRESCRIPTION
  const fetchOrderDetails = async (order_id) => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.FETCH_ORDER_DETAILS}?orderId=${order_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        data[0] && setPrescriptionData(data[0]);
        setClickedOrder(true);
        // setClickedOrder(true)
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="pharmacyShop-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                <img
                  src={
                    profileInfo?.pet_shop_image_path
                      ? profileInfo?.pet_shop_image_path
                      : dummyprofile
                  }
                  className="docImg"
                  alt=""
                />
                <div className="docDetTxt">
                  <div>
                    {profileInfo?.pet_shop_name && (
                      <h1 className="docName">{profileInfo?.pet_shop_name}</h1>
                    )}
                    {profileInfo?.type_of_pet_shop && (
                      <label className="docDomain">
                        {profileInfo?.type_of_pet_shop}
                      </label>
                    )}
                  </div>
                  {profileInfo?.is_disabled && (
                    <div className="disable_img">
                      {" "}
                      <img src={disabled} />{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="dropDown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={ThreeDots} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        disablePharmacyShop(!profileInfo?.is_disabled)
                      }
                    >
                      <Link to="#">
                        {profileInfo?.is_disabled ? "Enable" : "Disable"}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={removePharmacyShop}>
                      <Link to="#">Remove</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="doctorTabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Profile">
                <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                  <Nav.Item>
                    <Nav.Link eventKey="Profile">Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Orders">Orders</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Transaction">Transaction</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="PaymentDetails">
                      Payment Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="RatingReviews">
                      Rating & Reviews
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Profile">
                    <div className="row">
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle moreMt">
                          <h1 className="heading">General Information</h1>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Owner Name</h1>
                          {profileInfo?.owner_name && (
                            <label htmlFor="" className="value">
                              {profileInfo?.owner_name}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Contact Number</h1>
                          {profileInfo?.phone_number && (
                            <label htmlFor="" className="value">
                              {profileInfo?.phone_number}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Email</h1>
                          {profileInfo?.email && (
                            <label htmlFor="" className="value">
                              {profileInfo?.email}
                            </label>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-md-6 col-sm-12">
                        <img src={scanner} alt="" />
                        <img src={scannerWrapper} alt="" />
                      </div> */}
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle moreMt">
                          <h1 className="heading">Shop Information</h1>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Shop Name</h1>
                          {profileInfo?.pet_shop_name && (
                            <label htmlFor="" className="value">
                              {profileInfo?.pet_shop_name}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Shop Type</h1>
                          {profileInfo?.pet_shop_name && (
                            <label htmlFor="" className="value">
                              {profileInfo?.type_of_pet_shop}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Address</h1>
                          {profileInfo?.pet_shop_address && (
                            <label htmlFor="" className="value">
                              {profileInfo?.pet_shop_address}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">License Number</h1>
                          <label htmlFor="" className="value">
                            {profileInfo?.pet_shop_license_number !== "null"
                              ? profileInfo?.pet_shop_license_number
                              : "-"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Orders">
                    {allOrders && allOrders.length ? (
                      <div className="mainAppointments">
                        <div className="allApointmentsRow">
                          <h1 className="headTxt">All Orders</h1>
                          {/* <img src={FilterIcon} className="FilImg" alt="" /> */}
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="incedoTable">
                              <table className="defaultTable">
                                <thead>
                                  <tr>
                                    <th>Sr No. </th>
                                    <th>Order Date</th>
                                    <th>Time</th>
                                    <th>Customer</th>
                                    {/* <th>Particulars</th> */}
                                    {/* <th>Pet Type</th> */}
                                    <th>Bill By</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody className="tableBody">
                                  {allOrders.map((item, idx) => {
                                    return (
                                      <tr key={"allOrders" + idx}>
                                        <td>{idx + 1}</td>
                                        <td>
                                          {moment(
                                            item?.pet_shop_order_date
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                          {moment(
                                            item?.pet_shop_order_date
                                          ).format("hh:mm:ss A")}
                                        </td>
                                        <td>
                                          <img
                                            src={
                                              item?.customer_profile_pic
                                                ? item?.customer_profile_pic
                                                : Patient
                                            }
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          {item?.customer_name}
                                        </td>
                                        {/* <td>
                                          <img
                                            src={Print}
                                            onClick={() => {
                                              fetchOrderDetails(
                                                item.pet_shop_order_id
                                              );
                                            }}
                                            className="clickableImg"
                                            alt=""
                                          />
                                        </td> */}
                                        {/* <td>Dog</td> */}
                                        <td>{item?.owner_name}</td>
                                        <td>₹{item?.total_amount}</td>
                                        <td>
                                          {" "}
                                          {/* "status": "order placed" */}
                                          <img
                                            src={
                                              item?.status === "order placed"
                                                ? pending
                                                : item?.status ===
                                                  "order delivered"
                                                ? approved
                                                : cancel
                                            }
                                            className="checks"
                                            alt=""
                                          />
                                          {item?.status === "order placed"
                                            ? "Pending"
                                            : item?.status === "order delivered"
                                            ? "completed"
                                            : "Rejected"}
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  {/* <tr>
                                    <td>2</td>
                                    <td>12-06-2023</td>
                                    <td>04:58:58s</td>
                                    <td>
                                      <img
                                        src={Patient}
                                        className='rounded-circle tableImg'
                                        alt=''
                                      />
                                      Drogo
                                    </td>
                                    <td>
                                      <img
                                        src={Print}
                                        onClick={() => setClickedOrder(true)}
                                        className='clickableImg'
                                        alt=''
                                      />
                                    </td>
                                    <td>Dog</td>
                                    <td>Sam Billings</td>
                                    <td>₹15</td>
                                    <td>
                                      {' '}
                                      <img
                                        src={cancel}
                                        className='checks'
                                        alt=''
                                      />
                                      Rejected
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>12-06-2023</td>
                                    <td>04:58:58s</td>
                                    <td>
                                      <img
                                        src={Patient}
                                        className='rounded-circle tableImg'
                                        alt=''
                                      />
                                      Drogo
                                    </td>
                                    <td>
                                      <img
                                        src={Print}
                                        onClick={() => setClickedOrder(true)}
                                        className='clickableImg'
                                        alt=''
                                      />
                                    </td>
                                    <td>Dog</td>
                                    <td>Sam Billings</td>
                                    <td>₹15</td>
                                    <td>
                                      {' '}
                                      <img
                                        src={approved}
                                        className='checks'
                                        alt=''
                                      />
                                      Checked
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NoDataMsg />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Transaction">
                    {allTransaction && allTransaction.length ? (
                      <div className="mainAppointments">
                        <div className="allApointmentsRow">
                          <h1 className="headTxt">All Transactions</h1>
                          {/* <img src={FilterIcon} className="FilImg" alt="" /> */}
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="incedoTable">
                              <table className="defaultTable">
                                <thead>
                                  <tr>
                                    <th>Sr No. </th>
                                    <th>Transaction Date</th>
                                    <th>Time</th>
                                    <th>Customer</th>
                                    {/* <th>Pet Type</th> */}
                                    <th>Particular</th>
                                    <th>Bill By</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody className="tableBody">
                                  {allTransaction.map((item, idx) => {
                                    return (
                                      <tr>
                                        <td>{idx + 1}</td>
                                        <td>
                                          {moment(
                                            item?.transaction_date
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                          {moment(
                                            item?.transaction_date
                                          ).format("hh:mm:ss A")}
                                        </td>
                                        <td>
                                          <img
                                            src={
                                              item?.customer_profile_pic
                                                ? item?.customer_profile_pic
                                                : Patient
                                            }
                                            style={{ width: "41px" }}
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          {item?.customer_name}
                                        </td>
                                        {/* <td>Dog</td> */}

                                        <td>
                                          <img
                                            src={Print}
                                            onClick={() => {
                                              fetchOrderDetails(
                                                item.pet_shop_order_id
                                              );
                                              // setClickedOrder(true)
                                            }}
                                            className="clickableImg"
                                            alt=""
                                          />
                                        </td>

                                        <td>{item?.owner_name}</td>
                                        <td>₹{item?.total_amount}</td>
                                        <td>
                                          {" "}
                                          <img
                                            src={
                                              item?.status === "order placed"
                                                ? pending
                                                : item?.status === "successful"
                                                ? approved
                                                : cancel
                                            }
                                            className="checks"
                                            alt=""
                                          />
                                          {item?.status === "order placed"
                                            ? "Pending"
                                            : item?.status === "successful"
                                            ? "Completed"
                                            : "Rejected"}
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  {/* <tr>
                                    <td>2</td>
                                    <td>12-06-2023</td>
                                    <td>01:20PM - 02:20PM</td>
                                    <td>
                                      <img
                                        src={Patient}
                                        className='rounded-circle tableImg'
                                        alt=''
                                      />
                                      Drogo
                                    </td>
                                    <td>Dog</td>
                                    <td>
                                      <img
                                        src={Print}
                                        onClick={() => setClickedOrder(true)}
                                        className='clickableImg'
                                        alt=''
                                      />
                                    </td>
                                    <td>Sam Billings</td>
                                    <td>₹500</td>
                                    <td>
                                      {' '}
                                      <img
                                        src={cancel}
                                        className='checks'
                                        alt=''
                                      />
                                      Rejected
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>12-06-2023</td>
                                    <td>01:20PM - 02:20PM</td>
                                    <td>
                                      <img
                                        src={Patient}
                                        className='rounded-circle tableImg'
                                        alt=''
                                      />
                                      Drogo
                                    </td>
                                    <td>Dog</td>
                                    <td>
                                      <img
                                        src={Print}
                                        onClick={() => setClickedOrder(true)}
                                        className='clickableImg'
                                        alt=''
                                      />
                                    </td>
                                    <td>Sam Billings</td>
                                    <td>₹500</td>
                                    <td>
                                      {' '}
                                      <img
                                        src={approved}
                                        className='checks'
                                        alt=''
                                      />
                                      Checked
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NoDataMsg />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="PaymentDetails">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="heightScroller">
                          {payMentInfo && payMentInfo.length > 0 ? (
                            payMentInfo.map((data, index) => {
                              return (
                                <div className="bankDetailsCard shadow-sm">
                                  <div className="heading">
                                    <label className="headTxt">
                                      Bank Details {index + 1}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Account Holder Name
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data?.account_holder_name
                                        ? data?.account_holder_name
                                        : "-"}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Account Number
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data?.account_number
                                        ? data?.account_number
                                        : "-"}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Bank Name
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data?.pet_shop_bank
                                        ? data?.pet_shop_bank
                                        : "-"}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      IFSC/ SWIFT Code
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data?.ifsc_code ? data?.ifsc_code : "-"}
                                    </label>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-md-6 text-center p-3 text-danger">
                              No Data Found
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="bankDetailsCard shadow-sm">
                          {/**{totalRevenue?.totRevenue && ( */}

                          <div className="heading">
                            <label className="headTxt">Revenue Summary</label>
                          </div>
                          {/*  )} */}
                          <div className="graphs">
                            {/** {totalRevenue?.totRevenue ||
                              (currentMonth?.currentMonthRevenue && (*/}

                            <div className="profitGraph">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  ₹{totalRevenue?.currentMonth_total}
                                </label>
                                <label className="plusPercent">
                                  +{totalRevenue?.percentage}%
                                </label>
                              </div>
                              <div className="graph">
                                <Chart
                                  options={stateChaneg.options}
                                  series={stateChaneg.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  Total Revenue
                                </label>
                              </div>
                            </div>

                            {/*))} */}
                            {/*{currentMonth?.currentMonthRevenue && ( */}

                            <div className="lossGraph">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  ₹{currentRevenue?.total}
                                </label>
                                <label className="lossPercent">
                                  -{currentRevenue?.percentage}%
                                </label>
                              </div>
                              <div className="graph">
                                <Chart
                                  options={lossState.options}
                                  series={lossState.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  This Month
                                </label>
                              </div>
                            </div>

                            {/*)} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="RatingReviews">
                    <div className="ratingBox">
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="totalReviewCard">
                            <h1 className="headTxt">Total Reviews</h1>
                            <p className="amntTxt">
                              {ratingAndReviews?.totalReviews}
                              {Number(ratingAndReviews?.totalReviews) > 1000
                                ? "K"
                                : ""}
                            </p>
                            <label className="detailTxt">
                              Growth in the review from last month
                            </label>
                          </div>
                        </div>
                        {ratingAndReviews?.averageRatings && (
                          <div className="col-lg-4 col-md-12">
                            <div className="totalReviewCard">
                              <h1 className="headTxt">Average Ratings</h1>
                              <div className="innerStars">
                                <p className="amntTxt">
                                  {parseFloat(
                                    ratingAndReviews?.averageRatings
                                  ).toFixed(2)}
                                </p>
                                <img
                                  src={
                                    Number(ratingAndReviews?.averageRatings) >=
                                    1
                                      ? Star
                                      : ic_star_deactive
                                  }
                                  alt=""
                                />
                                <img
                                  src={
                                    Number(ratingAndReviews?.averageRatings) >=
                                    2
                                      ? Star
                                      : ic_star_deactive
                                  }
                                  alt=""
                                />
                                <img
                                  src={
                                    Number(ratingAndReviews?.averageRatings) >=
                                    3
                                      ? Star
                                      : ic_star_deactive
                                  }
                                  alt=""
                                />
                                <img
                                  src={
                                    Number(ratingAndReviews?.averageRatings) >=
                                    4
                                      ? Star
                                      : ic_star_deactive
                                  }
                                  alt=""
                                />
                                <img
                                  src={
                                    Number(ratingAndReviews?.averageRatings) >=
                                    5
                                      ? Star
                                      : ic_star_deactive
                                  }
                                  alt=""
                                />
                              </div>
                              <label className="detailTxt">
                                Average rating from month
                              </label>
                            </div>
                          </div>
                        )}

                        <div className="col-lg-4 col-md-12">
                          <div className="ratingStars">
                            {ratingAndReviews?.ratings &&
                              ratingAndReviews?.ratings.length > 0 &&
                              ratingAndReviews?.ratings.map((item, idx) => {
                                return (
                                  <div className="starLevels">
                                    <img
                                      src={Star}
                                      className="starImg"
                                      alt=""
                                    />
                                    <label className="starAmnt">
                                      {item?.rating}
                                    </label>
                                    <ProgressBar
                                      now={
                                        (Number(
                                          ratingAndReviews?.totalReviews
                                        ) /
                                          Number(item?.count)) *
                                        100
                                      }
                                      className="greenBar"
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="belowLine"></div>
                        </div>
                        {ratingAndReviews?.reviews?.map((item, idx) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-12">
                                <div className="petDetailsCard">
                                  <img src={DogImg} alt="" />
                                  <div className="cardDetails">
                                    <label className="headingTx">
                                      {item?.customer_name}
                                    </label>
                                    <label className="reviewTxt">
                                      Total Reviews:{" "}
                                      <span>{item?.total_reviews}</span>{" "}
                                    </label>
                                    <label className="spendsTxt">
                                      Total Spends:{" "}
                                      <span>₹{item?.total_spent}</span>{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {item?.ratings_and_reviews &&
                                item?.ratings_and_reviews.map(
                                  (innerItem, innerIdx) => {
                                    return (
                                      <div className="col-lg-8 col-md-12">
                                        <div className="regularCheqCard">
                                          <div className="ratingDetailsHead">
                                            <div className="nameAndDate">
                                              <label className="nameLab">
                                                {innerItem?.review_title}
                                              </label>
                                              <label className="dateLab">
                                                {moment(
                                                  innerItem?.review_date
                                                ).format("DD-MM-YYYY")}
                                              </label>
                                            </div>
                                            {innerItem?.rating && (
                                              <div className="starRate">
                                                <img
                                                  src={Star}
                                                  className="starIcon"
                                                  alt=""
                                                />
                                                <label className="rateNum">
                                                  {innerItem?.rating}
                                                </label>
                                              </div>
                                            )}
                                          </div>
                                          <div className="paraFlow">
                                            <p className="insideParaDet">
                                              {innerItem?.review}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </>
                          );
                        })}

                        {/* <div className='col-lg-4 col-md-12'>
                          <div className='petDetailsCard'>
                            <img src={catImg} alt='' />
                            <div className='cardDetails'>
                              <label className='headingTx'>James Bond</label>
                              <label className='reviewTxt'>
                                Total Reviews: <span>10</span>{' '}
                              </label>
                              <label className='spendsTxt'>
                                Total Spends: <span>₹1000</span>{' '}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-8 col-md-12'>
                          <div className='regularCheqCard'>
                            <div className='ratingDetailsHead'>
                              <div className='nameAndDate'>
                                <label className='nameLab'>
                                  Regular Checkup
                                </label>
                                <label className='dateLab'>24-02-2023</label>
                              </div>
                              <div className='starRate'>
                                <img src={Star} className='starIcon' alt='' />
                                <label className='rateNum'>4.5</label>
                              </div>
                            </div>
                            <div className='paraFlow'>
                              <p className='insideParaDet'>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit . Minima dicta officiis,
                                possimus cumque magni odit, obcaecati, numquam
                                quaerat in eligendi quae provident ratione?
                                Aperiam nam ratione inventore voluptas dolore
                                distinctio deleniti accusamus voluptatibus qui
                                officia !
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Modal For Order */}
      <Modal
        show={clickedOrder}
        onHide={() => setClickedOrder(false)}
        size="lg"
      >
        <Modal.Body>
          <div className="petModalInvoice">
            <div className="modalHeading">
              <div className="upperBlue">
                <h1 className="docName">
                  {prescriptionData?.basic_details &&
                    prescriptionData?.basic_details[0]?.pet_shop_name}{" "}
                </h1>
                <label className="domainLab">
                  {prescriptionData?.basic_details &&
                    prescriptionData?.basic_details[0]?.type_of_pet_shop}{" "}
                </label>

                <label className="certificateLab">
                  Invoice Details{" "}
                  {/* {prescriptionData?.basic_details &&
                    prescriptionData?.basic_details[0]?.pet_shop_license_number} */}
                </label>
              </div>
              <div className="docSymbolBox">
                <img src={Dog} className="docSymImg" alt="" />
              </div>
            </div>
            <div className="modalBody">
              <div className="detailsFill">
                <div className="labelTxt">
                  <label className="widthFixed">Customer Name:</label>
                </div>
                <input
                  style={{ width: "83%" }}
                  disabled
                  type="text"
                  value={
                    prescriptionData?.basic_details &&
                    prescriptionData?.basic_details[0]?.customer_name
                  }
                  className="form-control formSolution"
                />
              </div>
              <div className="addressFlex">
                <div className="labelTxt">
                  <label className="keyQuestion">Address:</label>
                </div>
                <input
                  disabled
                  type="text"
                  value={
                    prescriptionData?.basic_details &&
                    prescriptionData?.basic_details[0]?.customer_address
                  }
                  className="form-control formSolution"
                />
              </div>
              <div className="dateNdAgeFlex">
                {/*
                <div className="ageBox">
                <label className="ageLab">Age:</label>
                <input
                  type="text"
                  value="14"
                  className="form-control formSolution"
                />
              </div>
              */}
                <div className="dateBox">
                  <label className="ageLab">Date:</label>
                  <input
                    disabled
                    type="text"
                    value={moment(
                      prescriptionData?.basic_details &&
                        prescriptionData?.basic_details[0]?.order_date
                    ).format("DD-MM-YYYY")}
                    className="form-control formSolution"
                  />
                </div>
              </div>
              {/* <div className="addressFlex">
                <div className="labelTxt">
                  <label className="keyQuestion">Diagnosis:</label>
                </div>
                <input
                  type="text"
                  value="Regular check up"
                  className="form-control formSolution"
                />
              </div> */}

              <div className="tableAndLandingBg">
                <div className="backGroundPic"></div>
                <div className="d-flex justify-content-end">
                  {/* <label htmlFor="" className="InvoiceTxt">
                Invoice No. {prescriptionData?.pet_shop_order_id}
              </label> */}
                </div>
                <div className="innerTable">
                  <table className="modalTable">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Prescription</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescriptionData?.order_details &&
                        prescriptionData?.order_details?.map((item, idx) => {
                          return (
                            <tr>
                              <td>{idx + 1}</td>
                              <td>{item?.product}</td>
                              <td>{item?.product_quantity}</td>
                              <td>{item?.product_rate}</td>
                              <td>{item?.amount}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="belowTableDetails">
                  <div className="paymentTxt">
                    <label className="paymntTxtHead">Payment Method:</label>
                    <label className="paymntOption">
                      {prescriptionData?.basic_details &&
                        prescriptionData?.basic_details[0]?.mode_of_payment}
                      {/*  UPI / Credit Card / Debit Card/ Cash */}
                    </label>
                  </div>
                  <div className="rightTable">
                    <div className="tableInside">
                      <div className="detailFlexy">
                        <label className="insideLabTxt">Sub Total</label>
                        <label className="insideLab">₹{subTotal}</label>
                      </div>
                      <div className="detailFlexy">
                        <label className="insideLabTxt">Tax</label>
                        <label className="insideLab">₹ 0</label>
                      </div>
                      <div className="totalSec">
                        <div className="detailFlexy">
                          <label className="insideLabTxt">Total</label>
                          <label className="insideLab">₹ {subTotal}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*
                <div className="signatureContent">
                <div className="sigBox">
                  <label htmlFor="sigNatureFor" className="signaturePad">
                    <img src={Signature} alt="" />
                    <input type="file" id="sigNatureFor" className="d-none" />
                  </label>
                  <label className="belowTxt">SIGNATURE</label>
                </div>
              </div>
              */}
            </div>
            <div className="belowDetails">
              <div className="imgWrapper">
                <div className="row heightAuto">
                  <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                    <div className="txt">
                      <div className="innerTxt">Contact</div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-12 p-0">
                    <div className="allCOntact">
                      <div className="emailAndCall">
                        <div className="callNnumber">
                          <img src={CallIcon} alt="" />
                          <label className="numberInn">
                            {/* {prescriptionData?.basic_details &&
                              prescriptionData?.basic_details[0]?.phone_number} */}
                            +91 9509932234
                          </label>
                        </div>
                        <div className="emialInn">
                          <img src={EmailIcon} alt="" />
                          <label className="numberInn">
                            {/* {prescriptionData?.basic_details &&
                              prescriptionData?.basic_details[0]?.email} */}
                            info@animalsapp.in
                          </label>
                        </div>
                      </div>
                      <div className="locationInn">
                        <img src={Location} alt="" />
                        <label className="locationTxt">
                          {/* {prescriptionData?.basic_details &&
                            prescriptionData?.basic_details[0]
                              ?.pet_shop_address} */}
                          Near Ravi steel, kamde, ratu Road, Ranchi, Jharkhand,
                          India 835222
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PetShopDetails;
