import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import MedicalImg from "../../assets/images/icons/medicalImg.svg";
import dummypharmacy from "../../assets/images/dummypharmacy.jpg";
import Star from "../../assets/images/icons/ratingStar.svg";
import Clock from "../../assets/images/icons/clock.svg";
import { Link } from "react-router-dom";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import dummyprofile from "../../assets/images/dummyprofile.jpeg";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";


const Shops = () => {
  const { sidebar, slideBelowRef } = useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  // STATES
  const [shopList, setShopList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState({ last_page: false });
  const [filterData, setFilterData] = useState({
    page: 1,
    search: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // STATES
  useEffect(() => {
    fetchShopList();
  }, [filterData]);

  const fetchShopList = async () => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_PHARMACY_SHOP_LIST}?page=${filterData?.page}${
          filterData?.search ? `&search=${filterData?.search}` : ""
        }`
      );
      const { success, pharmacyList, last_page } = res?.json;
      if (success) {
        pharmacyList && setShopList(pharmacyList);
        setCurrentPageData({ last_page: last_page });
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchMoreShopList();
    }
  }, [currentPage]);

  const [isMoreLoader, setIsMoreLoader] = useState(false);

  const fetchMoreShopList = async () => {
    try {
      setIsMoreLoader(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_PHARMACY_SHOP_LIST}?page=${currentPage}${
          filterData?.search ? `&search=${filterData?.search}` : ""
        }`
      );

      const { success, pharmacyList, last_page } = res?.json;
      if (success) {
        pharmacyList && setShopList((prev) => [...prev, ...pharmacyList]);
        setCurrentPageData({ last_page: last_page });
        if (last_page) {
          !last_page && setCurrentPage(currentPage + 1);
        }
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsMoreLoader(false);
    }
  };

  // const handleInfiniteScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop + 1 >=
  //     document.documentElement.scrollHeight
  //   ) {
  //     if (!currentPageData.last_page) {
  //       setCurrentPage(currentPage + 1);
  //     }
  //   }
  // };

  const handleInfiniteScroll = () => {
    const slideBelow = slideBelowRef.current;

    if (
      slideBelow &&
      slideBelow.clientHeight + slideBelow.scrollTop + 1 >=
        slideBelow.scrollHeight
    ) {
      if (!currentPageData.last_page) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const slideBelow = slideBelowRef.current;
    if (slideBelow) {
      slideBelow.addEventListener("scroll", handleInfiniteScroll);
    }
    // window.addEventListener("scroll", handleInfiniteScroll);
    // return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  const handleErrorImage = (ev) => {
    ev.target.src = dummyprofile;
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="pharmacyShop-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="innerSearchItem">
                <div className="leftFilterTop">
                  <div className="topSearch">
                    <input
                      type="text"
                      className="form-control searchDoc"
                      placeholder="Search by name..."
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          search: e.target.value,
                        });
                      }}
                    />
                    <img src={SearchIcon} className="searchInner" alt="" />
                  </div>
                  {/* <div className='topFilter'>
                    <img src={FilterIcon} className='filterInner' alt='' />
                  </div> */}
                </div>
                <Link to="/AddPharmacy" className="routButton">
                  <button className="innerBtn">Add Pharmacy Shop</button>
                </Link>
              </div>
            </div>
            {/* Cards Content */}
            {isLoading ? (
              <RotatingLoader />
            ) : shopList.length > 0 ? (
              <div className="slideBelow row p-0 m-0" ref={slideBelowRef}>
                {shopList &&
                  shopList.map((item, idx) => {
                    return (
                      <div
                        className="col-lg-3 cardsResponsive"
                        key={"shopList" + idx}
                      >
                        <div className="doctorsDetailsCard">
                          <img
                            src={
                              item?.profile_image_path
                                ? item?.profile_image_path
                                : ""
                            }
                            onError={(ev) => {
                              ev.target.src = dummypharmacy;
                            }}
                            className="docInsideImg"
                            alt=""
                          />

                          <div iv className="medicalbelowDetails">
                            <label className="headTxt">
                              {item?.pharmacy_shop_name}
                            </label>
                            {item?.rating && (
                              <>
                                <div className="starFlex">
                                  <img src={Star} className="star" alt="" />
                                </div>
                                <label className="numTxt">
                                  {parseFloat(item?.rating).toFixed(2)}
                                </label>
                              </>
                            )}
                          </div>
                          <div className="clockDet">
                            {/* <img src={Clock} className="clock" alt="" /> */}
                            {/* <label htmlFor="" className="timeDet">
                              Mon- Sat : 10:00 AM - 06:00 PM
                            </label> */}
                          </div>
                          <div className="belowBtns">
                            <Link to={"/PharmacyShopDetails"}>
                              <button
                                type="button"
                                className="rejectBtn"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "pharmacy_id",
                                    item?.pharmacy_id
                                  );
                                  sessionStorage.setItem(
                                    "pharmacy_shop_id",
                                    item?.pharmacy_shop_id
                                  );
                                  // navigate('/PharmacyShopDetails', {
                                  //   state: {
                                  //     pharmacyShopId: item?.pharmacy_shop_id
                                  //   }
                                  // })
                                }}
                              >
                                Shop Details
                              </button>
                            </Link>
                            <Link
                              to={`/ShopsInventory/${item?.pharmacy_shop_id}`}
                            >
                              <button type="button" className="AcceptBtn">
                                Inventory
                              </button>
                            </Link>
                          </div>
                          {item?.is_disabled && (
                            <div className="disable_img">
                              {" "}
                              <img src={disabled} />{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <NoDataMsg />
            )}
            {isMoreLoader && <RotatingLoader />}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Shops;
