import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { Link, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import AmbulanceImg from '../../assets/images/icons/AmbulanceImg.svg'
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { notifySuccess } from '../../sharedComponent/Notify'

const ViewAmbulance = () => {
  const { sidebar, setBkdropLdr, navigate } = useContext(AppContext)

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }

  const params = useParams()
  const ambulance_driver_id = params?.id
  // const ambulance_id = sessionStorage.ambulance_id;

  const [ambulanceDetails, setAmbulanceDetails] = useState({})
  console.log('ambulanceDetails,', ambulanceDetails)

  useEffect(() => {
    fetchAmbulanceDetails()
  }, [])

  const fetchAmbulanceDetails = async () => {
    try {
      setBkdropLdr(true)
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.AMBULANCE_DETAILS}?ambulance_driver_id=${ambulance_driver_id}`
      )
      const { success, data } = res?.json
      if (success && data) {
        data[0] && setAmbulanceDetails(data[0])
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  const acceptAndReject = async (ambulance_driver_id, isVarify) => {
    try {
      setBkdropLdr(true)
      const payLoad = {
        ambulance_driver_id: ambulance_driver_id,
        is_verified: isVarify
      }
      const res = await PostCallWithErrorResponse(
        ApiConfig.ADD_AMBULANCE,
        payLoad
      )
      const { success } = res?.json
      if (success) {
        notifySuccess(
          isVarify
            ? 'Ambulance accepted successfully'
            : 'Ambulance rejected successfully'
        )
        navigate('/AddAmbulance')
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  return (
    <>
      <motion.div
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
        id='cx-main'
      >
        <div className='viewDoctor-wrapper'>
          <div className='mainDetailCard'>
            <div className='topSection'>
              <div className='innerImg'>
                <img
                  src={
                    ambulanceDetails?.vehicle_image_path
                      ? ambulanceDetails?.vehicle_image_path
                      : AmbulanceImg
                  }
                  className='docImg'
                  alt='ambulance im'
                />
                <div className='docDetTxt'>
                  <h1 className='docName'>
                    {ambulanceDetails?.ambulance_driver_name}
                  </h1>
                  {/* <label className='docDomain'>Orthopedic</label> */}
                </div>
              </div>
            </div>
            <div className='belowDetails'>
              <div className='row'>
                {/* Heading Text */}
                <div className='col-md-12'>
                  <div className='headTittle'>
                    <h1 className='heading'>General Information</h1>
                  </div>
                </div>
                {/* Inner Values */}
                <div className='col-lg-12'>
                  <h1 className='AmbulanceHead'>Ambulance</h1>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Driver Name</h1>
                    <label htmlFor='' className='value'>
                      {ambulanceDetails?.ambulance_driver_name}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Driver Contact No.</h1>
                    <label htmlFor='' className='value'>
                      {ambulanceDetails?.ambulance_driver_contact_no}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Vehicle type</h1>
                    <label htmlFor='' className='value'>
                      {ambulanceDetails?.vehicle_type}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Vehicle No.</h1>
                    <label htmlFor='' className='value'>
                      {ambulanceDetails?.vehicle_no}
                    </label>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='belowLine'></div>
                </div>
                {/*
               <div className="col-lg-12">
                  <h1 className="AmbulanceHead">Ambulance -102</h1>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Driver Name</h1>
                    <label htmlFor="" className="value">
                      Floyd Miles
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Driver Contact No.</h1>
                    <label htmlFor="" className="value">
                      +91 9999 9999 99
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Vehicle type</h1>
                    <label htmlFor="" className="value">
                      Van
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Vehicle No.</h1>
                    <label htmlFor="" className="value">
                      MH09GF1234
                    </label>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="belowLine"></div>
                </div>
                <div className="col-lg-12">
                  <h1 className="AmbulanceHead">Ambulance -103</h1>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Driver Name</h1>
                    <label htmlFor="" className="value">
                      Floyd Miles
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Driver Contact No.</h1>
                    <label htmlFor="" className="value">
                      +91 9999 9999 99
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Vehicle type</h1>
                    <label htmlFor="" className="value">
                      Van
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Vehicle No.</h1>
                    <label htmlFor="" className="value">
                      MH09GF1234
                    </label>
                  </div>
                </div>
                
              */}
                {/* Heading Text */}
                <div className='col-md-12'>
                  <div className='headTittle moreMt'>
                    <h1 className='heading'>Bank Details</h1>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Bank Name</h1>
                    <label htmlFor='' className='value'>
                      {ambulanceDetails?.ambulance_driver_bank}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Account Number</h1>
                    <label htmlFor='' className='value'>
                      {ambulanceDetails?.account_number}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Account Holder Name</h1>
                    <label htmlFor='' className='value'>
                      {ambulanceDetails?.account_holder_name}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>IFSC / SWIFT Code</h1>
                    <label htmlFor='' className='value'>
                      {ambulanceDetails?.ifsc_code}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-end'>
            <button
              className='rejectBtn me-2'
              type='button'
              onClick={() => {
                acceptAndReject(ambulanceDetails.ambulance_driver_id, false)
              }}
            >
              Reject
            </button>
            <button
              className='AcceptBtn'
              type='button'
              onClick={() => {
                acceptAndReject(ambulanceDetails.ambulance_driver_id, true)
              }}
            >
              Accept
            </button>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default ViewAmbulance
