import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import EditPen from "../../assets/images/icons/editPen.svg";
import Delete from "../../assets/images/icons/deleteIcon.svg"
import circleX from "../../assets/images/icons/circleX.svg";
import { Link, useLocation } from "react-router-dom";
import { deleteWithAuthCall, simpleDeteCall, simpleGetCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import moment from 'moment';
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";

const Discount = () => {
    const { sidebar, setSidebar } = useContext(AppContext);
    const [discountList, setDiscountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };

    const getDiscountList = () => {
        setLoading(true)
        simpleGetCall(ApiConfig.GET_DISCOUNT_LIST)
            .then((res) => {
                if (res.success) {
                    setLoading(false);
                    setDiscountList(res?.data);
                } else {
                    setDiscountList([]);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const deleteData = (id) => {
        simpleGetCall(ApiConfig.DELETE_DISCOUNT_BY_ID + `?coupon_id=${id}`)
            .then((res) => {
                if (res.success) {
                    notifySuccess(res?.message);
                    getDiscountList()
                } else {
                    notifyError(res?.message)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getDiscountList();
    }, [])

    return (
        <>
            <motion.div
                variants={aninations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
                className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
                id="cx-main">
                <div className="mainSubsPlanTab">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="innerSearchItem">
                                <div className="leftFilterTop">
                                    <div className="topSearch">
                                        <input
                                            type="text"
                                            name="searchTxt"
                                            autoFocus
                                            // value={searchTxt}
                                            // onChange={(e) => setSearchTxt(e.target.value)}
                                            className="form-control searchDoc"
                                            placeholder="Search"
                                        />
                                        <img src={SearchIcon} className="searchInner" alt="" />
                                    </div>
                                </div>
                                <Link to="/AddDiscount" className="routButton">
                                    <button className="innerBtn">Add Discount</button>
                                </Link>
                            </div>
                        </div>
                        {
                            loading ? <Loader />
                                :
                                <div className="col-md-12">
                                    <div className="table-wrapper fixedHeight">
                                        <table className="defaultTable">
                                            <thead>
                                                <tr>
                                                    <th>Sr No.</th>
                                                    <th>Coupon name</th>
                                                    <th>Coupon code</th>
                                                    <th>Coupon discount</th>
                                                    <th>Start date</th>
                                                    <th>End date</th>
                                                    <th>Min Amount</th>
                                                    <th>max Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    discountList && discountList.length > 0 ?
                                                        discountList.map((data, index) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data?.coupon_name}</td>
                                                                        <td>{data?.coupon_code}</td>
                                                                        <td>{data?.coupon_discount_per + `%`}</td>
                                                                        <td>{moment(data?.coupon_valid_from).format("LL")}</td>
                                                                        <td>{moment(data?.coupon_valid_upto).format("LL")}</td>
                                                                        <td>{data?.min_amount}</td>
                                                                        <td>{data?.max_amount}</td>
                                                                        <td>
                                                                            <Link to={`/EditDiscount/${data?.coupon_id}`}>
                                                                                <img style={{ cursor: "pointer", marginRight: "10px" }} src={EditPen} alt="" />
                                                                            </Link>
                                                                            <img style={{ cursor: "pointer" }} onClick={(() => deleteData(data?.coupon_id))} src={Delete} alt="" />
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                        : null

                                                }
                                            </tbody>
                                        </table>
                                        {/* <div className="pageData">
                              <label>Showing Page {page} - {totalData}</label>
                            </div> */}
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default Discount;
