import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/main.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Doctors from "./pages/Doctors/Doctors";
import Shops from "./pages/Pharmacy/Shops";
import VetPetShops from "./pages/vetPetShops/Shops";
import VetPetMasterInventory from "./pages/vetPetShops/MasterInventory";
import Vaccinators from "./pages/Vaccinators/Vaccinators";
import Ambulances from "./pages/Ambulances/Ambulances";
import DeliveryBoy from "./pages/DeliveryBoy/DeliveryBoy";
import StateAdmin from "./pages/Admin/StateAdmin";
import DistrictAdmin from "./pages/Admin/DistrictAdmin";
import CityAdmin from "./pages/Admin/CityAdmin";
import RevenueReports from "./pages/Reports/RevenueReports";
import UsersReports from "./pages/Reports/UsersReports";
import OtherReports from "./pages/Reports/OtherReports";
import Sidebar from "./sharedComponent/Sidebar";
import Header from "./sharedComponent/Header";
import DoctorDetails from "./pages/Doctors/DoctorDetails";
import AddDoctor from "./pages/Doctors/AddDoctor";
import PharmacyShopDetails from "./pages/Pharmacy/PharmacyShopDetails";
import ViewDoctorDetails from "./pages/Doctors/ViewDoctorDetails";
import ShopsInventory from "./pages/Pharmacy/ShopsInventory";
import AddPharmacy from "./pages/Pharmacy/AddPharmacy";
import ViewShopDetails from "./pages/Pharmacy/ViewShopDetails";
import MasterProductList from "./pages/Pharmacy/MasterProductList";
import AddProduct from "./pages/Pharmacy/AddProduct";
import EditProduct from "./pages/Pharmacy/EditProduct";
import AddCategory from "./pages/Pharmacy/AddCategory";
import EditCategory from "./pages/Pharmacy/EditCategory";
import PetShopDetails from "./pages/vetPetShops/PetShopDetails";
import PetShopInventory from "./pages/vetPetShops/PetShopInventory";
import AddPetProduct from "./pages/vetPetShops/AddPetProduct";
import EditPetProduct from "./pages/vetPetShops/EditPetProduct";
import AddPetCategory from "./pages/vetPetShops/AddPetCategory";
import EditPetCategory from "./pages/vetPetShops/EditPetCategory";
import AddPetShops from "./pages/vetPetShops/AddPetShops";
import ViewPetShops from "./pages/vetPetShops/ViewPetShops";
import VaccinatorDetails from "./pages/Vaccinators/VaccinatorDetails";
import AddVacinators from "./pages/Vaccinators/AddVacinators";
import ViewVaccinators from "./pages/Vaccinators/ViewVaccinators";
import AmbulanceDetails from "./pages/Ambulances/AmbulanceDetails";
import AddAmbulance from "./pages/Ambulances/AddAmbulance";
import ViewAmbulance from "./pages/Ambulances/ViewAmbulance";
import AddDeliveryBoy from "./pages/DeliveryBoy/AddDeliveryBoy";
import DeliveryBoyDetails from "./pages/DeliveryBoy/DeliveryBoyDetails";
import ViewDeilveryBoy from "./pages/DeliveryBoy/ViewDeilveryBoy";
import { ToastContainer } from "react-toastify";
import { AppContext } from "./context/AppContext";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import BackDropLoader from "./sharedComponent/BackDropLoader";
import MapComponent from "./sharedComponent/MapComponent";
import Notification from "./sharedComponent/Notification";
import SubscriptionPlan from "./pages/Subscription/SubscriptionPlan";
import SubscriptionRequest from "./pages/Subscription/SubscriptionRequest";
import ActiveSubscription from "./pages/Subscription/ActiveSubscription";
import AddSubscription from "./pages/Subscription/AddSubscription";
import Discount from "./pages/Discount/Discount";
import AddDiscount from "./pages/Discount/AddDiscount";
import EditSubscription from "./pages/Subscription/EditSubscription";
import EditDiscount from "./pages/Discount/EditDiscount";
import WithdrawList from "./pages/Withdraw/WithdrawList";

function App() {
  const { adminDetails, setAdminDetails, bkdropLdr } = useContext(AppContext);
  const loggedIn = adminDetails.isLoggedIn;
  const location = useLocation();
  let currentRoute = location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn) {
      if (currentRoute.includes("ForgetPassword")) {
        navigate(currentRoute);
      } else navigate("/");
    } else {
      if (currentRoute !== "/") {
        navigate(currentRoute);
      } else navigate("/Dashboard");
    }
  }, [loggedIn, currentRoute]);

  return (
    <>
      {bkdropLdr && <BackDropLoader />}
      <ToastContainer />
      <div className="authContainer">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
        </Routes>
      </div>
      <div className="App background_main_wrapper">
        {currentRoute !== "/" && currentRoute !== "/ForgetPassword" && (
          <>
            (<Sidebar />
            <Header />)
          </>
        )}
        <Routes>
          {/* Dashboard Module */}
          <Route path="/Dashboard" element={<Dashboard />} />
          {/* Dashboard Module */}
          <Route path="/Doctors" element={<Doctors />} />
          <Route path="/DoctorDetails/:id" element={<DoctorDetails />} />
          <Route path="/AddDoctor" element={<AddDoctor />} />
          <Route
            path="/ViewDoctorDetails/:id"
            element={<ViewDoctorDetails />}
          />
          {/* pharmacy Module */}
          {/* SHOPS MODULE */}
          <Route path="/pharmacyShops" element={<Shops />} />
          <Route
            path="/PharmacyShopDetails"
            element={<PharmacyShopDetails />}
          />
          <Route path="/ShopsInventory/:id" element={<ShopsInventory />} />
          <Route path="/AddPharmacy" element={<AddPharmacy />} />
          <Route path="/ViewShopDetails" element={<ViewShopDetails />} />
          {/* MASTER INVENTORY */}
          <Route path="/MasterInventory" element={<MasterProductList />} />
          <Route path="/AddProduct" element={<AddProduct />} />
          <Route path="/EditProduct/:id" element={<EditProduct />} />
          <Route path="/AddCategory" element={<AddCategory />} />
          <Route path="/EditCategory/:id" element={<EditCategory />} />
          {/* VET/PET Module */}
          {/* SHOPS MODULE */}
          <Route path="/vetPetShops" element={<VetPetShops />} />
          <Route path="/PetShopDetails" element={<PetShopDetails />} />
          <Route path="/PetShopInventory/:id" element={<PetShopInventory />} />
          <Route path="/AddPetShops" element={<AddPetShops />} />
          <Route path="/ViewPetShops" element={<ViewPetShops />} />
          {/* MASTER INVENTORY */}
          <Route path="/vetPetInventory" element={<VetPetMasterInventory />} />
          <Route path="/AddPetProduct" element={<AddPetProduct />} />
          <Route path="/EditPetProduct/:id" element={<EditPetProduct />} />
          <Route path="/AddPetCategory" element={<AddPetCategory />} />
          <Route path="/EditPetCategory/:id" element={<EditPetCategory />} />
          {/* VACCINATORS Modlue */}
          <Route path="/Vaccinators" element={<Vaccinators />} />
          <Route path="/VaccinatorDetails" element={<VaccinatorDetails />} />
          <Route path="/AddVacinators" element={<AddVacinators />} />
          <Route path="/ViewVaccinators" element={<ViewVaccinators />} />
          {/* AMBULANCE Module */}
          <Route path="/Ambulances" element={<Ambulances />} />
          <Route path="/AmbulanceDetails/:id" element={<AmbulanceDetails />} />
          <Route path="/AddAmbulance" element={<AddAmbulance />} />
          <Route path="/ViewAmbulance/:id" element={<ViewAmbulance />} />
          {/* DELIVERY BOY Module */}
          <Route path="/DeliveryBoy" element={<DeliveryBoy />} />
          <Route path="/AddDeliveryBoy" element={<AddDeliveryBoy />} />
          <Route path="/DeliveryBoyDetails/:id" element={<DeliveryBoyDetails />} />
          <Route path="/ViewDeilveryBoy/:id" element={<ViewDeilveryBoy />} />
          <Route path="/MapComponent" element={<MapComponent />} />
          {/* Admin Module */}
          <Route path="/StateAdmin" element={<StateAdmin />} />
          <Route path="/DistrictAdmin" element={<DistrictAdmin />} />
          <Route path="/CityAdmin" element={<CityAdmin />} />
          {/* Admin Module */}
          <Route path="/RevenueReports" element={<RevenueReports />} />
          <Route path="/UsersReports" element={<UsersReports />} />
          <Route path="/OtherReports" element={<OtherReports />} />
          <Route path="/Notification" element={<Notification />} />
          {/* Subscripton Module */}
          <Route path="/SubscriptionPlan" element={<SubscriptionPlan />} />
          <Route path="/AddSubscription" element={<AddSubscription />} />
          <Route path="/EditSubscription/:id" element={<EditSubscription />} />
          <Route path="/SubscriptionRequest" element={<SubscriptionRequest />} />
          <Route path="/ActiveSubscription" element={<ActiveSubscription />} />
          {/* Discount Module */}
          <Route path="/DiscountList" element={<Discount />} />
          <Route path="/AddDiscount" element={<AddDiscount />} />
          <Route path="/EditDiscount/:id" element={<EditDiscount />} />
          {/* Withdraw List  */}
          <Route path="/withdrawlist" element={<WithdrawList />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
