// ========================================= LOCAL SERVER ================================
// export const BASE_URL = "http://192.168.1.56:3000/"; // Vinod IP
// ========================================= LOCAL SERVER ================================

// ========================================= DEVELOPMENT SERVER (REDBYTES_SERVER) ================================
// export const BASE_URL = "https://animal-api.app.redbytes.in:3000/"
// ========================================= DEVELOPMENT SERVER (REDBYTES_SERVER) ================================

// ========================================= PRODUCTION SERVER ================================
export const BASE_URL = "https://api.theanimalsapp.com:3000/"
// ========================================= PRODUCTION SERVER ================================

const ADMIN_PATH = "api/admin/";
const PHARMACY_PATH = "api/pharmacy/";
const PETSHOP_PATH = "api/petshop/";
const VACCINATOR_PATH = "api/vaccinator/admin/";

const ApiConfig = {
  SOCKET_URL: BASE_URL,
  LOGIN_ADMIN: BASE_URL + ADMIN_PATH + "login",
  FORGET_PASSWORD: BASE_URL + ADMIN_PATH + "forgot-password",
  VERIFY_OTP: BASE_URL + ADMIN_PATH + "verify-otp",
  RESET_PASSWORD: BASE_URL + ADMIN_PATH + "reset-password",

  //// Admin ///

  DASHBORD_CATEGORY: BASE_URL + ADMIN_PATH + "dashboard/categories",
  DASHBORD_REVENUE: BASE_URL + ADMIN_PATH + "dashboard/revenue",
  DASHBORD_USER: BASE_URL + ADMIN_PATH + "dashboard/users?month=",
  DASHBORD_TREANSACTION:
    BASE_URL + ADMIN_PATH + "dashboard/transactions?page=1",

  // DOCTOR'S MODULE API
  VERIFIED_DOCS_LIST: BASE_URL + ADMIN_PATH + "verifiedDoctList",
  GET_ALL_DOC_LIST: BASE_URL + ADMIN_PATH + "doctList",
  DOCTOR_STATUS: BASE_URL + ADMIN_PATH + "doctorStatus",
  DOCTOR_BASIC_INFORMATION: BASE_URL + ADMIN_PATH + "DoctorGetBasicInformation",
  DOCTOR_GENERAL_INFORMATION:
    BASE_URL + ADMIN_PATH + "DoctorGetGeneralInformation",
  GET_DOCTOR_DOCUMENTS: BASE_URL + ADMIN_PATH + "DoctorGetDocuments",
  DOCTOR_CLINIC_INFORMATION: BASE_URL + ADMIN_PATH + "DoctorGetClinic",
  DOCTOR_TEAMS: BASE_URL + ADMIN_PATH + "DoctorGetDoctorTeam",
  GET_DOCTORS_APPOINTMENT_LIST: BASE_URL + ADMIN_PATH + "allAppointmentList",
  GET_DOCTORS_TRANSACTION_LIST: BASE_URL + ADMIN_PATH + "allTransactionList",
  GET_DOCTOR_BANK_DETAILS: BASE_URL + ADMIN_PATH + "DoctorBankDetails",
  GET_ALL_DOCTOR_RATINGS: BASE_URL + ADMIN_PATH + "getAllRatings",
  GET_DOCTOR_SIGNATURE: BASE_URL + ADMIN_PATH + "DoctorGetSignature",
  DOCTOR_ACCEPT: BASE_URL + ADMIN_PATH + "acceptVerification",
  DOCTOR_REJECT: BASE_URL + ADMIN_PATH + "rejectVerification",
  GET_DOCTOR_IMAGEAND_DETAIL: BASE_URL + ADMIN_PATH + "profile",
  DOCTOR_PROFILE: BASE_URL + ADMIN_PATH + "profile",
  GET_REVENUE_GRAPH: BASE_URL + ADMIN_PATH + "revenueGraphData",
  GET_RATING_REVIEW: BASE_URL + ADMIN_PATH + "getFiveStarRating",
  // =============================== PHARMACY SECTION ==================================
  // ======== PHARMACY SHOP ========= //
  GET_PHARMACY_SHOP_LIST: BASE_URL + ADMIN_PATH + "pharmacyShops",
  GET_NEW_PHARMACY_SHOP_LIST: BASE_URL + ADMIN_PATH + "newPharmacyShops",
  APPROVE_AND_REJECT_SHOP: BASE_URL + ADMIN_PATH + "pharmacyShopVerify",
  DISABLE_PHARMACY_SHOP: BASE_URL + ADMIN_PATH + "pharmacyShopEnableDisable",
  REMOVE_PHARMACY_SHOP: BASE_URL + ADMIN_PATH + "pharmacyShopReaddRemove",
  PHARMACY_SHOP_ORDERS: BASE_URL + ADMIN_PATH + "pharmacyShopOrders",
  PHARMACY_SHOP_TRANSACTIONS:
    BASE_URL + ADMIN_PATH + "pharmacyShopTransactions",
  PHARMACY_SHOP_RATINGS_REVIEWS:
    BASE_URL + ADMIN_PATH + "pharmacyShopRatingsnreviews",
  PHARMACY_SHOP_PROFILE: BASE_URL + ADMIN_PATH + "pharmacyShopProfile",
  PHARMACY_SHOP_PAYMENTS: BASE_URL + ADMIN_PATH + "pharmacyShopPayments",
  ORDER_PRESCRIPTION: BASE_URL + ADMIN_PATH + "pharmacyOrderPrescriptions",
  ORDER_TRANSACTION_PRESCRIPTION: BASE_URL + ADMIN_PATH + "pharmacyBill",
  // FETCH_SHOP_INVETORY: BASE_URL + PHARMACY_PATH + "getProductsList",
  FETCH_SHOP_INVETORY:
    BASE_URL + ADMIN_PATH + "getPharmacyInventoryProductsList",
  // FETCH_SHOP_INVETORY_CATEGORIES: BASE_URL + PHARMACY_PATH + "getCategoryList",
  FETCH_SHOP_INVETORY_CATEGORIES:
    BASE_URL + ADMIN_PATH + "getPharmacyInventoryCategoryList",
  FETCH_PET_SHOP_INVENTORY:
    BASE_URL + ADMIN_PATH + "getPetShopInventoryProductsList",
  FETCH_PET_SHOP_INVETORY_CATEGORIES:
    BASE_URL + ADMIN_PATH + "getPetShopInventoryCategoryList",

  // ======== PHARMACY SHOP ========= //

  // ======== MASTER_INVENTRY =========//
  // PRODUCT TAB
  INVENTRY_PHARMACY_PRODUCT_LIST:
    BASE_URL + ADMIN_PATH + "products/productList",
  ADD_PHARMACY_PRODUCT: BASE_URL + ADMIN_PATH + "products/addProduct",
  GET_LOOKUP_DATA_PHARMACY: BASE_URL + ADMIN_PATH + "productLookUpData",
  GET_PRODUCT_DETAILS: BASE_URL + ADMIN_PATH + "products/product",
  UPDATE_PHARMACY_PRODUCT: BASE_URL + ADMIN_PATH + "products/editProduct",
  ADD_BRAND_NAME : BASE_URL + ADMIN_PATH + "products/addBrand",
  ADD_ANIMAL_TYPE : BASE_URL + ADMIN_PATH + "products/addAnimalType",
  // PRODUCT TAB

  // PRODUCT CATEGORIES TAB
  PHARMACY_PRODUCT_CATEGORY_LIST:
    BASE_URL + ADMIN_PATH + "products/categoriesList",
  ADD_PHARMACY_CATEGORY: BASE_URL + ADMIN_PATH + "products/addCategory",
  GET_CATEGORY_DETAILS: BASE_URL + ADMIN_PATH + "products/category",
  UPDATED_CATEGORY: BASE_URL + ADMIN_PATH + "products/editCategory",
  PRODUCT_DISABLED: BASE_URL + ADMIN_PATH + "products/disableProduct",
  // PRODUCT CATEGORIES TAB
  // ======== MASTER_INVENTRY =========//

  // =============================== PHARMACY SECTION ================================== //

  // =============================== PET/VET SECTION ================================== //
  // ===== SHOP ===== //
  PET_SHOP_LIST: BASE_URL + ADMIN_PATH + "petVetShops",
  PET_NEW_SHOP_LIST: BASE_URL + ADMIN_PATH + "newPetVetShops",
  PETVET_SHOP_VERIFY: BASE_URL + ADMIN_PATH + "petVetShopVerify",
  PET_VET_SHOP_PROFILE: BASE_URL + ADMIN_PATH + "petVetShopProfile",
  PET_VET_SHOP_ORDERS: BASE_URL + ADMIN_PATH + "petVetShopOrders",
  PET_VET_SHOP_TRANSACTIONS: BASE_URL + ADMIN_PATH + "petVetShopTransactions",
  PET_VET_SHOP_PAYMENTS: BASE_URL + ADMIN_PATH + "petVetShopPayments",
  PET_VET_SHOP_RATINGS: BASE_URL + ADMIN_PATH + "petVetShopRatingsnreviews",
  PET_VET_SHOP_DISABLE: BASE_URL + ADMIN_PATH + "petVetShopEnableDisable",
  PET_VET_SHOP_REJECT: BASE_URL + ADMIN_PATH + "petVetShopReaddRemove",
  FETCH_ORDER_DETAILS: BASE_URL + ADMIN_PATH + "petShopBill",
  // ===== SHOP ===== //
  // =============================== PET/VET SECTION ================================== //

  // =============================== AMBULANCE SECTION ================================ //
  FETCH_AMBULANCE_LIST: BASE_URL + ADMIN_PATH + "accepted-ambulance-list",
  DISABLE_AMBULANCE: BASE_URL + ADMIN_PATH + "disable-ambulance",
  REMOVE_AMBULANCE: BASE_URL + ADMIN_PATH + "remove-ambulance",
  FETCH_NEW_AMBULANVE_LIST: BASE_URL + ADMIN_PATH + "new-ambulance-list",
  ADD_AMBULANCE: BASE_URL + ADMIN_PATH + "add-ambulance",
  AMBULANCE_DETAILS: BASE_URL + ADMIN_PATH + "single-ambulance",
  PAYMENT_DETAILS: BASE_URL + ADMIN_PATH + "payment-details",
  FETCH_REQUEST_LIST: BASE_URL + ADMIN_PATH + "req-list",
  TRANSACTION_LIST: BASE_URL + ADMIN_PATH + "transaction-details",
  RATING_AND_RAVIEWS: BASE_URL + ADMIN_PATH + "ambulance_review",
  // http://localhost:5000/api/admin/ambulance_review?ambulance_id=4
  // =============================== AMBULANCE SECTION ================================ //

  //================================ ADMIN SECTION ================================= //
  CITY_ADMIN: BASE_URL + ADMIN_PATH + "AdminList?admin_role_id=",
  STATE_ADMIN: BASE_URL + ADMIN_PATH + "AdminList?admin_role_id=",
  ADMIN_SHOP_DETAILS: BASE_URL + ADMIN_PATH + "adminShopsDetails",
  //================================ ADMIN SECTION ================================= //
  //================================ REPORTS SECTION ================================= //
  GET_REPORTS_DOCTOR_lIST:
    BASE_URL + ADMIN_PATH + "reports/revenue/doctor-list",
  GET_APPOINTMENT_DOC_LIST:
    BASE_URL + ADMIN_PATH + "reports/revenue/doctor-appointments-list",
  GET_SALES_REPORT_LIST:
    BASE_URL + ADMIN_PATH + "reports/revenue/sales-report/shop-list",
  GET_SINGLE_SHOP_LIST:
    BASE_URL + ADMIN_PATH + "reports/revenue/sales-report/order-list",
  GET_NEW_JOINED_DETAILS: BASE_URL + ADMIN_PATH + "reports/users/new-joined",

  //================================ dELIVERY bOY SECTION ================================= //
  GET_DELIVERY_LIST: BASE_URL + ADMIN_PATH + "verified-delivery-Boys",
  GET_DELIVERYBOY_DETAILS_BY_ID: BASE_URL + ADMIN_PATH + "deliveryBoyProfile/",
  GET_DELIVERYBOY_REQUEST_DET: BASE_URL + ADMIN_PATH + "deliveryRequestList",
  GET_DELIVERYBOY_TRANSACTION_DET:
    BASE_URL + ADMIN_PATH + "deliveryBoyTransactions",
  GET_DELIVERYBOY_MONTHLY_PAYMENT: BASE_URL + ADMIN_PATH + "payment-details",
  GET_DELIVERYBOY_RATINGS: BASE_URL + ADMIN_PATH + "deliveryBoyRatings",
  DISABLE_DELIVERY_BOY: BASE_URL + ADMIN_PATH + "disable-delivery-boy",
  REMOVE_DELIVERY_BOY: BASE_URL + ADMIN_PATH + "remove-delivery-boy",
  NEW_DELIVERY_BOY_LIST: BASE_URL + ADMIN_PATH + "deliveryBoys",
  GET_DELIVERY_CREDENTIALS_BY_ID: BASE_URL + ADMIN_PATH + "getDeliveryBoy",
  UPDATE_STATUS_OF_DELIVERY_BOY: BASE_URL + ADMIN_PATH + "verifyDeliveryBoy",
  //================================= Delivery Boy ==========================================//

  //================================ Notification ============================//
  NOTIFICATION: BASE_URL + "api/pharmacy/getNotification/",

  //================================ Subscription ============================//

  GET_SUBSCRIPTION_PLAN_LIST: BASE_URL + ADMIN_PATH + "subscriptions", //?entity_id=1
  GET_ENTITIES_DROPDOWN: BASE_URL + ADMIN_PATH + "subscriptionEntities",
  DELETE_SUBSCRIPTION: BASE_URL + ADMIN_PATH + "deleteSubscription",
  GET_SUBSCRIPTIONBY_ID: BASE_URL + ADMIN_PATH + "editSubscription",
  UPDATE_SUBSCRIPTIONBY_ID: BASE_URL + ADMIN_PATH + "updateSubscription",
  ADD_SUBSCRIPTION_DATA: BASE_URL + ADMIN_PATH + "addSubscription",
  REQUEST_SUBSCRIPTION_LIST: BASE_URL + ADMIN_PATH + "subscriptionRequestList",
  CHANGE_SUBSCRIPOTION_STATUS: BASE_URL + ADMIN_PATH + "subscriptionStatus",
  ACTIVE_SUBSCRIPTION_LIST: BASE_URL + ADMIN_PATH + "subscriptionActiveList",

  // Discount APIS
  GET_DISCOUNT_LIST: BASE_URL + ADMIN_PATH + "discounts",
  GET_DISCOUNTS_BY_ID: BASE_URL + ADMIN_PATH + "edit_discount",
  ADD_DISCOUNT: BASE_URL + ADMIN_PATH + "add_discount",
  UPDATE_DISCOUNTBY_ID: BASE_URL + ADMIN_PATH + "update_discount",
  DELETE_DISCOUNT_BY_ID: BASE_URL + ADMIN_PATH + "delete_discount",

  // ============================ WITHDRAWAL LIST ===============================
  FETCH_WITHDRAWAL_LIST: BASE_URL + ADMIN_PATH + "withdrawPaymentList",
  ACCEPT_WITHDRAW_REQUEST: BASE_URL + ADMIN_PATH + "acceptWithdrawalRequest",
  // ============================ WITHDRAWAL LIST ===============================

  // ============================ VACCINATORS LIST =============================== //
  FETCH_VACCINATOR_LIST: BASE_URL + VACCINATOR_PATH + "vaccinators",
  FETCH_NEW_VACCINATOR_LIST: BASE_URL + VACCINATOR_PATH + "newVaccinators",
  NEW_VACCINATOR_ACCEPT_REJECT: BASE_URL + VACCINATOR_PATH + "vaccinatorVerify",

  VACCINATOR_ORDERS: BASE_URL + VACCINATOR_PATH + "vaccinatorOrders",
  VACCINATOR_TRANSACTION: BASE_URL + VACCINATOR_PATH + "vaccinatorTransactions",
  VACCINATOR_PAYMENT: BASE_URL + VACCINATOR_PATH + "vaccinatorPayments",
  VACCINATOR_RATINGS_AND_RAVIEWS:
    BASE_URL + VACCINATOR_PATH + "vaccinatorRatingsnreviews",
  VACCINATOR_VACCINATOR_PROFILE:
    BASE_URL + VACCINATOR_PATH + "vaccinatorProfile",
  VACCINATOR_ENABLE_DISABLE:
    BASE_URL + VACCINATOR_PATH + "vaccinatorEnableDisable",
  REMOVE_VACCINATOR: BASE_URL + VACCINATOR_PATH + "vaccinatorReaddRemove",
  FETCH_PRESCRIPTION_DATA: BASE_URL + VACCINATOR_PATH + "vaccinationSheet",
  // /api/vaccinator/admin/vaccinationSheet?vaccApptmtId=21
  // /api/vaccinator/admin/vaccinatorReaddRemove
  // /api/vaccinator/admin/vaccinatorOrders?vaccinatorId=2
  // /api/vaccinator/admin/vaccinatorTransactions?vaccinatorId=2
  // /api/vaccinator/admin/vaccinatorPayments?vaccinatorId=2
  // /api/vaccinator/admin/vaccinatorRatingsnreviews?vaccinatorId=2
  // /api/vaccinator/admin/vaccinationSheet?vaccApptmtId=21
  // /api/vaccinator/admin/vaccinatorProfile?vaccinatorId=2
  // ============================ VACCINATORS LIST =============================== //
};

export default ApiConfig;
