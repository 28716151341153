import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import Accept from "../../assets/images/icons/accept.svg";
import circleX from "../../assets/images/icons/circleX.svg";
import { Link } from "react-router-dom";
import { simpleGetCall, simplePostAuthCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";
import Loader from "../../sharedComponent/Loader";

const SubscriptionRequest = () => {
  const { sidebar, setSidebar } = useContext(AppContext);
  const [requestedData, setRequestedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reasonStr, setReasonStr] = useState("");
  const [isReject, setIsReject] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const getRequestedList = () => {
    setLoading(true);
    simpleGetCall(ApiConfig.REQUEST_SUBSCRIPTION_LIST)
      .then((res) => {
        if (res.success) {
          setRequestedData(res?.data);
          setLoading(false);
        } else {
          setRequestedData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptSubs = (id) => {
    let payload = {
      subscription_history_id: id,
      reason: "",
      status: 19,
    };
    simplePostAuthCall(
      ApiConfig.CHANGE_SUBSCRIPOTION_STATUS,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (res.message) {
          notifySuccess(res?.message);
          getRequestedList();
        } else {
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const rejectSubs = (id) => {
    let payload = {
      subscription_history_id: id,
      reason: reasonStr,
      status: 20,
    };
    simplePostAuthCall(
      ApiConfig.CHANGE_SUBSCRIPOTION_STATUS,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (res.success) {
          setIsReject(false);
          notifySuccess(res?.message);
          getRequestedList();
        } else {
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRejectClick = (index) => {
    setIsReject(true);
    setSelectedRowIndex(index);
  };
  useEffect(() => {
    getRequestedList();
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="mainSubsPlanTab">
          <div className="row">
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="col-md-12">
                  <div className="innerSearchItem">
                    <div className="leftFilterTop">
                      <div className="topSearch">
                        <input
                          type="text"
                          name="searchTxt"
                          autoFocus
                          // value={searchTxt}
                          // onChange={(e) => setSearchTxt(e.target.value)}
                          className="form-control searchDoc"
                          placeholder="Search"
                        />
                        <img src={SearchIcon} className="searchInner" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="table-wrapper fixedHeight">
                    <table className="defaultTable">
                      <thead>
                        <tr>
                          <th>Sr No. </th>
                          <th>Request Id</th>
                          <th>Name</th>
                          <th>Email Id</th>
                          <th>Plan Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requestedData && requestedData.length > 0 ? (
                          requestedData.map((data, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{data?.subscription_history_id}</td>
                                <td>{data?.user_details?.user_name}</td>
                                <td>{data?.user_details?.user_email}</td>
                                <td>{data?.subscription_name}</td>
                                <td className="condtionalTd">
                                  {isReject && selectedRowIndex === index ? (
                                    <div className="boxHolder">
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                          setReasonStr(e.target.value)
                                        }
                                      />
                                      <span
                                        className="roundedImg"
                                        onClick={() =>
                                          rejectSubs(
                                            data?.subscription_history_id
                                          )
                                        }
                                      >
                                        <img
                                          src={Accept}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      <img
                                        src={Accept}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          acceptSubs(
                                            data?.subscription_history_id
                                          )
                                        }
                                        className="me-2"
                                        height="20"
                                        width="20"
                                        alt="check"
                                        title="Accept Request"
                                      />
                                      <img
                                        src={circleX}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleRejectClick(index)}
                                        alt=""
                                        title="Reject Request"
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <p className="text-center p-4 text-danger h-2">
                                No Subscription Requests
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* <div className="pageData">
                              <label>Showing Page {page} - {totalData}</label>
                            </div> */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SubscriptionRequest;
