import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import NothingToShow from "../../assets/images/ReportIcons/NothingToShow.svg"
import { motion } from "framer-motion";
const OtherReports = () => {
    const { sidebar, setSidebar } = useContext(AppContext);
    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };
    return (
        <>
            <motion.div
                variants={aninations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
                className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
                id="cx-main"
            >
                <div className="Other-wrapper">
                    <img src={NothingToShow} alt="img" />
                </div>
            </motion.div>
        </>
    )
}

export default OtherReports;
