import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import AmbulanceImg from "../../assets/images/icons/AmbulanceImg.svg";
import Star from "../../assets/images/icons/ratingStar.svg";
import Clock from "../../assets/images/icons/clock.svg";
import { Link } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";

const Ambulances = () => {
  const { sidebar, setSidebar } = useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState({ last_page: false });
  const [ambulanceList, setAmbulanceList] = useState([]);
  const [filterData, setFilterData] = useState({
    search: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // STATES
  useEffect(() => {
    fetchShopList();
  }, [filterData]);

  const fetchShopList = async () => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.FETCH_AMBULANCE_LIST}?page=${currentPage}&ambulance_driver_name=${filterData?.search}`
      );
      const { success, data, last_page } = res?.json;
      if (success) {
        data && setAmbulanceList(data);
        setCurrentPageData({ last_page });
      } else {
        setAmbulanceList([]);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchMoreShopList();
    }
  }, [currentPage]);

  const [isMoreLoader, setIsMoreLoader] = useState(false);

  const fetchMoreShopList = async () => {
    try {
      setIsMoreLoader(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.FETCH_AMBULANCE_LIST}?page=${currentPage}&ambulance_driver_name=${filterData?.search}`
      );
      console.log("res,", res);
      const { success, data, last_page } = res?.json;
      if (success) {
        data && setAmbulanceList(data);
        !last_page && setCurrentPage(currentPage + 1);
      } else {
        setAmbulanceList([]);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsMoreLoader(false);
    }
  };

  const handleInfiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (!currentPageData.last_page) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="pharmacyShop-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="innerSearchItem">
                <div className="leftFilterTop">
                  <div className="topSearch">
                    <input
                      type="text"
                      className="form-control searchDoc"
                      placeholder="Search by Name, Specialist, Ratings, Visits..."
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          search: e.target.value,
                        });
                      }}
                    />
                    <img src={SearchIcon} className="searchInner" alt="" />
                  </div>
                  <div className="topFilter">
                    <img src={FilterIcon} className="filterInner" alt="" />
                  </div>
                </div>
                <Link to="/AddAmbulance" className="routButton">
                  <button className="innerBtn">Add Ambulance</button>
                </Link>
              </div>
            </div>
            {/* Cards Content */}
            <div className="slideBelow row p-0 m-0">
              {isLoading ? (
                <RotatingLoader />
              ) : ambulanceList && ambulanceList.length > 0 ? (
                ambulanceList.map((item, idx) => {
                  return (
                    <div className="col-lg-3 cardsResponsive">
                      <div className="doctorsDetailsCard">
                        <Link
                          to={`/AmbulanceDetails/${item.ambulance_driver_id}`}
                          onClick={() => {
                            sessionStorage.setItem(
                              "ambulance_id",
                              item?.ambulance_id
                            );
                          }}
                        >
                          <img
                            src={
                              item.vehicle_image_path
                                ? item.vehicle_image_path
                                : AmbulanceImg
                            }
                            className="docInsideImg"
                            alt=""
                          />
                        </Link>
                        <div iv className="medicalbelowDetails">
                          <label className="headTxt">
                            {item?.ambulance_driver_name}
                          </label>
                          <div className="starFlex">
                            <img src={Star} className="star" alt="" />
                          </div>
                          <label className="numTxt">4.3</label>
                        </div>
                        <div className="clockDet">
                          <img src={Clock} className="clock" alt="" />
                          <label htmlFor="" className="timeDet">
                            24 X 7 Available
                          </label>
                        </div>
                        {/* is_disabled */}
                        {item?.is_disabled && (
                          <div className="disable_img">
                            <img src={disabled} />{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoDataMsg />
              )}
              {isMoreLoader && <RotatingLoader />}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Ambulances;
