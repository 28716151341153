import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import SearchIcon from '../../assets/images/icons/SearchIcon.svg'
import FilterIcon from '../../assets/images/icons/Filter.svg'
import { motion } from 'framer-motion'
import AmbulanceImg from '../../assets/images/icons/AmbulanceImg.svg'
import { Link } from 'react-router-dom'
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { notifySuccess } from '../../sharedComponent/Notify'
import RotatingLoader from '../../sharedComponent/RotatingLoader'

const AddAmbulance = () => {
  const { sidebar, setBkdropLdr, navigate } = useContext(AppContext)

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }

  // http://localhost:5000/api/admin/new-ambulance-list?page=1&ambulance_driver_name=
  // FETCH_NEW_AMBULANVE_LIST
  // STATES
  const [ambulanceList, setAmbulanceList] = useState([])
  const [filterData, setFilterData] = useState({
    page: 1,
    search: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  // STATES
  useEffect(() => {
    fetchShopList()
  }, [filterData])

  const fetchShopList = async () => {
    try {
      setIsLoading(true)
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.FETCH_NEW_AMBULANVE_LIST}?page=${filterData?.page}&ambulance_driver_name=${filterData?.search}`
      )
      const { success, data } = res?.json
      if (success) {
        data && setAmbulanceList(data)
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setIsLoading(false)
    }
  }

  const acceptAndReject = async (ambulance_driver_id, isVarify) => {
    try {
      setBkdropLdr(true)
      const payLoad = {
        ambulance_driver_id: ambulance_driver_id,
        is_verified: isVarify
      }
      const res = await PostCallWithErrorResponse(
        ApiConfig.ADD_AMBULANCE,
        payLoad
      )
      const { success } = res?.json
      if (success) {
        notifySuccess(
          isVarify
            ? 'Ambulance accepted successfully'
            : 'Ambulance rejected successfully'
        )
        fetchShopList()
        navigate('/Ambulances')
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  return (
    <>
      <motion.div
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
        id='cx-main'
      >
        <div className='adddoctor-wrapper'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='innerSearchItem'>
                <div className='leftFilterTop'>
                  <div className='topSearch'>
                    <input
                      type='text'
                      className='form-control searchDoc'
                      placeholder='Search by Name, Specialist, Ratings, Visits...'
                      onChange={e => {
                        setFilterData({
                          ...filterData,
                          search: e.target.value
                        })
                      }}
                    />
                    <img src={SearchIcon} className='searchInner' alt='' />
                  </div>
                  <div className='topFilter'>
                    <img src={FilterIcon} className='filterInner' alt='' />
                  </div>
                </div>
              </div>
            </div>
            {/* Cards Content */}
            {isLoading ? (
              <RotatingLoader />
            ) : (
              <div className='slideBelow row p-0 m-0'>
                {ambulanceList.map((item, idx) => {
                  return (
                    <div
                      className='col-lg-3 AddcardsResponsive'
                      key={'list' + idx}
                    >
                      <div className='cardLink'>
                        <div className='doctorsDetailsCard'>
                          <Link
                            to={`/ViewAmbulance/${item.ambulance_driver_id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <img
                              src={
                                item?.vehicle_image_path
                                  ? item?.vehicle_image_path
                                  : AmbulanceImg
                              }
                              className='docInsideImg'
                              alt='ambulance im'
                            />
                            <div className='docDetailsTxt'>
                              <h1>{item?.ambulance_driver_name}</h1>
                              <label style={{ visibility: 'hidden' }}>
                                Orthopedic
                              </label>
                            </div>
                          </Link>

                          <div className='innerButton'>
                            <button
                              className='rejectBtn'
                              type='button'
                              onClick={() => {
                                acceptAndReject(item.ambulance_driver_id, false)
                              }}
                            >
                              Reject
                            </button>
                            <button
                              className='AcceptBtn'
                              type='button'
                              onClick={() => {
                                acceptAndReject(item.ambulance_driver_id, true)
                              }}
                            >
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default AddAmbulance
