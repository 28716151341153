import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import percentage from "../../assets/images/icons/percentage.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { simpleGetCall, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import { notifySuccess } from "../../sharedComponent/Notify";
import moment from "moment";

const EditDiscount = () => {
    const { sidebar, setSidebar } = useContext(AppContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [discountIdData, setDiscountIdData] = useState({
        coupon_name: "",
        coupon_discount_per: "",
        coupon_use_quantity: null,
        min_amount: null,
        max_amount: null,
        coupon_valid_from: "",
        coupon_valid_upto: "",
        is_public: false,
        coupon_code: "",
        coupon_id: id
    });
    const [loading, setLoading] = useState(false);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        updateData(event);
    };
    const changeEve = (e) => {
        setDiscountIdData({
            ...discountIdData,
            [e.target.name]: e.target.value
        })
    };

    const updateData = (event) => {
        event.preventDefault();
        simplePostCall(ApiConfig.UPDATE_DISCOUNTBY_ID, JSON.stringify(discountIdData))
            .then((res) => {
                if (res.success) {
                    notifySuccess(res?.message);
                    navigate("/DiscountList")
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getDiscountList = () => {
        setLoading(true)
        simpleGetCall(ApiConfig.GET_DISCOUNTS_BY_ID + `?coupon_id=${id}`)
            .then((res) => {
                if (res.success) {
                    setLoading(false);
                    setDiscountIdData({
                        ...discountIdData,
                        coupon_name: res?.data?.coupon_name,
                        coupon_discount_per: res?.data?.coupon_discount_per,
                        coupon_use_quantity: res?.data?.coupon_use_quantity,
                        min_amount: res?.data?.min_amount,
                        max_amount: res?.data?.max_amount,
                        coupon_valid_from: res?.data?.coupon_valid_from,
                        coupon_valid_upto: res?.data?.coupon_valid_upto,
                        is_public: res?.data?.is_public,
                        coupon_code: res?.data?.coupon_code,
                    });
                } else {
                    setDiscountIdData({});
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        getDiscountList();
    }, [])

    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };
    return (
        <>
            <motion.div
                variants={aninations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
                className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
                id="cx-main">
                <div className="addEditMain">
                    {
                        loading ? <Loader />
                            :
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="row cardRow">
                                    <div className="col-md-8">
                                        <div className="discountCard">
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <Form.Group controlId="validationCustom01">
                                                        <Form.Label>Coupon Name</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            name="coupon_name"
                                                            onChange={changeEve}
                                                            value={discountIdData?.coupon_name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Coupon name is required</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group controlId="validationCustom01">
                                                        <Form.Label>Coupon Quantity</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            name="coupon_use_quantity"
                                                            onChange={changeEve}
                                                            value={discountIdData?.coupon_use_quantity}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Coupon quantity is required</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group controlId="validationCustom01">
                                                        <Form.Label>Coupon Percentage</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            name="coupon_discount_per"
                                                            onChange={changeEve}
                                                            value={discountIdData?.coupon_discount_per}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Coupon percentage is required</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group controlId="validationCustom01">
                                                        <Form.Label>Minimum Amount</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            name="min_amount"
                                                            onChange={changeEve}
                                                            value={discountIdData?.min_amount}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Minimum Amount required</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group controlId="validationCustom01">
                                                        <Form.Label>Maxmimum Amount</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            name="max_amount"
                                                            onChange={changeEve}
                                                            value={discountIdData?.max_amount}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Maxmimum Amount required</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group controlId="validationCustom01">
                                                        <Form.Label>Coupon Valid From</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="date"
                                                            name="coupon_valid_from"
                                                            onChange={changeEve}
                                                            value={moment(discountIdData?.coupon_valid_from).format('YYYY-MM-DD')}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Date is required</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group controlId="validationCustom01">
                                                        <Form.Label>Coupon Valid Upto</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="date"
                                                            name="coupon_valid_upto"
                                                            onChange={changeEve}
                                                            value={moment(discountIdData?.coupon_valid_upto).format('YYYY-MM-DD')}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Date is required</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <Form.Group controlId="validationCustom01">
                                                        <Form.Label>Coupon Code</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            name="coupon_code"
                                                            onChange={changeEve}
                                                            value={discountIdData?.coupon_code}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Coupon Code</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form.Group className="mb-3 d-flex">
                                                        <Form.Check
                                                            required
                                                            id="checkHere"
                                                            feedbackType="invalid"
                                                            className="formCheck"
                                                            checked={discountIdData?.is_public}
                                                            onClick={() => setDiscountIdData({ ...discountIdData, is_public: !discountIdData?.is_public })}
                                                        />
                                                        <label htmlFor="checkHere" className="checkHere">Is Public</label>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12 text-end">
                                                    <input type="submit" value="Update Discount" className="submitBtn" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                    }
                </div>
            </motion.div>
        </>
    )
}

export default EditDiscount;
