import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import searchIcon from "../assets/images/headerIcons/SearchIcon.svg";
import roundCircle from "../assets/images/headerIcons/headerCircle.svg";
import moon from "../assets/images/headerIcons/moon.svg";
import bell from "../assets/images/headerIcons/bell.svg";
import { simpleGetCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";

const Header = () => {
  const navigate = useNavigate();
  const { sidebar, setSidebar, adminDetails, setAdminDetails } =
    useContext(AppContext);
  // console.log("adminDetails", adminDetails)
  const [clicked, setClicked] = useState(false);
  const [notificationList, setnoticationList] = useState([]);
  const location = useLocation();
  const currentRoute = location.pathname;

  const getNotification = () => {
    simpleGetCall(ApiConfig.NOTIFICATION + "adm_" + adminDetails?.admin_id)
      .then((res) => {
        if (res.success === true) {
          setnoticationList(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <>
      <main className="mainHeader">
        <header className="top-header">
          <div className="d-flex flex-column bothHeaders">
            {currentRoute === "/Dashboard" ||
            currentRoute === "/Notification" ? null : (
              <div className="breadCrumbsTop">
                <label className={sidebar ? "leftHeadBread" : "leftSideBread"}>
                  {
                    // Doctor Module Routes
                    (currentRoute === "/Doctors" && "Doctors") ||
                      (currentRoute.includes("DoctorDetails") && (
                        <>
                          {" "}
                          <Link to="/Doctors">Doctor /</Link> Doctor Details{" "}
                        </>
                      )) ||
                      (currentRoute === "/ViewDoctorDetails" && (
                        <>
                          {" "}
                          <Link to="/Doctors">Doctor /</Link>{" "}
                          <Link to="/AddDoctor">Add Doctor /</Link> View Doctor
                          Details{" "}
                        </>
                      )) ||
                      (currentRoute === "/AddDoctor" && (
                        <>
                          {" "}
                          <Link to="/Doctors">Doctor /</Link> Add Doctor's{" "}
                        </>
                      )) ||
                      // Pharmacy Module Routes
                      (currentRoute === "/pharmacyShops" && "Pharmacy") ||
                      (currentRoute === "/PharmacyShopDetails" && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link> Shop
                          Details{" "}
                        </>
                      )) ||
                      (currentRoute.includes("ShopsInventory") && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link> Gayatri
                          Medicals{" "}
                        </>
                      )) ||
                      (currentRoute === "/AddPharmacy" && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link> Add
                          Pharmacy Shop{" "}
                        </>
                      )) ||
                      (currentRoute === "/ViewShopDetails" && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link>{" "}
                          <Link to="/AddPharmacy"> Add Pharmacy Shop /</Link>{" "}
                          Shop Details
                        </>
                      )) ||
                      (currentRoute === "/MasterInventory" && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link> Master
                          Inventory{" "}
                        </>
                      )) ||
                      (currentRoute === "/AddProduct" && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link>{" "}
                          <Link to="/MasterInventory">Master Inventory /</Link>{" "}
                          Add Product
                        </>
                      )) ||
                      (currentRoute.includes("EditProduct") && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link>{" "}
                          <Link to="/MasterInventory">Master Inventory /</Link>{" "}
                          Edit Product
                        </>
                      )) ||
                      (currentRoute === "/AddCategory" && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link>{" "}
                          <Link to="/MasterInventory">Master Inventory /</Link>{" "}
                          Add Category
                        </>
                      )) ||
                      (currentRoute.includes("EditCategory") && (
                        <>
                          {" "}
                          <Link to="/pharmacyShops">Pharmacy /</Link>{" "}
                          <Link to="/MasterInventory">Master Inventory /</Link>{" "}
                          Edit Category
                        </>
                      )) ||
                      // Pharmacy Module Routes
                      (currentRoute === "/vetPetShops" && "Vet / Pet Shop's") ||
                      (currentRoute === "/PetShopDetails" && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link> Shop
                          Details{" "}
                        </>
                      )) ||
                      (currentRoute.includes("PetShopInventory") && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link> Shop
                          Details{" "}
                        </>
                      )) ||
                      (currentRoute === "/vetPetInventory" && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link> Master
                          Inventory{" "}
                        </>
                      )) ||
                      (currentRoute === "/AddPetShops" && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link> Add
                          Pet Shop{" "}
                        </>
                      )) ||
                      (currentRoute === "/ViewPetShops" && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link>{" "}
                          <Link to="/AddPetShops">Add Pet Shop /</Link> Shop
                          Details
                        </>
                      )) ||
                      (currentRoute === "/AddPetProduct" && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link>{" "}
                          <Link to="/vetPetInventory">Master Inventory /</Link>{" "}
                          Add Product
                        </>
                      )) ||
                      (currentRoute.includes("EditPetProduct") && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link>{" "}
                          <Link to="/vetPetInventory">Master Inventory /</Link>{" "}
                          Edit Product
                        </>
                      )) ||
                      (currentRoute === "/AddPetCategory" && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link>{" "}
                          <Link to="/vetPetInventory">Master Inventory /</Link>{" "}
                          Add Category
                        </>
                      )) ||
                      (currentRoute === "/EditPetCategory" && (
                        <>
                          {" "}
                          <Link to="/vetPetShops">Vet-Pet Shop's /</Link>{" "}
                          <Link to="/vetPetInventory">Master Inventory /</Link>{" "}
                          Edit Category
                        </>
                      )) ||
                      // Vaccinators Module Routes
                      (currentRoute === "/Vaccinators" && "Vaccinator’s") ||
                      (currentRoute === "/VaccinatorDetails" && (
                        <>
                          {" "}
                          <Link to="/Vaccinators">Vaccinator’s /</Link>{" "}
                          Vaccinator Details
                        </>
                      )) ||
                      (currentRoute === "/AddVacinators" && (
                        <>
                          {" "}
                          <Link to="/Vaccinators">Vaccinator’s /</Link> Add
                          Vaccinator
                        </>
                      )) ||
                      (currentRoute === "/ViewVaccinators" && (
                        <>
                          {" "}
                          <Link to="/Vaccinators">Vaccinator’s /</Link>{" "}
                          <Link to="/AddVacinators">Add Vaccinator /</Link>{" "}
                          Vaccinator Details
                        </>
                      )) ||
                      // Ambulance module Routes
                      (currentRoute === "/Ambulances" && "Ambulance") ||
                      (currentRoute.includes("AmbulanceDetails") && (
                        <>
                          {" "}
                          <Link to="/Ambulances">Ambulance /</Link> Ambulance
                          Details
                        </>
                      )) ||
                      (currentRoute === "/AddAmbulance" && (
                        <>
                          {" "}
                          <Link to="/Ambulances">Ambulance /</Link> Add
                          Ambulance
                        </>
                      )) ||
                      (currentRoute.includes("ViewAmbulance") && (
                        <>
                          {" "}
                          <Link to="/Ambulances">Ambulance /</Link>{" "}
                          <Link to="/AddAmbulance">Add Ambulance /</Link> View
                          Ambulance Details
                        </>
                      )) ||
                      // Delivery Boy module Routes
                      (currentRoute === "/DeliveryBoy" && "Delivery Boy") ||
                      (currentRoute.includes("DeliveryBoyDetails") && (
                        <>
                          {" "}
                          <Link to="/DeliveryBoy">Delivery Boy /</Link> Delivery
                          Boy Details
                        </>
                      )) ||
                      (currentRoute === "/AddDeliveryBoy" && (
                        <>
                          {" "}
                          <Link to="/DeliveryBoy">Delivery Boy /</Link> Add
                          Ambulance
                        </>
                      )) ||
                      (currentRoute === "/ViewDeilveryBoy" && (
                        <>
                          {" "}
                          <Link to="/DeliveryBoy">Delivery Boy /</Link>{" "}
                          <Link to="/AddDeliveryBoy">Add Delivery Boy /</Link>{" "}
                          View Delivery Boy Details
                        </>
                      )) ||
                      //Report
                      (currentRoute === "/RevenueReports" && (
                        <>
                          {" "}
                          <Link to="/RevenueReports">Report’s / </Link> Revenue
                          Report{" "}
                        </>
                      )) ||
                      (currentRoute === "/OtherReports" && (
                        <>
                          {" "}
                          <Link to="/OtherReports">Report’s / </Link> Other
                          Report{" "}
                        </>
                      )) ||
                      (currentRoute === "/UsersReports" && (
                        <>
                          {" "}
                          <Link to="/UsersReports">Report’s / </Link> User's
                          Report{" "}
                        </>
                      )) ||
                      //user
                      (currentRoute === "/StateAdmin" && (
                        <>
                          {" "}
                          <Link to="/StateAdmin">Admin / </Link>State Admin{" "}
                        </>
                      )) ||
                      (currentRoute === "/DistrictAdmin" && (
                        <>
                          {" "}
                          <Link to="/DistrictAdmin">Admin / </Link>District
                          Admin{" "}
                        </>
                      )) ||
                      (currentRoute.includes("withdrawlist") && (
                        <>
                          <Link >Withdraw Management </Link>
                        </>
                      )) ||
                      (currentRoute === "/SubscriptionPlan" &&
                        "Subscription Plan") ||
                      (currentRoute === "/AddSubscription" &&
                        "Add Subscription") ||
                      (currentRoute === "/EditSubscription" &&
                        "Edit Subscription") ||
                      (currentRoute === "/DiscountList" && "Discount List") ||
                      (currentRoute === "/SubscriptionRequest" &&
                        "Subscription Request") ||
                      (currentRoute === "/AddDiscount" && (
                        <>
                          {" "}
                          <Link to="/DiscountList">Discount List /</Link>{" "}
                          <span>Add Discount</span>{" "}
                        </>
                      )) ||
                      (currentRoute === "/AddDiscount" && "Add Discount") ||
                      (currentRoute.includes("/EditDiscount") && (
                        <>
                          {" "}
                          <Link to="/DiscountList">Discount List /</Link> Edit
                          Discount
                        </>
                      ))
                  }
                </label>
              </div>
            )}
            <div className="headingBreads">
              <label className={sidebar ? "leftHeadTxt" : "leftSideCloseBar"}>
                {
                  // Dashboard Module Routes
                  (currentRoute === "/Dashboard" && "Dashboard") ||
                    // Doctor Module Routes
                    (currentRoute === "/Doctors" && "Doctors") ||
                    (currentRoute.includes("DoctorDetails") &&
                      "Doctor Details") ||
                    (currentRoute === "/ViewDoctorDetails" &&
                      "Doctor Details") ||
                    (currentRoute === "/Notification" && "Notification's") ||
                    (currentRoute === "/AddDoctor" && "Add Doctor") ||
                    // Pharmacy Module Routes
                    (currentRoute === "/pharmacyShops" && "Pharmacy") ||
                    (currentRoute === "/PharmacyShopDetails" &&
                      "Shop Details") ||
                    (currentRoute.includes("ShopsInventory") && "Inventory") ||
                    (currentRoute === "/AddPharmacy" && "Pharmacy Shop’s") ||
                    (currentRoute === "/ViewShopDetails" && "Shop Details") ||
                    (currentRoute === "/AddPharmacy" && "Pharmacy Shop’s") ||
                    (currentRoute === "/MasterInventory" &&
                      "Master Inventory") ||
                    (currentRoute === "/AddProduct" && "Add Product") ||
                    (currentRoute.includes("EditProduct") && "Edit Product") ||
                    (currentRoute === "/AddCategory" && "Add Category") ||
                    (currentRoute.includes("EditCategory") &&
                      "Edit Category") ||
                    // Vet / Pet shop Module Routes
                    (currentRoute === "/vetPetShops" && "Pet Shop's") ||
                    (currentRoute === "/PetShopDetails" && "Shop Details") ||
                    (currentRoute.includes("PetShopInventory") &&
                      "Inventory") ||
                    (currentRoute === "/AddPetShops" && "Pet Shop's") ||
                    (currentRoute === "/ViewPetShops" && "Shop Details") ||
                    (currentRoute === "/vetPetInventory" &&
                      "Master Inventory") ||
                    (currentRoute === "/AddPetProduct" && "Add Product") ||
                    (currentRoute.includes("EditPetProduct") &&
                      "Edit Product") ||
                    (currentRoute === "/AddPetCategory" && "Add Category") ||
                    (currentRoute === "/EditPetCategory" && "Edit Category") ||
                    // Vaccinators Module Routes
                    (currentRoute === "/Vaccinators" && "Vaccinator’s") ||
                    (currentRoute === "/VaccinatorDetails" &&
                      "Vaccinator Details") ||
                    (currentRoute === "/AddVacinators" && "Add Vaccinator’s") ||
                    (currentRoute === "/ViewVaccinators" &&
                      "Vaccinator Details") ||
                    // Ambulance module Routes
                    (currentRoute === "/Ambulances" && "Ambulance") ||
                    (currentRoute.includes("AmbulanceDetails") &&
                      "Ambulance Details") ||
                    (currentRoute === "/AddAmbulance" && "Add Ambulance") ||
                    (currentRoute.includes("ViewAmbulance") &&
                      "View Ambulance") ||
                    // Delivery bot module Routes
                    (currentRoute === "/DeliveryBoy" && "Delivery Boy") ||
                    (currentRoute.includes("DeliveryBoyDetails") &&
                      "Delivery Boy Details") ||
                    (currentRoute === "/AddDeliveryBoy" &&
                      "Add Delivery Boy") ||
                    (currentRoute === "/ViewDeilveryBoy" &&
                      "View Delivery Boy Details") ||
                    // Reports
                    (currentRoute === "/RevenueReports" && "Revenue Report") ||
                    (currentRoute === "/OtherReports" && "Other Report") ||
                    (currentRoute === "/UsersReports" && "User's Report") ||
                    //User
                    (currentRoute === "/StateAdmin" && "State Admin") ||
                    (currentRoute === "/DistrictAdmin" && "District Admin") ||
                    (currentRoute === "/withdrawlist" && "Withdraw Management") ||
                    // Subscription
                    (currentRoute === "/SubscriptionPlan" &&
                      "Subscription Plan") ||
                    (currentRoute === "/SubscriptionRequest" &&
                      "Subscription Request") ||
                    (currentRoute === "/AddSubscription" &&
                      "Add Subscription") ||
                    (currentRoute === "/EditSubscription" &&
                      "Edit Subscription") ||
                    (currentRoute === "/ActiveSubscription" &&
                      "Active Subscription") ||
                    (currentRoute === "/DiscountList" && "Discount List") ||
                    (currentRoute === "/AddDiscount" && "Add Discount") ||
                    (currentRoute.includes("/EditDiscount") &&
                      "Edit Discount") &&
                    currentRoute.includes("withdrawlist")
                }
              </label>
            </div>
          </div>
          <div className="searchBlog">
            <div className={clicked ? "innerSearch" : "smallInnerSearch"}>
              <input
                type="text"
                className="form-control innerForm"
                placeholder="search"
              />
              <img src={searchIcon} className="searchInside" alt="" />
              <div
                className="notiBox"
                onClick={() => navigate("/Notification")}
              >
                <img src={bell} className="insideIcons" alt="" />
                <div className="bagde">
                  <span>{notificationList.length}</span>
                </div>
              </div>
              <img src={moon} className="insideIcons" alt="" />
              <img
                src={adminDetails?.admin_image}
                onClick={() => setClicked(!clicked)}
                className="rounded-circle circleImg"
                alt=""
              />
            </div>
          </div>
        </header>
      </main>
    </>
  );
};

export default Header;
