import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import Star from "../../assets/images/icons/ratingStar.svg";
import NoData from "../../assets/images/icons/noDataImg.svg";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import noData from "../../assets/images/icons/noDataImg.svg";
import emptyBox from "../../assets/images/icons/emptyBoxes.svg";
import Form from "react-bootstrap/Form";
import AddIcon from "../../assets/images/icons/addsquare.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  PutCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";

const VetPetMasterInventory = () => {
  const { sidebar, setBkdropLdr, pharmacuEventKey, aninations } = useContext(AppContext);
 

  // STATES
  const [productList, setProductList] = useState([]);
  const [currentPageData, setCurrentPageData] = useState({ last_page: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [isMoreLoader, setIsMoreLoader] = useState(false);
  const [productCategory, setProductCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    page: 1,
    product_category: "",
    search: "",
    page_limit: 10,
  });
  const [categorySearch, setCategorySearch] = useState("");
  const [selectedTab, setSelectedTab] = useState("products");
  // STATES

  useEffect(() => {
    fetchProductCategory();
    fetchLookupData();
  }, []);

  const fetchProductCategory = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.PHARMACY_PRODUCT_CATEGORY_LIST}?productFor=petshop&page_limit=10&page=1`
      );

      const { success, data, last_page } = res?.json;
      if (success && data) {
        setProductCategory(data);
        // setCurrentPageData({ last_page: last_page });
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };
  const [categories, setCategories] = useState([]);
  const fetchLookupData = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_LOOKUP_DATA_PHARMACY}?productFor=petshop`
      );
      // console.log("res,", res);
      const { success, lookupData } = res?.json;
      if (lookupData) {
        lookupData?.productTypes && setCategories(lookupData?.productTypes);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, [filterData]);

  const fetchProductList = async () => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.INVENTRY_PHARMACY_PRODUCT_LIST}?productFor=petshop&page_limit=${filterData?.page_limit}&page=${currentPage}&filter=${filterData?.search}&product_category=${filterData?.product_category}`
      );
      const { success, data, last_page } = res?.json;
      if (success && data) {
        setProductList(data);
        setCurrentPageData({ last_page: last_page });
      } else {
        setProductList([]);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage > 1 && selectedTab === "products") {
      fetchMoreProductList();
    } else if (currentPage > 1 && selectedTab === "categories") {
      fetchMoreProductCategory();
    }
  }, [currentPage]);

  const fetchMoreProductList = async () => {
    try {
      setIsMoreLoader(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.INVENTRY_PHARMACY_PRODUCT_LIST}?productFor=petshop&page_limit=${filterData?.page_limit}&page=${currentPage}&filter=${filterData?.search}&product_category=${filterData?.product_category}`
      );

      const { success, data, last_page } = res?.json;
      if (success && data) {
        setProductList((prev) => [...prev, ...data]);
        setCurrentPageData({ last_page });
        !last_page && setCurrentPage(currentPage + 1);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsMoreLoader(false);
    }
  };

  const fetchMoreProductCategory = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.PHARMACY_PRODUCT_CATEGORY_LIST}?productFor=petshop&page_limit=10&page=${currentPage}`
      );

      const { success, data, last_page } = res?.json;
      if (success && data) {
        setProductCategory((prev) => [...prev, ...data]);
        setCurrentPageData({ last_page });
        !last_page && setCurrentPage(currentPage + 1);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  const disabledPetProduct = async (productId, active) => {
    try {
      setBkdropLdr(true);
      const payLoad = {
        is_active: active,
      };
      const res = await PutCallWithErrorResponse(
        `${ApiConfig.PRODUCT_DISABLED}/${productId}`,
        payLoad
      );
      const { success, message } = res?.json;
      if (success) {
        notifySuccess(
          active
            ? "Product enabled successfully"
            : "Product disabled successfully"
        );
        fetchProductList();
        setCurrentPage(1);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  // const handleInfiniteScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop + 1 >=
  //     document.documentElement.scrollHeight
  //   ) {
  //     if (!currentPageData.last_page) {
  //       setCurrentPage(currentPage + 1);
  //     }
  //   }
  // };

  const slideBelowRef = useRef(null);

  const handleInfiniteScroll = () => {
    const slideBelow = slideBelowRef.current;

    if (
      slideBelow &&
      slideBelow.clientHeight + slideBelow.scrollTop + 1 >=
        slideBelow.scrollHeight
    ) {
      if (!currentPageData.last_page) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const slideBelow = slideBelowRef.current;
    if (slideBelow) {
      slideBelow.addEventListener("scroll", handleInfiniteScroll);
    }

    // window.addEventListener("scroll", handleInfiniteScroll);
    // return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, [productList]);

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="masterProductList-wrapper">
          <div className="MasterInvenTabs">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={pharmacuEventKey}
            >
              <Nav
                variant="pills"
                id="newTabMai"
                className="tob_nav_pills tasbInerTwo"
              >
                <Nav.Item
                  onClick={() => {
                    setSelectedTab("products");
                    setCurrentPage(1);
                    setCurrentPageData({ last_page: false });
                  }}
                >
                  <Nav.Link eventKey="ProductList">Product List</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  onClick={() => {
                    setSelectedTab("categories");
                    setCurrentPage(1);
                    setCurrentPageData({ last_page: false });
                  }}
                >
                  <Nav.Link eventKey="ProductCategory">
                    Product Category
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="ProductList">
                  <div className="row">
                    <div className="col-lg-3 col-md-12">
                      <div className="searchBox">
                        <img src={SearchIcon} className="searchImg" alt="" />
                        <input
                          type="text"
                          className="form-control searchDoc"
                          placeholder="Search by name..."
                          onChange={(e) => {
                            setFilterData({
                              ...filterData,
                              search: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="selectBox">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            setFilterData({
                              ...filterData,
                              product_category: e.target.value
                                ? e.target.value
                                : "",
                            });
                          }}
                          value={
                            filterData?.product_category
                              ? filterData?.product_category
                              : ""
                          }
                        >
                          <option value="">All Categories...</option>
                          {categories.map((item, idx) => {
                            return (
                              <option
                                value={item?.product_type}
                                key={item?.product_type_id}
                              >
                                {item?.product_type}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-between">
                      <div
                        className="topFilter"
                        style={{ visibility: "hidden" }}
                      >
                        <img src={FilterIcon} className="filterInner" alt="" />
                      </div>
                      <div className="topFilter">
                        <Link to="/AddPetProduct">
                          <div className="addBox">
                            <img src={AddIcon} alt="" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* Cards section */}
                    {isLoading ? (
                      <RotatingLoader />
                    ) : productList && productList.length > 0 ? (
                      <div
                        className="slideBelow row p-0 m-0"
                        ref={slideBelowRef}
                      >
                        {productList.map((item, idx) => {
                          return (
                            <div
                              className="col-lg-3 cardsResponsive"
                              key={"productList" + idx}
                            >
                              <div className="productCards">
                                <img
                                  src={
                                    item?.product_image_path
                                      ? item?.product_image_path
                                      : ""
                                  }
                                  onError={(e) => {
                                    e.target.src = noData;
                                  }}
                                  className="cardImg"
                                  alt=""
                                />
                                {item?.avg_rating && (
                                  <div className="starFlex">
                                    <img
                                      src={Star}
                                      className="innerStar"
                                      alt=""
                                    />
                                    <label className="innerRate">
                                      {item?.avg_rating}
                                    </label>
                                  </div>
                                )}
                                <div className="switchFlex">
                                  <div className="cardDetails">
                                    {item?.product && <p>{item?.product}</p>}
                                    <p>
                                      {item?.product_brand} |{" "}
                                      {item?.product_type}
                                    </p>
                                  </div>
                                  <div className="checkSwitch">
                                    <Form.Check
                                      type="switch"
                                      checked={item?.is_active}
                                      onChange={(e) => {
                                        disabledPetProduct(
                                          item?.product_id,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <p className="paraTXt">{item?.product_desc}</p>
                                <div className="text-center cardBtmBtn">
                                  <Link
                                    to={`/EditPetProduct/${item?.product_id}`}
                                    className="routButton"
                                  >
                                    <button className="innerBtn">
                                      Edit Product
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <NoDataMsg />
                    )}
                    {isMoreLoader && <RotatingLoader />}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="ProductCategory">
                  <div className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                      <div className="searchBox">
                        <img src={SearchIcon} className="searchImg" alt="" />
                        <input
                          type="text"
                          className="form-control searchDoc"
                          placeholder="Search by name..."
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12 d-flex justify-content-between">
                      <div
                        className="topFilter"
                        style={{ visibility: "hidden" }}
                      >
                        <img src={FilterIcon} className="filterInner" alt="" />
                      </div>
                      <div className="topFilter">
                        <Link to="/AddPetCategory">
                          <div className="addBox">
                            <img src={AddIcon} alt="" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* Cards section */}
                    <div className="slideBelow row p-0 m-0">
                      {productCategory.map((item, idx) => {
                        return (
                          <div
                            className="col-lg-3 cardsResponsive"
                            key={"productCategory" + idx}
                          >
                            <div className="productCards">
                              <img src={emptyBox} className="cardImg" alt="" />
                              <div className="switchFlex">
                                <div className="cardDetails">
                                  <p className="productName">
                                    {item?.category}
                                  </p>
                                </div>
                                {/* <div className="checkSwitch">
                                <Form.Check type="switch" />
                              </div>*/}
                              </div>
                              <div className="quantityAnPrice">
                                <div className="row">
                                  <div className="col-lg-6 text-center p-0">
                                    <div className="quant">
                                      <p className="greyTxt">Products</p>
                                      <label className="Amnt">
                                        {item?.total_products}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 text-center p-0">
                                    <div className="price">
                                      <p className="greyTxt">Brands</p>
                                      <label className="Amnt">
                                        {item?.total_brands}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center cardBtmBtn">
                                <Link
                                  to={`/EditPetCategory/${item?.product_type_id}`}
                                  className="routButton"
                                >
                                  <button className="innerBtn">
                                    Edit Category
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {/*
                   <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 cardsResponsive">
                      <div className="productCards">
                        <img src={emptyBox} className="cardImg" alt="" />
                        <div className="switchFlex">
                          <div className="cardDetails">
                            <p className="productName">Category Name</p>
                          </div>
                          <div className="checkSwitch">
                            <Form.Check type="switch" />
                          </div>
                        </div>
                        <div className="quantityAnPrice">
                          <div className="row">
                            <div className="col-lg-6 text-center p-0">
                              <div className="quant">
                                <p className="greyTxt">Products</p>
                                <label className="Amnt">100</label>
                              </div>
                            </div>
                            <div className="col-lg-6 text-center p-0">
                              <div className="price">
                                <p className="greyTxt">Brands</p>
                                <label className="Amnt">10</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center cardBtmBtn">
                          <Link to="/EditCategory" className="routButton">
                            <button className="innerBtn">
                              Edit Category
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  */}
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default VetPetMasterInventory;
