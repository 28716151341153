import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import Star from "../../assets/images/icons/ratingStar.svg";
import { Link } from "react-router-dom";
import DBimg from "../../assets/images/icons/DeliveryBoy.svg";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import NoData from "../../sharedComponent/NoData";
import InfiniteScroll from "react-infinite-scroll-component";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";

const DeliveryBoy = () => {
  const { sidebar, setSidebar } = useContext(AppContext);
  const [deliveryBoyArr, setDeliveryBoyArr] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState(1);
  const [page_limit, setPage_limit] = useState(10);
  const [last_page, setlast_page] = useState(false);
  const [TotalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);

  const getDeliveryBoyList = (currentPage) => {
    setLoading(true);
    simpleGetCallWithToken(
      ApiConfig.GET_DELIVERY_LIST +
        `?name=${searchTxt}&page=${page}&page_limit=${page_limit}`
    )
      .then((res) => {
        if (res.status === true) {
          setLoading(false);
          let total = res.total ? res.total : 0;
          setTotalData(total);
          setlast_page(res.last_page);

          let delBoyArr = res?.data ? res?.data : [];
          let finalArr = delBoyArr.map((innerData, index) => {
            return {
              ...innerData,
              delivery_person_name: innerData?.delivery_person_name || "",
            };
          });
          if (currentPage === 1) {
            setDeliveryBoyArr(res?.data);
            setLoading(false);
          } else {
            setDeliveryBoyArr([...deliveryBoyArr, ...finalArr]);
            setLoading(false);
          }
        } else {
          setDeliveryBoyArr([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDeliveryBoyList();
  }, [searchTxt]);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const onPageLoad = () => {
    setPage(page + 1);
    if (last_page === false) {
      getDeliveryBoyList(page + 1);
    } else {
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={deliveryBoyArr?.length}
        next={onPageLoad}
        // hasMore={true}
        hasMore={deliveryBoyArr.length !== TotalData}
        scrollableTarget="scroll-table"
        loader={
          <h4
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          ></h4>
        }
      >
        <motion.div
          variants={aninations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.1 }}
          className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
          id="cx-main"
        >
          {loading ? (
            <Loader />
          ) : (
            <div className="doctor-wrapper">
              <div className="row">
                <div className="col-md-12">
                  <div className="innerSearchItem">
                    <div className="leftFilterTop">
                      <div className="topSearch">
                        <input
                          type="text"
                          name="searchTxt"
                          autoFocus
                          value={searchTxt}
                          onChange={(e) => setSearchTxt(e.target.value)}
                          className="form-control searchDoc"
                          placeholder="Search by Name, Specialist, Ratings, Visits..."
                        />
                        <img src={SearchIcon} className="searchInner" alt="" />
                      </div>
                      <div className="topFilter">
                        <img src={FilterIcon} className="filterInner" alt="" />
                      </div>
                    </div>
                    <Link to="/AddDeliveryBoy" className="routButton">
                      <button className="innerBtn">Add Delivery Boy</button>
                    </Link>
                  </div>
                </div>
                {/* Cards Content */}
                <div className="slideBelow row p-0 m-0">
                  {deliveryBoyArr && deliveryBoyArr.length > 0 ? (
                    deliveryBoyArr.map((data, index) => {
                      return (
                        <div className="col-lg-3 cardsResponsive">
                          <Link
                            to={`/DeliveryBoyDetails/${data?.delivery_person_id}`}
                            className="cardLink"
                          >
                            <div className="doctorsDetailsCard">
                              <img
                                src={data?.delivery_person_image_path}
                                className="docInsideImg"
                                alt=""
                              />
                              <div className="ratingDet">
                                <img src={Star} className="innerRate" alt="" />
                                <label className="rateTxt">
                                  {data?.delivery_boy_rating}
                                </label>
                              </div>
                              <div className="docDetailsTxt">
                                <h1>{data?.delivery_person_name}</h1>
                                {/*<label>Vaccinator</label>*/}
                              </div>
                              {data?.is_disabled && (
                                <div className="disable_img">
                                  <img src={disabled} />{" "}
                                </div>
                              )}
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <NoData />
                  )}
                  <>
                    {/* <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 cardsResponsive">
                                        <Link to="/DeliveryBoyDetails" className='cardLink'>
                                            <div className="doctorsDetailsCard">
                                                <img src={DBimg} className='docInsideImg' alt="" />
                                                <div className="ratingDet">
                                                    <img src={Star} className='innerRate' alt="" />
                                                    <label className='rateTxt'>4.5</label>
                                                </div>
                                                <div className="docDetailsTxt">
                                                    <h1>Floyd Miles</h1>
                                                    <label>Vaccinator</label>
                                                </div>
                                            </div>
                                        </Link>
                                    </div> */}
                  </>
                </div>
              </div>
            </div>
          )}
        </motion.div>
      </InfiniteScroll>
    </>
  );
};

export default DeliveryBoy;
