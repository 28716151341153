import React, { useContext, useEffect, useState } from "react";
import roundCircle from "../../assets/images/headerIcons/headerCircle.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import ic_export from "../../assets/images/icons/ic_export.svg";
import { Link } from "react-router-dom";
import DocImg from "../../assets/images/icons/docImg.svg";
import Star from "../../assets/images/icons/ratingStar.svg";
import { AppContext } from "../../context/AppContext";
import MedicalImg from "../../assets/images/icons/medicalImg.svg";
import Clock from "../../assets/images/icons/clock.svg";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import arrowleft from "../../assets/images/icons/arrowleft.svg";
import PetShop from "../../assets/images/icons/petShopImg.svg";
import AmbulanceImg from "../../assets/images/icons/AmbulanceImg.svg";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePostAuthCallWithFormData,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";
import moment from "moment";
import Pagination from "../../sharedComponent/Pagination";
import { useDebounce } from "../../customeHooks/useDebounce";
import { Button, Form, Modal } from "react-bootstrap";
import { convertInFormData, formatCurrency } from "../../common/commonFun";
import NoDataMsg from "../../sharedComponent/NoDataMsg";

const WithdrawList = () => {
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const [validated, setValidated] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { sidebar, setSidebar } = useContext(AppContext);
  const [withdrawlList, setWithdrawlList] = useState([]);
  const [paginationDataFromAPIResponse, setPaginationDataFromAPIResponse] =
    useState({});
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState({
    page: 1,
    page_limit: 10,
  });
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    fetchWithdrawList();
  }, [filteredData, debouncedSearchValue]);
  const fetchWithdrawList = async () => {
    // search, page, page_limit
    try {
      const res = await simpleGetCallWithToken(
        `${ApiConfig?.FETCH_WITHDRAWAL_LIST}?page=${filteredData?.page}&page_limit=${filteredData?.page_limit}&search=${debouncedSearchValue}`
      );
      const { success, data, last_page, page, total, total_page } = res;
      if (success) {
        data && setWithdrawlList(data);
        setPaginationDataFromAPIResponse({
          last_page,
          page,
          total,
          total_page,
        });
      } else {
        setWithdrawlList([]);
      }
    } catch (error) {
      console.log("cath error,", error);
    }
  };

  const pageChangeHandler = ({ selected }) => {
    setFilteredData({ ...filteredData, page: selected + 1 });
  };


  const [actionType, setactionType] = useState("");
  const [withdrawRequest, setWithdrawRequest] = useState({
    withdraw_request_id: "",
    is_accepted: "",
    user_id: "vac_6",
    amount: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        const formData = convertInFormData(withdrawRequest);
        const res = await simplePostAuthCallWithFormData(
          ApiConfig.ACCEPT_WITHDRAW_REQUEST,
          formData
        );
        const { success, message } = res;
        if (success) {
          notifySuccess(message);
          const tempArr =
            withdrawlList &&
            withdrawlList.map((item) => {
              return item?.withdraw_request_id ==
                withdrawRequest?.withdraw_request_id
                ? {
                    ...item,
                    status: actionType == "accept" ? "Accepted" : "Rejected",
                  }
                : item;
            });
          setWithdrawlList(tempArr);
          setOpenModal(false);
          setWithdrawRequest({
            withdraw_request_id: "",
            is_accepted: "",
            user_id: "",
            amount: "",
          });
          setValidated(false)
        } else {
          notifyError(message)
        }
      } catch (error) {
        console.log("catch error,", error);
      }
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className=" commmon-top-search-section">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-4">
              <div className="innerSearchItem">
                <div className="topSearch">
                  <input
                    type="text"
                    className="form-control searchDoc"
                    placeholder="Search by name..."
                    // value={search}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <img src={SearchIcon} className="searchInner" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-wrapper">
          {withdrawlList && withdrawlList.length > 0 ? (
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Sr No. </th>
                  <th>User Name</th>
                  <th>Request Amount</th>
                  <th>Available Balance</th>
                  <th>Account Number</th>
                  <th>Bank Name</th>
                  <th>Contact Number</th>
                  <th>Request Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {withdrawlList.map((item, index) => {
                  return (
                    <tr key={index + "withdrawlList"}>
                      <td>{index + 1}</td>
                      <td className="capital">{item?.user_name}</td>
                      <td>
                        {/* {data.state} */}
                        {parseInt(item?.request_amount) &&
                          formatCurrency(parseInt(item?.request_amount))}
                      </td>
                      <td>
                        {" "}
                        {parseInt(item?.available_balance) &&
                          formatCurrency(parseInt(item?.available_balance))}
                      </td>
                      <td>{item?.account_number}</td>
                      <td>
                        {item?.bank_name}
                        {/* <img
                                src={approved}
                                className="checks tableImg"
                                alt=""
                              />
                              {data.is_active ? "Active" : "In_Active"} */}
                      </td>
                      <td>{item?.contact_no}</td>
                      <td>
                        {item?.request_datetime
                          ? moment(item?.request_datetime).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td>
                        {item?.status?.includes("Pending") ? (
                          <div style={{ display: "flex" }}>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#0cb10c",
                                marginRight: "10px",
                                padding: "2px 8px",
                                borderRadius: "5px",
                                color: "#fff",
                              }}
                              onClick={() => {
                                setOpenModal(true);
                                setWithdrawRequest({
                                  ...withdrawRequest,
                                  withdraw_request_id:
                                    item?.withdraw_request_id,
                                  user_id: item?.user_id,
                                  amount: item?.request_amount,
                                  is_accepted: true,
                                });
                                setactionType("accept");
                              }}
                            >
                              Accept
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "red",
                                marginRight: "10px",
                                padding: "2px 8px",
                                borderRadius: "5px",
                                color: "#fff",
                              }}
                              onClick={() => {
                                setOpenModal(true);
                                setWithdrawRequest({
                                  ...withdrawRequest,
                                  withdraw_request_id:
                                    item?.withdraw_request_id,
                                  user_id: item?.user_id,
                                  amount: item?.request_amount,
                                  is_accepted: false,
                                });
                                setactionType("reject");
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        ) : (
                          item?.status
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <NoDataMsg MSG={"No Request Found"} />
          )}
        </div>
        <div>
          <Pagination
            pageCount={
              paginationDataFromAPIResponse?.total / filteredData?.page_limit
            }
            onPageChange={pageChangeHandler}
          />
        </div>
      </motion.div>
      <div className="withdraw-modal">
        <Modal
          className="accept-request-modal"
          show={openModal}
          onHide={() => {
            setOpenModal(false);
            setWithdrawRequest({
              ...withdrawRequest,
              withdraw_request_id: "",
              user_id: "",
              amount: "",
              is_accepted: false,
              reason: "",
              receipt: "",
            });
            setValidated(false)
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Modal heading
            </Modal.Title>
          </Modal.Header>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="container py-3 withdraw-modal-content">
                {actionType?.includes("accept") ? (
                  <div className="form-items mb-3">
                    <label htmlFor="formFile" class="form-label">
                      Upload Receipt
                    </label>
                    <input
                      required
                      class="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => {
                        setWithdrawRequest({
                          ...withdrawRequest,
                          receipt: e.target.files[0],
                        });
                      }}
                    />
                    <div className="invalid-feedback">
                      Please upload receipt
                    </div>
                  </div>
                ) : (
                  <div className="form-items mb-3">
                    <label class="form-label">Reason</label>
                    <input
                      required
                      class="form-control"
                      type="text"
                      onChange={(e) => {
                        setWithdrawRequest({
                          ...withdrawRequest,
                          reason: e.target.value,
                        });
                      }}
                    />
                    <div className="invalid-feedback">Reason is required</div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="accept-btn">
                {`${actionType?.includes("accept") ? "Accept" : "Reject"}`}{" "}
                Request
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default WithdrawList;
