import React, { useContext, useEffect, useState } from 'react'
import roundCircle from "../../assets/images/headerIcons/headerCircle.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import ic_export from "../../assets/images/icons/ic_export.svg";
import { Link } from 'react-router-dom';
import DocImg from "../../assets/images/icons/docImg.svg"
import Star from "../../assets/images/icons/ratingStar.svg"
import { AppContext } from '../../context/AppContext';
import MedicalImg from "../../assets/images/icons/medicalImg.svg";
import Clock from "../../assets/images/icons/clock.svg";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import arrowleft from "../../assets/images/icons/arrowleft.svg";
import PetShop from "../../assets/images/icons/petShopImg.svg";
import AmbulanceImg from "../../assets/images/icons/AmbulanceImg.svg";
import ApiConfig from '../../api/ApiConfig';
import { notifyError, notifySuccess } from '../../sharedComponent/Notify';
import { simpleGetCallWithToken, simplePostAuthCall } from '../../api/ApiServices';

const DistrictAdmin = () => {
    const { sidebar, setSidebar } = useContext(AppContext);
    const [districtDetails, setDistrictDetails] = useState(false);
    const [district, setDistrict] = useState([]);
    const [DoctorDetails, setDoctorDetails] = useState([]);
    const [PharmacyDetails, setPharmacyDetails] = useState([]);
    const [PetShopDetails, setPetShopDetails] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [search, setSearch] = useState("");

    const AdminDistrict = () => {
        simpleGetCallWithToken(ApiConfig.CITY_ADMIN + 1 + "&keyword=" + search)
            .then((res) => {
                if (res.success === true) {
                    setDistrict(res?.data);
                } else if (res.success === false) {
                    notifyError(res.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const DistrictDetail = (admin_role_id, state_id) => {

        setDistrictDetails(true);
        const payload = {
            admin_role_id: admin_role_id,
            id: state_id
        }
        simplePostAuthCall(ApiConfig.ADMIN_SHOP_DETAILS, JSON.stringify(payload))
            .then((res) => {
                if (res.success === true) {
                    setDoctorDetails(res?.doctors)
                    setPharmacyDetails(res?.pharmacies)
                    setPetShopDetails(res?.petVetShop)
                    setSearchResult(res?.Details)
                } else if (res.success === false) {
                    notifyError(res.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        AdminDistrict();
    }, [search])



    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };
    return (
        <>
            <motion.div
                variants={aninations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
                className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
                id='cx-main'>
                <div className=" commmon-top-search-section">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-4">
                            <div className="innerSearchItem">
                                <div className="topSearch">
                                    <input
                                        type="text"
                                        className="form-control searchDoc"
                                        placeholder="Search by Name, Specialist, Ratings, Visits..."
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <img
                                        src={SearchIcon}
                                        className="searchInner"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                            <div className="topFilter">
                                <img src={FilterIcon} className='filterInner' alt="" />
                            </div>
                            {/* <div className="topFilter">
                                <Link to="/AddPetProduct">
                                    <div className="addBox">
                                        <img src={ic_export} alt="" />
                                    </div>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
                {districtDetails === false ?
                    <div className="table-wrapper">
                        <table className="defaultTable">
                            <thead>
                                <tr>
                                    <th>Sr No. </th>
                                    <th>Admin Name</th>
                                    <th>District Name</th>
                                    <th>No. of Districts</th>
                                    <th>Contact No.</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                                {
                                    district && district.length > 0 ? district.map((data, index) => {
                                        return (
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    <img
                                                        src={data.admin_image ? ApiConfig.IMAGE_BASE_URL + data.admin_image : roundCircle}
                                                        className="rounded-circle tableImg"
                                                        alt=""
                                                    />
                                                    {data.admin}
                                                </td>
                                                <td className='ClickableTD' onClick={() => { DistrictDetail(data.admin_role_id, data.dist_id) }}>{data.district}</td>
                                                <td>
                                                    00
                                                </td>
                                                <td>{data.contact_no}</td>
                                                <td>
                                                    <img
                                                        src={approved}
                                                        className="checks tableImg"
                                                        alt=""
                                                    />
                                                    {data.is_active ? "Active" : "In_Active"}
                                                </td>
                                            </tr>
                                        )
                                    }) : null
                                }
                            </tbody>
                        </table>
                    </div> :
                    <>
                        <div className="doc-name-back-btn">
                            <Link onClick={() => setDistrictDetails(false)}>
                                <img src={arrowleft} alt="" />
                            </Link>
                            <label htmlFor="" className="ms-3">Showing result for : </label> <span className="innerDatils">{searchResult.district}</span>
                        </div>
                        <div className="slideBelowInUser">
                            {/* Doctor */}
                            <div className=" row p-0 m-0">
                                <div className="sectionHeader">
                                    <p>Doctor <span>( {DoctorDetails.length} )</span></p>
                                    <Link to="/Doctors"> {DoctorDetails.length > 0 ? "View All" : null} </Link>
                                </div>
                                {
                                    DoctorDetails && DoctorDetails.length > 0 ? DoctorDetails.map((data) => {
                                        return (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <Link to="/DoctorDetails" className='cardLink'>
                                                    <div className="AdminCardView">
                                                        <img src={data.doctor_profile_image_path ? ApiConfig.IMAGE_BASE_URL + data.doctor_profile_image_path : DocImg} className='docInsideImg' alt="" />
                                                        {/* <div className="ratingDet">
                                                        <img src={Star} className='innerRate' alt="" />
                                                    </div> */}
                                                        <div className="docDetailsTxt">
                                                            <h1>{data.doctor_name}</h1>
                                                            <label>{data.specializations}</label>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    }) : <div className="col-md-12 p-4 text-center">
                                        <label htmlFor="" className='text-danger fw-bold'>No Data Found</label>
                                    </div>
                                }

                            </div>
                            {/* Pharmacy */}
                            <div className="row p-0 m-0">
                                <div className="sectionHeader">
                                    <p>Pharmacy <span>( {PharmacyDetails.length} )</span></p>
                                    <Link to="/pharmacyShops">{PharmacyDetails.length > 0 ? "View All" : null}</Link>
                                </div>
                                {
                                    PharmacyDetails && PharmacyDetails.length > 0 ? PharmacyDetails.map((data) => {
                                        return (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <Link to="/DoctorDetails" className='cardLink'>
                                                    <div className="AdminCardView">
                                                        <img src={data.profile_image_path ? ApiConfig.IMAGE_BASE_URL + data.profile_image_path : DocImg} className='docInsideImg' alt="" />
                                                        {/* <div className="ratingDet">
                                                        <img src={Star} className='innerRate' alt="" />
                                                    </div> */}
                                                        <div className="docDetailsTxt">
                                                            <h1>{data.pharmacy_shop_name}</h1>
                                                            <label>{data.specializations}</label>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    }) : <div className="col-md-12 p-4 text-center">
                                        <label htmlFor="" className='text-danger fw-bold'>No Data Found</label>
                                    </div>
                                }
                            </div>
                            {/* Pet / Vet Shops  */}
                            <div className="row p-0 m-0">
                                <div className="sectionHeader">
                                    <p>Pet / Vet Shops <span>( {PetShopDetails.length} )</span></p>
                                    <Link to="/vetPetShops"> {PetShopDetails.length ? "View All" : null} </Link>
                                </div>
                                {
                                    PetShopDetails && PetShopDetails.length > 0 ? PetShopDetails.map((data) => {
                                        return (
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <Link to="/DoctorDetails" className='cardLink'>
                                                    <div className="AdminCardView">
                                                        <img src={data.pet_shop_image_path ? ApiConfig.IMAGE_BASE_URL + data.pet_shop_image_path : DocImg} className='docInsideImg' alt="" />
                                                        {/* <div className="ratingDet">
                                                        <img src={Star} className='innerRate' alt="" />
                                                    </div> */}
                                                        <div className="docDetailsTxt">
                                                            <h1>{data.pet_shop_name}</h1>
                                                            <label>{data.specializations}</label>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    }) : <div className="col-md-12 p-4 text-center">
                                        <label htmlFor="" className='text-danger fw-bold'>No Data Found</label>
                                    </div>
                                }
                            </div>
                            {/* Vaccinators (200) */}
                            {/* <div className="row p-0 m-0">
                            <div className="sectionHeader">
                                <p>Vaccinators <span> ( 344 )</span></p>
                                <Link to="/Vaccinators"> View All </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/VaccinatorDetails" className='cardLink'>
                                    <div className="AdminCardView">
                                        <img src={DocImg} className='docInsideImg' alt="" />
                                        <div className="ratingDet">
                                            <img src={Star} className='innerRate' alt="" />
                                            <label className='rateTxt'>4.5</label>
                                        </div>
                                        <div className="docDetailsTxt">
                                            <h1>Floyd Miles</h1>
                                            <label>Vaccinator</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/VaccinatorDetails" className='cardLink'>
                                    <div className="AdminCardView">
                                        <img src={DocImg} className='docInsideImg' alt="" />
                                        <div className="ratingDet">
                                            <img src={Star} className='innerRate' alt="" />
                                            <label className='rateTxt'>4.5</label>
                                        </div>
                                        <div className="docDetailsTxt">
                                            <h1>Floyd Miles</h1>
                                            <label>Vaccinator</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/VaccinatorDetails" className='cardLink'>
                                    <div className="AdminCardView">
                                        <img src={DocImg} className='docInsideImg' alt="" />
                                        <div className="ratingDet">
                                            <img src={Star} className='innerRate' alt="" />
                                            <label className='rateTxt'>4.5</label>
                                        </div>
                                        <div className="docDetailsTxt">
                                            <h1>Floyd Miles</h1>
                                            <label>Vaccinator</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/VaccinatorDetails" className='cardLink'>
                                    <div className="AdminCardView">
                                        <img src={DocImg} className='docInsideImg' alt="" />
                                        <div className="ratingDet">
                                            <img src={Star} className='innerRate' alt="" />
                                            <label className='rateTxt'>4.5</label>
                                        </div>
                                        <div className="docDetailsTxt">
                                            <h1>Floyd Miles</h1>
                                            <label>Vaccinator</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                            {/* Ambulance  */}
                            {/* <div className="row p-0 m-0">
                            <div className="sectionHeader">
                                <p>Ambulance  <span> ( 584 )</span></p>
                                <Link to="/Ambulances"> View All </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/AmbulanceDetails">
                                    <div className="AdminCardView">
                                        <img src={AmbulanceImg} className='docInsideImg' alt="" />
                                        <div iv className="medicalbelowDetails">
                                            <label className='headTxt'>David Ambulance</label>
                                            <div className="starFlex">
                                                <img src={Star} className='star' alt="" />
                                            </div>
                                            <label className='numTxt'>4.3</label>
                                        </div>
                                        <div className="clockDet">
                                            <img src={Clock} className='clock' alt="" />
                                            <label htmlFor="" className='timeDet'>Mon- Sat : 10:00 AM - 06:00 PM</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/AmbulanceDetails">
                                    <div className="AdminCardView">
                                        <img src={AmbulanceImg} className='docInsideImg' alt="" />
                                        <div iv className="medicalbelowDetails">
                                            <label className='headTxt'>David Ambulance</label>
                                            <div className="starFlex">
                                                <img src={Star} className='star' alt="" />
                                            </div>
                                            <label className='numTxt'>4.3</label>
                                        </div>
                                        <div className="clockDet">
                                            <img src={Clock} className='clock' alt="" />
                                            <label htmlFor="" className='timeDet'>Mon- Sat : 10:00 AM - 06:00 PM</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/AmbulanceDetails">
                                    <div className="AdminCardView">
                                        <img src={AmbulanceImg} className='docInsideImg' alt="" />
                                        <div iv className="medicalbelowDetails">
                                            <label className='headTxt'>David Ambulance</label>
                                            <div className="starFlex">
                                                <img src={Star} className='star' alt="" />
                                            </div>
                                            <label className='numTxt'>4.3</label>
                                        </div>
                                        <div className="clockDet">
                                            <img src={Clock} className='clock' alt="" />
                                            <label htmlFor="" className='timeDet'>Mon- Sat : 10:00 AM - 06:00 PM</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/AmbulanceDetails">
                                    <div className="AdminCardView">
                                        <img src={AmbulanceImg} className='docInsideImg' alt="" />
                                        <div iv className="medicalbelowDetails">
                                            <label className='headTxt'>David Ambulance</label>
                                            <div className="starFlex">
                                                <img src={Star} className='star' alt="" />
                                            </div>
                                            <label className='numTxt'>4.3</label>
                                        </div>
                                        <div className="clockDet">
                                            <img src={Clock} className='clock' alt="" />
                                            <label htmlFor="" className='timeDet'>Mon- Sat : 10:00 AM - 06:00 PM</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                            {/* Delivary Boy */}
                            {/* <div className="row p-0 m-0">
                            <div className="sectionHeader">
                                <p>Delivery Boy <span> ( 344 )</span></p>
                                <Link to="/DeliveryBoy"> View All </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/DeliveryBoyDetails" className='cardLink'>
                                    <div className="AdminCardView">
                                        <img src={DocImg} className='docInsideImg' alt="" />
                                        <div className="ratingDet">
                                            <img src={Star} className='innerRate' alt="" />
                                            <label className='rateTxt'>4.5</label>
                                        </div>
                                        <div className="docDetailsTxt">
                                            <h1>Floyd Miles</h1>
                                            <label>Vaccinator</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/DeliveryBoyDetails" className='cardLink'>
                                    <div className="AdminCardView">
                                        <img src={DocImg} className='docInsideImg' alt="" />
                                        <div className="ratingDet">
                                            <img src={Star} className='innerRate' alt="" />
                                            <label className='rateTxt'>4.5</label>
                                        </div>
                                        <div className="docDetailsTxt">
                                            <h1>Floyd Miles</h1>
                                            <label>Vaccinator</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/DeliveryBoyDetails" className='cardLink'>
                                    <div className="AdminCardView">
                                        <img src={DocImg} className='docInsideImg' alt="" />
                                        <div className="ratingDet">
                                            <img src={Star} className='innerRate' alt="" />
                                            <label className='rateTxt'>4.5</label>
                                        </div>
                                        <div className="docDetailsTxt">
                                            <h1>Floyd Miles</h1>
                                            <label>Vaccinator</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <Link to="/DeliveryBoyDetails" className='cardLink'>
                                    <div className="AdminCardView">
                                        <img src={DocImg} className='docInsideImg' alt="" />
                                        <div className="ratingDet">
                                            <img src={Star} className='innerRate' alt="" />
                                            <label className='rateTxt'>4.5</label>
                                        </div>
                                        <div className="docDetailsTxt">
                                            <h1>Floyd Miles</h1>
                                            <label>Vaccinator</label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                        </div>
                    </>
                }
            </motion.div>

        </>
    )
}

export default DistrictAdmin;
