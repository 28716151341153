import React, { useContext, useState } from 'react'
import yourLogo from '../../assets/images/icons/mainLogo.svg'
import teleCall from '../../assets/images/icons/telephone.svg'
import insiderImg from '../../assets/images/icons/landigIcon.svg'
import mailBox from '../../assets/images/icons/emailSvg.svg'
import passBox from '../../assets/images/icons/lock.svg'
import eye from '../../assets/images/icons/eye.svg'
import ic_show from '../../assets/images/icons/ic_show_eyes.svg'
import ic_hide_eyes from '../../assets/images/icons/ic_hide_eyes.svg'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { simplePostCall } from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { notifyError, notifySuccess } from '../../sharedComponent/Notify'
import { AppContext } from '../../context/AppContext'

const Login = () => {
  const { adminDetails, setAdminDetails } = useContext(AppContext)
  const [validated, setValidated] = useState(false)
  const [password, setpassword] = useState("password");
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  })

  const changeVal = e => {
    setLoginDetails({
      ...loginDetails,
      [e.target.name]: e.target.value
    })
  }

  const passwordChange = () => {
    if (password == 'password') {
      setpassword('text');
    } else if (password == 'text') {
      setpassword('password')
    }
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
    } else {
      loginUser(event)
    }
  }

  const loginUser = event => {
    event.preventDefault()
    simplePostCall(ApiConfig.LOGIN_ADMIN, JSON.stringify(loginDetails))
      .then(res => {
        if (res.success === true) {
          notifySuccess(res?.message)
          localStorage.setItem('user_loggedIn_data', JSON.stringify(res?.data))
          localStorage.setItem('Token', res?.token)
          localStorage.setItem('isLoggedIn', true)
          localStorage.setItem('admin_id', res?.data?.admin_id)
          localStorage.setItem('admin_image', res?.data?.admin_image)
          localStorage.setItem('country_id', res?.data?.country_id)
          localStorage.setItem('state_id', res?.data?.state_id)
          localStorage.setItem('city_id', res?.data?.city_id)
          localStorage.setItem('admin_role_id', res?.data?.admin_role_id)
          localStorage.setItem('is_super_admin', res?.data?.is_super_admin)
          localStorage.setItem('is_active', res?.data?.is_active)
          localStorage.setItem('user_id', res?.data?.user_id)
          setAdminDetails({
            admin_id: res?.data?.admin_id,
            admin_image: res?.data?.admin_image,
            country_id: res?.data?.country_id,
            state_id: res?.data?.state_id,
            city_id: res?.data?.city_id,
            admin_role_id: res?.data?.admin_role_id,
            is_super_admin: res?.data?.is_super_admin,
            is_active: res?.data?.is_active,
            isLoggedIn: true
          })
        } else {
          notifyError(res?.message)
        }
      })
      .catch(err => {
        notifyError(err)
      })
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className='authMain'>
          <div className='row'>
            <div className='col-md-12 col-lg-6'>
              <img src={yourLogo} className='logoImg' alt='' />
              <div className='signinForm'>
                <h1 className='signInTxt'>Sign in</h1>
                <Form.Group className='inputBox' controlId='validationCustom01'>
                  <Form.Label htmlFor='' className='emailLabel'>
                    Email
                  </Form.Label>
                  <img src={mailBox} className='mailImg' alt='' />
                  <Form.Control
                    required
                    type='text'
                    name='email'
                    value={loginDetails.email}
                    onChange={changeVal}
                    className='form-control formInput'
                    placeholder='Enter your email address'
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please Enter Your Email...
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='inputBox' controlId='validationCustom02'>
                  <Form.Label htmlFor='' className='emailLabel'>
                    Password
                  </Form.Label>
                  <img src={passBox} className='mailImg' alt='' />
                  {/* <img src={eye} className='eyeImg' onClick={() => {
                    password(true)
                  }} alt='' /> */}

                  {password === "password" ? (
                    <img src={ic_show} className="eyeImg" onClick={() => {
                      passwordChange()
                    }} alt="" />
                  ) : (
                    <img src={ic_hide_eyes} className="eyeImg" onClick={() => {
                      passwordChange()
                    }} alt="" />
                  )}


                  <Form.Control
                    required
                    type={password}
                    name='password'
                    value={loginDetails.password}
                    onChange={changeVal}
                    className='form-control formInput'
                    placeholder=' Please Enter Your Password'
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please Enter Your Password...
                  </Form.Control.Feedback>
                  <div className='belowChecks'>
                    <Form.Group class='form-check'>
                      <Form.Check
                        // required
                        label='Remember Me'
                        feedback='You must agree before submitting.'
                        feedbackType='invalid'
                        class='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                      />
                    </Form.Group>
                    <div className='forgetPass'>
                      <Link to='/ForgetPassword' className='forgetLab'>
                        Forgot Password ?
                      </Link>
                    </div>
                  </div>
                </Form.Group>
                <div className='login'>
                  <button type='submit'>Login</button>
                </div>
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='landingImg'>
                <div className='mobileNumber'>
                  <span className='callNumber'>
                    <img src={teleCall} height='15' width='15' alt='' />+91 9509932234
                  </span>
                </div>
                <div className='insiderIcon'>
                  <img src={insiderImg} alt='' />
                </div>
                <div className='landingTxt'>
                  <div className='d-flex flex-column justify-content-start'>
                    <h1>Welcome to Admin Panel</h1>
                    <p>Lets get started !</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

export default Login
