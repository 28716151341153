import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import { motion } from "framer-motion";
import DocImg from "../../assets/images/icons/docImg.svg";
import { Link } from "react-router-dom";
import {
  putMultipartWithAuthCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import { useDebounce } from "../../customeHooks/useDebounce";
import ApiConfig from "../../api/ApiConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import { notifySuccess } from "../../sharedComponent/Notify";
import NoDataMsg from "../../sharedComponent/NoDataMsg";

const AddVacinators = () => {
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { sidebar, navigate } = useContext(AppContext);

  const [newVaccinatorList, setNewVaccinatorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { current_page, last_page, total_count, total_pages } = paginationData;
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce(searchValue, 500);

  const fetchVaccinatorList = async (page) => {
    try {
      const res = await simpleGetCallWithToken(
        `${ApiConfig.FETCH_NEW_VACCINATOR_LIST}?page=${page}&search=${debouncedValue}`
      );

      const {
        success,
        current_page,
        last_page,
        total_count,
        total_pages,
        vaccinatorsList,
      } = res;
      if (success) {
        vaccinatorsList && setNewVaccinatorList(vaccinatorsList);
        setPaginationData({
          current_page,
          last_page,
          total_count,
          total_pages,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  useEffect(() => {
    fetchVaccinatorList(currentPage);
  }, [debouncedValue]);

  const onPageLoad = () => {
    setCurrentPage(currentPage + 1)
    if (!last_page) {
      fetchVaccinatorList(currentPage + 1);
    }
  };

  const acceptAndReject = async (vaccinatorId, isVarify) => {
    const payLoad = {
      vaccinatorId: vaccinatorId,
      verify: isVarify,
    };
    try {
      const res = await putMultipartWithAuthCall(
        ApiConfig.NEW_VACCINATOR_ACCEPT_REJECT,
        JSON.stringify(payLoad)
      );
      const { success } = res;
      if (success) {
        notifySuccess(
          `Vaccinator ${isVarify ? "accepted" : "rejected"} successfully`
        );
        fetchVaccinatorList();
        navigate("/Vaccinators");
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="adddoctor-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="innerSearchItem">
                <div className="leftFilterTop">
                  <div className="topSearch">
                    <input
                      type="text"
                      className="form-control searchDoc"
                      placeholder="Search by name..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                    <img src={SearchIcon} className="searchInner" alt="" />
                  </div>
                  <div className="topFilter">
                    <img src={FilterIcon} className="filterInner" alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* Cards Content */}
            <InfiniteScroll
              dataLength={newVaccinatorList?.length}
              next={onPageLoad}
              hasMore={!last_page}
              scrollableTarget="scroll-table"
              loader={
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                ></h4>
              }
            >
              {newVaccinatorList.length > 0 ? (
                <div className="slideBelow row p-0 m-0">
                  {newVaccinatorList &&
                    newVaccinatorList.map((item, idx) => {
                      return (
                        <div
                          className="col-lg-3 AddcardsResponsive"
                          key={"newVaccinatorList" + idx}
                        >
                          <div className="cardLink">
                            {/* to="/ViewVaccinators" */}
                            <div className="doctorsDetailsCard">
                              <img
                                style={{
                                  width: "238px",
                                  height: "279px",
                                  borderRadius: "10px",
                                  objectFit: "cover",
                                  marginLeft: "67px",
                                }}
                                src={item?.vaccinator_profile_image_path}
                                className="docInsideImg"
                                alt=""
                              />
                              <div className="docDetailsTxt">
                                <h1>{item?.vaccinator_name}</h1>
                                {/* <label>Orthopedic</label> */}
                              </div>
                              <div className="innerButton">
                                <button
                                  className="rejectBtn"
                                  onClick={() =>
                                    acceptAndReject(item?.vaccinator_id, false)
                                  }
                                >
                                  Reject
                                </button>
                                <button
                                  className="AcceptBtn"
                                  onClick={() =>
                                    acceptAndReject(item?.vaccinator_id, true)
                                  }
                                >
                                  Accept
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <NoDataMsg MSG={"No Vaccinators Founds"} />
              )}
            </InfiniteScroll>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default AddVacinators;
