import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import { motion } from "framer-motion";
import DocImg from "../../assets/images/icons/docImg.svg";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { Link, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";
import Loader from "../../sharedComponent/Loader";
import dummydoctor from "../../assets/images/icons/dummydoctor.jpg";
import { useDebounce } from "../../customeHooks/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomInfiniteScroll from "../../customeHooks/CustomInfiniteScroll";
import { Modal } from "react-bootstrap";
import { formatCurrency } from "../../common/commonFun";
import Select from "react-select";
import moment from "moment";

const AddDoctor = () => {
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const navigate = useNavigate();
  const { sidebar, setBkdropLdr } = useContext(AppContext);
  const [docList, setDocList] = useState([]);
  const [page, setPage] = useState(1);
  const [last_page, setlast_page] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDoctorAccept, setIsDoctorAccept] = useState({
    show: false,
    doctorId: "",
  });
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const [subscriptionPayload, setSubscriptionPayload] = useState({
    subscription_id: "",
    startDate: new Date(),
    endDate: "",
    duration_months: "",
  });

  const localData = JSON.parse(localStorage.getItem("user_loggedIn_data"));

  const debouncedValue = useDebounce(search, 500);
  // useDebounce
  useEffect(() => {
    getAllDocsList(page);
  }, [debouncedValue]);

  const getAllDocsList = async (page) => {
    if (page == 1) {
      setLoading(true);
    } else {
      setBkdropLdr(true);
    }

    let payload = {
      country_id: localData?.country_id,
      state_id: localData?.state_id,
      city_id: localData?.city_id,
      admin_role_id: localData?.admin_role_id,
      is_super_admin: localData?.is_super_admin,
      keyword: debouncedValue,
      page_limit: 10,
      page: page ? page : 1,
    };

    try {
      const res = await simplePostAuthCall(
        ApiConfig.GET_ALL_DOC_LIST,
        JSON.stringify(payload)
      );
      const { success, last_page, data, current_page } = res;
      if (success === true) {
        setlast_page(last_page);
        if (page > 1) {
          setDocList([...docList, ...data]);
          !last_page && setPage(parseInt(current_page) + 1);
        } else {
          setDocList(data);
          !last_page && setPage(parseInt(current_page) + 1);
        }
      } else {
        setDocList([]);
      }
    } catch (error) {
      console.error("Error fetching more data:", error);
    } finally {
      setLoading(false);
      setBkdropLdr(false);
    }
  };

  const onPageLoad = () => {
    setPage(page + 1);
    if (last_page === false) {
      getAllDocsList(page + 1);
    }
  };

  const doctorAccept = (doctor_id) => {
    if (!subscriptionPayload.subscription_id) {
      notifyError("Please select subscription plan");
      return;
    }
    let payload = {
      doctor_id: doctor_id,
      ...subscriptionPayload,
    };
    simplePostAuthCall(ApiConfig.DOCTOR_ACCEPT, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          // getAllDocsList();
          navigate("/Doctors");
        }else{
          notifyError(res.message);
        
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doctorReject = (doctor_id) => {
    let payload = {
      doctor_id: doctor_id,
    };
    // Assuming `simplePostAuthCall` returns a Promise
    simplePostAuthCall(ApiConfig.DOCTOR_REJECT, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getAllDocsList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  // GET_SUBSCRIPTION_PLAN_LIST
  const getAllSubscriptions = async () => {
    try {
      const res = await simpleGetCallWithToken(
        `${ApiConfig.GET_SUBSCRIPTION_PLAN_LIST}?entity_id=1`
      );
      const { success } = res;
      if (success) {
        const formattedArr =
          res.data &&
          res.data.map((item) => {
            return {
              label: item.subscription_description || "",
              value: item.subscription_id || "",
              duration_months: item.duration_months || "",
            };
          });

        formattedArr && setSubscriptionPlans(formattedArr);
        setSubscriptionPayload((prev) => {
          return {
            ...prev,
            subscription_id: 15,
            duration_months:
              res.data && res.data.length > 0
                ? res.data.find((it) => it.subscription_id == 15)
                    .duration_months
                : "",
          };
        });
      }
    } catch (error) {
      console.error("Catch error");
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="adddoctor-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="innerSearchItem">
                <div className="leftFilterTop">
                  <div className="topSearch">
                    <input
                      type="text"
                      className="form-control searchDoc"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Search by name..."
                    />
                    <img src={SearchIcon} className="searchInner" alt="" />
                  </div>
                  {/* <div className="topFilter">
                      <img src={FilterIcon} className="filterInner" alt="" />
                    </div> */}
                </div>
              </div>
            </div>
            {/* Cards Content */}
            {loading ? (
              <Loader />
            ) : (
              <>
                <CustomInfiniteScroll
                  fetchMoreData={getAllDocsList}
                  hasMore={!last_page && !loading}
                  currentPage={page}
                >
                  {/* slideBelow */}
                  <div className=" row">
                    {docList && docList.length > 0 ? (
                      docList.map((data, index) => {
                        return (
                          <div
                            className="col-lg-3 AddcardsResponsive"
                            key={"docList" + index}
                          >
                            <div className="doctorsDetailsCard">
                              <Link
                                to={`/ViewDoctorDetails/${data.doctor_id}`}
                                className="cardLink"
                              >
                                <img
                                  src={
                                    data?.doctor_profile_image_path
                                      ? data?.doctor_profile_image_path
                                      : dummydoctor
                                  }
                                  className="docInsideImg"
                                  alt=""
                                />
                              </Link>
                              <div className="docDetailsTxt">
                                <h1>{data.doctor_name}</h1>
                                <label>
                                  {data?.specializations
                                    ? data?.specializations
                                    : ""}
                                </label>
                              </div>
                              <div className="innerButton">
                                <button
                                  className="rejectBtn"
                                  onClick={() => {
                                    doctorReject(data.doctor_id);
                                  }}
                                >
                                  Reject
                                </button>
                                <button
                                  className="AcceptBtn"
                                  onClick={() => {
                                    // doctorAccept(data.doctor_id);
                                    setIsDoctorAccept({
                                      show: true,
                                      doctorId: data.doctor_id,
                                    });
                                  }}
                                >
                                  Accept
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          height: "100vh",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="" className="text-danger">
                          No Data Found
                        </label>
                      </div>
                    )}
                  </div>
                </CustomInfiniteScroll>
              </>
            )}
          </div>
          <div className="subscription-modal">
            <Modal
              show={isDoctorAccept.show}
              onHide={() => {
                setIsDoctorAccept({
                  ...isDoctorAccept,
                  show: false,
                });
              }}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Subscription Plans</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="subscription_main_div">
                  <div className="group">
                    <label>Select Plan</label>
                    <div className="select_input">
                      <Select
                        value={
                          subscriptionPlans &&
                          subscriptionPlans.filter(
                            (item) =>
                              item.value == subscriptionPayload.subscription_id
                          )
                        }
                        options={subscriptionPlans}
                        onChange={(e) => {
                          setSubscriptionPayload((prevPayload) => {
                            const startDate = new Date(prevPayload.startDate);
                            const endDate = new Date(startDate);

                            endDate.setMonth(
                              startDate.getMonth() + e.duration_months
                            );

                            return {
                              ...prevPayload,
                              subscription_id: e.value,
                              duration_months: e.duration_months,
                              endDate,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="group">
                    <label>Start Date</label>
                    <div className="select_input">
                      <input
                        type="date"
                        className="form-control"
                        value={moment(subscriptionPayload.startDate).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value) {
                            const startDate = new Date(value);
                            const endDate = new Date(startDate);
                            endDate.setMonth(
                              startDate.getMonth() +
                                subscriptionPayload.duration_months
                            );

                            setSubscriptionPayload({
                              ...subscriptionPayload,
                              startDate,
                              endDate,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {subscriptionPayload.endDate && (
                    <div>
                      <label className="end-date">
                        End Date :{" "}
                        {subscriptionPayload.endDate &&
                          moment(subscriptionPayload.endDate).format(
                            "DD-MM-YYYY"
                          )}{" "}
                      </label>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="cancel_btn"
                  onClick={() =>
                    setIsDoctorAccept({
                      ...isDoctorAccept,
                      show: false,
                    })
                  }
                >
                  Cancel
                </button>
                <button
                  className="apply_btn"
                  onClick={() => {
                    isDoctorAccept.doctorId &&
                      doctorAccept(isDoctorAccept.doctorId);
                  }}
                >
                  Apply
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </motion.div>
    </>
  );
};
export default AddDoctor;
