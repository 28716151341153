import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Form from "react-bootstrap/Form";
import FileSelect from "../../assets/images/icons/selectFile.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  PostCallWithErrorResponse,
  PostCallWithFormData,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";
import { convertInFormData } from "../../common/commonFun";
import Select from "react-select";
import { Modal } from "react-bootstrap";

const AddProduct = () => {
  const { sidebar, navigate, setBkdropLdr, validNumber } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  // STATES
  const [validated, setValidated] = useState(false);
  const [addProductPayLoad, setAddProductPayLoad] = useState({
    product_name: "",
    product_brand_id: "",
    product_code: "",
    product_desc: "",
    productFor: "pharmacy",
    product_rate: "",
    symptom_ids: [],
    type_of_animal_id: "",
    product_type_id: "",
    product_image: "",
    is_featured: false,
    medicine_type_id: "",
    qauntity_per_package: 1,
  });
  const [show, setShow] = useState(false);
  const [animalTypeModalShow, setAnimalTypeModalShow] = useState(false);

  useEffect(() => {
    fetchLookupData();
  }, []);

  const [lookupData, setLookupData] = useState({
    brand: [],
    symptoms: [],
    animalTypes: [],
    productTypes: [],
    medicineTypes: [],
  });

  const { brand, symptoms, animalTypes, productTypes, medicineTypes } =
    lookupData;

  const fetchLookupData = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_LOOKUP_DATA_PHARMACY}?productFor=pharmacy`
      );

      const { lookupData } = res?.json;
      if (lookupData) {
        const { brand, symptoms, animalTypes, productTypes, medicineTypes } =
          lookupData;
        const tempArr =
          symptoms &&
          symptoms.map((item) => {
            return { value: item?.symptom_id, label: item?.symptom };
          });
        setLookupData({
          brand: brand ? brand : [],
          symptoms: tempArr ? tempArr : [],
          animalTypes: animalTypes ? animalTypes : [],
          productTypes: productTypes ? productTypes : [],
          medicineTypes: medicineTypes ? medicineTypes : [],
        });
      }
    } catch (error) {}
  };

  // const [errMsg, setErrMsg] = useState({
  //   symptom_ids: "",
  // });
  // ADD_PHARMACY_PRODUCT == products/addProduct
  const addPharmacyProduct = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      } else {
        if(addProductPayLoad.qauntity_per_package <= 0){
          notifyError("Quantity must be greater than zero")
          return
        }
        setBkdropLdr(true);
        const formData = convertInFormData(addProductPayLoad);
        const res = await PostCallWithFormData(
          ApiConfig.ADD_PHARMACY_PRODUCT,
          formData
        );
        const { success } = res?.json;
        if (success) {
          notifySuccess("Product added successfully");
          navigate("/MasterInventory");
        }
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setAddProductPayLoad({
      ...addProductPayLoad,
      [name]: value,
    });
  };

  const [brandNameErrMsg, setBrandNameErrMsg] = useState("");

  const [addBrandPayLoad, setaddBrandPayLoad] = useState({
    brand_name: "",
    productFor: "pharmacy",
  });

  // ADD_BRAND_NAME
  const addBrand = async () => {
    if (!addBrandPayLoad.brand_name) {
      setBrandNameErrMsg("Brand name  is required");
      return;
    }
    try {
      const res = await PostCallWithErrorResponse(
        ApiConfig.ADD_BRAND_NAME,
        addBrandPayLoad
      );
      if (res.json.success) {
        setaddBrandPayLoad({
          ...addBrandPayLoad,
          brand_name: "",
        });
        notifySuccess("Brand added successfully");
        fetchLookupData();
        setShow(false);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  // ADD_ANIMAL_TYPE
  const [animalTypeErrMsg, setAnimalTypeErrMsg] = useState("");

  const [addAnimalTypePayLoad, setAnimalTypePayLoad] = useState({
    animal_type: "",
  });

  const addAnimalType = async () => {
    if (!addAnimalTypePayLoad.animal_type) {
      setAnimalTypeErrMsg("Animal type is required");
      return;
    }
    try {
      const res = await PostCallWithErrorResponse(
        ApiConfig.ADD_ANIMAL_TYPE,
        addAnimalTypePayLoad
      );
      if (res.json.success) {
        setAnimalTypePayLoad({
          ...addAnimalTypePayLoad,
          animal_type: "",
        });
        notifySuccess("Animal type added successfully");
        fetchLookupData();
        setAnimalTypeModalShow(false);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <Form noValidate validated={validated} onSubmit={addPharmacyProduct}>
          <div className="addProduct-wrapper">
            <div className="addCardWraper">
              <div className="cardHeader">
                <label className="headTxt">Add New Product</label>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      name="product_name"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Product name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/*
                <div className="col-lg-6 col-md-12">
                    <div className="insideForm">
                    <label className="inputLabel">Brand Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Brand Name"
                    />
                    </div>
                </div>
            */}
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="inputLabel">Brand Name</label>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          textDecoration: "underline",
                          fontSize: "13px",
                        }}
                        onClick={() => setShow(true)}
                      >
                        Add brand
                      </button>
                    </div>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="product_brand_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_brand_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select brand name</option>
                      {brand &&
                        brand.map((item, idx) => {
                          return (
                            <option
                              value={item?.product_brand_id}
                              key={"brands" + idx}
                            >
                              {item?.product_brand}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Brand name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="inputLabel">Animal Type</label>
                      {/* <button
                        type="button"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          textDecoration: "underline",
                          fontSize: "13px",
                        }}
                        onClick={() => setAnimalTypeModalShow(true)}
                      >
                        Add Animal type
                      </button> */}
                    </div>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="type_of_animal_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          type_of_animal_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select animal type</option>
                      {animalTypes &&
                        animalTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.type_of_animal_id}
                              key={"animalTypes" + idx}
                            >
                              {item?.type_of_animal}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Animal type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product Type</label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="product_type_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_type_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select product type</option>
                      {productTypes &&
                        productTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.product_type_id}
                              key={"productTypes" + idx}
                            >
                              {item?.product_type}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Product type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Medicine Types</label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="medicine_type_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          medicine_type_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select medicine type</option>
                      {medicineTypes &&
                        medicineTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.medicine_type_id}
                              key={"medicineTypes" + idx}
                            >
                              {item?.medicine_type}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Medicine type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/**
              <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Animal Grooming</label>
                    <Form.Select
                      aria-label="Default select example"
                      // required
                      name="animal_grooming_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          animal_grooming_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select product type</option>
                      {productTypes &&
                        productTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.product_type_id}
                              key={"productTypes" + idx}
                            >
                              {item?.product_type}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Product type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
              */}

                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Symptoms</label>
                    <Select
                      options={lookupData.symptoms}
                      isMulti
                      onChange={(selectedValue) => {
                        const symptomIds = selectedValue
                          .map((item) => item.value)
                          .join(",");
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          symptom_ids: symptomIds,
                        });
                        // setErrMsg({
                        //   ...errMsg,
                        //   symptom_ids: "",
                        // });
                      }}
                    />
                    {/* {errMsg.symptom_ids && (
                      <div className="error-msg">{errMsg.symptom_ids}</div>
                    )} */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product Rate</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      name="product_rate"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Product rate is required
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Quantity per package</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter qauntity..."
                      required
                      name="qauntity_per_package"
                      value={addProductPayLoad.qauntity_per_package}
                      onChange={(e) => {
                        const { value } = e.target;
                        // Check if the entered value contains only numbers
                        if (validNumber.test(value)) {
                            setAddProductPayLoad({
                                ...addProductPayLoad,
                                qauntity_per_package: Number(value),
                            });
                        }
                    }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter qauntity
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Featured</label>
                    <select
                      className="form-control"
                      value={addProductPayLoad.is_featured}
                      onChange={(e) => {
                        console.log("e.target,", e.target.value);
                        const isfeatured =
                          e.target.value == "true" ? true : false;
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          is_featured: isfeatured,
                        });
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {/* <input
                      type="radio"
                      className="form-control"
                      placeholder="Enter Product Code"
                      required
                      name="product_code"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          is_featured: e.target.checked,
                        });
                      }}
                    /> */}
                    <Form.Control.Feedback type="invalid">
                      Product code is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Product Code"
                      required
                      name="product_code"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_code: e.target.value,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Product code is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Brand Description</label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ height: "100px" }}
                      placeholder="Enter Product Description"
                      required
                      name="product_desc"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Brand description is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product image</label>
                    <br />
                    <label htmlFor="SelectFile" className="selectFileBox">
                      <input
                        type="file"
                        id="SelectFile"
                        className="d-none"
                        onChange={(e) => {
                          setAddProductPayLoad({
                            ...addProductPayLoad,
                            product_image: e.target.files[0],
                          });
                        }}
                      />
                      <img src={FileSelect} className="fileImg" alt="" />
                      <label htmlFor="" className="innerFIleTXt">
                        Drop your files here.
                        <br />
                        or <span className="diffColor">Browse</span>
                      </label>
                    </label>
                  </div>
                </div>
              </div>
              {addProductPayLoad.product_image && (
                <div className="previewSection">
                  <label className="previewLabel">Preview</label>
                  <div className="previewImg">
                    <img
                      // src={FileSelect}
                      src={
                        addProductPayLoad.product_image &&
                        URL.createObjectURL(addProductPayLoad.product_image)
                      }
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="text-end">
              <button
                type="button"
                className="rejectBtn me-2"
                onClick={() => navigate("/MasterInventory")}
              >
                Cancel
              </button>
              <button className="AcceptBtn">Save</button>
            </div>
          </div>
        </Form>

        {/* ADD BRAND START */}
        <Modal
          style={{ borderRadius: "10px" }}
          show={show}
          onHide={() => setShow(false)}
          // animation={false}
          centered
        >
          <Modal.Header style={{ borderBottom: "none" }} closeButton>
            <Modal.Title style={{ color: "#4675F1", borderBottom: "none" }}>
              Brand Name
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ padding: "5px 10px" }}>
              <div style={{ marginBottom: "15px" }}>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setaddBrandPayLoad((prev) => {
                      return {
                        ...prev,
                        brand_name: e.target.value,
                      };
                    });
                    setBrandNameErrMsg("");
                  }}
                />
                {brandNameErrMsg && (
                  <div className="error-msg">{brandNameErrMsg}</div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{
                // borderColor: "#4675F1",
                border: "none",
                backgroundColor: "transparent",
                marginRight: "10px",
                padding: "5px 15px",
                borderRadius: "7px",
                color: "#4675F1",
              }}
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{
                //  border :"1px solid #4675F1",
                border: "none",
                backgroundColor: "#4675F1",
                marginRight: "10px",
                padding: "5px 15px",
                borderRadius: "7px",
                color: "#fff",
              }}
              onClick={addBrand}
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>
        {/* ADD BRAND END */}

        {/* ADD ANIMAL TYPE START */}
        <Modal
          show={animalTypeModalShow}
          onHide={() => setAnimalTypeModalShow(false)}
          // animation={false}
          centered
        >
          <Modal.Header style={{ borderBottom: "none" }} closeButton>
            <Modal.Title style={{ color: "#4675F1", borderBottom: "none" }}>
              Animal Type
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ padding: "5px 10px" }}>
              <div style={{ marginBottom: "15px" }}>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setAnimalTypePayLoad((prev) => {
                      return {
                        ...prev,
                        animal_type: e.target.value,
                      };
                    });
                    setAnimalTypeErrMsg("");
                  }}
                />
                {animalTypeErrMsg && (
                  <div className="error-msg">{animalTypeErrMsg}</div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{
                // borderColor: "#4675F1",
                border: "none",
                backgroundColor: "transparent",
                marginRight: "10px",
                padding: "5px 15px",
                borderRadius: "7px",
                color: "#4675F1",
              }}
              onClick={() => setAnimalTypeModalShow(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{
                //  border :"1px solid #4675F1",
                border: "none",
                backgroundColor: "#4675F1",
                marginRight: "10px",
                padding: "5px 15px",
                borderRadius: "7px",
                color: "#fff",
              }}
              onClick={addAnimalType}
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>
        {/* ADD ANIMAL TYPE END */}
      </motion.div>
    </>
  );
};

export default AddProduct;
