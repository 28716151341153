import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import EditPen from "../../assets/images/icons/editPen.svg";
import deleteIcon from "../../assets/images/icons/deleteIcon.svg";
import { Link } from "react-router-dom";
import { deleteWithAuthCall, simpleGetCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import moment from "moment";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";

const SubscriptionPlan = () => {

    const { sidebar, setSidebar } = useContext(AppContext);
    const [subsList, setSubsList] = useState([]);
    const [loading, setLoading] = useState(false);

    const getSubsList = () => {
        setLoading(true);
        simpleGetCall(ApiConfig.GET_SUBSCRIPTION_PLAN_LIST)
            .then((res) => {
                if (res.success) {
                    setSubsList(res?.data);
                    setLoading(false);
                } else {
                    setSubsList([]);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const deleteSubs = (id) => {
        deleteWithAuthCall(ApiConfig.DELETE_SUBSCRIPTION + `?subscription_id=${id}`)
            .then((res) => {
                if (res.success) {
                    notifySuccess(res?.message);
                    getSubsList();
                } else {
                    notifyError(res?.message)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getSubsList();
    }, [])

    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };

    return (
        <>
            <motion.div
                variants={aninations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
                className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
                id="cx-main">
                <div className="mainSubsPlanTab">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="innerSearchItem">
                                <div className="leftFilterTop">
                                    <div className="topSearch">
                                        <input
                                            type="text"
                                            name="searchTxt"
                                            autoFocus
                                            className="form-control searchDoc"
                                            placeholder="Search"
                                        />
                                        <img src={SearchIcon} className="searchInner" alt="" />
                                    </div>
                                </div>
                                <Link to="/AddSubscription" className="routButton">
                                    <button className="innerBtn">Add Subscription</button>
                                </Link>
                            </div>
                        </div>
                        {
                            loading ?
                                <Loader />
                                :
                                <>
                                    <div className="col-md-12">
                                        <div className="table-wrapper fixedHeight">
                                            <table className="defaultTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No. </th>
                                                        <th>Plan Name</th>
                                                        <th>Plan Id</th>
                                                        <th>Price</th>
                                                        <th>Sign up Fee</th>
                                                        <th>Subscription Type</th>
                                                        <th>Status</th>
                                                        <th>Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        subsList && subsList.length > 0 ?
                                                            subsList.map((data, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data?.subscription_name}</td>
                                                                        <td>{data?.subscription_id}</td>
                                                                        <td>{data?.subscription_price}</td>
                                                                        <td>{data?.renewal_price}</td>
                                                                        <td>{data?.subscription_type}</td>
                                                                        <td>1</td>
                                                                        <td>{moment(data?.created_at).format("L")}</td>
                                                                        <td>
                                                                            <Link to={`/EditSubscription/${data?.subscription_id}`} >
                                                                            <img src={EditPen} style={{ cursor: "pointer" }} className="me-2" alt="" />
                                                                        </Link>
                                                                        <img src={deleteIcon} onClick={() => deleteSubs(data?.subscription_id)} style={{ cursor: "pointer" }} alt="" />
                                                                    </td>
                                                                    </tr>
                                                )
                                                            })
                                                :
                                                <tr>
                                                    <td colSpan="9" style={{ textAlign: 'center', color: 'red' }}>No Data Found</td>
                                                </tr>
                                                    }
                                            </tbody>
                                        </table>
                                        {/* <div className="pageData">
                              <label>Showing Page {page} - {totalData}</label>
                            </div> */}
                                    </div>
                                </div>
                    </>
                        }
                </div>
            </div>
        </motion.div >
        </>
    )
}

export default SubscriptionPlan
