import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import MedicalImg from '../../assets/images/icons/medicalImg.svg'
import {
  PutCallWithErrorResponse,
  simpleGetCallWithErrorResponse
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { notifySuccess } from '../../sharedComponent/Notify'

const ViewShopDetails = () => {
  const { sidebar, setBkdropLdr, navigate } = useContext(AppContext)
  const pharmacy_shop_id =
    sessionStorage.pharmacy_shop_id && sessionStorage.pharmacy_shop_id

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }

  useEffect(() => {
    fetchProfileInfo()
  }, [])

  const [shopDetails, setShopDetails] = useState({})
  console.log('shopDetails,', shopDetails)
  // PHARMACY_SHOP_PROFILE
  const fetchProfileInfo = async () => {
    try {
      setBkdropLdr(true)
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.PHARMACY_SHOP_PROFILE}?pharmacyShopId=${pharmacy_shop_id}`
      )
      console.log('res,', res)
      const { success, data } = res?.json
      if (success) {
        data[0] && setShopDetails(data[0])
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  const approveAndReject = async (pharmacyId, isVerify) => {
    try {
      const payLoad = {
        pharmacyId: pharmacyId,
        verify: isVerify
      }
      setBkdropLdr(true)
      const res = await PutCallWithErrorResponse(
        ApiConfig.APPROVE_AND_REJECT_SHOP,
        payLoad
      )
      const { success } = res?.json
      if (success) {
        notifySuccess(
          isVerify ? 'Approved successfully' : 'Rejected successfully'
        )
        navigate('/AddPharmacy')
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  return (
    <>
      <motion.div
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
        id='cx-main'
      >
        <div className='viewPharmacy-wrapper'>
          <div className='mainDetailCard'>
            <div className='topSection'>
              <div className='innerImg'>
                <img
                  style={{ borderRadius: '10px' }}
                  src={
                    shopDetails?.profile_image_path
                      ? shopDetails?.profile_image_path
                      : MedicalImg
                  }
                  className='docImg'
                  alt=''
                />
                <div className='docDetTxt'>
                  {shopDetails?.pharmacy_shop_name && (
                    <h1 className='docName'>
                      {shopDetails?.pharmacy_shop_name}
                    </h1>
                  )}
                  {shopDetails?.type_of_pharmacy_shop && (
                    <label className='docDomain'>
                      {shopDetails?.type_of_pharmacy_shop}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className='belowDetails'>
              <div className='row'>
                {/* Heading Text */}
                <div className='col-md-12'>
                  <div className='headTittle'>
                    <h1 className='heading'>General Information</h1>
                  </div>
                </div>
                {/* Inner Values */}
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Owner Name</h1>
                    {shopDetails?.owner_name && (
                      <label htmlFor='' className='value'>
                        {shopDetails?.owner_name}
                      </label>
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Contact Number</h1>
                    {shopDetails?.phone_number && (
                      <label htmlFor='' className='value'>
                        {shopDetails?.phone_number}
                      </label>
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Email</h1>
                    {shopDetails?.email && (
                      <label htmlFor='' className='value'>
                        {shopDetails?.email}
                      </label>
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 dispNoneMedia'></div>
                {/* Heading Text */}
                <div className='col-md-12'>
                  <div className='headTittle moreMt'>
                    <h1 className='heading'>Shop Information</h1>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Shop Name</h1>
                    {shopDetails?.pharmacy_shop_name && (
                      <label htmlFor='' className='value'>
                        {shopDetails?.pharmacy_shop_name}
                      </label>
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Shop Type</h1>
                    {shopDetails?.type_of_pharmacy_shop && (
                      <label htmlFor='' className='value'>
                        {shopDetails?.type_of_pharmacy_shop}
                      </label>
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Address</h1>
                    {shopDetails?.pharmacy_shop_address && (
                      <label htmlFor='' className='value'>
                        {shopDetails?.pharmacy_shop_address}
                      </label>
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>License Number</h1>
                    {shopDetails?.pharmacy_shop_license_number && (
                      <label htmlFor='' className='value'>
                        {shopDetails?.pharmacy_shop_license_number}
                      </label>
                    )}
                  </div>
                </div>
                {/* Heading Text */}
                <div className='col-md-12'>
                  <div className='headTittle moreMt'>
                    <h1 className='heading'>Bank Details</h1>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Bank Name</h1>
                    {shopDetails?.bank_details &&
                    shopDetails?.bank_details[0]?.pharmacy_bank ? (
                      <label htmlFor='' className='value'>
                        {shopDetails?.bank_details[0]?.pharmacy_bank
                          ? shopDetails?.bank_details[0]?.pharmacy_bank
                          : '-'}
                      </label>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Account Number</h1>
                    {shopDetails?.bank_details &&
                    shopDetails?.bank_details[0]?.account_number ? (
                      <label htmlFor='' className='value'>
                        {shopDetails?.bank_details[0]?.account_number
                          ? shopDetails?.bank_details[0]?.account_number
                          : '-'}
                      </label>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Account Holder Name</h1>
                    {shopDetails?.bank_details &&
                    shopDetails?.bank_details[0]?.account_holder_name ? (
                      <label htmlFor='' className='value'>
                        {shopDetails?.bank_details[0]?.account_holder_name
                          ? shopDetails?.bank_details[0]?.account_holder_name
                          : '-'}
                      </label>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>IFSC / SWIFT Code</h1>
                    {shopDetails?.bank_details &&
                    shopDetails?.bank_details[0]?.ifsc_code ? (
                      <label htmlFor='' className='value'>
                        {shopDetails?.bank_details[0]?.ifsc_code
                          ? shopDetails?.bank_details[0]?.ifsc_code
                          : '-'}
                      </label>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-end'>
            <button
              className='rejectBtn me-2'
              type='button'
              onClick={() => approveAndReject(shopDetails?.pharmacy_id, false)}
            >
              Reject
            </button>
            <button
              className='AcceptBtn'
              type='button'
              onClick={() => approveAndReject(shopDetails?.pharmacy_id, true)}
            >
              Accept
            </button>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default ViewShopDetails
