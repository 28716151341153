import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifySuccess } from "../../sharedComponent/Notify";
import { Form } from "react-bootstrap";

const AddPetCategory = () => {
  const { sidebar, setBkdropLdr, navigate } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  // STATES
  const [validated, setValidated] = useState(false);
  const [categoryPayLoad, setCategoryPayLoad] = useState({
    category_name: "",
    productFor: "petshop",
  });
  // STATES

  // ADD_PHARMACY_CATEGORY === products/addCategory
  const addPharmacyProductCategory = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      } else {
        setBkdropLdr(true);
        const res = await PostCallWithErrorResponse(
          ApiConfig.ADD_PHARMACY_CATEGORY,
          categoryPayLoad
        );
        const { success } = res?.json;
        if (success === true) {
          notifySuccess("Category added successfully");
          navigate("/vetPetInventory");
        }
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={addPharmacyProductCategory}
        >
          <div className="addProduct-wrapper">
            <div className="addCardWraper">
              <div className="cardHeader">
                <label className="headTxt">Add New Product Category</label>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Category Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Category Name"
                      required
                      onChange={(e) =>
                        setCategoryPayLoad({
                          ...categoryPayLoad,
                          category_name: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Category name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="rejectBtn me-2"
                onClick={() => {
                  navigate("/vetPetInventory");
                  //   setPharmacuEventKey("ProductCategory");
                }}
              >
                Cancel
              </button>
              <button className="AcceptBtn">Save</button>
            </div>
          </div>
        </Form>
      </motion.div>
    </>
  );
};

export default AddPetCategory;
