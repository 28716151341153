import React from 'react'

const NoData = () => {
  return (
    <div style={{height:"100vh", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <label className='text-danger'>No Data Found</label>
    </div>
  )
}

export default NoData
