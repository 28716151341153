import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Nav, Tab } from "react-bootstrap";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import ic_export from "../../assets/images/icons/ic_export.svg";
import roundCircle from "../../assets/images/headerIcons/headerCircle.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import { Link } from "react-router-dom";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import ic_location from "../../assets/images/icons/ic_location.svg";
import ic_action from "../../assets/images/icons/ic_action.svg";
import arrowleft from "../../assets/images/icons/arrowleft.svg";

import car1 from "../../assets/images/ReportIcons/catarigy (1).svg";
import car2 from "../../assets/images/ReportIcons/catarigy (2).svg";
import car3 from "../../assets/images/ReportIcons/catarigy (3).svg";
import car4 from "../../assets/images/ReportIcons/catarigy (4).svg";
import car5 from "../../assets/images/ReportIcons/catarigy (5).svg";
import car6 from "../../assets/images/ReportIcons/catarigy (6).svg";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
const UsersReports = () => {
  const { sidebar, setSidebar } = useContext(AppContext);
  const [subscription, setSubscription] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState(1);
  const [page_limit, setPage_limit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [last_page, setLast_page] = useState(false);
  const [userReportsData, setUserReportsData] = useState([]);

  const getUserReports = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_NEW_JOINED_DETAILS +
        `?page=${page}&page_limit=${page_limit}`
    )
      .then((res) => {
        if (res.success === true) {
          setUserReportsData(res?.data);
          setLoading(false);
        } else {
          setUserReportsData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchTxt === "") {
      setLoading(true);
      getUserReports();
    } else {
      getUserReports();
    }
  }, [searchTxt]);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="revenueReports-wrapper">
            <div className="reportsTabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                  <Nav.Item>
                    <Nav.Link eventKey="1">New Joined</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                                    <Nav.Link eventKey="2">Subscription</Nav.Link>
                                </Nav.Item>
                                <Nav.Item> 
                                    <Nav.Link eventKey="3">Ongoing Subscription</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="4">Expired Subscription</Nav.Link>
                                </Nav.Item> */}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <div className=" commmon-top-search-section">
                      <div className="row align-items-center">
                        <div className="col-md-12 col-lg-4">
                          <div className="innerSearchItem">
                            <div className="topSearch">
                              <input
                                type="text"
                                className="form-control searchDoc"
                                placeholder="Search by Name, Specialist, Ratings, Visits..."
                              />
                              <img
                                src={SearchIcon}
                                className="searchInner"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                          {/* <div className="topFilter">
                            <img
                              src={FilterIcon}
                              className="filterInner"
                              alt=""
                            />
                          </div> */}
                          <div className="topFilter">
                            <Link to="/AddPetProduct">
                              <div className="addBox">
                                <img src={ic_export} alt="" />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-wrapper">
                      <table className="defaultTable">
                        <thead>
                          <tr>
                            <th>Sr No. </th>
                            <th>User Name</th>
                            <th>User Type</th>
                            <th>Contact No.</th>
                            <th>Joining Date</th>
                            <th className="center">Action</th>
                          </tr>
                        </thead>
                        {userReportsData && userReportsData.length > 0 ? (
                          <tbody className="tableBody">
                            {userReportsData.map((data, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <img
                                      src={roundCircle}
                                      className="rounded-circle tableImg"
                                      alt=""
                                    />
                                    {data?.user_name}
                                  </td>
                                  <td>{data?.user_type}</td>
                                  <td>{data?.contact_no}</td>
                                  <td>{data?.joining_date}</td>
                                  <td>
                                    <img
                                      src={approved}
                                      className="checks tableImg"
                                      alt=""
                                    />
                                    Approved
                                  </td>
                                </tr>
                              );
                            })}
                            <>
                            {/* <tr>
                              <td>2</td>
                              <td>
                                <img
                                  src={roundCircle}
                                  className="rounded-circle tableImg"
                                  alt=""
                                />
                                Ronald D’souza
                              </td>
                              <td>Doctor</td>
                              <td>+91 9999 9999 99</td>
                              <td>State</td>
                              <td>
                                <img
                                  src={ic_location}
                                  alt=""
                                  className="tableImg"
                                />
                                <Link className="map-link">View in Map</Link>
                              </td>
                              <td>12-06-2023</td>
                              <td>$15 (Monthly Sub.)</td>
                              <td>
                                <img
                                  src={approved}
                                  className="checks tableImg"
                                  alt=""
                                />
                                Approved
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>
                                <img
                                  src={roundCircle}
                                  className="rounded-circle tableImg"
                                  alt=""
                                />
                                Manpreet Singh
                              </td>
                              <td>Doctor</td>
                              <td>+91 9999 9999 99</td>
                              <td>State</td>
                              <td>
                                <img
                                  src={ic_location}
                                  alt=""
                                  className="tableImg"
                                />
                                <Link className="map-link">View in Map</Link>
                              </td>
                              <td>12-06-2023</td>
                              <td>$15 (Monthly Sub.)</td>
                              <td>
                                <img
                                  src={approved}
                                  className="checks tableImg"
                                  alt=""
                                />
                                Approved
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>
                                <img
                                  src={roundCircle}
                                  className="rounded-circle tableImg"
                                  alt=""
                                />
                                Shreyas Gopal
                              </td>
                              <td>Doctor</td>
                              <td>+91 9999 9999 99</td>
                              <td>State</td>
                              <td>
                                <img
                                  src={ic_location}
                                  alt=""
                                  className="tableImg"
                                />
                                <Link className="map-link">View in Map</Link>
                              </td>
                              <td>12-06-2023</td>
                              <td>$15 (Monthly Sub.)</td>
                              <td>
                                <img
                                  src={cancel}
                                  className="checks tableImg"
                                  alt=""
                                />
                                Failed
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>
                                <img
                                  src={roundCircle}
                                  className="rounded-circle tableImg"
                                  alt=""
                                />
                                David Millan
                              </td>
                              <td>Doctor</td>
                              <td>+91 9999 9999 99</td>
                              <td>State</td>
                              <td>
                                <img
                                  src={ic_location}
                                  alt=""
                                  className="tableImg"
                                />
                                <Link className="map-link">View in Map</Link>
                              </td>
                              <td>12-06-2023</td>
                              <td>$15 (Monthly Sub.)</td>
                              <td>
                                <img
                                  src={pending}
                                  className="checks tableImg"
                                  alt=""
                                />
                                Pending
                              </td>
                            </tr> */}
                            </>
                          </tbody>
                        ) : null}
                      </table>
                    </div>
                  </Tab.Pane>
                  <>
                    {/* <Tab.Pane eventKey="2">
                                    <div className=" commmon-top-search-section">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-lg-4">
                                                <div className="innerSearchItem">
                                                    <div className="topSearch">
                                                        <input
                                                            type="text"
                                                            className="form-control searchDoc"
                                                            placeholder="Search by Name, Specialist, Ratings, Visits..."
                                                        />
                                                        <img
                                                            src={SearchIcon}
                                                            className="searchInner"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                                                <div className="topFilter">
                                                    <img src={FilterIcon} className='filterInner' alt="" />
                                                </div>
                                                <div className="topFilter">
                                                    <Link to="/AddPetProduct">
                                                        <div className="addBox">
                                                            <img src={ic_export} alt="" />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {subscription === true ?
                                        <div className="table-wrapper">
                                            <table className="defaultTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No. </th>
                                                        <th>Subscription’s</th>
                                                        <th>No. of Subscriber’s</th>
                                                        <th className="center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tableBody">
                                                    <tr>
                                                        <td>1</td>
                                                        <td>
                                                            <img
                                                                src={car1}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Doctor
                                                        </td>
                                                        <td>1000</td>
                                                        <td className="center">
                                                            <Link onClick={() => { setSubscription(false) }}>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>
                                                            <img
                                                                src={car2}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Pharmacy
                                                        </td>
                                                        <td>200</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>
                                                            <img
                                                                src={car3}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Pet Shop
                                                        </td>
                                                        <td>20</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>
                                                            <img
                                                                src={car4}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Vaccinator
                                                        </td>
                                                        <td>580</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>
                                                            <img
                                                                src={car5}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Ambulance
                                                        </td>
                                                        <td>240</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>
                                                            <img
                                                                src={car6}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Delivery Boy
                                                        </td>
                                                        <td>240</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <>
                                            <div>
                                                <div className="doc-name-back-btn" onClick={() => { setSubscription(true) }}>
                                                    <Link >
                                                        <img src={arrowleft} alt="" />
                                                    </Link>
                                                    <label htmlFor="" className=" ms-3">Showing result for : </label> <span className="innerDatils">Doctor</span>
                                                </div>
                                                <div className="table-wrapper">
                                                    <table className="defaultTable">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No. </th>
                                                                <th>Doctor Name</th>
                                                                <th>Contact No.</th>
                                                                <th>Location</th>
                                                                <th>Specialty</th>
                                                                <th>Joining Date</th>
                                                                <th>Subscription Plan</th>
                                                                <th>Due Date</th>
                                                                <th className="center">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tableBody">
                                                            <tr>
                                                                <td>1</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-2023
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className='red'>
                                                                    12-06-2023
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className='red'>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="3">
                                    <div className=" commmon-top-search-section">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-lg-4">
                                                <div className="innerSearchItem">
                                                    <div className="topSearch">
                                                        <input
                                                            type="text"
                                                            className="form-control searchDoc"
                                                            placeholder="Search by Name, Specialist, Ratings, Visits..."
                                                        />
                                                        <img
                                                            src={SearchIcon}
                                                            className="searchInner"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                                                <div className="topFilter">
                                                    <img src={FilterIcon} className='filterInner' alt="" />
                                                </div>
                                                <div className="topFilter">
                                                    <Link to="/AddPetProduct">
                                                        <div className="addBox">
                                                            <img src={ic_export} alt="" />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {subscription === true ?
                                        <div className="table-wrapper">
                                            <table className="defaultTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No. </th>
                                                        <th>Subscription’s</th>
                                                        <th>Ongoing Subscription</th>
                                                        <th className="center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tableBody">
                                                    <tr>
                                                        <td>1</td>
                                                        <td>
                                                            <img
                                                                src={car1}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Doctor
                                                        </td>
                                                        <td>1000</td>
                                                        <td className="center">
                                                            <Link onClick={() => { setSubscription(false) }}>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>
                                                            <img
                                                                src={car2}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Pharmacy
                                                        </td>
                                                        <td>200</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>
                                                            <img
                                                                src={car3}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Pet Shop
                                                        </td>
                                                        <td>20</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>
                                                            <img
                                                                src={car4}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Vaccinator
                                                        </td>
                                                        <td>580</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>
                                                            <img
                                                                src={car5}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Ambulance
                                                        </td>
                                                        <td>240</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>
                                                            <img
                                                                src={car6}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Delivery Boy
                                                        </td>
                                                        <td>240</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <>
                                            <div>
                                                <div className="doc-name-back-btn" onClick={() => { setSubscription(true) }}>
                                                    <Link >
                                                        <img src={arrowleft} alt="" />
                                                    </Link>
                                                    <label htmlFor="" className=" ms-3">Showing result for : </label> <span className="innerDatils">Doctor</span>
                                                </div>
                                                <div className="table-wrapper">
                                                    <table className="defaultTable">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No. </th>
                                                                <th>Doctor Name</th>
                                                                <th>Contact No.</th>
                                                                <th>Location</th>
                                                                <th>Specialty</th>
                                                                <th>Joining Date</th>
                                                                <th>Subscription Plan</th>
                                                                <th>Due Date</th>
                                                                <th className="center">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tableBody">
                                                            <tr>
                                                                <td>1</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-2023
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className='red'>
                                                                    12-06-2023
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className='red'>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="4">
                                    <div className=" commmon-top-search-section">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-lg-4">
                                                <div className="innerSearchItem">
                                                    <div className="topSearch">
                                                        <input
                                                            type="text"
                                                            className="form-control searchDoc"
                                                            placeholder="Search by Name, Specialist, Ratings, Visits..."
                                                        />
                                                        <img
                                                            src={SearchIcon}
                                                            className="searchInner"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                                                <div className="topFilter">
                                                    <img src={FilterIcon} className='filterInner' alt="" />
                                                </div>
                                                <div className="topFilter">
                                                    <Link to="/AddPetProduct">
                                                        <div className="addBox">
                                                            <img src={ic_export} alt="" />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {subscription === true ?
                                        <div className="table-wrapper">
                                            <table className="defaultTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No. </th>
                                                        <th>Subscription’s</th>
                                                        <th>Expired Subscriptions</th>
                                                        <th className="center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tableBody">
                                                    <tr>
                                                        <td>1</td>
                                                        <td>
                                                            <img
                                                                src={car1}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Doctor
                                                        </td>
                                                        <td>1000</td>
                                                        <td className="center">
                                                            <Link onClick={() => { setSubscription(false) }}>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>
                                                            <img
                                                                src={car2}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Pharmacy
                                                        </td>
                                                        <td>200</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>
                                                            <img
                                                                src={car3}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Pet Shop
                                                        </td>
                                                        <td>20</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>
                                                            <img
                                                                src={car4}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Vaccinator
                                                        </td>
                                                        <td>580</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>
                                                            <img
                                                                src={car5}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Ambulance
                                                        </td>
                                                        <td>240</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>
                                                            <img
                                                                src={car6}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Delivery Boy
                                                        </td>
                                                        <td>240</td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <>
                                            <div>
                                                <div className="doc-name-back-btn" onClick={() => { setSubscription(true) }}>
                                                    <Link >
                                                        <img src={arrowleft} alt="" />
                                                    </Link>
                                                    <label htmlFor="" className=" ms-3">Showing result for : </label> <span className="innerDatils">Doctor</span>
                                                </div>
                                                <div className="table-wrapper">
                                                    <table className="defaultTable">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No. </th>
                                                                <th>Doctor Name</th>
                                                                <th>Contact No.</th>
                                                                <th>Location</th>
                                                                <th>Specialty</th>
                                                                <th>Joining Date</th>
                                                                <th>Subscription Plan</th>
                                                                <th>Due Date</th>
                                                                <th className="center">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tableBody">
                                                            <tr>
                                                                <td>1</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-2023
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className='red'>
                                                                    12-06-2023
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className='red'>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td>
                                                                    12-06-21
                                                                </td>
                                                                <td className="center">
                                                                    <img
                                                                        src={approved}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Active
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </Tab.Pane> */}
                  </>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        )}
      </motion.div>
    </>
  );
};

export default UsersReports;
