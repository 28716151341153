export const convertInFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      object[key].forEach((item, index) => {
        formData.append(`${key}`, item);
      });
      // formData.append('animalphotos', file, `animal_photo_${i}.jpg`)
    } else {
      formData.append(key, object[key]);
    }
  });
  return formData;
};

export function formatCurrency(amount, locale = 'en-US', currencyCode = 'INR') {
  const inpunAmt = Number(amount)
  const amt = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).format(inpunAmt)
  return amt ? amt : ""
}

export function formatTotalReviews(totalReviews) {
  const inputVal = Number(totalReviews)
  if (inputVal >= 1000) {
    const formattedReviews = (inputVal / 1000).toFixed(1);
    return `${formattedReviews}k`;
  } else {
    return inputVal.toString();
  }
}

export function getYears(startYear, endYear) {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
}

