import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import ThreeDots from "../../assets/images/icons/ThreeDots.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import Patient from "../../assets/images/icons/patientImg.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import DogImg from "../../assets/images/icons/dogImg.svg";
import ic_star_deactive from "../../assets/images/icons/ic_star_deactive.svg";
import catImg from "../../assets/images/icons/catImg.svg";
import Chart from "react-apexcharts";
import Star from "../../assets/images/icons/ratingStar.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Print from "../../assets/images/icons/PrintImg.svg";
import AmbulanceImg from "../../assets/images/icons/AmbulanceImg.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ic_location from "../../assets/images/icons/ic_location.svg";
import dummyprofile from "../../assets/images/dummyprofile.jpeg";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifySuccess } from "../../sharedComponent/Notify";
import moment from "moment";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";

const AmbulanceDetails = () => {
  const { sidebar, navigate, setBkdropLdr } = useContext(AppContext);
  const [payMentDetails, setPayMentDetails] = useState({});
  const [totalRevenuGraph, setTotalRevenuGraph] = useState({
    series: [
      {
        name: "Amount",
        data: [],
        // data: [0, 500, 100, 700, 300],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#4675F1"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#34B53A"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "₹" +
            "</div>"
          );
        },
      },
    },
  });

  const [monthGraph, setTotalMonthGraph] = useState({
    series: [
      {
        name: "Amount",
        data: [],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#D0182E"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#D0182E"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [clickedOrder, setClickedOrder] = useState(false);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const tooltip = (
    <Tooltip id="tooltip" className="innerToolTip">
      Emergency as my dog is unable to breathe and need immediate assistance
    </Tooltip>
  );
  const NumberTT = (
    <Tooltip id="tooltip" className="innerToolTip">
      <div className="numberFlex">
        <div className="aboveDEtail">
          <label className="key">Driver : </label>
          <label className="value">Floyd Miles</label>
        </div>
        <div className="belowDEtail">
          <label className="key">Ambulance Type : </label>
          <label className="value">Mini Van</label>
        </div>
      </div>
    </Tooltip>
  );

  const params = useParams();
  const ambulance_driver_id = params?.id;
  const ambulance_id = sessionStorage.ambulance_id;

  const disableAmbulance = async (is_disabled) => {
    try {
      const payLoad = {
        ambulance_driver_id: ambulance_driver_id,
        is_disabled: is_disabled ? true : false,
      };
      const res = await PostCallWithErrorResponse(
        ApiConfig.DISABLE_AMBULANCE,
        payLoad
      );
      const { success, message } = res.json;
      if (success) {
        notifySuccess(message);
        navigate("/Ambulances");
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  const removeAmbulance = async () => {
    try {
      const payLoad = {
        ambulance_driver_id: ambulance_driver_id,
        is_disabled: false,
      };
      const res = await PostCallWithErrorResponse(
        ApiConfig.DISABLE_AMBULANCE,
        payLoad
      );
      const { success, message } = res.json;
      if (success) {
        notifySuccess(message);
        navigate("/Ambulances");
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };
  const [ambulanceDetails, setAmbulanceDetails] = useState({});
  console.log("ambulanceDetails,", ambulanceDetails);
  useEffect(() => {
    fetchAmbulanceDetails();
    fetchPaymentDetailsRequestList();
    fetchTransactionList();
    fetchRatingAndReviews();
    fetchPaymentDetails();
  }, []);

  const fetchAmbulanceDetails = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.AMBULANCE_DETAILS}?ambulance_driver_id=${ambulance_driver_id}`
      );
      const { success, data } = res?.json;
      if (success && data) {
        data[0] && setAmbulanceDetails(data[0]);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };
  // FETCH_REQUEST_LIST
  const [allRequestList, setAllRequestList] = useState([]);
  const fetchPaymentDetailsRequestList = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.FETCH_REQUEST_LIST}?ambulance_id=${ambulance_id}`
      );
      const { success, data } = res?.json;

      if (success && data) {
        data && setAllRequestList(data);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [transactionList, setTransactionList] = useState([]);
  const fetchTransactionList = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.TRANSACTION_LIST}?ambulance_id=${ambulance_id}`
      );
      const { success, data } = res?.json;
      if (success && data) {
        data && setTransactionList(data);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  // RATING_AND_RAVIEWS
  const [reviews, setReviews] = useState([]);
  const [ratingDetails, setRatingDetails] = useState({});
  const totalRating =
    ratingDetails.deliveryBoyRatings &&
    ratingDetails.deliveryBoyRatings.total_ratings &&
    ratingDetails.deliveryBoyRatings.total_ratings;

  const avgRating =
    ratingDetails.deliveryBoyRatings &&
    ratingDetails.deliveryBoyRatings.avg_rating &&
    ratingDetails.deliveryBoyRatings.avg_rating;
  // const ratingArr =
  //   ratingDetails.deliveryBoyRatings &&
  //   Object.keys(ratingDetails.deliveryBoyRatings).map((item, idx) => {
  //     return {
  //       [item]: ratingDetails.deliveryBoyRatings[item],
  //     };
  //   });
  const ratingArr =
    ratingDetails.deliveryBoyRatings &&
    Object.values(ratingDetails.deliveryBoyRatings);
  const ratingPercentage = ratingArr && ratingArr.slice(2, 7).reverse();
  const fetchRatingAndReviews = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.RATING_AND_RAVIEWS}?ambulance_id=${ambulance_id}`
      );
      const { success, data, ratings } = res?.json;
      if (success && data) {
        data && setReviews(data);
        ratings && setRatingDetails(ratings);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const fetchPaymentDetails = async () => {
    try {
      setBkdropLdr(true);
      const payLoad = {
        ambulance_id: ambulance_id,
      };
      const res = await PostCallWithErrorResponse(
        ApiConfig.PAYMENT_DETAILS,
        payLoad
      );
      const { success, totalRevenueGraph, monthlyGraph } = res?.json;
      if (success && totalRevenueGraph && monthlyGraph) {
        const totalMonth = monthlyGraph?.currentMonthPerDay?.data;
        const totalRevenue = totalRevenueGraph?.perMonth;
        setTotalRevenuGraph({
          ...totalRevenuGraph,
          series: [
            {
              name: "Amount",
              data: totalRevenue ? totalRevenue : [0, 0, 0, 0, 0, 0],
              // data: [0, 500, 100, 700, 300],
            },
          ],
        });
        setTotalMonthGraph({
          ...totalRevenuGraph,
          series: [
            {
              name: "Amount",
              data: totalMonth ? totalMonth : [0, 0, 0, 0, 0, 0],
              // data: [0, 500, 100, 700, 300],
            },
          ],
        });
        setPayMentDetails({
          totalRevenueGraph,
          monthlyGraph,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="doctorDetails-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                <img
                  src={
                    ambulanceDetails?.vehicle_image_path
                      ? ambulanceDetails?.vehicle_image_path
                      : AmbulanceImg
                  }
                  className="docImg"
                  alt="Ambulance"
                />
                <div className="docDetTxt">
                  <div>
                    <h1 className="docName">
                      {" "}
                      {ambulanceDetails?.account_holder_name}
                    </h1>
                    <label className="docDomain">
                      {ambulanceDetails?.vehicle_type}
                    </label>
                  </div>

                  {ambulanceDetails?.is_disabled && (
                    <div className="disable_img">
                      <img src={disabled} />{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="dropDown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={ThreeDots} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        disableAmbulance(!ambulanceDetails?.is_disabled)
                      }
                    >
                      <Link to="#">
                        {ambulanceDetails?.is_disabled ? "Enable" : "Disable"}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={removeAmbulance}>
                      <Link to="#">Remove</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="doctorTabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Profile">
                <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                  <Nav.Item>
                    <Nav.Link eventKey="Profile">Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Requests">Requests</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Transaction">Transaction</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="PaymentDetails">
                      Payment Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="RatingReviews">
                      Rating & Reviews
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Profile">
                    <div className="row">
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle">
                          <h1 className="heading">General Information</h1>
                        </div>
                      </div>
                      {/* Inner Values */}
                      <div className="col-lg-12">
                        <h1 className="AmbulanceHead">Ambulance -101</h1>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Driver Name</h1>
                          <label htmlFor="" className="value">
                            {ambulanceDetails?.ambulance_driver_name}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Driver Contact No.</h1>
                          <label htmlFor="" className="value">
                            {ambulanceDetails?.ambulance_driver_contact_no}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Vehicle type</h1>
                          <label htmlFor="" className="value">
                            {ambulanceDetails?.vehicle_type}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Vehicle No.</h1>
                          <label htmlFor="" className="value">
                            {ambulanceDetails?.vehicle_no}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="belowLine"></div>
                      </div>
                      {/* 
                      <div className="col-lg-12">
                        <h1 className="AmbulanceHead">Ambulance -102</h1>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Driver Name</h1>
                          <label htmlFor="" className="value">
                            Floyd Miles
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Driver Contact No.</h1>
                          <label htmlFor="" className="value">
                            +91 9999 9999 99
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Vehicle type</h1>
                          <label htmlFor="" className="value">
                            Van
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Vehicle No.</h1>
                          <label htmlFor="" className="value">
                            MH09GF1234
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="belowLine"></div>
                      </div>
                      <div className="col-lg-12">
                        <h1 className="AmbulanceHead">Ambulance -103</h1>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Driver Name</h1>
                          <label htmlFor="" className="value">
                            Floyd Miles
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Driver Contact No.</h1>
                          <label htmlFor="" className="value">
                            +91 9999 9999 99
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Vehicle type</h1>
                          <label htmlFor="" className="value">
                            Van
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Vehicle No.</h1>
                          <label htmlFor="" className="value">
                            MH09GF1234
                          </label>
                        </div>
                      </div>
                      */}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Requests">
                    {allRequestList && allRequestList.length > 0 ? (
                      <div className="mainAppointments">
                        <div className="allApointmentsRow">
                          <h1 className="headTxt">All Request’s</h1>
                          <img src={FilterIcon} className="FilImg" alt="" />
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="incedoTable">
                              <table className="defaultTable">
                                <thead>
                                  <tr>
                                    <th>Sr No. </th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Patient</th>
                                    <th>Pet Type</th>
                                    <th>Description</th>
                                    <th>Ambulance</th>
                                    <th>Location</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody className="tableBody">
                                  {allRequestList.map((item, idx) => {
                                    return (
                                      <tr key={"allRequest" + idx}>
                                        <td>{idx + 1}</td>
                                        <td>
                                          {moment(
                                            item?.transaction_date
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                          {moment(
                                            item?.transaction_date
                                          ).format("hh:mm:ss A")}
                                        </td>
                                        <td>
                                          {/* <img
                                    src={Patient}
                                    className="rounded-circle tableImg"
                                    alt=""
                                  /> */}

                                          {item?.animal_name}
                                        </td>
                                        <td>{item?.type_of_animal}</td>
                                        <td>
                                          {/*
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={tooltip}
                                      >
                                      
                                      </OverlayTrigger>
                                      */}
                                          <label className="insiderWidth">
                                            {item.ambulance_booking_reason}
                                          </label>
                                        </td>
                                        <td>
                                          {/* <OverlayTrigger
                                    placement="top"
                                    overlay={NumberTT}
                                  ></OverlayTrigger> */}

                                          <label>{item.vehicle_no}</label>
                                        </td>
                                        <td>
                                          {" "}
                                          <img
                                            src={ic_location}
                                            alt=""
                                            className="tableImg"
                                          />
                                          <Link className="map-link">
                                            View in Map
                                          </Link>
                                        </td>
                                        <td>
                                          {" "}
                                          <img
                                            src={
                                              item?.status === "Completed"
                                                ? approved
                                                : item?.status === "pending"
                                                ? pending
                                                : cancel
                                            }
                                            className="checks"
                                            alt=""
                                          />
                                          {item?.status === "Completed"
                                            ? "Completed"
                                            : item?.status === "pending"
                                            ? "Pending"
                                            : "Rejected"}
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  {/*
                              <tr>
                              <td>2</td>
                              <td>12-06-2023</td>
                              <td>04:58:58s</td>
                              <td>
                                <img
                                  src={Patient}
                                  className="rounded-circle tableImg"
                                  alt=""
                                />
                                Drogo
                              </td>

                              <td>Dog</td>
                              <td>
                                {" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip}
                                >
                                  <label className="insiderWidth">
                                    Emergency as my dog is unable to breathe
                                    and need immediate assistance
                                  </label>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={NumberTT}
                                >
                                  <label>MH09GF1234</label>
                                </OverlayTrigger>
                              </td>
                              <td>
                                {" "}
                                <img
                                  src={ic_location}
                                  alt=""
                                  className="tableImg"
                                />
                                <Link className="map-link">
                                  View in Map
                                </Link>
                              </td>
                              <td>
                                {" "}
                                <img
                                  src={cancel}
                                  className="checks"
                                  alt=""
                                />
                                Rejected
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>12-06-2023</td>
                              <td>04:58:58s</td>
                              <td>
                                <img
                                  src={Patient}
                                  className="rounded-circle tableImg"
                                  alt=""
                                />
                                Drogo
                              </td>
                              <td>Dog</td>
                              <td>
                                {" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip}
                                >
                                  <label className="insiderWidth">
                                    Emergency as my dog is unable to breathe
                                    and need immediate assistance
                                  </label>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={NumberTT}
                                >
                                  <label>MH09GF1234</label>
                                </OverlayTrigger>
                              </td>
                              <td>
                                {" "}
                                <img
                                  src={ic_location}
                                  alt=""
                                  className="tableImg"
                                />
                                <Link className="map-link">
                                  View in Map
                                </Link>
                              </td>
                              <td>
                                {" "}
                                <img
                                  src={approved}
                                  className="checks"
                                  alt=""
                                />
                                Checked
                              </td>
                            </tr>
                            */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NoDataMsg MSG={"No Request found"} />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Transaction">
                    {transactionList && transactionList.length > 0 ? (
                      <div className="mainAppointments">
                        <div className="allApointmentsRow">
                          <h1 className="headTxt">All Transaction's</h1>
                          <img src={FilterIcon} className="FilImg" alt="" />
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="incedoTable">
                              <table className="defaultTable">
                                <thead>
                                  <tr>
                                    <th>Sr No. </th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Patient</th>
                                    <th>Pet Type</th>
                                    <th>Ambulance</th>
                                    {/*<th>Location</th>*/}
                                    <th>Charges</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody className="tableBody">
                                  {transactionList.map((item, idx) => {
                                    return (
                                      <tr>
                                        <td>{idx + 1}</td>
                                        <td>
                                          {moment(item.transaction_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {moment(item.transaction_date).format(
                                            "hh:mm:ss A"
                                          )}
                                        </td>
                                        <td>
                                          {/* 
                                                <img
                                                src={Patient}
                                                className="rounded-circle tableImg"
                                                alt=""
                                                />
                                                */}

                                          {item.animal_name}
                                        </td>

                                        <td>{item?.type_of_animal}</td>
                                        <td>{item?.vehicle_no}</td>
                                        {/*
                                                  <td>
                                                  <td>
                                                    {" "}
                                                    <img
                                                      src={ic_location}
                                                      alt=""
                                                      className="tableImg"
                                                    />
                                                    <Link className="map-link">
                                                      View in Map
                                                    </Link>
                                                  </td>
                                                </td>
                                                */}

                                        <td>₹{item?.total_charges}</td>
                                        <td>
                                          {" "}
                                          <img
                                            src={
                                              item.status === "success"
                                                ? approved
                                                : item.status === "pending"
                                                ? pending
                                                : cancel
                                            }
                                            className="checks"
                                            alt=""
                                          />
                                          {item.status === "success"
                                            ? "Completed"
                                            : item.status === "pending"
                                            ? "Pending"
                                            : "Rejected"}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  {/* <tr>
                                         <td>2</td>
                                         <td>12-06-2023</td>
                                         <td>04:58:58s</td>
                                         <td>
                                           <img
                                             src={Patient}
                                             className="rounded-circle tableImg"
                                             alt=""
                                           />
                                           Drogo
                                         </td>
          
                                         <td>Dog</td>
                                         <td>MH09GF1234</td>
                                         <td>
                                           <td>
                                             {" "}
                                             <img
                                               src={ic_location}
                                               alt=""
                                               className="tableImg"
                                             />
                                             <Link className="map-link">
                                               View in Map
                                             </Link>
                                           </td>
                                         </td>
                                         <td>$200</td>
                                         <td>
                                           {" "}
                                           <img
                                             src={cancel}
                                             className="checks"
                                             alt=""
                                           />
                                           Rejected
                                         </td>
                                       </tr>
                                       <tr>
                                         <td>3</td>
                                         <td>12-06-2023</td>
                                         <td>04:58:58s</td>
                                         <td>
                                           <img
                                             src={Patient}
                                             className="rounded-circle tableImg"
                                             alt=""
                                           />
                                           Drogo
                                         </td>
          
                                         <td>Dog</td>
                                         <td>MH09GF1234</td>
                                         <td>
                                           <td>
                                             {" "}
                                             <img
                                               src={ic_location}
                                               alt=""
                                               className="tableImg"
                                             />
                                             <Link className="map-link">
                                               View in Map
                                             </Link>
                                           </td>
                                         </td>
                                         <td>$200</td>
                                         <td>
                                           {" "}
                                           <img
                                             src={approved}
                                             className="checks"
                                             alt=""
                                           />
                                           Checked
                                         </td>
                                       </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NoDataMsg MSG={"No Transactions Founds"} />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="PaymentDetails">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="bankDetailsCard shadow-sm">
                          <div className="heading">
                            <label className="headTxt">Bank Details</label>
                          </div>
                          <div className="detailsInsider">
                            <label htmlFor="" className="key">
                              Account Holder Name
                            </label>
                            <label htmlFor="" className="Value">
                              {ambulanceDetails?.account_holder_name
                                ? ambulanceDetails?.account_holder_name
                                : "-"}
                            </label>
                          </div>
                          <div className="detailsInsider">
                            <label htmlFor="" className="key">
                              Account Number
                            </label>
                            <label htmlFor="" className="Value">
                              {ambulanceDetails?.account_number
                                ? ambulanceDetails?.account_number
                                : "-"}
                            </label>
                          </div>
                          <div className="detailsInsider">
                            <label htmlFor="" className="key">
                              Bank Name
                            </label>
                            <label htmlFor="" className="Value">
                              {ambulanceDetails?.ambulance_driver_bank
                                ? ambulanceDetails?.ambulance_driver_bank
                                : "-"}
                            </label>
                          </div>
                          <div className="detailsInsider">
                            <label htmlFor="" className="key">
                              IFSC/ SWIFT Code
                            </label>
                            <label htmlFor="" className="Value">
                              {ambulanceDetails?.ifsc_code
                                ? ambulanceDetails?.ifsc_code
                                : "-"}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="bankDetailsCard shadow-sm">
                          <div className="heading">
                            <label className="headTxt">Revenue Summary</label>
                          </div>
                          <div className="graphs">
                            <div className="profitGraph col-lg-12">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  ₹
                                  {payMentDetails?.totalRevenueGraph &&
                                    payMentDetails?.totalRevenueGraph
                                      ?.totalRevenue &&
                                    payMentDetails?.totalRevenueGraph
                                      ?.totalRevenue}
                                </label>
                                {/*  <label className="plusPercent">+5%</label> */}
                              </div>
                              <div className="graph">
                                <Chart
                                  options={totalRevenuGraph.options}
                                  series={totalRevenuGraph.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  Total Revenue
                                </label>
                              </div>
                            </div>
                            <div className="lossGraph col-lg-12">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  ₹
                                  {payMentDetails?.monthlyGraph &&
                                    payMentDetails?.monthlyGraph
                                      ?.currentMonth_total &&
                                    payMentDetails?.monthlyGraph
                                      ?.currentMonth_total}
                                </label>
                                {/* <label className="lossPercent">+5%</label> */}
                              </div>
                              <div className="graph">
                                <Chart
                                  options={monthGraph.options}
                                  series={monthGraph.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  This Month
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="RatingReviews">
                    <div className="ratingBox">
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="totalReviewCard">
                            <h1 className="headTxt">Total Reviews</h1>
                            <p className="amntTxt">{totalRating}</p>
                            <label className="detailTxt">
                              Growth in the review from last month
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="totalReviewCard">
                            <h1 className="headTxt">Average Ratings</h1>
                            <div className="innerStars">
                              <p className="amntTxt">{avgRating}</p>
                              <img
                                src={avgRating >= 1 ? Star : ic_star_deactive}
                                alt=""
                              />
                              <img
                                src={avgRating >= 2 ? Star : ic_star_deactive}
                                alt=""
                              />
                              <img
                                src={avgRating >= 3 ? Star : ic_star_deactive}
                                alt=""
                              />
                              <img
                                src={avgRating >= 4 ? Star : ic_star_deactive}
                                alt=""
                              />
                              <img
                                src={avgRating >= 5 ? Star : ic_star_deactive}
                                alt=""
                              />
                            </div>
                            <label className="detailTxt">
                              Average rating from month
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="ratingStars">
                            {ratingPercentage &&
                              ratingPercentage.length > 0 &&
                              ratingPercentage.map((item, idx) => {
                                return (
                                  <div className="starLevels">
                                    <img
                                      src={Star}
                                      className="starImg"
                                      alt=""
                                    />
                                    <label className="starAmnt">
                                      {5 - idx}
                                    </label>
                                    <ProgressBar
                                      now={item}
                                      className="greenBar"
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="belowLine"></div>
                        </div>
                        {reviews &&
                          reviews.length > 0 &&
                          reviews.map((item, idx) => {
                            return (
                              <>
                                <div className="col-lg-4 col-md-12">
                                  <div className="petDetailsCard">
                                    <img src={dummyprofile} alt="" />
                                    <div className="cardDetails">
                                      <label className="headingTx">
                                        {item.first_name} {item.last_name}
                                      </label>
                                      <label className="reviewTxt">
                                        Total Reviews:{" "}
                                        <span>{item.total_reviews}</span>{" "}
                                      </label>
                                      <label className="spendsTxt">
                                        Total Spends:{" "}
                                        <span>₹{item.total_spends}</span>{" "}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                  <div className="regularCheqCard">
                                    <div className="ratingDetailsHead">
                                      <div className="nameAndDate">
                                        <label className="nameLab">
                                          {item?.review_title}
                                        </label>
                                        <label className="dateLab">
                                          {moment(
                                            item.rating_and_review_on
                                          ).format("DD-MM-YYYY")}
                                        </label>
                                      </div>
                                      {item.rating && (
                                        <div className="starRate">
                                          <img
                                            src={Star}
                                            className="starIcon"
                                            alt=""
                                          />
                                          <label className="rateNum">
                                            {item.rating}
                                          </label>
                                        </div>
                                      )}
                                    </div>
                                    <div className="paraFlow">
                                      <p className="insideParaDet">
                                        {item.review}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        {/*
                        <div className="col-lg-4 col-md-12">
                        <div className="petDetailsCard">
                          <img src={catImg} alt="" />
                          <div className="cardDetails">
                            <label className="headingTx">James Bond</label>
                            <label className="reviewTxt">
                              Total Reviews: <span>10</span>{" "}
                            </label>
                            <label className="spendsTxt">
                              Total Spends: <span>$1000</span>{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-12">
                        <div className="regularCheqCard">
                          <div className="ratingDetailsHead">
                            <div className="nameAndDate">
                              <label className="nameLab">
                                Regular Checkup
                              </label>
                              <label className="dateLab">24-02-2023</label>
                            </div>
                            <div className="starRate">
                              <img src={Star} className="starIcon" alt="" />
                              <label className="rateNum">4.5</label>
                            </div>
                          </div>
                          <div className="paraFlow">
                            <p className="insideParaDet">
                              Lorem ipsum dolor sit amet consectetur
                              adipisicing elit . Minima dicta officiis,
                              possimus cumque magni odit, obcaecati, numquam
                              quaerat in eligendi quae provident ratione?
                              Aperiam nam ratione inventore voluptas dolore
                              distinctio deleniti accusamus voluptatibus qui
                              officia !
                            </p>
                          </div>
                        </div>
                      </div>
                      */}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default AmbulanceDetails;
