import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import ThreeDots from "../../assets/images/icons/ThreeDots.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import Patient from "../../assets/images/icons/patientImg.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import DogImg from "../../assets/images/icons/dogImg.svg";
import catImg from "../../assets/images/icons/catImg.svg";
import Chart from "react-apexcharts";
import Star from "../../assets/images/icons/ratingStar.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ic_location from "../../assets/images/icons/ic_location.svg";
import DBimg from "../../assets/images/icons/DeliveryBoy.svg";
import {
  putMultipartWithAuthCall,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";
import moment from "moment";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";

const DeliveryBoyDetails = () => {
  const { sidebar, setSidebar, pickup, setPickup } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const delId = params.id;
  const [clickedOrder, setClickedOrder] = useState(false);
  const [page, setpage] = useState(1);
  const [page_limit, setPage_limit] = useState(10);
  const [DeliveryBoyDetails, setDeliveryBoyDetails] = useState({});

  const [requestDetailArr, setRequestDetailArr] = useState([]);
  const [transacArr, setTransacArr] = useState([]);
  const [bankDataArr, setBankDataArr] = useState([]);
  const [monthlyObj, setMonthlyObj] = useState({});
  const [revenueObj, setRevenueObj] = useState({});
  const [stateChaneg, setStateChaneg] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 900, 100, 700, 300, 100, 500, 100, 400, 200],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 1000,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#4675F1"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#34B53A"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [lossState, setLossState] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 900, 100, 700, 300, 100, 500, 100, 400, 200],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#D0182E"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#D0182E"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [ReviewArr, setReviewArr] = useState([]);
  const [Ratings, setRatings] = useState({});
  const getDataById = () => {
    simpleGetCallWithToken(ApiConfig.GET_DELIVERYBOY_DETAILS_BY_ID + `${delId}`)
      .then((res) => {
        if (res.success === true) {
          setDeliveryBoyDetails(res?.data);
        } else {
          setDeliveryBoyDetails({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRequestDetails = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DELIVERYBOY_REQUEST_DET +
        `?delivery_person_id=${delId}&page=${page}&page_limit=${page_limit}`
    )
      .then((res) => {
        if (res.status === true) {
          setRequestDetailArr(res?.data);
        } else {
          setRequestDetailArr([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTransactionDet = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DELIVERYBOY_TRANSACTION_DET +
        `?delivery_person_id=${delId}&page=${page}&page_limit=${page_limit}`
    )
      .then((res) => {
        if (res.status === true) {
          setTransacArr(res?.data);
        } else {
          setTransacArr([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPaymentDet = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DELIVERYBOY_MONTHLY_PAYMENT + `/${delId}`
    )
      .then((res) => {
        if (res.success === true) {
          setBankDataArr(res?.data);
          const monthData =
            res?.monthlyPaymentDetails?.currentMonthPerDay?.data;
          monthData.splice(10);
          setStateChaneg({
            ...stateChaneg,
            series: [
              {
                data: monthData,
              },
            ],
          });
          const monthlyDet = res?.monthlyPaymentDetails;
          setMonthlyObj(monthlyDet);
          setRevenueObj(res?.totalRevenue);
          setLossState({
            ...lossState,
            series: [
              {
                data: res?.totalRevenue?.perMonth,
              },
            ],
          });
        } else {
          setBankDataArr([]);
          setMonthlyObj({});
          setRevenueObj({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRatings = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DELIVERYBOY_RATINGS +
        `/${delId}?page=${page}&page_limit=${page_limit}`
    )
      .then((res) => {
        if (res.success === true) {
          setReviewArr(res?.data);
          setRatings(res?.rating);
        } else {
          setReviewArr([]);
          setRatings({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disbaleDeliveryBoy = (is_disabled) => {
    let payload = {
      is_disabled: is_disabled,
      delivery_person_id: JSON.parse(delId),
    };
    putMultipartWithAuthCall(
      ApiConfig.DISABLE_DELIVERY_BOY,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (res?.success === true) {
          notifySuccess(res?.message);
          navigate('/DeliveryBoy')
        } else {
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeDeliveryBoy = () => {
    let payload = {
      is_active: true,
      delivery_person_id: JSON.parse(delId),
    };
    putMultipartWithAuthCall(
      ApiConfig.REMOVE_DELIVERY_BOY,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
        } else {
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDataById();
    getRequestDetails();
    getTransactionDet();
    getPaymentDet();
    getRatings();
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="doctorDetails-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                <img
                  src={
                    DeliveryBoyDetails?.delivery_person_image_path
                      ? DeliveryBoyDetails?.delivery_person_image_path
                      : DBimg
                  }
                  className="docImg"
                  alt=""
                />
                <div className="docDetTxt">
                  <div>
                    <h1 className="docName">
                      {DeliveryBoyDetails?.delivery_person_name &&
                        DeliveryBoyDetails?.delivery_person_name}
                    </h1>
                    {/* <label className='docDomain'>Vaccinator</label>*/}
                  </div>
                  {/* DeliveryBoyDetails */}
                  {DeliveryBoyDetails?.is_disabled && (
                    <div className="disable_img">
                      <img src={disabled} />{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="dropDown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={ThreeDots} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="#" onClick={() => disbaleDeliveryBoy(!DeliveryBoyDetails?.is_disabled)}>
                        {DeliveryBoyDetails?.is_disabled ? "Enable" : "Disable"}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="#" onClick={removeDeliveryBoy}>
                        Remove
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="doctorTabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Profile">
                <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                  <Nav.Item>
                    <Nav.Link eventKey="Profile">Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Requests">Requests</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Transaction">Transaction</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="PaymentDetails">
                      Payment Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="RatingReviews">
                      Rating & Reviews
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Profile">
                    <div className="row">
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle">
                          <h1 className="heading">General Information</h1>
                        </div>
                      </div>
                      {/* Inner Values */}
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Driver Name</h1>
                          {DeliveryBoyDetails?.delivery_person_name ? (
                            <label htmlFor="" className="value">
                              {DeliveryBoyDetails?.delivery_person_name}
                            </label>
                          ) : (
                            <label htmlFor="" className="text-danger">
                              No Data
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Driver Contact No.</h1>
                          {DeliveryBoyDetails?.delivery_person_contact_no ? (
                            <label htmlFor="" className="value">
                              {DeliveryBoyDetails?.delivery_person_contact_no}
                            </label>
                          ) : (
                            <label htmlFor="" className="text-danger">
                              No Data
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Vehicle type</h1>
                          {DeliveryBoyDetails?.vehicle_type ? (
                            <label htmlFor="" className="value">
                              {DeliveryBoyDetails?.vehicle_type}
                            </label>
                          ) : (
                            <label htmlFor="" className="text-danger">
                              No Data
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Vehicle No.</h1>
                          {DeliveryBoyDetails?.vehicle_no ? (
                            <label htmlFor="" className="value">
                              {DeliveryBoyDetails?.vehicle_no}
                            </label>
                          ) : (
                            <label htmlFor="" className="text-danger">
                              No Data
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Requests">
                    <div className="mainAppointments">
                      <div className="allApointmentsRow">
                        <h1 className="headTxt">All Request’s</h1>
                        <img src={FilterIcon} className="FilImg" alt="" />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="incedoTable">
                            <table className="defaultTable">
                              <thead>
                                <tr>
                                  <th>Sr No. </th>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Customer</th>
                                  <th>Pick- Up Location</th>
                                  <th>Drop Location</th>
                                  <th>Fees</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              {requestDetailArr &&
                              requestDetailArr.length > 0 ? (
                                <tbody className="tableBody">
                                  {requestDetailArr.map((data, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{data?.date}</td>
                                        <td>
                                          {" "}
                                          {moment(data.time, "HH:mm:ss").format(
                                            "hh:mm A"
                                          )}
                                        </td>
                                        <td className="imgPlusName">
                                          <img
                                            src={
                                              data?.customer_image
                                                ? data?.customer_image
                                                : Patient
                                            }
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          {data?.customer_name ? (
                                            data?.customer_name
                                          ) : (
                                            <span className="text-danger">
                                              No Data
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          <img
                                            src={ic_location}
                                            alt=""
                                            className="tableImg"
                                          />
                                          {/* <Link className="map-link" to="/MapComponent" state={{ from: data?.pickup_location_coordinates }}>View in Map</Link> */}
                                          <label
                                            className="map-lab"
                                            onClick={() => {
                                              navigate("/MapComponent");
                                              setPickup(
                                                data?.pickup_location_coordinates
                                              );
                                            }}
                                          >
                                            View in Map
                                          </label>
                                        </td>
                                        <td>
                                          {" "}
                                          <img
                                            src={ic_location}
                                            alt=""
                                            className="tableImg"
                                          />
                                          {/* <Link className="map-link">View in Map</Link> */}
                                          <label
                                            className="map-lab"
                                            onClick={() => {
                                              navigate("/MapComponent");
                                              setPickup(
                                                data?.drop_location_coordinates
                                              );
                                            }}
                                          >
                                            View in Map
                                          </label>
                                        </td>
                                        <td>₹{data?.delivery_booking_fees}</td>
                                        <td>{data?.status}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan="8" className="text-center p-3">
                                      <label
                                        htmlFor=""
                                        className="text-danger fw-bold"
                                      >
                                        No Data Found
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Transaction">
                    <div className="mainAppointments">
                      <div className="allApointmentsRow">
                        <h1 className="headTxt">All Transaction's</h1>
                        <img src={FilterIcon} className="FilImg" alt="" />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="incedoTable">
                            <table className="defaultTable">
                              <thead>
                                <tr>
                                  <th>Sr No. </th>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Customer</th>
                                  <th>Pick- Up Location</th>
                                  <th>Drop Location</th>
                                  <th>Fees</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              {transacArr && transacArr.length > 0 ? (
                                <tbody className="tableBody">
                                  {transacArr.map((data, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{data?.date}</td>
                                        <td>
                                          {" "}
                                          {moment(data.time, "HH:mm:ss").format(
                                            "hh:mm A"
                                          )}
                                        </td>
                                        <td className="imgPlusName">
                                          <img
                                            src={
                                              data?.customer_image
                                                ? data?.customer_image
                                                : Patient
                                            }
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          {data?.customer_name}
                                        </td>
                                        <td>
                                          {" "}
                                          <img
                                            src={ic_location}
                                            alt=""
                                            className="tableImg"
                                          />
                                          {/* <Link className="map-link">View in Map</Link> */}
                                          <label
                                            className="map-lab"
                                            onClick={() => {
                                              navigate("/MapComponent");
                                              setPickup(
                                                data?.pickup_location_coordinates
                                              );
                                            }}
                                          >
                                            View in Map
                                          </label>
                                        </td>
                                        <td>
                                          {" "}
                                          <img
                                            src={ic_location}
                                            alt=""
                                            className="tableImg"
                                          />
                                          {/* <Link className="map-link">View in Map</Link> */}
                                          <label
                                            className="map-lab"
                                            onClick={() => {
                                              navigate("/MapComponent");
                                              setPickup(
                                                data?.drop_location_coordinates
                                              );
                                            }}
                                          >
                                            View in Map
                                          </label>
                                        </td>
                                        <td>₹{data?.delivery_booking_fees}</td>
                                        <td>{data?.status}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan="8" className="text-center p-3">
                                      <label
                                        htmlFor=""
                                        className="text-danger fw-bold"
                                      >
                                        No Data Found
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="PaymentDetails">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="heightScroller">
                          {bankDataArr && bankDataArr.length > 0 ? (
                            bankDataArr.map((data, index) => {
                              return (
                                <div className="bankDetailsCard shadow-sm">
                                  <div className="heading">
                                    <label className="headTxt">
                                      Bank Details {index + 1}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Account Holder Name
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data.account_holder_name}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Account Number
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data.account_number}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Bank Name
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data.delivery_person_bank}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      IFSC/ SWIFT Code
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data.ifsc_code}
                                    </label>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-md-6 text-center p-3 text-danger">
                              No Data Found
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="bankDetailsCard shadow-sm">
                          <div className="heading">
                            <label className="headTxt">Revenue Summary</label>
                          </div>
                          <div className="graphs">
                            <div className="profitGraph">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  ₹
                                  {monthlyObj?.currentMonth_total ? (
                                    monthlyObj?.currentMonth_total
                                  ) : (
                                    <span className="text-danger">0</span>
                                  )}
                                </label>
                                <label className="plusPercent">
                                  +
                                  {monthlyObj?.percentage ? (
                                    Number(monthlyObj?.percentage).toFixed()
                                  ) : (
                                    <span className="text-danger">0</span>
                                  )}
                                  %
                                </label>
                              </div>
                              <div className="graph">
                                <Chart
                                  options={stateChaneg.options}
                                  series={stateChaneg.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  Total Revenue
                                </label>
                              </div>
                            </div>
                            <div className="lossGraph">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  ₹
                                  {revenueObj?.totalRevenue ? (
                                    revenueObj?.totalRevenue
                                  ) : (
                                    <span className="text-danger">0</span>
                                  )}
                                </label>
                                <label className="lossPercent">
                                  -
                                  {revenueObj?.percentage ? (
                                    revenueObj?.percentage
                                  ) : (
                                    <span className="text-danger">0</span>
                                  )}
                                  %
                                </label>
                              </div>
                              <div className="graph">
                                <Chart
                                  options={lossState.options}
                                  series={lossState.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  This Month
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="RatingReviews">
                    <div className="ratingBox">
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="totalReviewCard">
                            <h1 className="headTxt">Total Reviews</h1>
                            <p className="amntTxt">
                              {Ratings?.total_ratings
                                ? Ratings?.total_ratings + "K"
                                : 0}
                            </p>
                            <label className="detailTxt">
                              Growth in the review from last month
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="totalReviewCard">
                            <h1 className="headTxt">Average Ratings</h1>
                            <div className="innerStars">
                              <p className="amntTxt">
                                {Ratings?.avg_rating ? Ratings?.avg_rating : 0}
                              </p>
                              <img src={Star} alt="" />
                              <img src={Star} alt="" />
                              <img src={Star} alt="" />
                              <img src={Star} alt="" />
                              <img src={Star} alt="" />
                            </div>
                            <label className="detailTxt">
                              Average rating from month
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="ratingStars">
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">5</label>
                              <ProgressBar
                                now={Ratings?.rating_5}
                                className="greenBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">4</label>
                              <ProgressBar
                                now={Ratings?.rating_4}
                                className="bottlegreenBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">3</label>
                              <ProgressBar
                                now={Ratings?.rating_3}
                                className="liteOrangeBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">2</label>
                              <ProgressBar
                                now={Ratings?.rating_2}
                                className="darkOrangeBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">1</label>
                              <ProgressBar
                                now={Ratings?.rating_1}
                                className="redBar"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="belowLine"></div>
                        </div>
                        {ReviewArr && ReviewArr.length > 0 ? (
                          ReviewArr.map((data, index) => {
                            return (
                              <>
                                <div className="row gx-3" key={index}>
                                  <div className="col-lg-4 col-md-12">
                                    <div className="petDetailsCard">
                                      <img
                                        src={
                                          data?.profile_image_path
                                            ? data?.profile_image_path
                                            : DogImg
                                        }
                                        alt=""
                                      />
                                      <div className="cardDetails">
                                        <label className="headingTx">
                                          {data?.customer_name ? (
                                            data?.customer_name
                                          ) : (
                                            <span className="text-danger">
                                              No Data
                                            </span>
                                          )}
                                        </label>
                                        <label className="reviewTxt">
                                          Total Reviews: <span>10</span>{" "}
                                        </label>
                                        <label className="spendsTxt">
                                          Total Spends: <span>$1000</span>{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-8 col-md-12">
                                    <div className="regularCheqCard">
                                      <div className="ratingDetailsHead">
                                        <div className="nameAndDate">
                                          <label className="nameLab">
                                            {data?.review_title ? (
                                              data?.review_title
                                            ) : (
                                              <span className="text-danger">
                                                No Data
                                              </span>
                                            )}
                                          </label>
                                          <label className="dateLab">
                                            {data?.date ? (
                                              data?.date
                                            ) : (
                                              <span className="text-danger">
                                                No Data
                                              </span>
                                            )}
                                          </label>
                                        </div>
                                        <div className="starRate">
                                          <img
                                            src={Star}
                                            className="starIcon"
                                            alt=""
                                          />
                                          <label className="rateNum">
                                            {data?.rating ? (
                                              data?.rating
                                            ) : (
                                              <span className="text-danger">
                                                No Data
                                              </span>
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="paraFlow">
                                        <p className="insideParaDet">
                                          {data?.review ? (
                                            data?.review
                                          ) : (
                                            <span className="text-danger">
                                              No Data
                                            </span>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <div className="col-md-12 text-center p-3 text-danger">
                            Oops ! No Data Found
                          </div>
                        )}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default DeliveryBoyDetails;
