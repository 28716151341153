import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import DocImg from "../../assets/images/icons/docImg.svg";
import Star from "../../assets/images/icons/ratingStar.svg";
import ic_export from "../../assets/images/icons/ic_export.svg";
import DoctorImg from "../../assets/images/icons/DoctorsIcon.svg";
import bottomArrow from "../../assets/images/icons/bottomArrow.svg";
import roundCircle from "../../assets/images/headerIcons/headerCircle.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import ic_location from "../../assets/images/icons/ic_location.svg";
import ic_action from "../../assets/images/icons/ic_action.svg";
import Recpite from "../../assets/images/icons/PrintImg.svg";
import arrowleft from "../../assets/images/icons/arrowleft.svg";
import Chart from "react-apexcharts";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import InfiniteScroll from "react-infinite-scroll-component";
import car1 from "../../assets/images/ReportIcons/catarigy (1).svg";
import car2 from "../../assets/images/ReportIcons/catarigy (2).svg";
import car3 from "../../assets/images/ReportIcons/catarigy (3).svg";
import car4 from "../../assets/images/ReportIcons/catarigy (4).svg";
import car5 from "../../assets/images/ReportIcons/catarigy (5).svg";
import car6 from "../../assets/images/ReportIcons/catarigy (6).svg";
import Down from "../../assets/images/ReportIcons/Vector.svg";
import UP from "../../assets/images/ReportIcons/Vector (1).svg";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import moment from "moment";

const RevenueReports = () => {
  const { sidebar, setSidebar } = useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const [filterArr, setFilterArr] = useState([
    { id: "1", value: "Verified" },
    { id: "2", value: "Reject" },
    { id: "3", value: "Processing" },
  ]);
  const [AppDetails, setAppDetails] = useState(true);
  const [doctorDetails, setDoctorDetails] = useState(false);
  const [page, setPage] = useState(1);
  const [page_limit, setPage_limit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [last_page, setLast_page] = useState(false);
  const [revenueDocBool, setRevenueDocBool] = useState(false);
  const [revenueDocList, setRevenueDocList] = useState([]);
  const [docAppointmentList, setDocAppointmentList] = useState([]);
  const [salesReport, setSalesReport] = useState([]);
  const [singleSalesData, setSingleSalesData] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [searchSales, setSearchSales] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("DD-MM-YYYY"));
  const [loading, setLoading] = useState(false);
  const [queryState, setQueryState] = useState({
    shopId: "",
    shop_category: ""
  })
  // const [revenueBar, setRevenueBar] = useState({
  //     series: [
  //         {
  //             name: "series-1",
  //             data: [29, 39, 49, 59, 99, 65, 49, 42, 40]
  //         }
  //     ],
  //     chart: {
  //         height: 350,
  //         type: false,
  //         zoom: {
  //             enabled: false,
  //         },
  //         toolbar: {
  //             show: false,
  //         },
  //     },
  //     options: {
  //         xaxis: {
  //             categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
  //         },
  //         chart: {
  //             height: 440,
  //             type: "area",
  //             toolbar: {
  //                 show: false,
  //             },
  //         },

  //         stroke: {
  //             curve: "straight",
  //             width: 1,
  //             colors: ["#4339F2"],
  //         },

  //         dataLabels: {
  //             enabled: true,
  //             enabledOnSeries: [1],
  //         },
  //         grid: {
  //             show: false,
  //         },
  //         tooltip: {
  //             custom: function ({ series, seriesIndex, dataPointIndex, w }) {
  //                 return (
  //                     '<div class="arrow_box">' +
  //                     "<span>" +
  //                     series[seriesIndex][dataPointIndex] +
  //                     "</span>" +
  //                     "$" +
  //                     "</div>"
  //                 );
  //             },
  //         },
  //     }
  // })
  const [subsrib, setSubsrib] = useState({
    series: [
      {
        name: "series-1",
        data: [29, 39, 49, 59, 99, 65, 49, 42, 40, 20, 15, 10, 5, 2],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    options: {
      xaxis: {
        categories: [
          2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
          2021, 2022, 2023,
        ],
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [100],
          colorStops: [],
        },
      },
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 7,
          columnWidth: "30%",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      stroke: {
        curve: "straight",
        width: 0.5,
        colors: ["#4339F2"],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      grid: {
        show: false,
      },
      markers: {
        size: 6, // Adjust the marker size as needed
        strokeWidth: 0,
        strokeColors: ["#4339F2"], // Adjust the stroke color of the markers
        hover: {
          size: 8, // Adjust the marker size on hover
        },
        radius: 50, // Add border radius (rounded corners) to the markers
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "</div>"
          );
        },
      },
    },
  });
  const [revenueBar, setRevenueBar] = useState({
    series: [
      {
        name: "series-1",
        data: [65, 49, 42, 40, 20, 15, 10, 5, 2],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [100],
          colorStops: [],
        },
      },
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#00D50A"],
      plotOptions: {
        bar: {
          borderRadius: 7,
          columnWidth: "30%",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      stroke: {
        curve: "straight",
        width: 0.5,
        colors: ["#00D50A"],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      grid: {
        show: false,
      },
      markers: {
        size: 6, // Adjust the marker size as needed
        strokeWidth: 0,
        strokeColors: ["#00D50A"], // Adjust the stroke color of the markers
        hover: {
          size: 8, // Adjust the marker size on hover
        },
        radius: 50, // Add border radius (rounded corners) to the markers
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box_sec">' +
            "$" +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "</div>"
          );
        },
      },
    },
  });
  // Doctor
  const [doctor, setDoctor] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 900, 100, 700, 300, 100, 500, 100, 400, 200],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    options: {
      xaxis: {
        // categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
        show: false, // Hide the x-axis
      },
      yaxis: {
        show: false, // Hide the y-axis
      },

      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#4675F1"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#34B53A"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [pharmacy, setPharmacy] = useState({
    series: [
      {
        name: "Amount",
        data: [700, 300, 100, 500, 0, 900, 100, 10, 400, 200],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    options: {
      xaxis: {
        // categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
        show: false, // Hide the x-axis
      },
      yaxis: {
        show: false, // Hide the y-axis
      },

      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FF3A29"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#FF3A29",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#FF3A29"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [petShop, setPetShop] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 100, 500, 100, 900, 100, 700, 300, 400, 200],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    options: {
      xaxis: {
        // categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
        show: false, // Hide the x-axis
      },
      yaxis: {
        show: false, // Hide the y-axis
      },

      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#02A0FC"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#02A0FC",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#02A0FC"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [vaccinators, setVaccinators] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 10, 800, 100, 300, 700, 500, 100, 400, 200],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    options: {
      xaxis: {
        // categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
        show: false, // Hide the x-axis
      },
      yaxis: {
        show: false, // Hide the y-axis
      },

      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#042486"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#042486",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#042486"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [ambulance, setAmbulance] = useState({
    series: [
      {
        name: "Amount",
        data: [100, 500, 100, 400, 200, 0, 900, 100, 700, 300],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    options: {
      xaxis: {
        // categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
        labels: {
          show: true,
        },
      },
      yaxis: {
        show: false, // Hide the y-axis
      },

      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#4339F2"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4339F2",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#4339F2"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });

  const getDocList = (currentPage) => {
    simpleGetCallWithToken(
      ApiConfig.GET_REPORTS_DOCTOR_lIST +
      `?page=${currentPage}&page_limit=${page_limit}&status=${status}&q=${searchTxt}`
    )
      .then((res) => {
        if (res.success === true) {
          setLoading(false);
          let total = res?.total ? res?.total : 0;
          setTotalData(total);
          setLast_page(res?.last_page);

          let docListData = res?.data ? res?.data : [];
          let filterDataArr = docListData.map((eachItem, index) => {
            return {
              ...eachItem,
              doctor_name: eachItem?.doctor_name || ""
            }
          })
          if (currentPage === 1) {
            setRevenueDocList(res?.data);
            setLoading(false);
          } else {
            setRevenueDocList([...revenueDocList, ...filterDataArr]);
            setLoading(false);
          }
        } else {
          setRevenueDocList([]);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const fetchData = () => {
    setPage(page + 1)
    if (last_page) {
    } else {
      getDocList(page + 1)
    }
  }

  const getAppointmentData = (docId) => {
    simpleGetCallWithToken(
      ApiConfig.GET_APPOINTMENT_DOC_LIST +
      `?doctor_id=${docId}&page=${page}&page_limit=${page_limit}`
    )
      .then((res) => {
        if (res.success === true) {
          setDocAppointmentList(res?.data);
          setLoading(false);
        } else {
          setDocAppointmentList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSalesRevReport = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_SALES_REPORT_LIST +
      `?page=${page}&page_limit=${page_limit}&status=${status}&date=${date}&q=${searchSales}`
    )
      .then((res) => {
        if (res.success === true) {
          setSalesReport(res?.data);
          setTotalData(res?.total)
          setLoading(false);
        } else {
          setSalesReport([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSingleShop = (data) => {
    simpleGetCallWithToken(ApiConfig.GET_SINGLE_SHOP_LIST + `?shop_id=${data?.shop_id}&shop_category=${data?.shop_category}`)
      .then((res) => {
        if (res.success === true) {
          setSingleSalesData(res?.data);
          setTotalData(res?.total)
          setLoading(false);
        } else {
          setSingleSalesData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleDropdownChange = (selectedValue) => {
  //   setSearchTxt(selectedValue);
  // };


  useEffect(() => {
    if (searchTxt === "") {
      setLoading(true);
      getDocList();
      getSalesRevReport();
    } else {
      getDocList();
      getSalesRevReport();

    }
  }, [searchTxt, searchSales, queryState]);

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="revenueReports-wrapper">
              <div className="reportsTabs">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="1"
                >
                  <Nav
                    variant="pills"
                    id="newTabMai"
                    className="tob_nav_pills"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="1">Appointment</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                                    <Nav.Link eventKey="2">Purchase Orders</Nav.Link>
                                </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="3">Sales Report</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                                    <Nav.Link eventKey="4">Subscription</Nav.Link>
                                </Nav.Item> */}
                  </Nav>
                  <Tab.Content>
                    {/* <InfiniteScroll
                      dataLength={revenueDocList.length}
                      next={fetchData}
                      hasMore={
                        AppDetails === true ? false : revenueDocList.length !== totalData
                      }
                      scrollableTarget="scroll-table"
                      loader={
                        AppDetails === true ? "" : <h4 style={{textAlign:"center", marginTop:"20px", marginLeft:"20px"}}></h4>
                      }
                      > */}
                    <Tab.Pane eventKey="1">
                      <div className=" commmon-top-search-section">
                        <div className="row align-items-center">
                          <div className="col-md-12 col-lg-4">
                            <div className="innerSearchItem">
                              <div className="topSearch">
                                <input
                                  type="text"
                                  autoFocus
                                  value={searchTxt}
                                  onChange={(e) => {
                                    setSearchTxt(e.target.value);
                                  }}
                                  className="form-control searchDoc"
                                  placeholder="Search by Name, Specialist, Ratings, Visits..."
                                />
                                <img
                                  src={SearchIcon}
                                  className="searchInner"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                            {/* <div className="topFilter">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant=""
                                    id="dropdown-basic"
                                  >
                                    <img
                                      src={FilterIcon}
                                      className="filterInner"
                                      alt=""
                                    />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {filterArr &&
                                      filterArr.map((data, index) => {
                                        return (
                                          <Dropdown.Item
                                            key={index}
                                            eventKey={data.value}
                                            onClick={() =>
                                              handleDropdownChange(data.value)
                                            }
                                          >
                                            {data.value}
                                          </Dropdown.Item>
                                        );
                                      })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div> */}
                            <div className="topFilter">
                              <Link to="/AddPetProduct">
                                <div className="addBox">
                                  <img src={ic_export} alt="" />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {AppDetails ? (
                        <div className="table-wrapper">
                          <table className="defaultTable">
                            <thead>
                              <tr>
                                <th>Sr No. </th>
                                <th>Doctor Name</th>
                                <th>Appointment</th>
                                <th>Contact No.</th>
                                <th>Status</th>
                                <th className="center">Action</th>
                              </tr>
                            </thead>
                            {revenueDocList && revenueDocList.length > 0 ? (
                              <tbody className="tableBody">
                                {revenueDocList.map((data, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <img
                                          src={roundCircle}
                                          className="rounded-circle tableImg"
                                          alt=""
                                        />
                                        {data?.doctor_name}
                                      </td>
                                      <td>{data?.appointments}</td>
                                      <td>{data?.doctor_contact_number}</td>
                                      <td>
                                        <img
                                          src={approved}
                                          className="checks tableImg"
                                          alt=""
                                        />
                                        Approved
                                      </td>
                                      <td className="center">
                                        <Link
                                          onClick={() => {
                                            getAppointmentData(
                                              data?.doctor_id
                                            );
                                            setAppDetails(false);
                                            setRevenueDocBool(true);
                                          }}
                                        >
                                          <img src={ic_action} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody className="tableBody">
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td className="text-danger">
                                    No Data Found
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          {/* <div className="pageData">
                              <label>Showing Page {page} - {totalData}</label>
                            </div> */}
                        </div>
                      ) : (
                        <div>
                          <>
                            <div className="doc-name-back-btn mb-2">
                              <Link
                                onClick={() => {
                                  setAppDetails(true);
                                  setDocAppointmentList([]);
                                }}
                              >
                                <img src={arrowleft} alt="" />
                              </Link>
                              <label htmlFor="" className="ms-3">
                                Showing result for :{" "}
                              </label>{" "}
                              <span className="innerDatils">
                                {docAppointmentList[0]?.doctor_name}
                              </span>
                            </div>
                            <div className="table-wrapper">
                              <table className="defaultTable">
                                <thead>
                                  <tr>
                                    <th>Sr No. </th>
                                    <th>Appointment Date</th>
                                    <th>Time</th>
                                    <th>Patient</th>
                                    <th>Species</th>
                                    <th>Case</th>
                                    <th>Veterinarian</th>
                                    <th>Fees/Subscription Plan</th>
                                    <th className="center">Status</th>
                                  </tr>
                                </thead>
                                {docAppointmentList &&
                                  docAppointmentList.length > 0 ? (
                                  <tbody className="tableBody">
                                    {docAppointmentList.map(
                                      (data, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{data?.date}</td>
                                              <td>
                                                {" "}
                                                {moment(
                                                  data.time,
                                                  "HH:mm:ss"
                                                ).format("hh:mm A")}
                                              </td>
                                              <td>
                                                <img
                                                  src={roundCircle}
                                                  className="rounded-circle tableImg"
                                                  alt=""
                                                />
                                                {data?.animal_name}
                                              </td>
                                              <td>
                                                {data?.type_of_animal}
                                              </td>
                                              <td
                                              // className="CheckUP"
                                              >
                                                {data?.disease === "" ? (
                                                  <label className="text-danger">
                                                    No Data
                                                  </label>
                                                ) : (
                                                  <label>
                                                    {data?.disease}
                                                  </label>
                                                )}
                                              </td>
                                              <td>{data?.doctor_name}</td>
                                              <td>&#8377; 15 (Monthly Sub.)</td>
                                              <td className="center">
                                                {(data.status === "1" && (
                                                  <>
                                                    <img
                                                      src={pending}
                                                      className="checks tableImg"
                                                      alt=""
                                                    />
                                                    Pending
                                                  </>
                                                )) ||
                                                  (data.status === "4" && (
                                                    <>
                                                      <img
                                                        src={pending}
                                                        className="checks tableImg"
                                                        alt=""
                                                      />
                                                      Pending
                                                    </>
                                                  )) ||
                                                  (data.status === "2" && (
                                                    <>
                                                      <img
                                                        src={approved}
                                                        className="checks tableImg"
                                                        alt=""
                                                      />
                                                      Approved
                                                    </>
                                                  )) ||
                                                  (data.status === "5" && (
                                                    <>
                                                      <img
                                                        src={approved}
                                                        className="checks tableImg"
                                                        alt=""
                                                      />
                                                      Approved
                                                    </>
                                                  )) ||
                                                  (data.status === "3" && (
                                                    <>
                                                      <img
                                                        src={cancel}
                                                        className="checks tableImg"
                                                        alt=""
                                                      />
                                                      Failed
                                                    </>
                                                  )) ||
                                                  data.status === null ||
                                                  (undefined && (
                                                    <span className="text-danger">
                                                      No Data
                                                    </span>
                                                  ))}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                  </tbody>
                                ) : (
                                  <tbody className="tableBody">
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-danger">
                                        No Data Found
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                              {/* <div className="pageDataElse">
                              <label>Showing Page {page} - {totalData}</label>
                            </div> */}
                            </div>
                          </>
                        </div>
                      )}
                    </Tab.Pane>
                    {/* </InfiniteScroll> */}
                    <>
                      {/* <Tab.Pane eventKey="2">
                                    <div className=" commmon-top-search-section">
                                        <div className="row align-items-center">
                                        <div className="col-md-12 col-lg-4">
                                                <div className="innerSearchItem">
                                                    <div className="topSearch">
                                                        <input
                                                        type="text"
                                                            className="form-control searchDoc"
                                                            placeholder="Search by Name, Specialist, Ratings, Visits..."
                                                        />
                                                        <img
                                                            src={SearchIcon}
                                                            className="searchInner"
                                                            alt=""
                                                            />
                                                            </div>
                                                            </div>
                                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                                            <div className="topFilter">
                                                    <img src={FilterIcon} className='filterInner' alt="" />
                                                </div>
                                                <div className="topFilter">
                                                    <Link to="/AddPetProduct">
                                                        <div className="addBox">
                                                            <img src={ic_export} alt="" />
                                                        </div>
                                                        </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {AppDetails ? (
                                      <div className="table-wrapper">
                                      <table className="defaultTable">
                                      <thead>
                                      <tr>
                                                        <th>Sr No. </th>
                                                        <th>Shop Name</th>
                                                        <th>Shop Type</th>
                                                        <th>Order’s</th>
                                                        <th>Location</th>
                                                        <th>Contact No.</th>
                                                        <th>Status</th>
                                                        <th className="center">Action</th>
                                                        </tr>
                                                </thead>
                                                <tbody className="tableBody">
                                                    <tr>
                                                    <td>1</td>
                                                        <td>
                                                            <img
                                                            src={roundCircle}
                                                            className="rounded-circle tableImg"
                                                                alt=""
                                                                />
                                                            Gayatri Medicals
                                                        </td>
                                                        <td>Pharmacy</td>
                                                        <td>10</td>
                                                        <td>
                                                            <img
                                                                src={ic_location}
                                                                alt=""
                                                                className="tableImg"
                                                            />
                                                            <Link className="map-link">View in Map</Link>
                                                        </td>
                                                        <td>+91 9999 9999 99</td>
                                                        <td>
                                                        <img
                                                                src={approved}
                                                                className="checks tableImg"
                                                                alt=""
                                                            />
                                                            Active
                                                        </td>
                                                        <td className="center">
                                                            <Link onClick={() => setAppDetails(false)}>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                            </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>
                                                            <img
                                                            src={roundCircle}
                                                            className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Pets N Pups
                                                        </td>
                                                        <td>Pet</td>
                                                        <td>25</td>
                                                        <td>
                                                            <img
                                                                src={ic_location}
                                                                alt=""
                                                                className="tableImg"
                                                                />
                                                            <Link className="map-link">View in Map</Link>
                                                        </td>
                                                        <td>+91 9999 9999 99</td>
                                                        <td>
                                                            <img
                                                            src={approved}
                                                                className="checks tableImg"
                                                                alt=""
                                                                />
                                                            Active
                                                        </td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                                </Link>
                                                                </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>
                                                            <img
                                                                src={roundCircle}
                                                                className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            World Pets
                                                        </td>
                                                        <td>Pet</td>
                                                        <td>10</td>
                                                        <td>
                                                            <img
                                                            src={ic_location}
                                                                alt=""
                                                                className="tableImg"
                                                            />
                                                            <Link className="map-link">View in Map</Link>
                                                            </td>
                                                        <td>+91 9999 9999 99</td>
                                                        <td>
                                                            <img
                                                                src={approved}
                                                                className="checks tableImg"
                                                                alt=""
                                                            />
                                                            Active
                                                        </td>
                                                        <td className="center">
                                                        <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                        </tr>
                                                        <tr>
                                                        <td>4</td>
                                                        <td>
                                                            <img
                                                            src={roundCircle}
                                                            className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Wellness Forever
                                                        </td>
                                                        <td>Pharmacy</td>
                                                        <td>16</td>
                                                        <td>
                                                        <img
                                                                src={ic_location}
                                                                alt=""
                                                                className="tableImg"
                                                            />
                                                            <Link className="map-link">View in Map</Link>
                                                        </td>
                                                        <td>+91 9999 9999 99</td>
                                                        <td>
                                                            <img
                                                                src={cancel}
                                                                className="checks tableImg"
                                                                alt=""
                                                            />
                                                            In-Active
                                                        </td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>
                                                        <img
                                                        src={roundCircle}
                                                        className="rounded-circle tableImg"
                                                                alt=""
                                                            />
                                                            Om Pharmacy
                                                        </td>
                                                        <td>Pharmacy</td>
                                                        <td>10</td>
                                                        <td>
                                                            <img
                                                                src={ic_location}
                                                                alt=""
                                                                className="tableImg"
                                                                />
                                                            <Link className="map-link">View in Map</Link>
                                                        </td>
                                                        <td>+91 9999 9999 99</td>
                                                        <td>
                                                        <img
                                                                src={pending}
                                                                className="checks tableImg"
                                                                alt=""
                                                            />
                                                            Disabled
                                                            </td>
                                                        <td className="center">
                                                            <Link>
                                                                <img src={ic_action} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                    ) : (
                                        <div>
                                            <div className="doc-name-back-btn">
                                                <Link onClick={() => setAppDetails(true)}>
                                                    <img src={arrowleft} alt="" />
                                                </Link>
                                                <label htmlFor="">Showing result for : </label> <span>Royld Miles</span>
                                            </div>
                                            <div className="table-wrapper">
                                                <table className="defaultTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr No. </th>
                                                            <th>Order Date</th>
                                                            <th>Time</th>
                                                            <th>Patient</th>
                                                            <th>Species</th>
                                                            <th>Bill By</th>
                                                            <th>Receipt</th>
                                                            <th>Amount</th>
                                                            <th className="center">Status</th>
                                                            </tr>
                                                            </thead>
                                                    <tbody className="tableBody">
                                                        <tr>
                                                            <td>1</td>
                                                            <td>
                                                                12-06-2023
                                                                </td>
                                                            <td>01:20PM - 02:20PM</td>
                                                            <td>
                                                                <img
                                                                    src={roundCircle}
                                                                    className="rounded-circle tableImg"
                                                                    alt=""
                                                                />
                                                                David Millan
                                                                </td>
                                                            <td>Dog</td>
                                                            <td>
                                                                Sam Billings
                                                            </td>
                                                            <td>
                                                            <img src={Recpite} className='clickableImg' alt="" />
                                                            </td>
                                                            <td>$1500</td>
                                                            <td className="center">
                                                                <img
                                                                    src={pending}
                                                                    className="checks tableImg"
                                                                    alt=""
                                                                />
                                                                Pending
                                                                </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>
                                                            12-06-2023
                                                            </td>
                                                            <td>01:20PM - 02:20PM</td>
                                                            <td>
                                                            <img
                                                                    src={roundCircle}
                                                                    className="rounded-circle tableImg"
                                                                    alt=""
                                                                />
                                                                David Millan
                                                            </td>
                                                            <td>Dog</td>
                                                            <td>
                                                                Sam Billings
                                                            </td>
                                                            <td>
                                                                <img src={Recpite} className='clickableImg' alt="" />
                                                            </td>
                                                            <td>$1500</td>
                                                            <td className="center">
                                                                <img
                                                                    src={cancel}
                                                                    className="checks tableImg"
                                                                    alt=""
                                                                />
                                                                Failed
                                                                </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>
                                                                12-06-2023
                                                            </td>
                                                            <td>01:20PM - 02:20PM</td>
                                                            <td>
                                                                <img
                                                                    src={roundCircle}
                                                                    className="rounded-circle tableImg"
                                                                    alt=""
                                                                />
                                                                David Millan
                                                            </td>
                                                            <td>Dog</td>
                                                            <td>
                                                                Sam Billings
                                                            </td>
                                                            <td>
                                                                <img src={Recpite} className='clickableImg' alt="" />
                                                                </td>
                                                            <td>$1500</td>
                                                            <td className="center">
                                                                <img
                                                                src={approved}
                                                                    className="checks tableImg"
                                                                    alt=""
                                                                />
                                                                Approved
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>
                                                                12-06-2023
                                                            </td>
                                                            <td>01:20PM - 02:20PM</td>
                                                            <td>
                                                                <img
                                                                    src={roundCircle}
                                                                    className="rounded-circle tableImg"
                                                                    alt=""
                                                                />
                                                                David Millan
                                                            </td>
                                                            <td>Dog</td>
                                                            <td>
                                                                Sam Billings
                                                            </td>
                                                            <td>
                                                                <img src={Recpite} className='clickableImg' alt="" />
                                                            </td>
                                                            <td>$1500</td>
                                                            <td className="center">
                                                                <img
                                                                    src={pending}
                                                                    className="checks tableImg"
                                                                    alt=""
                                                                />
                                                                Pending
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>
                                                                12-06-2023
                                                                </td>
                                                            <td>01:20PM - 02:20PM</td>
                                                            <td>
                                                                <img
                                                                src={roundCircle}
                                                                    className="rounded-circle tableImg"
                                                                    alt=""
                                                                />
                                                                David Millan
                                                            </td>
                                                            <td>Dog</td>
                                                            <td>
                                                            Sam Billings
                                                            </td>
                                                            <td>
                                                                <img src={Recpite} className='clickableImg' alt="" />
                                                            </td>
                                                            <td>$1500</td>
                                                            <td className="center">
                                                                <img
                                                                    src={pending}
                                                                    className="checks tableImg"
                                                                    alt=""
                                                                    />
                                                                Pending
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                                </div>
                                    )}
                                </Tab.Pane> */}
                    </>
                    {/* <InfiniteScroll
                      dataLength={revenueDocList.length}
                      next={fetchData}
                      hasMore={
                        AppDetails === true ? false : revenueDocList.length !== totalData
                      }
                      scrollableTarget="scroll-table"
                      loader={
                        AppDetails === true ? "" : <h4 style={{textAlign:"center", marginTop:"20px", marginLeft:"20px"}}></h4>
                      }
                      > */}
                    <Tab.Pane eventKey="3">
                      <div className=" commmon-top-search-section">
                        <div className="row align-items-center">
                          <div className="col-md-12 col-lg-4">
                            <div className="innerSearchItem">
                              <div className="topSearch">
                                <input
                                  type="text"
                                  autoFocus
                                  value={searchSales}
                                  onChange={(e) => {
                                    setSearchSales(e.target.value);
                                  }}
                                  className="form-control searchDoc"
                                  placeholder="Search by Name..."
                                />
                                <img
                                  src={SearchIcon}
                                  className="searchInner"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                            <div className="topFilter">
                              <Link to="#">
                                <div className="addBox">
                                  <img src={ic_export} alt="" />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {AppDetails ? (
                        <div className="table-wrapper heightCalc">
                          <table className="defaultTable">
                            <thead>
                              <tr>
                                <th>Sr No. </th>
                                <th>Shop Name</th>
                                <th>Shop Type</th>
                                <th>Sale</th>
                                <th>Contact No.</th>
                                <th>Status</th>
                                <th className="center">Action</th>
                              </tr>
                            </thead>
                            {salesReport && salesReport.length > 0 ? (
                              <tbody className="tableBody">
                                {salesReport.map((data, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <img
                                          src={roundCircle}
                                          className="rounded-circle tableImg"
                                          alt=""
                                        />
                                        {data?.shop_name}
                                      </td>
                                      <td>{data?.shop_type}</td>
                                      <td>&#8377; {data?.sale_revenue}</td>
                                      <td>{data?.contact_no}</td>
                                      <td>
                                        <img
                                          src={approved}
                                          className="checks tableImg"
                                          alt=""
                                        />
                                        Active
                                      </td>
                                      <td className="center">
                                        <Link
                                          onClick={() => {
                                            setAppDetails(false);
                                            handleSingleShop(data);
                                            setQueryState({
                                              ...queryState,
                                              shopId: data?.shop_id,
                                              shop_category: data?.shop_category
                                            })
                                          }}
                                        >
                                          <img src={ic_action} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody className="tableBody">
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-danger">
                                    No Data Found
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          {/* <div className="pageData">
                              <label>Showing Page {page} - {totalData}</label>
                            </div> */}
                        </div>
                      ) : (
                        <div>
                          <div className="doc-name-back-btn mb-3">
                            <Link onClick={() => setAppDetails(true)}>
                              <img src={arrowleft} alt="" />
                            </Link>
                            <label htmlFor="" className="ms-3">
                              Showing result for :{" "}
                            </label>{" "}
                            <span className="innerDatils">
                              {singleSalesData[0]?.bill_by}
                            </span>
                          </div>
                          <div className="table-wrapper">
                            <table className="defaultTable">
                              <thead>
                                <tr>
                                  <th>Sr No. </th>
                                  <th>Order Date</th>
                                  <th>Time</th>
                                  <th>Patient</th>
                                  <th>Species</th>
                                  <th>Bill By</th>
                                  <th>Receipt</th>
                                  <th>Amount</th>
                                  {/* <th className="center">Status</th> */}
                                </tr>
                              </thead>
                              {
                                singleSalesData && singleSalesData.length > 0 ?
                                  <tbody className="tableBody">
                                    {
                                      singleSalesData.map((data, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{data?.order_date}</td>
                                            <td>{data?.time}</td>
                                            <td>
                                              <img
                                                src={roundCircle}
                                                className="rounded-circle tableImg"
                                                alt=""
                                              />
                                              {data?.animal_details?.animal}
                                            </td>
                                            <td>{data?.animal_details?.animal_type}</td>
                                            <td>{data?.bill_by}</td>
                                            <td>
                                              <img
                                                src={Recpite}
                                                className="clickableImg"
                                                alt=""
                                              />
                                            </td>
                                            <td>&#8377; {data?.total_amount}</td>
                                            <td className="center">
                                              {
                                                data?.payment_status === "in progress" &&
                                                <>
                                                  <img
                                                    src={pending}
                                                    className="checks tableImg"
                                                    alt=""
                                                  />
                                                  Pending
                                                </>
                                                ||
                                                data?.payment_status === "completed" &&
                                                <>
                                                  <img
                                                    src={approved}
                                                    className="checks tableImg"
                                                    alt=""
                                                  />
                                                  Approved
                                                </>
                                                ||
                                                data?.payment_status === "cancelled" &&
                                                <>
                                                  <img
                                                    src={cancel}
                                                    className="checks tableImg"
                                                    alt=""
                                                  />
                                                  Failed
                                                </>
                                              }
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                    {/* <tr>
                                    <td>2</td>
                                    <td>12-06-2023</td>
                                    <td>01:20PM - 02:20PM</td>
                                    <td>
                                      <img
                                        src={roundCircle}
                                        className="rounded-circle tableImg"
                                        alt=""
                                      />
                                      David Millan
                                    </td>
                                    <td>Dog</td>
                                    <td>Sam Billings</td>
                                    <td>
                                      <img
                                        src={Recpite}
                                        className="clickableImg"
                                        alt=""
                                      />
                                    </td>
                                    <td>$500</td>
                                    <td className="center">
                                      <img
                                        src={cancel}
                                        className="checks tableImg"
                                        alt=""
                                      />
                                      Failed
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>12-06-2023</td>
                                    <td>01:20PM - 02:20PM</td>
                                    <td>
                                      <img
                                        src={roundCircle}
                                        className="rounded-circle tableImg"
                                        alt=""
                                      />
                                      David Millan
                                    </td>
                                    <td>Dog</td>
                                    <td>Sam Billings</td>
                                    <td>
                                      <img
                                        src={Recpite}
                                        className="clickableImg"
                                        alt=""
                                      />
                                    </td>
                                    <td>$202</td>
                                    <td className="center">
                                      <img
                                        src={approved}
                                        className="checks tableImg"
                                        alt=""
                                      />
                                      Approved
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>12-06-2023</td>
                                    <td>01:20PM - 02:20PM</td>
                                    <td>
                                      <img
                                        src={roundCircle}
                                        className="rounded-circle tableImg"
                                        alt=""
                                      />
                                      David Millan
                                    </td>
                                    <td>Dog</td>
                                    <td>Sam Billings</td>
                                    <td>
                                      <img
                                        src={Recpite}
                                        className="clickableImg"
                                        alt=""
                                      />
                                    </td>
                                    <td>$150</td>
                                    <td className="center">
                                      <img
                                        src={pending}
                                        className="checks tableImg"
                                        alt=""
                                      />
                                      Pending
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>12-06-2023</td>
                                    <td>01:20PM - 02:20PM</td>
                                    <td>
                                      <img
                                        src={roundCircle}
                                        className="rounded-circle tableImg"
                                        alt=""
                                      />
                                      David Millan
                                    </td>
                                    <td>Dog</td>
                                    <td>Sam Billings</td>
                                    <td>
                                      <img
                                        src={Recpite}
                                        className="clickableImg"
                                        alt=""
                                      />
                                    </td>
                                    <td>$15</td>
                                    <td className="center">
                                      <img
                                        src={pending}
                                        className="checks tableImg"
                                        alt=""
                                      />
                                      Pending
                                    </td>
                                  </tr> */}
                                  </tbody>
                                  :
                                  <tbody className="tableBody">
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-danger">
                                        No Data Found
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                              }
                            </table>
                            {/* <div className="pageData">
                              <label>Showing Page {page} - {totalData}</label>
                            </div> */}
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    {/* </InfiniteScroll> */}
                    {/* <Tab.Pane eventKey="4">
                                    <div className=" commmon-top-search-section">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-lg-4">
                                                <div className="innerSearchItem">
                                                    <div className="topSearch">
                                                        <input
                                                            type="text"
                                                            className="form-control searchDoc"
                                                            placeholder="Search by Name, Specialist, Ratings, Visits..."
                                                        />
                                                        <img
                                                            src={SearchIcon}
                                                            className="searchInner"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-between">
                                                <div className="topFilter">
                                                    <img src={FilterIcon} className='filterInner' alt="" />
                                                </div>
                                                <div className="topFilter">
                                                    <Link to="/AddPetProduct">
                                                        <div className="addBox">
                                                            <img src={ic_export} alt="" />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {doctorDetails === false ?
                                        <>
                                            <div className="Subscribtion_main">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="subscription_chart_main">
                                                            <div className="chart_Head">
                                                                <p className='chartTitle'>Subscriptions</p>
                                                                <div className="formSelect">
                                                                    <Form.Select aria-label="Default select example">
                                                                        <option>Year</option>
                                                                        <option value="1">2022</option>
                                                                        <option value="2">2021</option>
                                                                        <option value="3">2020</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </div>
                                                            <div className="revenueBarGraph">
                                                                <Chart options={subsrib.options} series={subsrib.series} type="bar" height="230" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="subscription_chart_main">
                                                            <div className="chart_Head">
                                                                <p className='chartTitle'>Revenue</p>
                                                                <div className="formSelect">
                                                                    <Form.Select aria-label="Default select example">
                                                                        <option>Months</option>
                                                                        <option value="1">Jan</option>
                                                                        <option value="2">Feb</option>
                                                                        <option value="3">Mar</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </div>
                                                            <div className="revenueBarGraph">
                                                                <Chart options={revenueBar.options} series={revenueBar.series} type="bar" height="230" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6" onClick={() => { setDoctorDetails(true) }}>
                                                        <div className="Mini_chart_Catagity">
                                                            <img src={car1} alt="" className="catagiry_logo" />
                                                            <p className="catagity_Name">Doctor</p>
                                                            <p className="Catagiry_amount">
                                                                $434
                                                                <span><img src={Down} alt="" className="cartagity_Graph_indicator" /></span>
                                                            </p>
                                                            <p className="graphValue green">
                                                                120
                                                            </p>
                                                            <Chart
                                                                options={doctor.options}
                                                                series={doctor.series}
                                                                type="area"
                                                                width="100%"
                                                                height="40%"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                                        <div className="Mini_chart_Catagity">
                                                            <img src={car2} alt="" className="catagiry_logo" />
                                                            <p className="catagity_Name">Pharmacy</p>
                                                            <p className="Catagiry_amount">
                                                                $200
                                                                <span><img src={UP} alt="" className="cartagity_Graph_indicator" /></span>
                                                            </p>
                                                            <p className="graphValue red">
                                                                160
                                                            </p>
                                                            <Chart
                                                                options={pharmacy.options}
                                                                series={pharmacy.series}
                                                                type="area"
                                                                width="100%"
                                                                height="40%"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                                        <div className="Mini_chart_Catagity">
                                                            <img src={car3} alt="" className="catagiry_logo" />
                                                            <p className="catagity_Name">Pet Shops</p>
                                                            <p className="Catagiry_amount">
                                                                $150
                                                                <span><img src={Down} alt="" className="cartagity_Graph_indicator" /></span>
                                                            </p>
                                                            <p className="graphValue perpule">
                                                                60
                                                            </p>
                                                            <Chart
                                                                options={petShop.options}
                                                                series={petShop.series}
                                                                type="area"
                                                                width="100%"
                                                                height="40%"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                                        <div className="Mini_chart_Catagity">
                                                            <img src={car4} alt="" className="catagiry_logo" />
                                                            <p className="catagity_Name">Vaccinators</p>
                                                            <p className="Catagiry_amount">
                                                                $500
                                                                <span><img src={UP} alt="" className="cartagity_Graph_indicator" /></span>
                                                            </p>
                                                            <p className="graphValue blue">
                                                                120
                                                            </p>
                                                            <Chart
                                                                options={vaccinators.options}
                                                                series={vaccinators.series}
                                                                type="area"
                                                                width="100%"
                                                                height="40%"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                                        <div className="Mini_chart_Catagity">
                                                            <img src={car5} alt="" className="catagiry_logo" />
                                                            <p className="catagity_Name">Ambulance</p>
                                                            <p className="Catagiry_amount">
                                                                $248
                                                                <span><img src={Down} alt="" className="cartagity_Graph_indicator" /></span>
                                                            </p>
                                                            <p className="graphValue orange">
                                                                100
                                                            </p>
                                                            <Chart
                                                                options={ambulance.options}
                                                                series={ambulance.series}
                                                                type="area"
                                                                width="100%"
                                                                height="40%"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                                        <div className="Mini_chart_Catagity">
                                                            <img src={car6} alt="" className="catagiry_logo" />
                                                            <p className="catagity_Name">Delivery Boy</p>
                                                            <p className="Catagiry_amount">
                                                                $150
                                                                <span><img src={UP} alt="" className="cartagity_Graph_indicator" /></span>
                                                            </p>
                                                            <p className="graphValue red">
                                                                60
                                                            </p>
                                                            <Chart
                                                                options={pharmacy.options}
                                                                series={pharmacy.series}
                                                                type="area"
                                                                width="100%"
                                                                height="40%"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div>
                                                <div className="doc-name-back-btn">
                                                    <Link onClick={() => setDoctorDetails(false)}>
                                                        <img src={arrowleft} alt="" />
                                                    </Link>
                                                    <label htmlFor="" className=" ms-3">Showing result for : </label> <span className="innerDatils">Doctor</span>
                                                </div>
                                                <div className="table-wrapper">
                                                    <table className="defaultTable">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No. </th>
                                                                <th>Doctor Name</th>
                                                                <th>Contact No.</th>
                                                                <th>Location</th>
                                                                <th>Team</th>
                                                                <th>Specialty</th>
                                                                <th>Experience</th>
                                                                <th>Subscription Plan</th>
                                                                <th className="center">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tableBody">
                                                            <tr>
                                                                <td>1</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    Othopedic
                                                                </td>
                                                                <td>
                                                                    5+yrs
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    Othopedic
                                                                </td>
                                                                <td>
                                                                    5+yrs
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    Othopedic
                                                                </td>
                                                                <td>
                                                                    5+yrs
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    Othopedic
                                                                </td>
                                                                <td>
                                                                    5+yrs
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    Othopedic
                                                                </td>
                                                                <td>
                                                                    5+yrs
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>
                                                                    <img
                                                                        src={roundCircle}
                                                                        className="rounded-circle tableImg"
                                                                        alt=""
                                                                    />
                                                                    David Millan
                                                                </td>
                                                                <td>
                                                                    +91 9999 9999 99
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        src={ic_location}
                                                                        alt=""
                                                                        className="tableImg"
                                                                    />
                                                                    <Link className="map-link">View in Map</Link>
                                                                </td>
                                                                <td>10</td>
                                                                <td>
                                                                    Othopedic
                                                                </td>
                                                                <td>
                                                                    5+yrs
                                                                </td>
                                                                <td>$15 (Monthly Sub.)</td>
                                                                <td className="center">
                                                                    <img
                                                                        src={pending}
                                                                        className="checks tableImg"
                                                                        alt=""
                                                                    />
                                                                    Pending
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </>
        )}
      </motion.div>
    </>
  );
};

export default RevenueReports;
