import React, { useRef, useState, useEffect } from 'react';

const CustomInfiniteScroll = ({ fetchMoreData, hasMore, currentPage, children }) => {
  const containerRef = useRef(null);

  const handleScroll = () => {
    const container = containerRef.current;

    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 20 && hasMore) {
        // Fetch more data only if not already fetching
        if (!container.getAttribute('data-fetching')) {
          container.setAttribute('data-fetching', 'true');
          const fetchDataPromise = fetchMoreData(currentPage);
          
          if (fetchDataPromise && fetchDataPromise.then) {
            fetchDataPromise
              .then(() => {
                container.removeAttribute('data-fetching');
              })
              .catch(() => {
                container.removeAttribute('data-fetching');
              });
          } else {
            container.removeAttribute('data-fetching');
          }
        }
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <div
      ref={containerRef}
      style={{maxHeight: 'calc(100vh - 180px)', overflow: 'auto'}}
      // style={{ height: '950px', overflow: 'auto' }}
    >
      {children}
    </div>
  );
};

export default CustomInfiniteScroll;
