import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import DocImg from "../../assets/images/icons/docImg.svg";
import Star from "../../assets/images/icons/ratingStar.svg";
import { Link } from "react-router-dom";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDebounce } from "../../customeHooks/useDebounce";
import NoDataMsg from "../../sharedComponent/NoDataMsg";

const Vaccinators = () => {
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { sidebar, navigate } = useContext(AppContext);

  // FETCH_VACCINATOR_LIST
  const [vaccinatorList, setVaccinatorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { current_page, last_page, total_count, total_pages } = paginationData;
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce(searchValue, 500);

  const fetchVaccinatorList = async (page) => {
    try {
      const res = await simpleGetCallWithToken(
        `${ApiConfig.FETCH_VACCINATOR_LIST}?page=${page}&search=${debouncedValue}`
      );
      const {
        success,
        current_page,
        last_page,
        total_count,
        total_pages,
        vaccinatorList,
      } = res;
      if (success) {
        vaccinatorList && setVaccinatorList(vaccinatorList);
        setPaginationData({
          current_page,
          last_page,
          total_count,
          total_pages,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  useEffect(() => {
    fetchVaccinatorList(currentPage);
  }, [debouncedValue]);

  const onPageLoad = () => {
    setCurrentPage(currentPage + 1);
    if (!last_page) {
      fetchVaccinatorList(currentPage + 1);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="doctor-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="innerSearchItem">
                <div className="leftFilterTop">
                  <div className="topSearch">
                    <input
                      type="text"
                      className="form-control searchDoc"
                      placeholder="Search by Name..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                    <img src={SearchIcon} className="searchInner" alt="" />
                  </div>
                  {/* <div className="topFilter">
                    <img src={FilterIcon} className="filterInner" alt="" />
                  </div> */}
                </div>
                <Link to="/AddVacinators" className="routButton">
                  <button className="innerBtn">Add Vaccinator</button>
                </Link>
              </div>
            </div>
            {/* Cards Content */}
            <InfiniteScroll
              dataLength={vaccinatorList?.length}
              next={onPageLoad}
              hasMore={!last_page}
              scrollableTarget="scroll-table"
              loader={
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                ></h4>
              }
            >
              {vaccinatorList.length > 1 ? (
                <div className="slideBelow row p-0 m-0">
                  {vaccinatorList &&
                    vaccinatorList.map((item, idx) => {
                      // vaccinator_profile_image_path
                      return (
                        <div
                          className="col-lg-3 cardsResponsive"
                          key={"vaccinatorList" + idx}
                        >
                          <div className="cardLink">
                            {/* to="/VaccinatorDetails" */}
                            <div className="doctorsDetailsCard">
                              <img
                                src={
                                  item?.vaccinator_profile_image_path
                                    ? item?.vaccinator_profile_image_path
                                    : ""
                                }
                                className="docInsideImg"
                                alt=""
                                onClick={() => {
                                  navigate(`/VaccinatorDetails`);
                                  sessionStorage.setItem(
                                    "vaccinator_id",
                                    item?.vaccinator_id
                                  );
                                }}
                              />
                              {item?.rating && (
                                <div className="ratingDet">
                                  <img
                                    src={Star}
                                    className="innerRate"
                                    alt=""
                                  />
                                  <label className="rateTxt">
                                    {item?.rating}
                                  </label>
                                </div>
                              )}
                              <div className="docDetailsTxt">
                                <h1>
                                  {item?.vaccinator_name
                                    ? item?.vaccinator_name
                                    : ""}
                                </h1>
                                {/* <label>Vaccinator</label> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <NoDataMsg MSG={"No Vaccinators Founds"} />
              )}
            </InfiniteScroll>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Vaccinators;
