import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import DoctorSahab from "../../assets/images/icons/doctorSahab.svg";
import ThreeDots from "../../assets/images/icons/ThreeDots.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs, Row, Col } from "react-bootstrap";
import collageImg from "../../assets/images/icons/bunchImg.svg";
import MoreImg from "../../assets/images/icons/moreImg+.svg";
import Doctor1 from "../../assets/images/icons/Doc1.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import Patient from "../../assets/images/icons/patientImg.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import DogImg from "../../assets/images/icons/dogImg.svg";
import catImg from "../../assets/images/icons/catImg.svg";
import Chart from "react-apexcharts";
import Star from "../../assets/images/icons/ratingStar.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import moment, { Moment } from "moment";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifySuccess } from "../../sharedComponent/Notify";
import Loader from "../../sharedComponent/Loader";
import FileIn from "../../assets/images/icons/fileIcon.svg";
// import disabled from "../../assets/images/disabled.png";
import disabled from "../../assets/images/ad-blocker.png";
import CustomInfiniteScroll from "../../customeHooks/CustomInfiniteScroll";
import Pagination from "../../sharedComponent/Pagination";

const DoctorDetails = () => {
  const params = useParams();
  const id = JSON.parse(params.id);
  const navigate = useNavigate();
  const { sidebar, setSidebar } = useContext(AppContext);
  const [stateChaneg, setStateChaneg] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#4675F1"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#34B53A"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "&#8377;" +
            "</div>"
          );
        },
      },
    },
  });
  const [lossState, setLossState] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#D0182E"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#D0182E"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "&#8377;" +
            "</div>"
          );
        },
      },
    },
  });
  const [actionMode, setActionMode] = useState({
    doctor_id: JSON.parse(params.id),
    status: "disable",
  });
  const [actionModeRem, setActionModeRem] = useState({
    doctor_id: JSON.parse(params.id),
    status: "remove",
  });
  // Basic Information State
  const [basicInform, setBasicInform] = useState({});
  console.log("basicInform,", basicInform);
  const [languageArr, setLanguageArr] = useState([]);
  //   General Information State
  const [generalInform, setGeneralInform] = useState({});
  const [servicesArr, setservicesArr] = useState([]);
  //   Clinic Information State
  const [clinicArr, setClinicArr] = useState([]);
  const [facilitiesArr, setFacilitiesArr] = useState([]);
  const [clinicImgArr, setClinicImgArr] = useState([]);
  // Get Doc Documents
  const [doctorDocs, setDoctorDocs] = useState([]);
  // Single Doc Name Nd Image
  const [doctorDocIdArr, setDoctorIdArr] = useState([]);
  console.log("doctorDocIdArr,", doctorDocIdArr);
  //   Teams Information State
  const [teamsArr, setTeamsArr] = useState([]);
  //   Appointments Information
  const [appointmentDet, setAppointmentDet] = useState([]);
  // Transaction Information
  const [transactionDet, setTransactionDet] = useState([]);
  // Bank Details Info
  const [bankDataArr, setBankDataArr] = useState([]);
  // Signature Details Info
  const [signatureArr, setSignatureArr] = useState({});
  // ratings Details Info
  const [ratingDetArr, setRatingDetArr] = useState([]);
  const [progressArr, setProgressArr] = useState([]);
  const [doctorInfo, setDoctorInfo] = useState([]);
  const [reviewDetArr, setReviewDetArr] = useState({});
  // Doctor's Details State
  const [basicInfo, setBasicInfo] = useState(false);
  const [generalInfo, setGeneralInfo] = useState(false);
  const [clinicInfo, setClinicInfo] = useState(false);
  const [teamsInfo, setTeamsInfo] = useState(false);
  const [schedule,setSchedule] = useState(null);
  // Revenue Graph of doctor
  const [firstRevenue, setFirstRevenue] = useState([]);
  const [lastRevenue, setLastRevenue] = useState([]);

  // Rating State
  const [ratings, setRatings] = useState({});

  // FUNCTIONS BELOW For Tabs
  const getDocDet = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DOCTOR_IMAGEAND_DETAIL + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setDoctorIdArr(res?.data);
        } else {
          setDoctorIdArr([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBasicInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_BASIC_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setBasicInfo(true);
          setBasicInform(res?.data);
          setLanguageArr(res?.data?.language);
        } else {
          setBasicInfo(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGeneralInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_GENERAL_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setGeneralInfo(true);
          setGeneralInform(res?.data);
          setservicesArr(res?.data?.specializations);
        } else {
          setGeneralInfo(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClinicInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_CLINIC_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setClinicInfo(true);
          setClinicArr(res?.data);
          setSchedule(res?.clinicSchedule);
          let arr = res?.data?.facilitiesString;
          let facilitiesArr = arr.map((data, index) => {
            return data;
          });
          setFacilitiesArr(facilitiesArr);
          let clinicArr = res?.data?.images;
          let storeClImg = clinicArr.map((data, index) => {
            return data;
          });
          setClinicImgArr(storeClImg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeamsInfo = () => {
    simpleGetCallWithToken(ApiConfig.DOCTOR_TEAMS + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setTeamsInfo(true);
          setTeamsArr(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumentsInfo = () => {
    simpleGetCallWithToken(ApiConfig.GET_DOCTOR_DOCUMENTS + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setDoctorDocs(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocSignatur = () => {
    simpleGetCallWithToken(ApiConfig.GET_DOCTOR_SIGNATURE + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setSignatureArr(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDoctorInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DOCTOR_IMAGEAND_DETAIL + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setDoctorInfo(res?.data);
        } else {
          setGeneralInfo(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [paginationData, setPaginationData] = useState({
    current_page: "",
    total: "",
    total_pages: "",
    last_page: false,
  });
  // const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = ({ selected }) => {
    getAppointmentList(selected + 1);
  };

  const getAppointmentList = (currentPage) => {
    let payload = {
      doctor_id: id,
      page: currentPage ? currentPage : 1,
      limit: 10,
    };
    simplePostAuthCall(
      ApiConfig.GET_DOCTORS_APPOINTMENT_LIST,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (res.success === true) {
          setPaginationData({
            current_page: res.current_page,
            total: res.total,
            total_pages: res.total_pages,
            last_page: res.last_page,
          });
          res?.data &&
            setAppointmentDet(
              res?.data.map((it) => {
                return {
                  ...it,
                  srno: res?.index,
                };
              })
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [totalPages, setTotalPages] = useState(0);

  const onTransactionPageChange = ({ selected }) => {
    getTrasactionInfo(selected + 1);
  };

  const getTrasactionInfo = (currentPage) => {
    let payload = {
      doctor_id: id,
      page: currentPage ? currentPage : 1,
      limit: 10,
    };

    simplePostAuthCall(
      ApiConfig.GET_DOCTORS_TRANSACTION_LIST,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (res.success === true) {
          const offesetValue = 10 * (currentPage - 1)
          res.total_pages && setTotalPages(res.total_pages);
          res?.data && setTransactionDet(res?.data?.map(it => {
            return {
              ...it,
               srno: offesetValue,
            }
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankDetails = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DOCTOR_BANK_DETAILS + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          res?.data && setBankDataArr(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTotalRatings = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_ALL_DOCTOR_RATINGS + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setRatingDetArr(res?.data);
          setProgressArr(res?.ratingPercentage);
          setReviewDetArr(res?.totalReviews);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   Actions Function
  const changetoDisable = (isDisable) => {
    // const [actionMode, setActionMode] = useState({
    //   doctor_id: JSON.parse(params.id),
    //   status: isDisable ? "enable" : "disable",
    // });
    const actionMode = {
      doctor_id: JSON.parse(params.id),
      status: isDisable ? "disable" : "enable",
    };
    simplePostAuthCall(ApiConfig.DOCTOR_STATUS, JSON.stringify(actionMode))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          navigate("/Doctors");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changetoRemove = () => {
    simplePostAuthCall(ApiConfig.DOCTOR_STATUS, JSON.stringify(actionModeRem))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          navigate("/Doctors");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRevenueGraph = () => {
    simpleGetCallWithToken(ApiConfig.GET_REVENUE_GRAPH + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          let firstRevData = res?.data;
          setFirstRevenue(firstRevData);
          setLastRevenue(res?.lastData);
          setStateChaneg({
            ...stateChaneg,
            series: [{ data: res?.lastData?.perMonth }],
          });
          setLossState({
            ...lossState,
            series: [{ data: res?.data?.current_month_data.splice(10) }],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRatingReview = () => {
    simpleGetCallWithToken(ApiConfig.GET_RATING_REVIEW + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setRatings(res?.data?.deliveryBoyRatings);
        } else {
          setRatings();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   Use State For Rendering
  useState(() => {
    getDocDet();
    getBasicInfo();
    getGeneralInfo();
    getClinicInfo();
    getTeamsInfo();
    getAppointmentList(1);
    getTrasactionInfo(1);
    getBankDetails();
    getTotalRatings();
    getDocumentsInfo();
    getDocSignatur();
    getRevenueGraph();
    getRatingReview();
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="doctorDetails-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                <img
                  src={
                    basicInform?.doctor_profile_image_path
                      ? basicInform?.doctor_profile_image_path
                      : DoctorSahab
                  }
                  className="docImg"
                  alt=""
                />
                <div className="docDetTxt">
                  <div>
                    <h1 className="docName">{basicInform?.doctor_name}</h1>
                    <label className="docDomain">
                      {basicInform?.specializations}
                    </label>
                  </div>
                  {basicInform?.is_disabled && (
                    <div className="disable_img">
                      {" "}
                      <img src={disabled} />{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="dropDown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={ThreeDots} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link
                        to="#"
                        onClick={() =>
                          changetoDisable(!basicInform?.is_disabled)
                        }
                      >
                        {basicInform?.is_disabled ? "Enable" : "Disable"}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="#" onClick={() => changetoRemove()}>
                        Remove
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="doctorTabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Profile">
                <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                  <Nav.Item>
                    <Nav.Link eventKey="Profile">Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Appointments">Appointments</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Transaction">Transaction</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="PaymentDetails">
                      Payment Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="RatingReviews">
                      Rating & Reviews
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Profile">
                    <div className="row">
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle">
                          <h1 className="heading">Basic Information</h1>
                        </div>
                      </div>
                      {/* Inner Values */}
                      {basicInfo ? (
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Name</h1>
                              <label htmlFor="" className="value">
                                {basicInform?.doctor_name}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Age</h1>
                              <label htmlFor="" className="value">
                                {basicInform?.doctor_age}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Gender</h1>
                              <label htmlFor="" className="value">
                                {basicInform?.doctor_gender}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Contact Number</h1>
                              <label htmlFor="" className="value">
                                {basicInform?.doctor_contact_number}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Email</h1>
                              <label htmlFor="" className="value">
                                {basicInform?.doctor_email}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Registration No.</h1>
                              <label htmlFor="" className="value">
                                {basicInform?.doctor_registration_no}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Known Languages</h1>
                              {languageArr && languageArr.length > 0
                                ? languageArr.map((data, index) => {
                                    return (
                                      <>
                                        <label
                                          htmlFor=""
                                          className="value"
                                          key={index}
                                        >
                                          {data?.language}
                                          {index < languageArr.length - 1 &&
                                            ","}
                                        </label>
                                      </>
                                    );
                                  })
                                : null}
                              {/* <label htmlFor="" className="value">
                                English, Hindi, Marathi, Urdu
                              </label> */}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 dispNoneMedia"></div>
                        </>
                      ) : (
                        <div className="col-md-12 text-center p-3 text-danger">
                          No Data Found
                        </div>
                      )}

                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle moreMt">
                          <h1 className="heading">General Information</h1>
                        </div>
                      </div>
                      {generalInfo ? (
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Specialization</h1>
                              <ul>
                                {servicesArr && servicesArr.length > 0
                                  ? servicesArr.map((data, index) => {
                                      return (
                                        <li key={index}>
                                          {data?.specialization}
                                        </li>
                                      );
                                    })
                                  : null}
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Services</h1>
                              <ul>
                                <li>{generalInform?.services}</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Experience</h1>
                              <label htmlFor="" className="value">
                                {generalInform?.experience}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Education</h1>
                              <label htmlFor="" className="value">
                                {generalInform?.education}
                              </label>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-12 text-center p-3 text-danger">
                          No Data Found
                        </div>
                      )}
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle moreMt">
                          <h1 className="heading">Clinic Information</h1>
                        </div>
                      </div>
                      {clinicInfo ? (
                        <>
                          {clinicArr && clinicArr.length > 0
                            ? clinicArr.map((data, index) => {
                                return (
                                  <>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                      <div className="keyValueContent">
                                        <h1 className="Key">Clinic Name</h1>
                                        <label htmlFor="" className="value">
                                          {data?.doctor_clinic_name}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                      <div className="keyValueContent">
                                        <h1 className="Key">Facilities</h1>
                                        <ul>
                                          {data.facilitiesString &&
                                          data.facilitiesString.length > 0
                                            ? data.facilitiesString.map(
                                                (data, index) => {
                                                  return (
                                                    <li key={index}>
                                                      {data?.clinic_facility}
                                                    </li>
                                                  );
                                                }
                                              )
                                            : null}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                      <div className="keyValueContent">
                                        <h1 className="Key">Address</h1>
                                        <label htmlFor="" className="value">
                                          {typeof data?.doctor_clinic_address ===
                                          "undefined"
                                            ? data?.doctor_clinic_address
                                            : ""}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                      <div className="keyValueContent">
                                        <h1 className="Key">Photos</h1>
                                        <div className="imagesBunch">
                                          {data?.images &&
                                          data?.images.length > 0
                                            ? data?.images?.map(
                                                (data, index) => {
                                                  return (
                                                    <img
                                                      key={"sadf" + index}
                                                      src={
                                                        data?.doctor_clinic_image_path
                                                          ? data?.doctor_clinic_image_path
                                                          : collageImg
                                                      }
                                                      alt=""
                                                    />
                                                  );
                                                }
                                              )
                                            : null}
                                          {/* <img src={collageImg} alt="" />
                                        <img src={collageImg} alt="" />
                                        <img src={MoreImg} alt="" /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </>
                      ) : (
                        <div className="col-md-12 text-center p-3 text-danger">
                          No Data Found
                        </div>
                      )}

                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle moreMt">
                          <h1 className="heading">Team</h1>
                        </div>
                      </div>
                      {/* DOctor's Cards */}
                      {teamsInfo ? (
                        <div className="flexyDoctorsInner">
                          {teamsArr && teamsArr.length > 0
                            ? teamsArr.map((data, index) => {
                                return (
                                  <div className="cardMd25">
                                    <div className="teamsCard shadow-sm">
                                      <img
                                        src={
                                          data?.doctor_profile_image_path
                                            ? data?.doctor_profile_image_path
                                            : Doctor1
                                        }
                                        alt=""
                                      />
                                      <div className="detailsInner">
                                        <h1 className="NameDOc">
                                          {data?.doctor_name}
                                        </h1>
                                        <label htmlFor="" className="DomainDoc">
                                          {data?.specialization}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      ) : (
                        <div className="col-md-12 text-center p-3 text-danger">
                          No Data Found
                        </div>
                      )}
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle moreMt">
                          <h1 className="heading">Documents</h1>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div id="docsTabs">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="first"
                          >
                            <Nav variant="pills" className="flex-column">
                              {doctorDocs && doctorDocs.length > 0 ? (
                                doctorDocs.map((data, index) => {
                                  return (
                                    <Nav.Item>
                                      <Nav.Link
                                        href={data?.clinic_certificate_path}
                                        target="_blank"
                                        eventKey={index}
                                      >
                                        <div className="fileInside">
                                          <div className="imgHolder">
                                            <img src={FileIn} alt="" />
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  );
                                })
                              ) : (
                                <div className="col-md-12 text-center p-3 text-danger">
                                  No Data Found
                                </div>
                              )}
                              {/* <Nav.Item>
                                <Nav.Link eventKey="first">
                                  <div className="fileInside">
                                    <div className="imgHolder">
                                      <img src={FileIn} alt="" />
                                    </div>
                                    <h1>Tittle Inside</h1>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="second">Tab 2</Nav.Link>
                              </Nav.Item> */}
                            </Nav>
                          </Tab.Container>
                        </div>
                      </div>
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle moreMt">
                          <h1 className="heading">Signature</h1>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="allSignsFlex">
                          <div className="signatureHolder">
                            <a href={signatureArr?.doctor_signature_path}>
                              <img
                                src={signatureArr?.doctor_signature_path}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Clinic Schedule</h1>
                  </div>
                </div>
                {schedule ? (
                  <>
                    {schedule && (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="keyValueContent">
                            <h1 className="Key">Working days</h1>
                            <label
                              htmlFor=""
                              className="value"
                              style={{ display: "flex", gap: "15px" }}
                            >
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_sun
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  S
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_mon
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  M
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_tue
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  T
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_wed
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  W
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_thu
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  T
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_fri
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  F
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_sat
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  S
                                </span>
                              }
                            </label>
                          </div>
                        </div>
                        {schedule?.is_morning_shift && (
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Morning Shift</h1>
                              <label htmlFor="" className="value">
                                {moment(schedule?.slot_morning_start_time, [
                                  "HH:mm",
                                ]).format("hh:mm A")}{" "}
                                -{" "}
                                {schedule &&
                                  moment(schedule?.slot_morning_end_time, [
                                    "HH:mm",
                                  ]).format("hh:mm A")}
                              </label>
                            </div>
                          </div>
                        )}
                        {schedule?.is_evening_shift && (
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Evening Shift</h1>
                              <label htmlFor="" className="value">
                                {moment(schedule?.slot_evening_start_time, [
                                  "HH:mm",
                                ]).format("hh:mm A")}{" "}
                                -{" "}
                                {schedule &&
                                  moment(schedule?.slot_evening_end_time, [
                                    "HH:mm",
                                  ]).format("hh:mm A")}
                              </label>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="keyValueContent">
                            <h1 className="Key">Charges</h1>
                            {schedule?.is_morning_shift && (
                              <>
                                {" "}
                                <label htmlFor="" className="value">
                                  Morning - ₹
                                  {schedule?.slot_morning_doctor_charge}
                                </label>{" "}
                                <br />
                              </>
                            )}
                            {schedule?.is_evening_shift && (
                              <label htmlFor="" className="value">
                                Evening - ₹
                                {schedule?.slot_evening_doctor_charge}
                              </label>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Appointments">
                    <div className="mainAppointments">
                      <div className="allApointmentsRow">
                        <h1 className="headTxt">All Appointment’s</h1>
                        {/* <img src={FilterIcon} className="FilImg" alt="" /> */}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {/* incedoTable */}
                          <div className="incedoTable">
                            {/* <div className="innerYscroll"> */}
                            <table className="defaultTable">
                              <thead>
                                <tr>
                                  <th>Sr No. </th>
                                  <th>Appointment Date</th>
                                  <th>Appointment Type</th>
                                  <th>Time</th>
                                  <th>Patient</th>
                                  <th>Pet Type</th>
                                  <th>Diagnose</th>
                                  <th>Veterinarian</th>
                                  <th>Call Duration</th>
                                  <th>Fees</th>
                                  <th>Status</th>
                                </tr>
                              </thead>

                              {appointmentDet && appointmentDet.length > 0 ? (
                                <tbody className="tableBody">
                                  {appointmentDet.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{data.srno + index + 1}</td>
                                        <td>
                                          {moment(data?.date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {data.appointment_type == 1
                                            ? "At Clinic"
                                            : "At Video"}
                                        </td>
                                        <td>
                                          {moment(data.time, "HH:mm:ss").format(
                                            "hh:mm A"
                                          )}
                                        </td>
                                        <td className="imgPlusName">
                                          <img
                                            src={
                                              data?.image_path
                                                ? data?.image_path
                                                : Patient
                                            }
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          {data?.animal_name}
                                        </td>
                                        <td>{data?.type_of_animal}</td>
                                        <td>
                                          {(data.case_type === "surgery" && (
                                            <div className="diagnoseSignalsSurgery">
                                              Surgery
                                            </div>
                                          )) ||
                                            (data.case_type === "checkup" && (
                                              <div className="diagnoseSignalsCheck">
                                                Check Up
                                              </div>
                                            )) ||
                                            (data.case_type === "Test" && (
                                              <div className="diagnoseSignalsTest">
                                                Test
                                              </div>
                                            )) ||
                                            (data.case_type === "vaccine" && (
                                              <div className="diagnoseSignalsVaccine">
                                                Vaccine
                                              </div>
                                            )) ||
                                            (data.case_type === null && (
                                              <div className="noData">
                                                No Diagnose
                                              </div>
                                            ))}
                                        </td>
                                        <td>{basicInform?.doctor_name}</td>
                                        <td style={{textAlign : "center"}}>{data.total_call_duration ? data.total_call_duration : "-"}</td>
                                        <td>₹{data.charges}</td>
                                        <td>
                                          {(data.stringStatus ===
                                            "Rejected" && (
                                            <span>
                                              <img
                                                src={cancel}
                                                className="checks"
                                                alt=""
                                              />
                                              Rejected
                                            </span>
                                          )) ||
                                            (data.stringStatus ===
                                              "Accepted" && (
                                              <span>
                                                <img
                                                  src={approved}
                                                  className="checks"
                                                  alt=""
                                                />
                                                Checked
                                              </span>
                                            )) ||
                                            (data.stringStatus ===
                                              "Pending" && (
                                              <span>
                                                <img
                                                  src={pending}
                                                  className="checks"
                                                  alt=""
                                                />
                                                Pending
                                              </span>
                                            )) ||
                                            (data.stringStatus ===
                                              "Completed" && (
                                              <span>
                                                <img
                                                  src={approved}
                                                  className="checks"
                                                  alt=""
                                                />
                                                Completed
                                              </span>
                                            ))}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan="8" className="text-center p-3">
                                      <label
                                        htmlFor=""
                                        className="text-danger fw-bold"
                                      >
                                        No Data Found
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      {paginationData.total_pages && (
                        <Pagination
                          pageCount={paginationData.total_pages}
                          onPageChange={onPageChange}
                        />
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Transaction">
                    <div className="mainAppointments">
                      <div className="allApointmentsRow">
                        <h1 className="headTxt">All Transaction’s</h1>
                        <img src={FilterIcon} className="FilImg" alt="" />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="incedoTable">
                            {/* <div className="innerYscroll"> */}
                            <table className="defaultTable">
                              <thead>
                                <tr>
                                  <th>Sr No. </th>
                                  <th>Transaction Date</th>
                                  <th>Time</th>
                                  <th>Patient</th>
                                  <th>Pet Type</th>
                                  <th>Diagnose</th>
                                  <th>Veterinarian</th>
                                  <th>Fees/Subscription</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              {transactionDet && transactionDet.length > 0 ? (
                                <tbody className="tableBody">
                                  {transactionDet.map((data, index) => {
                                    return (
                                      <tr>
                                        <td>{data.srno + index + 1}</td>
                                        <td>
                                          {moment(
                                            data?.transaction_date
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                          {" "}
                                          {moment(data.time, "HH:mm:ss").format(
                                            "hh:mm A"
                                          )}
                                        </td>
                                        <td className="imgPlusName">
                                          <img
                                            src={
                                              data?.image_path
                                                ? data?.image_path
                                                : Patient
                                            }
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          {data.animal_name}
                                        </td>
                                        <td>{data.type_of_animal}</td>
                                        <td>
                                          {(data.case_type === "surgery" && (
                                            <div className="diagnoseSignalsSurgery">
                                              Surgery
                                            </div>
                                          )) ||
                                            (data.case_type === "checkup" && (
                                              <div className="diagnoseSignalsCheck">
                                                Check Up
                                              </div>
                                            )) ||
                                            (data.case_type === "Test" && (
                                              <div className="diagnoseSignalsTest">
                                                Test
                                              </div>
                                            )) ||
                                            (data.case_type === "vaccine" && (
                                              <div className="diagnoseSignalsVaccine">
                                                Vaccine
                                              </div>
                                            )) ||
                                            (data.case_type === null && (
                                              <div className="noData">
                                                No Diagnose
                                              </div>
                                            ))}
                                        </td>
                                        <td>{basicInform?.doctor_name}</td>
                                        <td>₹{data.charges} (Monthly Sub.)</td>
                                        <td>
                                          {(data.stringStatus ===
                                            "Rejected" && (
                                            <span>
                                              <img
                                                src={cancel}
                                                className="checks"
                                                alt=""
                                              />
                                              Rejected
                                            </span>
                                          )) ||
                                            (data.stringStatus ===
                                              "Accepted" && (
                                              <span>
                                                <img
                                                  src={approved}
                                                  className="checks"
                                                  alt=""
                                                />
                                                Checked
                                              </span>
                                            )) ||
                                            (data.stringStatus ===
                                              "Pending" && (
                                              <span>
                                                <img
                                                  src={pending}
                                                  className="checks"
                                                  alt=""
                                                />
                                                Pending
                                              </span>
                                            )) ||
                                            (data.stringStatus ===
                                              "Completed" && (
                                              <span>
                                                <img
                                                  src={approved}
                                                  className="checks"
                                                  alt=""
                                                />
                                                Completed
                                              </span>
                                            ))}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  {/* <tr>
                                      <td>2</td>
                                      <td>12-06-2023</td>
                                      <td>01:20PM - 02:20PM</td>
                                      <td>
                                        <img
                                          src={Patient}
                                          className="rounded-circle tableImg"
                                          alt=""
                                        />
                                        Drogo
                                      </td>
                                      <td>Dog</td>
                                      <td>
                                        <div className="diagnoseSignalsVaccine">
                                          Vaccine
                                        </div>
                                      </td>
                                      <td>Sam Billings</td>
                                      <td>₹15 (Monthly Sub.)</td>
                                      <td>
                                        {" "}
                                        <img
                                          src={cancel}
                                          className="checks"
                                          alt=""
                                        />
                                        Rejected
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>12-06-2023</td>
                                      <td>01:20PM - 02:20PM</td>
                                      <td>
                                        <img
                                          src={Patient}
                                          className="rounded-circle tableImg"
                                          alt=""
                                        />
                                        Drogo
                                      </td>
                                      <td>Dog</td>
                                      <td>
                                        <div className="diagnoseSignalsSurgery">
                                          Surgery
                                        </div>
                                      </td>
                                      <td>Sam Billings</td>
                                      <td>₹15 (Monthly Sub.)</td>
                                      <td>
                                        {" "}
                                        <img
                                          src={approved}
                                          className="checks"
                                          alt=""
                                        />
                                        Checked
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>12-06-2023</td>
                                      <td>01:20PM - 02:20PM</td>
                                      <td>
                                        <img
                                          src={Patient}
                                          className="rounded-circle tableImg"
                                          alt=""
                                        />
                                        Drogo
                                      </td>
                                      <td>Dog</td>
                                      <td>
                                        <div className="diagnoseSignalsTest">
                                          Test
                                        </div>
                                      </td>
                                      <td>Sam Billings</td>
                                      <td>₹15 (Monthly Sub.)</td>
                                      <td>
                                        {" "}
                                        <img
                                          src={approved}
                                          className="checks"
                                          alt=""
                                        />
                                        Checked
                                      </td>
                                    </tr> */}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan="8" className="text-center p-3">
                                      <label
                                        htmlFor=""
                                        className="text-danger fw-bold"
                                      >
                                        No Data Found
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      {totalPages ? (
                        <Pagination
                          pageCount={totalPages}
                          onPageChange={onTransactionPageChange}
                        />
                      ) : null}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="PaymentDetails">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="heightScroller">
                          {bankDataArr && bankDataArr.length > 0 ? (
                            bankDataArr.map((data, index) => {
                              return (
                                <div className="bankDetailsCard shadow-sm">
                                  <div className="heading">
                                    <label className="headTxt">
                                      Bank Details {index + 1}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Account Holder Name
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data.account_holder_name}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Account Number
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data.account_number}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Bank Name
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data.doctor_bank}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      IFSC/ SWIFT Code
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {data.ifsc_code}
                                    </label>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-md-6 text-center p-3 text-danger">
                              No Data Found
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="bankDetailsCard shadow-sm">
                          <div className="heading">
                            <label className="headTxt">Revenue Summary</label>
                          </div>
                          <div className="graphs">
                            <div className="profitGraph">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  &#8377; {lastRevenue?.totalRevenue}
                                </label>
                                <label className="plusPercent">
                                  +{lastRevenue?.percentage}%
                                </label>
                              </div>
                              <div className="graph">
                                <Chart
                                  options={stateChaneg.options}
                                  series={stateChaneg.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  Total Revenue
                                </label>
                              </div>
                            </div>
                            <div className="lossGraph">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  &#8377;{firstRevenue?.currentMonth_total}
                                </label>
                                <label className="lossPercent">
                                  -{firstRevenue?.percentage}%
                                </label>
                              </div>
                              <div className="graph">
                                <Chart
                                  options={lossState.options}
                                  series={lossState.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  This Month
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="RatingReviews">
                    <div className="ratingBox">
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="totalReviewCard">
                            <h1 className="headTxt">Total Reviews</h1>
                            <p className="amntTxt">{ratings?.total_ratings}</p>
                            <label className="detailTxt">
                              Growth in the review from last month
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="totalReviewCard">
                            <h1 className="headTxt">Average Ratings</h1>
                            <div className="innerStars">
                              <p className="amntTxt">{ratings?.avg_rating}</p>
                              {(ratings?.avg_rating === 1 && (
                                <img src={Star} alt="" />
                              )) ||
                                (ratings?.avg_rating === 2 && (
                                  <>
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                  </>
                                )) ||
                                (ratings?.avg_rating === 3 && (
                                  <>
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                  </>
                                )) ||
                                (ratings?.avg_rating === 4 && (
                                  <>
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                  </>
                                )) ||
                                ratings?.avg_rating === 5 ||
                                (ratings?.avg_rating >= 5 && (
                                  <>
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                    <img src={Star} alt="" />
                                  </>
                                ))}
                            </div>
                            <label className="detailTxt">
                              Average rating from month
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="ratingStars">
                            {/* {progressArr && progressArr.length > 0 ? (
                              progressArr.map((data, index) => {
                                return (
                                  <div className="starLevels">
                                    <img
                                      src={Star}
                                      className="starImg"
                                      alt=""
                                    />
                                    <label className="starAmnt">
                                      {index + 1}
                                    </label>
                                    <ProgressBar
                                      now={data?.[index]}
                                      className="greenBar"
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <div className="col-md-12 text-center p-3 text-danger">
                                No Data Found
                              </div>
                            )} */}
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">5</label>
                              <ProgressBar
                                now={ratings?.rating_5}
                                className="greenBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">4</label>
                              <ProgressBar
                                now={ratings?.rating_4}
                                className="bottlegreenBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">3</label>
                              <ProgressBar
                                now={ratings?.rating_3}
                                className="liteOrangeBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">2</label>
                              <ProgressBar
                                now={ratings?.rating_2}
                                className="darkOrangeBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">1</label>
                              <ProgressBar
                                now={ratings?.rating_1}
                                className="redBar"
                              />
                            </div>
                          </div>
                        </div>
                        {ratingDetArr && ratingDetArr.length > 0 ? (
                          ratingDetArr.map((data, index) => {
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12">
                                    <div className="belowLine"></div>
                                  </div>
                                  <div className="col-lg-4 col-md-12">
                                    <div className="petDetailsCard">
                                      <img
                                        src={
                                          data?.animal_photograph_path
                                            ? data?.animal_photograph_path
                                            : DogImg
                                        }
                                        alt=""
                                      />
                                      <div className="cardDetails">
                                        <label className="headingTx">
                                          {data?.first_name +
                                            " " +
                                            data?.last_name}
                                        </label>
                                        <label className="reviewTxt">
                                          Total Reviews:{" "}
                                          <span>
                                            {data?.count_of_customer_id}
                                          </span>{" "}
                                        </label>
                                        <label className="spendsTxt">
                                          Total Spends:{" "}
                                          <span>{data?.total_charges}</span>{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-8 col-md-12">
                                    <div className="regularCheqCard">
                                      <div className="ratingDetailsHead">
                                        <div className="nameAndDate">
                                          <label className="nameLab">
                                            {data?.review_title}
                                          </label>
                                          <label className="dateLab">
                                            {moment(data?.date).format("L")}
                                          </label>
                                        </div>
                                        <div className="starRate">
                                          <img
                                            src={Star}
                                            className="starIcon"
                                            alt=""
                                          />
                                          <label className="rateNum">
                                            {data?.appointment_customer_rating}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="paraFlow">
                                        <p className="insideParaDet">
                                          {data?.appointment_customer_review}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <div className="col-md-12 text-center p-3 text-danger">
                            No Data Found
                          </div>
                        )}
                        {/* <div className="col-lg-4 col-md-12">
                          <div className="petDetailsCard">
                            <img src={catImg} alt="" />
                            <div className="cardDetails">
                              <label className="headingTx">James Bond</label>
                              <label className="reviewTxt">
                                Total Reviews: <span>10</span>{" "}
                              </label>
                              <label className="spendsTxt">
                                Total Spends: <span>$1000</span>{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div className="regularCheqCard">
                            <div className="ratingDetailsHead">
                              <div className="nameAndDate">
                                <label className="nameLab">
                                  Regular Checkup
                                </label>
                                <label className="dateLab">24-02-2023</label>
                              </div>
                              <div className="starRate">
                                <img src={Star} className="starIcon" alt="" />
                                <label className="rateNum">4.5</label>
                              </div>
                            </div>
                            <div className="paraFlow">
                              <p className="insideParaDet">
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit . Minima dicta officiis,
                                possimus cumque magni odit, obcaecati, numquam
                                quaerat in eligendi quae provident ratione?
                                Aperiam nam ratione inventore voluptas dolore
                                distinctio deleniti accusamus voluptatibus qui
                                officia !
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default DoctorDetails;
