import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import DoctorSahab from "../../assets/images/icons/doctorSahab.svg";
import collageImg from "../../assets/images/icons/bunchImg.svg";
import MoreImg from "../../assets/images/icons/moreImg+.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePostCall,
} from "../../api/ApiServices";
import { Modal, Nav, Tab } from "react-bootstrap";
import FileIn from "../../assets/images/icons/fileIcon.svg";
import { notifyError, notifySuccess } from "../../sharedComponent/Notify";
import dummydoctor from "../../assets/images/icons/dummydoctor.jpg";
import moment from "moment";
import Select from "react-select";

const ViewDoctorDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { sidebar, setSidebar } = useContext(AppContext);
  const [basicInfo, setBasicInfo] = useState([]);
  const [languageArr, setLanguageArr] = useState([]);
  const [basic, setBasic] = useState([]);
  const [servicesArr, setservicesArr] = useState([]);
  const [generalInfo, setGeneralInfo] = useState([]);
  const [general, setGeneral] = useState([]);
  const [clinicInfo, setClinicInfo] = useState([]);
  const [Clinic, setClinic] = useState(false);
  const [bank, seBank] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [profile, setProfile] = useState(false);
  const [doctorPro, setDoctorPro] = useState([]);
  const [doctorDocs, setDoctorDocs] = useState([]);
  const [signatureArr, setSignatureArr] = useState([]);
  const [schedule,setSchedule] = useState(null);

  const [isDoctorAccept, setIsDoctorAccept] = useState({
    show: false,
    doctorId: "",
  });
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const [subscriptionPayload, setSubscriptionPayload] = useState({
    subscription_id: "",
    startDate: new Date(),
    endDate: "",
    duration_months: "",
  });
  

  const id = JSON.parse(params.id);
  // console.log(id)

  const getBasicInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_BASIC_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setBasic(true);
          setBasicInfo(res?.data);
          setLanguageArr(res?.data?.language);
        } else {
          setBasicInfo(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGeneralInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_GENERAL_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setGeneral(true);
          setGeneralInfo(res?.data);
          setservicesArr(res?.data?.specializations);
        } else {
          setGeneralInfo(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClinicInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_CLINIC_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setClinic(true);
          setClinicInfo(res?.data);
          setSchedule(res?.clinicSchedule)
          if (res?.data?.facilitiesString) {
            let arr = res?.data?.facilitiesString;
            let facilities = arr.map((data, index) => {
              return data;
            });
          }
          // let facilitiesArrNested = facilitiesArr.map((data) => {
          //   return data;
          // });
          console.log("facilitiesArr =>", facilities);
          setFacilities(facilities);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankDetails = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DOCTOR_BANK_DETAILS + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          seBank(true);
          setBankDetails(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doctorProfile = () => {
    simpleGetCallWithToken(ApiConfig.DOCTOR_PROFILE + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setProfile(true);
          console.log(res);
          setDoctorPro(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumentsInfo = () => {
    simpleGetCallWithToken(ApiConfig.GET_DOCTOR_DOCUMENTS + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setDoctorDocs(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocSignatur = () => {
    simpleGetCallWithToken(ApiConfig.GET_DOCTOR_SIGNATURE + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setSignatureArr(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doctorAccept = (doctor_id) => {
    let payload = {
      doctor_id: doctor_id,
    };
    // Assuming `simplePostAuthCall` returns a Promise
    simplePostAuthCall(ApiConfig.DOCTOR_ACCEPT, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          navigate("/Doctors");
        }else{
          notifyError(res.message)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doctorReject = (doctor_id) => {
    let payload = {
      doctor_id: doctor_id,
    };
    // Assuming `simplePostAuthCall` returns a Promise
    simplePostAuthCall(ApiConfig.DOCTOR_REJECT, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSubscriptions = async () => {
    try {
      const res = await simpleGetCallWithToken(
        `${ApiConfig.GET_SUBSCRIPTION_PLAN_LIST}?entity_id=1`
      );
      const { success } = res;
      if (success) {
        const formattedArr =
          res.data &&
          res.data.map((item) => {
            return {
              label: item.subscription_description || "",
              value: item.subscription_id || "",
              duration_months: item.duration_months || "",
            };
          });

        formattedArr && setSubscriptionPlans(formattedArr);
        setSubscriptionPayload((prev) => {
          return {
            ...prev,
            subscription_id: 15,
            duration_months:
              res.data && res.data.length > 0
                ? res.data.find((it) => it.subscription_id == 15)
                    .duration_months
                : "",
          };
        });
      }
    } catch (error) {
      console.error("Catch error");
    }
  };

  useEffect(() => {
    getBasicInfo();
    getGeneralInfo();
    getClinicInfo();
    getClinicInfo();
    getBankDetails();
    doctorProfile();
    getDocSignatur();
    getDocumentsInfo();
    getAllSubscriptions()
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  console.log("schedule ====>",schedule)

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="viewDoctor-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                {profile ? (
                  <>
                    <img
                      src={
                        basicInfo?.doctor_profile_image_path
                          ? basicInfo?.doctor_profile_image_path
                          : dummydoctor
                      }
                      className="docImg"
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummydoctor;
                      }}
                    />
                    <div className="docDetTxt">
                      <h1 className="docName">
                        {doctorPro ? doctorPro[0]?.doctor_name : ""}
                      </h1>
                      <label className="docDomain">
                        {doctorPro[0]?.specializations}
                      </label>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={
                        basicInfo?.doctor_profile_image_path
                          ? basicInfo?.doctor_profile_image_path
                          : dummydoctor
                      }
                      // dummydoctor
                      className="docImg"
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummydoctor;
                      }}
                    />
                    <div className="docDetTxt">
                      <h1 className="docName">{basicInfo?.doctor_name}</h1>
                      {/* <label className="docDomain">Not found</label> */}
                    </div>
                  </>
                )}
                {/* // <img src={DoctorSahab} className="docImg" alt="" />
                // <div className="docDetTxt">
                //   <h1 className="docName">Floyd Miles</h1>
                //   <label className="docDomain">Orthopedic</label>
                // </div> */}
              </div>
            </div>
            <div className="belowDetails">
              <div className="row">
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle">
                    <h1 className="heading">Basic Information</h1>
                  </div>
                </div>
                {/* Inner Values */}
                {basic ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Name</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_name}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Age</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_age}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Gender</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_gender}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Contact Number</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_contact_number}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Email</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_email}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Registration No.</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_registration_no}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Known Languages</h1>
                        {languageArr && languageArr.length > 0
                          ? languageArr.map((data, index) => {
                              return (
                                <>
                                  <label
                                    htmlFor=""
                                    className="value"
                                    key={index}
                                  >
                                    {data?.language + ","}
                                  </label>
                                </>
                              );
                            })
                          : "No language"}
                        {/* <label htmlFor="" className="value">
                                English, Hindi, Marathi, Urdu
                              </label> */}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 dispNoneMedia"></div>
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
                <div className="col-lg-3 col-md-6 col-sm-12 dispNoneMedia"></div>
                <div className="col-lg-3 col-md-6 col-sm-12 dispNoneMedia"></div>
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">General Information</h1>
                  </div>
                </div>
                {generalInfo ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Specialization</h1>
                        <ul>
                          {servicesArr && servicesArr.length > 0
                            ? servicesArr.map((data, index) => {
                                return (
                                  <li key={index}>{data?.specialization}</li>
                                );
                              })
                            : null}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Services</h1>
                        <label htmlFor="" className="value">
                          {generalInfo?.services}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Experience</h1>
                        <label htmlFor="" className="value">
                          {generalInfo?.experience}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Education</h1>
                        <label htmlFor="" className="value">
                          {generalInfo?.education}
                        </label>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Clinic Information</h1>
                  </div>
                </div>
                {Clinic ? (
                  <>
                    {clinicInfo && clinicInfo.length > 0
                      ? clinicInfo.map((data, index) => {
                          return (
                            <>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Clinic Name</h1>
                                  <label htmlFor="" className="value">
                                    {data?.doctor_clinic_name}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Facilities</h1>
                                  <ul>
                                    {data.facilitiesString &&
                                    data.facilitiesString.length > 0
                                      ? data.facilitiesString.map(
                                          (data, index) => {
                                            return (
                                              <li key={index}>
                                                {data?.clinic_facility}
                                              </li>
                                            );
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Address</h1>
                                  <label htmlFor="" className="value">
                                    {data?.doctor_clinic_address}
                                  </label>
                                </div>
                              </div>
                              {/* <div className="col-lg-3 col-md-6 col-sm-12">
                              <div className="keyValueContent">
                                <h1 className="Key">Photos</h1>
                                <div className="imagesBunch">
                                  <img src={collageImg} alt="" />
                                  <img src={collageImg} alt="" />
                                  <img src={collageImg} alt="" />
                                  <img src={MoreImg} alt="" />
                                </div>
                              </div>
                            </div> */}
                            </>
                          );
                        })
                      : null}
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Documents</h1>
                  </div>
                </div>
                <div className="col-md-12">
                  <div id="docsTabs">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Nav variant="pills" className="flex-column">
                        {doctorDocs && doctorDocs.length > 0 ? (
                          doctorDocs.map((data, index) => {
                            return (
                              <Nav.Item>
                                <Nav.Link
                                  href={data?.clinic_certificate_path}
                                  target="_blank"
                                  eventKey={index}
                                >
                                  <div className="fileInside">
                                    <div className="imgHolder">
                                      <img src={FileIn} alt="" />
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                            );
                          })
                        ) : (
                          <div className="col-md-12 text-center p-3 text-danger">
                            No Data Found
                          </div>
                        )}
                      </Nav>
                    </Tab.Container>
                  </div>
                </div>
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Signature</h1>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="allSignsFlex">
                    <div className="signatureHolder">
                      <img
                        src={
                          signatureArr?.doctor_signature_path
                            ? signatureArr?.doctor_signature_path
                            : ""
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Bank Details</h1>
                  </div>
                </div>
                {console.log(bankDetails)}
                {bank ? (
                  <>
                    {bankDetails && bankDetails.length > 0
                      ? bankDetails.map((data, index) => {
                          return (
                            <>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Bank Name</h1>
                                  <label htmlFor="" className="value">
                                    {data.doctor_bank}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Account Number</h1>
                                  <label htmlFor="" className="value">
                                    {data.account_number}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Account Holder Name</h1>
                                  <label htmlFor="" className="value">
                                    {data.account_holder_name}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">IFSC</h1>
                                  <label htmlFor="" className="value">
                                    {data.ifsc_code}
                                  </label>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Clinic Schedule</h1>
                  </div>
                </div>
                {schedule ? (
                  <>
                    {schedule && (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="keyValueContent">
                            <h1 className="Key">Working days</h1>
                            <label
                              htmlFor=""
                              className="value"
                              style={{ display: "flex", gap: "15px" }}
                            >
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_sun
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  S
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_mon
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  M
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_tue
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  T
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_wed
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  W
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_thu
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  T
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_fri
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  F
                                </span>
                              }{" "}
                              {
                                <span
                                  className={
                                    !schedule?.is_weekend_sat
                                      ? "docAvailableStatus"
                                      : "weekendDays"
                                  }
                                >
                                  S
                                </span>
                              }
                            </label>
                          </div>
                        </div>
                        {schedule?.is_morning_shift && (
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Morning Shift</h1>
                              <label htmlFor="" className="value">
                                {moment(schedule?.slot_morning_start_time, [
                                  "HH:mm",
                                ]).format("hh:mm A")}{" "}
                                -{" "}
                                {schedule &&
                                  moment(schedule?.slot_morning_end_time, [
                                    "HH:mm",
                                  ]).format("hh:mm A")}
                              </label>
                            </div>
                          </div>
                        )}
                        {schedule?.is_evening_shift && (
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="keyValueContent">
                              <h1 className="Key">Evening Shift</h1>
                              <label htmlFor="" className="value">
                                {moment(schedule?.slot_evening_start_time, [
                                  "HH:mm",
                                ]).format("hh:mm A")}{" "}
                                -{" "}
                                {schedule &&
                                  moment(schedule?.slot_evening_end_time, [
                                    "HH:mm",
                                  ]).format("hh:mm A")}
                              </label>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="keyValueContent">
                            <h1 className="Key">Charges</h1>
                            {schedule?.is_morning_shift && (
                              <>
                                {" "}
                                <label htmlFor="" className="value">
                                  Morning - ₹
                                  {schedule?.slot_morning_doctor_charge}
                                </label>{" "}
                                <br />
                              </>
                            )}
                            {schedule?.is_evening_shift && (
                              <label htmlFor="" className="value">
                                Evening - ₹
                                {schedule?.slot_evening_doctor_charge}
                              </label>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-end">
            <button
              className="rejectBtn me-2"
              onClick={() => {
                doctorReject(basicInfo?.doctor_id);
              }}
            >
              Reject
            </button>
            <button
              className="AcceptBtn"
              onClick={() => {
                // doctorAccept(basicInfo?.doctor_id);
                setIsDoctorAccept({
                  show: true,
                  doctorId: basicInfo.doctor_id,
                });
              }}
            >
              Accept
            </button>
          </div>
        </div>
        <div className="subscription-modal">
            <Modal
              show={isDoctorAccept.show}
              onHide={() => {
                setIsDoctorAccept({
                  ...isDoctorAccept,
                  show: false,
                });
              }}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Subscription Plans</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="subscription_main_div">
                  <div className="group">
                    <label>Select Plan</label>
                    <div className="select_input">
                      <Select
                        value={
                          subscriptionPlans &&
                          subscriptionPlans.filter(
                            (item) =>
                              item.value == subscriptionPayload.subscription_id
                          )
                        }
                        options={subscriptionPlans}
                        onChange={(e) => {
                          setSubscriptionPayload((prevPayload) => {
                            const startDate = new Date(prevPayload.startDate);
                            const endDate = new Date(startDate);

                            endDate.setMonth(
                              startDate.getMonth() + e.duration_months
                            );

                            return {
                              ...prevPayload,
                              subscription_id: e.value,
                              duration_months: e.duration_months,
                              endDate,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="group">
                    <label>Start Date</label>
                    <div className="select_input">
                      <input
                        type="date"
                        className="form-control"
                        value={moment(subscriptionPayload.startDate).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value) {
                            const startDate = new Date(value);
                            const endDate = new Date(startDate);
                            endDate.setMonth(
                              startDate.getMonth() +
                                subscriptionPayload.duration_months
                            );

                            setSubscriptionPayload({
                              ...subscriptionPayload,
                              startDate,
                              endDate,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {subscriptionPayload.endDate && (
                    <div>
                      <label className="end-date">
                        End Date :{" "}
                        {subscriptionPayload.endDate &&
                          moment(subscriptionPayload.endDate).format(
                            "DD-MM-YYYY"
                          )}{" "}
                      </label>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="cancel_btn"
                  onClick={() =>
                    setIsDoctorAccept({
                      ...isDoctorAccept,
                      show: false,
                    })
                  }
                >
                  Cancel
                </button>
                <button
                  className="apply_btn"
                  onClick={() => {
                    isDoctorAccept.doctorId &&
                      doctorAccept(isDoctorAccept.doctorId);
                  }}
                >
                  Apply
                </button>
              </Modal.Footer>
            </Modal>
          </div>
      </motion.div>
    </>
  );
};

export default ViewDoctorDetails;
