import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DoctorImg from "../../assets/images/icons/DoctorsIcon.svg";
import pharma from "../../assets/images/icons/pharmacyIcon.svg";
import petShop from "../../assets/images/icons/petShpIcon.svg";
import Transit from "../../assets/images/icons/transactionIcon.svg";
import Form from "react-bootstrap/Form";
import bottomArrow from "../../assets/images/icons/bottomArrow.svg";
import roundCircle from "../../assets/images/headerIcons/headerCircle.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import { motion } from "framer-motion";
import Chart from "react-apexcharts";
import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import { formatCurrency } from "../../common/commonFun";

const Dashboard = () => {
  const { sidebar, getYearDataArr } = useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const [DashabordCategory, setDashabordCategory] = useState("");
  const [DashabordUser, setDashabordUser] = useState("");
  const [DashabordRevenuo, setDashabordRevenuo] = useState({});
  const [reansationDetails, settreansationDetails] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [revenuePayload, setRevenuePayload] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const handleChange = (e, year) => {
    getrevenueDetails(e.target.value);
  };

  const handleChangeTm = (e) => {
    setSelectedItem(e.target.value);
    getDetails(e.target.value);
  };
  const [donut, setDonut] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ["#01B529", "#FF5C00", "#FFB200", "#5500DF"],
      },
      // dataLabels: {
      //   style: {
      //     colors: ['#01B529', '#FF5C00', '#FFB200', '#5500DF']
      //   }
      // },
      markers: {
        colors: ["#01B529", "#FF5C00", "#FFB200", "#5500DF"],
      },
    },
    series: [40, 40, 71, 30],
    labels: ["A", "B", "C", "D"],
    colors: ["#01B529", "#FF5C00", "#FFB200", "#5500DF"],
  });
  const [revenueBar, setRevenueBar] = useState({
    series: [
      {
        name: "PRODUCT A",
        data: [
          44, 55, 41, 67, 22, 43, 22, 56, 23, 44, 55, 41, 67, 22, 43, 22, 56,
          23, 44, 55, 41, 67, 22, 43, 22, 56, 23,
        ],
      },
      {
        name: "PRODUCT B",
        data: [
          13, 23, 20, 8, 13, 27, 21, 60, 23, 13, 23, 20, 8, 13, 27, 21, 60, 23,
          13, 23, 20, 8, 13, 27, 21, 60, 23,
        ],
      },
      {
        name: "PRODUCT C",
        data: [
          11, 17, 15, 15, 21, 14, 15, 10, 31, 11, 17, 15, 15, 21, 14, 15, 10,
          31, 11, 17, 15, 15, 21, 14, 15, 10, 31,
        ],
      },
      {
        name: "PRODUCT D",
        data: [
          21, 7, 25, 13, 22, 8, 56, 23, 12, 21, 7, 25, 13, 22, 8, 56, 23, 12,
          21, 7, 25, 13, 22, 8, 56, 23, 12,
        ],
      },
    ],
    colors: ["#9E00FF", "#FFB200", "#34B53A", "#4339F2"],
    options: {
      legend: {
        show: false,
      },
      fill: {
        colors: ["#9E00FF", "#FFB200", "#34B53A", "#4339F2"],
      },
      markers: {
        colors: ["#9E00FF", "#FFB200", "#34B53A", "#4339F2"],
      },
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 0.5,
        colors: ["#9E00FF", "#FFB200", "#34B53A", "#4339F2"],
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "25%",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
          22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
    },
  });

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  useEffect(() => {
    if(revenuePayload?.month && revenuePayload?.year){
      getrevenueDetails();
    }
  }, [revenuePayload]);

  useEffect(() => {
    getrevenueDetails(11);
    getCategoryDetails();
    getUserDetails();
    getDetails("doctor");
  }, []);
  const getCategoryDetails = () => {
    // setdoctorAppoinmentID(id)

    // setLoading(true);

    simpleGetCallWithToken(ApiConfig.DASHBORD_CATEGORY)
      .then((res) => {
        setDashabordCategory(res.data);
        // setClintVisitlist(res);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const getrevenueDetails = () => {
    let newRequestBody = JSON.stringify(revenuePayload);
    simplePostAuthCall(ApiConfig.DASHBORD_REVENUE, newRequestBody)
      .then((res) => {
        setDashabordRevenuo(res.data);

        // Here, update the revenueBar state with the API data
        setRevenueBar((prevRevenueBar) => {
          return {
            ...prevRevenueBar,
            series: res.data, // Replace 'some_key_for_series_data' with the actual key from your API response
          };
        });
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const getUserDetails = () => {
    // setdoctorAppoinmentID(id)

    // setLoading(true);

    simpleGetCallWithToken(ApiConfig.DASHBORD_USER + 11)
      .then((res) => {
        setDashabordUser(res.newJoined);
        // setClintVisitlist(res);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const getDetails = (value) => {
    simpleGetCallWithToken(
      ApiConfig.DASHBORD_TREANSACTION + "&page_limit=" + 10 + `&user=${value} `
    )
      .then((data) => {
        settreansationDetails(data?.data);

        if (data.success) {
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="main-wrapper AameerResponsive">
          <div className="row">
            <div className="col-md-6 col-lg-3 widthResponsive">
              <div className="insideCard">
                <div className="cardinnerImg">
                  <img src={DoctorImg} alt="" />
                </div>
                <div className="detailsCard">
                  <label className="CardName">Doctor’s</label>
                  <label className="cardAMount">
                    {DashabordCategory?.doctor_count}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 widthResponsive">
              <div className="insideCard">
                <div className="cardinnerImg">
                  <img src={pharma} alt="" />
                </div>
                <div className="detailsCard">
                  <label className="CardName ">Pharma</label>
                  <label className="cardAMount">
                    {DashabordCategory?.pharmacy_shop_count}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 widthResponsive">
              <div className="insideCard">
                <div className="cardinnerImg">
                  <img src={petShop} alt="" />
                </div>
                <div className="detailsCard">
                  <label className="CardName">Pet Shops</label>
                  <label className="cardAMount">
                    {DashabordCategory?.pet_shop_count}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 widthResponsive">
              <div className="insideCard">
                <div className="cardinnerImg">
                  <img src={Transit} alt="" />
                </div>
                <div className="detailsCard">
                  <label className="CardName">Transactions</label>
                  <label className="cardAMount">
                    {DashabordCategory.orders_transactions
                      ? formatCurrency(DashabordCategory.orders_transactions)
                      : ""}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="revenueChartLeft">
                <div className="headingSetup">
                  <h1 className="chartTitle">Revenue</h1>
                  {/* getYearDataArr */}
                  <div className="selection-inputs">
                    <div className="formSelect">
                      <Form.Select
                        aria-label="Default select example"
                        value={revenuePayload?.year}
                        onChange={(e) => {
                          setRevenuePayload({
                            ...revenuePayload,
                            year: e.target.value,
                          });
                        }}
                      >
                        <option value={""}>Select Year</option>
                        {getYearDataArr &&
                          getYearDataArr.map((year, idx) => {
                            return (
                              <option key={"yearoption" + idx} value={year}>
                                {year}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>
                    <div className="formSelect">
                      <Form.Select
                        aria-label="Default select example"
                        value={revenuePayload?.month}
                        onChange={(e) => {
                          setRevenuePayload({
                            ...revenuePayload,
                            month: e.target.value,
                          });
                        }}
                      >
                        <option value={""}>Select Month</option>
                        <option value="1">January</option>
                        <option value="2">Febuary</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
                <div className="highlightsTxt">
                  <div className="apoointment">
                    <div className="blueTick"></div>
                    <label className="tickTxt">Appointment</label>
                  </div>
                  <div className="apoointment">
                    <div className="greenTick"></div>
                    <label className="tickTxt">Purchase</label>
                  </div>
                  <div className="apoointment">
                    <div className="orangeTick"></div>
                    <label className="tickTxt">Sales</label>
                  </div>
                  <div className="apoointment">
                    <div className="purpleTick"></div>
                    <label className="tickTxt">Subscription</label>
                  </div>
                </div>
                <div className="revenueBarGraph">
                  <Chart
                    options={revenueBar.options}
                    series={revenueBar.series}
                    type="bar"
                    width="100%"
                    height="230"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              {/* <div className="userRightCard"></div> */}
              <div className="revenueUserRight">
                <div className="headingSetup">
                  <h1 className="chartTitle">User's</h1>
                  <div className="formSelect">
                    <Form.Select aria-label="Default select example">
                      <option>Monthly</option>
                      <option value="1">January</option>
                      <option value="2">Febuary</option>
                      <option value="3">March</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="donutsChart">
                  <div className="leftSideDetails">
                    <div className="percentageDetails">
                      <div className="signalDetails">
                        <div className="greenSignalDot"></div>
                        <div className="signalTxt">New Joined</div>
                      </div>
                      <div className="perPart">
                        <label className="percentageTxt">
                          {DashabordUser?.totalPercentage}%
                        </label>
                      </div>
                    </div>
                    <div className="percentageDetails">
                      <div className="signalDetails">
                        <div className="orangeSignalDot"></div>
                        <div className="signalTxt">Subscriptions</div>
                      </div>
                      <div className="perPart">
                        <label className="percentageTxt">25%</label>
                      </div>
                    </div>
                    <div className="percentageDetails">
                      <div className="signalDetails">
                        <div className="darkOranSignalDot"></div>
                        <div className="signalTxt">Ongoing Subscriptions</div>
                      </div>
                      <div className="perPart">
                        <label className="percentageTxt">25%</label>
                      </div>
                    </div>
                    <div className="percentageDetails">
                      <div className="signalDetails">
                        <div className="darkPurpleSignalDot"></div>
                        <div className="signalTxt">Expired Subscriptions</div>
                      </div>
                      <div className="perPart">
                        <label className="percentageTxt">75%</label>
                      </div>
                    </div>
                  </div>
                  <div className="rightSidechart">
                    <Chart
                      options={donut.options}
                      series={donut.series}
                      type="donut"
                      width="250"
                      height="200"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="tableInner">
                <div className="headingSetup">
                  <h1 className="chartTitle">Transactions</h1>
                  <div className="formSelect">
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedItem}
                      onChange={handleChangeTm}
                    >
                      <option>User</option>
                      <option value="doctor">doctor</option>
                      <option value="pharmacy">pharmacy</option>
                      <option value="petshop">petshop</option>
                      <option value="ambulance">ambulance</option>
                      <option value="vaccinator">vaccinator</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="incedoTable">
                  <table className="defaultTable">
                    <thead>
                      <tr>
                        <th>
                          Sr No.{" "}
                          <img src={bottomArrow} className="headTxt" alt="" />{" "}
                        </th>
                        <th>
                          Customer Name{" "}
                          <img src={bottomArrow} className="headTxt" alt="" />{" "}
                        </th>
                        <th>
                          Transaction Type{" "}
                          <img src={bottomArrow} className="headTxt" alt="" />{" "}
                        </th>
                        <th>
                          Transaction For{" "}
                          <img src={bottomArrow} className="headTxt" alt="" />{" "}
                        </th>
                        <th>
                          Amount{" "}
                          <img src={bottomArrow} className="headTxt" alt="" />{" "}
                        </th>
                        <th>
                          Status{" "}
                          <img src={bottomArrow} className="headTxt" alt="" />{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {reansationDetails && reansationDetails.length > 0 ? (
                        reansationDetails.map((itemlist, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                {/* <img src={roundCircle} className='rounded-circle tableImg' alt="" /> */}
                                {itemlist?.customer_name ? (
                                  itemlist?.customer_name
                                ) : (
                                  <span className="text-danger">No Data</span>
                                )}
                              </td>
                              <td>
                                {itemlist?.transaction_type ? (
                                  itemlist?.transaction_type
                                ) : (
                                  <span className="text-danger">No Data</span>
                                )}
                              </td>
                              <td>
                                {itemlist?.transaction_for ? (
                                  itemlist?.transaction_for
                                ) : (
                                  <span className="text-danger">No Data</span>
                                )}
                              </td>
                              <td>
                                ₹
                                {itemlist?.amount ? (
                                  itemlist?.amount
                                ) : (
                                  <span className="text-danger">No Data</span>
                                )}
                              </td>
                              <td>
                                {/* <img src={approved} className='checks' alt="" /> */}
                                {itemlist?.status === null ||
                                itemlist?.status === "" ? (
                                  <span className="text-danger">No Data</span>
                                ) : (
                                  itemlist?.status
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Dashboard;
