import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import { motion } from "framer-motion";
import DBimg from "../../assets/images/icons/DeliveryBoy.svg";
import { Link } from 'react-router-dom';
import { putMultipartWithAuthCall, simpleGetCall, simpleGetCallWithToken } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import Loader from "../../sharedComponent/Loader";
import { notifyError, notifySuccess } from '../../sharedComponent/Notify';
import InfiniteScroll from "react-infinite-scroll-component";


const AddDeliveryBoy = () => {
  const { sidebar, setSidebar, adminDetails, setAdminDetails } = useContext(AppContext);
  const [allDeliveryBoys, setAllDeliveryBoys] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState(1);
  const [page_limit, setPage_limit] = useState(10);
  const [last_page, setlast_page] = useState(false);
  const [TotalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [adminId, setAdminId] = useState(JSON.parse(adminDetails.admin_id));

  const getAllDelBoys = (currentPage) => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.NEW_DELIVERY_BOY_LIST + `?name=${searchTxt}&page=${page}&page_limit=${page_limit}`)
      .then((res) => {
        if (res.status === true) {
          setLoading(false);

          let total = res.total ? res.total : 0;
          setTotalData(total);
          setlast_page(res.last_page);

          let delBoyListArr = res?.data ? res?.data : [];
          let finalArr = delBoyListArr.map((innerData, index) => {
            return {
              ...innerData,
              delivery_person_name: innerData?.delivery_person_name || ""
            }
          })
          if (currentPage === 1) {
            setAllDeliveryBoys(res?.data);
            setLoading(false);
          } else {
            setAllDeliveryBoys([...allDeliveryBoys, ...finalArr]);
            setLoading(false);
          }
        } else {
          setAllDeliveryBoys([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const acceptDriver = (id) => {
    let payload = {
      "delivery_person_id": id,
      "status": true,
      "admin_id": adminId
    }
    putMultipartWithAuthCall(ApiConfig.UPDATE_STATUS_OF_DELIVERY_BOY, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message)
        } else {
          notifyError(res?.message)
        }
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const rejectDriver = (id) => {
    let payload = {
      "delivery_person_id": id,
      "status": false,
      "admin_id": adminId
    }
    putMultipartWithAuthCall(ApiConfig.UPDATE_STATUS_OF_DELIVERY_BOY, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message)
        } else {
          notifyError(res?.message)
        }
      })
      .catch((err) => {
        console.log(err);
      })
  };

  useEffect(() => {
    getAllDelBoys();
  }, [searchTxt])

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };


  const onPageLoad = () => {
    // debugger
    setPage(page + 1);
    if (last_page === false) {
      getAllDelBoys(page + 1);
    } else {
    }
  };
  return (
    <>
      <InfiniteScroll
        dataLength={allDeliveryBoys?.length}
        next={onPageLoad}
        // hasMore={true}
        hasMore={allDeliveryBoys.length !== TotalData}
        scrollableTarget="scroll-table"
        loader={
          <h4
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          ></h4>
        }>
        <motion.div
          variants={aninations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.1 }}
          className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'} id='cx-main'>
          {
            loading ? <Loader /> :
              <div className="adddoctor-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    <div className="innerSearchItem">
                      <div className="leftFilterTop">
                        <div className="topSearch">
                          <input type="text" name='getAllDelBoys' value={searchTxt} autoFocus onChange={(e) => setSearchTxt(e.target.value)} className="form-control searchDoc" placeholder='Search by Name, Specialist, Ratings, Visits...' />
                          <img src={SearchIcon} className='searchInner' alt="" />
                        </div>
                        <div className="topFilter">
                          <img src={FilterIcon} className='filterInner' alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Cards Content */}
                  {
                    allDeliveryBoys && allDeliveryBoys.length > 0 ?
                      <div className="slideBelow row p-0 m-0">
                        {
                          allDeliveryBoys.map((data, index) => {
                            return (
                              <div className="col-lg-3 AddcardsResponsive" key={"DeliveryBoys" + index}>
                                <div className="doctorsDetailsCard">
                                  <Link to={`/ViewDeilveryBoy/${data?.delivery_person_id}`} className='cardLink'>
                                    <img src={data?.delivery_person_image_path} className='docInsideImg' alt="" />
                                  </Link>
                                  <div className="docDetailsTxt mb-2">
                                    <h1>{data?.delivery_person_name}</h1>
                                  </div>
                                  <div className="innerButton">
                                    <button className='rejectBtn' onClick={() => rejectDriver(data?.delivery_person_id)}>Reject</button>
                                    <button className='AcceptBtn' onClick={() => acceptDriver(data?.delivery_person_id)}>Accept</button>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      :
                      null
                  }
                </div>
              </div>
          }
        </motion.div>
      </InfiniteScroll>
    </>
  )
}

export default AddDeliveryBoy;
