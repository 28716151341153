import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import DoctorSahab from "../../assets/images/icons/doctorSahab.svg";


const ViewVaccinators = () => {
  const { sidebar, setSidebar } = useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'} id='cx-main'>
        <div className="viewDoctor-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                <img src={DoctorSahab} className='docImg' alt="" />
                <div className="docDetTxt">
                  <h1 className='docName'>Floyd Miles</h1>
                  <label className='docDomain'>Orthopedic</label>
                </div>
              </div>
            </div>
            <div className="belowDetails">
              <div className="row">
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle">
                    <h1 className='heading'>General Information</h1>
                  </div>
                </div>
                {/* Inner Values */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Name</h1>
                    <label htmlFor="" className='value'>Floyd Miles</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Contact Number</h1>
                    <label htmlFor="" className='value'>+91 9999 9999 99</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Email</h1>
                    <label htmlFor="" className='value'>shop@gayatri.com</label>
                  </div>
                </div>
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className='heading'>Bank Details</h1>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Bank Name</h1>
                    <label htmlFor="" className='value'>State Bank of India</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Account Number</h1>
                    <label htmlFor="" className='value'>1234567891045630</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Account  Holder Name</h1>
                    <label htmlFor="" className='value'>Floyd Miles</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">IFSC / SWIFT Code</h1>
                    <label htmlFor="" className='value'>IFSC12345678 /  1234567892000</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            <button className='rejectBtn me-2'>Reject</button>
            <button className='AcceptBtn'>Accept</button>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default ViewVaccinators;
