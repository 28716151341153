import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { Link, useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import DBimg from "../../assets/images/icons/DeliveryBoy.svg";
import { simpleGetCallWithToken } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';

const ViewDeilveryBoy = () => {
  const params = useParams();
  const delId = params?.id;
  const { sidebar, setSidebar } = useContext(AppContext);
  const [deliveryObj, setDeliveryObj] = useState({});

  const getDelById = () => {
    simpleGetCallWithToken(ApiConfig.GET_DELIVERY_CREDENTIALS_BY_ID + `/${delId}`)
      .then((res) => {
        if (res.success === true) {
          setDeliveryObj(res?.data);
        } else {
          setDeliveryObj({});
        }
      })
      .catch((err) => {
        console.log(err);
      })
  };

  useEffect(() => {
    getDelById();
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'} id='cx-main'>
        <div className="viewDoctor-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                <img src={deliveryObj?.delivery_person_image_path ? deliveryObj?.delivery_person_image_path : DBimg} className='docImg' alt="" />
                <div className="docDetTxt">
                  <h1 className='docName'>{deliveryObj?.delivery_person_name ? deliveryObj?.delivery_person_name : <span className='text-danger'>No Data</span>}</h1>
                </div>
              </div>
            </div>
            <div className="belowDetails">
              <div className="row">
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle">
                    <h1 className='heading'>General Information</h1>
                  </div>
                </div>
                {/* Inner Values */}
                {/* <div className="col-lg-12">
                  <h1 className='AmbulanceHead'>Ambulance -101</h1>
                </div> */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Driver Name</h1>
                    <label htmlFor="" className='value'>{deliveryObj?.delivery_person_name ? deliveryObj?.delivery_person_name : <span className='text-danger'>No Data</span>}</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Driver Contact No.</h1>
                    <label htmlFor="" className='value'>{deliveryObj?.delivery_person_contact_no ? deliveryObj?.delivery_person_contact_no : <span className='text-danger'>No Data</span>}</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Vehicle type</h1>
                    <label htmlFor="" className='value'>{deliveryObj?.vehicle_type ? deliveryObj?.vehicle_type : <span className='text-danger'>No Data</span>}</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Vehicle No.</h1>
                    <label htmlFor="" className='value'>{deliveryObj?.vehicle_no ? deliveryObj?.vehicle_no : <span className='text-danger'>No Data</span>}</label>
                  </div>
                </div>
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className='heading'>Bank Details</h1>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Bank Name</h1>
                    <label htmlFor="" className='value'>{deliveryObj?.delivery_person_bank ? deliveryObj?.delivery_person_bank : <span className='text-danger'>No Data</span>}</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Account Number</h1>
                    <label htmlFor="" className='value'>{deliveryObj?.account_number ? deliveryObj?.account_number : <span className='text-danger'>No Data</span>}</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">Account  Holder Name</h1>
                    <label htmlFor="" className='value'>{deliveryObj?.account_holder_name ? deliveryObj?.account_holder_name : <span className='text-danger'>No Data</span>}</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="keyValueContent">
                    <h1 className="Key">IFSC / SWIFT Code</h1>
                    <label htmlFor="" className='value'>{deliveryObj?.ifsc_code ? deliveryObj?.ifsc_code : <span className='text-danger'>No Data</span>}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            <button className='rejectBtn me-2'>Reject</button>
            <button className='AcceptBtn'>Accept</button>
          </div>
        </div>
      </motion.div >
    </>
  )
}

export default ViewDeilveryBoy
