import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { motion } from "framer-motion";
import ApiConfig from "../api/ApiConfig";
import { simpleGetCall } from "../api/ApiServices";
export const getDate = (inputDate) => {
    let date = new Date(inputDate);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
  
    var formattedDate = `${day < 10 ? 0 : ""}${day}-${month < 10 ? 0 : ""
        }${month}-${year}`;

    return formattedDate;
}

let userID = localStorage.user_id ? localStorage.user_id : "";

const Notification = () => {
    const { sidebar, setSidebar, adminDetails,
        socket } = useContext(AppContext);
        console.log("socket,", socket)
    const [notificationList, setnoticationList] = useState([]);
    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };

    const getNotification = () => {
        simpleGetCall(ApiConfig.NOTIFICATION + 'adm_' + adminDetails?.admin_id)
            .then((res) => {
                if (res.success === true) {
                    setnoticationList(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const notificationsIdsArr = notificationList
        ? notificationList.map((ele) => ele.notification_id)
        : [];
    // READ_NOTIFICATION
    const readNotification = (notification_ids) => {
        socket &&
            socket.emit("notification read", { notification_id: notification_ids });
    };

    const readAllnoti = () => {
        const payLoad = { user_id: userID }
        socket && socket.emit("notification read all", payLoad)
    };

    useEffect(() => {
        getNotification()
    }, []);

    return (
        <>
            <motion.div
                variants={aninations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
                className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
                id="cx-main">

                <div className="notificationWrapper">
                    <div className="insideTable">
                        <table className="tableNoti">
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>Name</th>
                                    <th>Message</th>
                                    <th>Time / Date</th>
                                    <th style={{ cursor: "pointer" }}>
                                        <span
                                            onClick={() => {
                                                readAllnoti()
                                                // readNotification(notificationsIdsArr);
                                                setTimeout(() => {
                                                    setnoticationList([]);
                                                    // setNotificationCount(0);
                                                }, 600);
                                            }}
                                        >
                                            Mark as all Read
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    notificationList && notificationList.length > 0 ? (
                                        notificationList.map((itemlist, index) => {
                                            return (
                                                <tr key={"notificationList" + index}>
                                                    <td>{index + 1}</td>
                                                    <td>{itemlist.notification_sender_name}</td>
                                                    <td>
                                                        <div className="scrollerHeight text-truncate" style={{ maxWidth: "400px" }}>{itemlist.notification_text}.</div>
                                                    </td>
                                                    <td>{itemlist.notification_time} / {getDate(itemlist.notification_date)} </td>
                                                    <td>
                                                        <span
                                                            style={{
                                                                cursor: "pointer",
                                                                fontSize: "13px",
                                                                fontWeight: "500",
                                                            }}
                                                            onClick={() => {
                                                                readNotification([itemlist.notification_id]);
                                                                setTimeout(() => {
                                                                    const filteredData = notificationList.filter(
                                                                        (innerItem) =>
                                                                            innerItem.notification_id !==
                                                                            itemlist?.notification_id
                                                                    );
                                                                    setnoticationList(filteredData);
                                                                }, 400);
                                                            }}
                                                        >
                                                            Mark as read
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default Notification;
