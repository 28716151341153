import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import EditPen from "../../assets/images/icons/editPen.svg";
import circleX from "../../assets/images/icons/circleX.svg";
import { Link } from "react-router-dom";
import { simpleGetCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";

const ActiveSubscription = () => {

  const { sidebar, setSidebar } = useContext(AppContext);
  const [activeList, setActiveList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getActiveList = () => {
    setLoading(true);
    simpleGetCall(ApiConfig.ACTIVE_SUBSCRIPTION_LIST)
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setActiveList(res?.data);
        } else {
          setActiveList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  };

  useEffect(() => {
    getActiveList();
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main">
        <div className="mainSubsPlanTab">
          <div className="row">
            {
              loading ?
                <Loader />
                :
                <>
                  <div className="col-md-12">
                    <div className="innerSearchItem">
                      <div className="leftFilterTop">
                        <div className="topSearch">
                          <input
                            type="text"
                            name="searchTxt"
                            autoFocus
                            // value={searchTxt}
                            // onChange={(e) => setSearchTxt(e.target.value)}
                            className="form-control searchDoc"
                            placeholder="Search"
                          />
                          <img src={SearchIcon} className="searchInner" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="table-wrapper fixedHeight">
                      <table className="defaultTable">
                        <thead>
                          <tr>
                            <th>Sr No. </th>
                            <th>Subscription Id</th>
                            <th>Name</th>
                            <th>Email Id</th>
                            <th>Plan Details</th>
                            <th>Renew Subscription</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            activeList && activeList.length > 0 ?
                              activeList.map((data, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{data?.subscription_history_id}</td>
                                      <td>{data?.user_details?.user_name}</td>
                                      <td>{data?.user_details?.user_email}</td>
                                      <td>{data?.subscription_name}</td>
                                      <td>
                                        <button className="renewBtn">Renew</button>
                                      </td>
                                      {/* <td><img src={EditPen} className="me-2" alt="" />
                                  <img src={circleX} alt="" /></td> */}
                                    </tr>
                                  </>
                                )
                              })
                              :
                              <>
                                <tr>
                                  <td colSpan="7">
                                    <p className="text-center text-danger">No Data Found</p>
                                  </td>
                                </tr>
                              </>
                          }
                        </tbody>
                      </table>
                      {/* <div className="pageData">
                              <label>Showing Page {page} - {totalData}</label>
                            </div> */}
                    </div>
                  </div>
                </>
            }
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default ActiveSubscription;
