import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import DoctorSahab from "../../assets/images/icons/doctorSahab.svg";
import ThreeDots from "../../assets/images/icons/ThreeDots.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import collageImg from "../../assets/images/icons/bunchImg.svg";
import MoreImg from "../../assets/images/icons/moreImg+.svg";
import Doctor1 from "../../assets/images/icons/Doc1.svg";
import FilterIcon from "../../assets/images/icons/Filter.svg";
import Patient from "../../assets/images/icons/patientImg.svg";
import approved from "../../assets/images/icons/successCheck.svg";
import pending from "../../assets/images/icons/pendingCheck.svg";
import cancel from "../../assets/images/icons/cancelCheck.svg";
import DogImg from "../../assets/images/icons/dogImg.svg";
import catImg from "../../assets/images/icons/catImg.svg";
import Chart from "react-apexcharts";
import Star from "../../assets/images/icons/ratingStar.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Print from "../../assets/images/icons/PrintImg.svg";
import Modal from "react-bootstrap/Modal";
import DocSYmbol from "../../assets/images/icons/DoctorSymbol.svg";
import MedicalKit from "../../assets/images/icons/medicals.svg";
import CallMaroon from "../../assets/images/icons/callMaroon.svg";
import EmailMaroon from "../../assets/images/icons/mailMaroon.svg";
import LocatMaroon from "../../assets/images/icons/locatioMaroon.svg";
import Signature from "../../assets/images/icons/Signature.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCall,
  simpleGetCallWithToken,
  simplePutAuthCall,
} from "../../api/ApiServices";
import moment from "moment";
import { formatCurrency, formatTotalReviews } from "../../common/commonFun";
import { notifySuccess } from "../../sharedComponent/Notify";
import NoDataMsg from "../../sharedComponent/NoDataMsg";

const VaccinatorDetails = () => {
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const vaccinator_id = sessionStorage.vaccinator_id
    ? sessionStorage.vaccinator_id
    : "";
  const { sidebar, navigate } = useContext(AppContext);
  const [stateChaneg, setStateChaneg] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 900, 100, 700, 300, 100, 500, 100, 400, 200],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#4675F1"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#34B53A"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [lossState, setLossState] = useState({
    series: [
      {
        name: "Amount",
        data: [0, 900, 100, 700, 300, 100, 500, 100, 400, 200],
      },
    ],
    chart: {
      height: 350,
      type: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    options: {
      chart: {
        height: 440,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#D0182E"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#4675F1",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 90, 10, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#D0182E"],
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {},

      grid: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "$" +
            "</div>"
          );
        },
      },
    },
  });
  const [clickedOrder, setClickedOrder] = useState(false);
  const [clickedTransaction, setClickedTransaction] = useState(false);

  const [orders, setOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [payment, setPayment] = useState([]);
  const [ratingAndReviews, setRatingAndReviews] = useState([]);
  const [vaccinatorProfile, setVaccinatorProfile] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState({});

  // ================================ Transaction Invioce Calculation =============================== //
  const subTotal =
    prescriptionData &&
    prescriptionData?.vaccines?.reduce((acc, item) => acc + item.price, 0);
  const TotalAmt = subTotal;
  // ================================ Transaction Invioce Calculation =============================== //

  useEffect(() => {
    fectchTopDoctorsInCities();
  }, []);
  const fectchTopDoctorsInCities = async () => {
    const orders = simpleGetCallWithToken(
      `${ApiConfig.VACCINATOR_ORDERS}?vaccinatorId=${vaccinator_id}`
    );
    const transactions = simpleGetCallWithToken(
      `${ApiConfig.VACCINATOR_TRANSACTION}?vaccinatorId=${vaccinator_id}`
    );
    const payments = simpleGetCallWithToken(
      `${ApiConfig.VACCINATOR_PAYMENT}?vaccinatorId=${vaccinator_id}`
    );
    const ratingsAndReviews = simpleGetCallWithToken(
      `${ApiConfig.VACCINATOR_RATINGS_AND_RAVIEWS}?vaccinatorId=${vaccinator_id}`
    );
    const vaccinationsheets = simpleGetCallWithToken(
      `${ApiConfig.VACCINATOR_VACCINATION_SHEET}?vaccApptmtId=${vaccinator_id}`
    );
    const vaccinatorProfile = simpleGetCallWithToken(
      `${ApiConfig.VACCINATOR_VACCINATOR_PROFILE}?vaccinatorId=${vaccinator_id}`
    );

    try {
      const responses = await Promise.all([
        orders,
        transactions,
        payments,
        ratingsAndReviews,
        vaccinatorProfile,
      ]);
      const [
        ordersRes,
        transactionsRes,
        paymentsRes,
        ratingsAndReviewsRes,
        vaccinatorProfileRes,
      ] = await Promise.all(
        responses.map((response) => {
          return response;
        })
      );
      if (ordersRes.success) {
        ordersRes.data && setOrders(ordersRes.data);
      }
      if (transactionsRes.success) {
        transactionsRes.data && setTransactions(transactionsRes.data);
      }
      if (paymentsRes.success) {
        paymentsRes.data && setPayment(paymentsRes.data);
      }
      if (ratingsAndReviewsRes.success) {
        ratingsAndReviewsRes.data &&
          setRatingAndReviews(ratingsAndReviewsRes.data[0]);
      }
      if (vaccinatorProfileRes.success) {
        vaccinatorProfileRes.data &&
          setVaccinatorProfile(vaccinatorProfileRes.data[0]);
      }
    } catch (error) {
      console.log("Catch error:,", error);
    }
  };

  const acceptAndReject = async (vaccinator_id, isEnable) => {
    try {
      const payLoad = {
        vaccinatorId: vaccinator_id,
        disable: isEnable,
      };
      const res = await simplePutAuthCall(
        ApiConfig.VACCINATOR_ENABLE_DISABLE,
        JSON.stringify(payLoad)
      );
      const { success } = res;
      if (success) {
        notifySuccess(
          isEnable
            ? "Vaccinator enable successfully"
            : "Vaccinator disable successfully"
        );
        navigate("/Vaccinators");
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  //   REMOVE_VACCINATOR
  const removeVaccinator = async (vaccinator_id, isRemove) => {
    try {
      const payLoad = {
        vaccinatorId: vaccinator_id,
        remove: isRemove,
      };
      const res = await simplePutAuthCall(
        ApiConfig.REMOVE_VACCINATOR,
        JSON.stringify(payLoad)
      );
      const { success } = res;
      if (success) {
        notifySuccess("Vaccinator remove successfully");
        navigate("/Vaccinators");
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  const fetchOrderPrescriptionData = async (
    vaccinator_appointment_id,
    ordersPrescription
  ) => {
    try {
      const res = await simpleGetCallWithToken(
        `${ApiConfig.FETCH_PRESCRIPTION_DATA}?vaccApptmtId=${vaccinator_appointment_id}`
      );
      const { success, data } = res;
      if (success && data) {
        setPrescriptionData(data[0]);
        if (ordersPrescription) {
          setClickedOrder(true);
        } else {
          setClickedTransaction(true);
        }
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="doctorDetails-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                <img
                  src={
                    vaccinatorProfile?.vaccinator_profile_image_path
                      ? vaccinatorProfile?.vaccinator_profile_image_path
                      : DoctorSahab
                  }
                  className="docImg"
                  alt=""
                />
                <div className="docDetTxt">
                  <h1 className="docName">
                    {vaccinatorProfile?.vaccinator_name}
                  </h1>
                  {/* <label className="docDomain">Vaccinator</label> */}
                </div>
              </div>
              <div className="dropDown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={ThreeDots} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        if (vaccinatorProfile?.vaccinator_id) {
                          acceptAndReject(
                            vaccinatorProfile?.vaccinator_id,
                            !vaccinatorProfile?.is_disabled
                          );
                        }
                      }}
                    >
                      <Link to="#">
                        {vaccinatorProfile?.is_disabled ? "Disable" : "Enable"}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        if (vaccinatorProfile?.vaccinator_id) {
                          removeVaccinator(
                            vaccinatorProfile?.vaccinator_id,
                            true
                          );
                        }
                      }}
                    >
                      <Link to="#">Remove</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="doctorTabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Profile">
                <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                  <Nav.Item>
                    <Nav.Link eventKey="Profile">Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Orders">Orders</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Transaction">Transaction</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="PaymentDetails">
                      Payment Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="RatingReviews">
                      Rating & Reviews
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Profile">
                    <div className="row">
                      {/* Heading Text */}
                      <div className="col-md-12">
                        <div className="headTittle">
                          <h1 className="heading">General Information</h1>
                        </div>
                      </div>
                      {/* Inner Values */}
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Name</h1>
                          <label htmlFor="" className="value">
                            {vaccinatorProfile?.vaccinator_name}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Contact Number</h1>
                          <label htmlFor="" className="value">
                            {vaccinatorProfile?.vaccinator_contact_number}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="keyValueContent">
                          <h1 className="Key">Email</h1>
                          <label htmlFor="" className="value">
                            {vaccinatorProfile?.vaccinator_email}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Orders">
                    <div className="mainAppointments">
                      <div className="allApointmentsRow">
                        <h1 className="headTxt">All Order’s</h1>
                        {/* <img src={FilterIcon} className="FilImg" alt="" /> */}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="incedoTable">
                            {orders && orders.length > 0 ? (
                              <table className="defaultTable">
                                <thead>
                                  <tr>
                                    <th>Sr No. </th>
                                    <th>Date</th>
                                    {/* <th>Time</th> */}
                                    <th>Patient</th>
                                    <th>Vaccine Prescription</th>
                                    <th>Pet Type</th>
                                    <th>Vaccinator</th>
                                    <th>Fees/Subscription</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody className="tableBody">
                                  {orders &&
                                    orders.map((item, idx) => {
                                      return (
                                        <tr key={"orders" + idx}>
                                          <td>{idx + 1}</td>
                                          <td>
                                            {moment(item.date).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </td>
                                          {/* <td>
                                                {moment(item.date).format("HH:MM A")}
                                              </td> */}
                                          <td>
                                            <img
                                              src={item?.animal_photographs[0]}
                                              className="rounded-circle tableImg"
                                              alt=""
                                            />
                                            {item?.animal_name}
                                          </td>
                                          <td>
                                            <img
                                              src={Print}
                                              onClick={() =>
                                                fetchOrderPrescriptionData(
                                                  item?.vaccinator_appointment_id,
                                                  true
                                                )
                                              }
                                              className="clickableImg"
                                              alt=""
                                            />
                                          </td>
                                          <td>{item?.type_of_animal}</td>
                                          <td>{item?.vaccinator_name}</td>
                                          <td>
                                            {formatCurrency(item?.charges)}
                                          </td>
                                          <td>
                                            {" "}
                                            <img
                                              src={
                                                item?.status?.includes(
                                                  "Complete"
                                                )
                                                  ? approved
                                                  : item?.status?.includes(
                                                      "Rejected"
                                                    )
                                                  ? cancel
                                                  : pending
                                              }
                                              className="checks"
                                              alt=""
                                            />
                                            {item?.status?.includes("Complete")
                                              ? "Completed"
                                              : item?.status?.includes(
                                                  "Rejected"
                                                )
                                              ? "Rejected"
                                              : "Pending"}
                                          </td>
                                        </tr>
                                      );
                                    })}

                                  {/* <tr>
                                        <td>2</td>
                                        <td>12-06-2023</td>
                                        <td>04:58:58s</td>
                                        <td>
                                          <img
                                            src={Patient}
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          Drogo
                                        </td>
                                        <td>
                                          <img
                                            src={Print}
                                            onClick={() => setClickedOrder(true)}
                                            className="clickableImg"
                                            alt=""
                                          />
                                        </td>
                                        <td>Dog</td>
                                        <td>Sam Billings</td>
                                        <td>₹15(Monthly Sub.)</td>
                                        <td>
                                          {" "}
                                          <img
                                            src={cancel}
                                            className="checks"
                                            alt=""
                                          />
                                          Rejected
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>3</td>
                                        <td>12-06-2023</td>
                                        <td>04:58:58s</td>
                                        <td>
                                          <img
                                            src={Patient}
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          Drogo
                                        </td>
                                        <td>
                                          <img
                                            src={Print}
                                            onClick={() => setClickedOrder(true)}
                                            className="clickableImg"
                                            alt=""
                                          />
                                        </td>
                                        <td>Dog</td>
                                        <td>Sam Billings</td>
                                        <td>₹15(Monthly Sub.)</td>
                                        <td>
                                          {" "}
                                          <img
                                            src={approved}
                                            className="checks"
                                            alt=""
                                          />
                                          Checked
                                        </td>
                                      </tr> */}
                                </tbody>
                              </table>
                            ) : (
                              <NoDataMsg MSG={"No orders founds"} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Transaction">
                    <div className="mainAppointments">
                      <div className="allApointmentsRow">
                        <h1 className="headTxt">All Transaction's</h1>
                        {/* <img src={FilterIcon} className="FilImg" alt="" /> */}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="incedoTable">
                            {transactions && transactions.length > 0 ? (
                              <table className="defaultTable">
                                <thead>
                                  <tr>
                                    <th>Sr No. </th>
                                    <th>Appointment Date</th>
                                    {/* <th>Time</th> */}
                                    <th>Patient</th>
                                    <th>Vaccine Invoice</th>
                                    <th>Pet Type</th>
                                    <th>Vaccinator</th>
                                    <th>Fees/Subscription</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody className="tableBody">
                                  {transactions &&
                                    transactions.map((item, idx) => {
                                      return (
                                        <tr key={"transactions" + idx}>
                                          <td>{idx + 1}</td>
                                          <td>
                                            {moment(item?.date).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </td>
                                          {/* <td>04:58:58s</td> */}
                                          <td>
                                            <img
                                              src={Patient}
                                              className="rounded-circle tableImg"
                                              alt=""
                                            />
                                            {item?.animal_name}
                                          </td>
                                          <td>
                                            <img
                                              src={Print}
                                              onClick={() =>
                                                fetchOrderPrescriptionData(
                                                  item?.vaccinator_appointment_id,
                                                  false
                                                )
                                              }
                                              className="clickableImg"
                                              alt=""
                                            />
                                          </td>
                                          <td>{item?.type_of_animal}</td>
                                          <td>{item?.vaccinator_name}</td>
                                          <td>
                                            {formatCurrency(item?.charges)}
                                          </td>
                                          <td>
                                            {" "}
                                            <img
                                              src={
                                                item?.status?.includes(
                                                  "successful"
                                                )
                                                  ? approved
                                                  : item?.status?.includes(
                                                      "Rejected"
                                                    )
                                                  ? cancel
                                                  : pending
                                              }
                                              className="checks"
                                              alt=""
                                            />
                                            {item?.status?.includes(
                                              "successful"
                                            )
                                              ? "Completed"
                                              : item?.status?.includes(
                                                  "Rejected"
                                                )
                                              ? "Rejected"
                                              : "Pending"}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  {/* <tr>
                                        <td>2</td>
                                        <td>12-06-2023</td>
                                        <td>04:58:58s</td>
                                        <td>
                                          <img
                                            src={Patient}
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          Drogo
                                        </td>
                                        <td>
                                          <img
                                            src={Print}
                                            onClick={() =>
                                              setClickedTransaction(true)
                                            }
                                            className="clickableImg"
                                            alt=""
                                          />
                                        </td>
                                        <td>Dog</td>
                                        <td>Sam Billings</td>
                                        <td>₹15(Monthly Sub.)</td>
                                        <td>
                                          {" "}
                                          <img
                                            src={cancel}
                                            className="checks"
                                            alt=""
                                          />
                                          Rejected
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>3</td>
                                        <td>12-06-2023</td>
                                        <td>04:58:58s</td>
                                        <td>
                                          <img
                                            src={Patient}
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          Drogo
                                        </td>
                                        <td>
                                          <img
                                            src={Print}
                                            onClick={() =>
                                              setClickedTransaction(true)
                                            }
                                            className="clickableImg"
                                            alt=""
                                          />
                                        </td>
                                        <td>Dog</td>
                                        <td>Sam Billings</td>
                                        <td>₹15(Monthly Sub.)</td>
                                        <td>
                                          {" "}
                                          <img
                                            src={approved}
                                            className="checks"
                                            alt=""
                                          />
                                          Checked
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>3</td>
                                        <td>12-06-2023</td>
                                        <td>04:58:58s</td>
                                        <td>
                                          <img
                                            src={Patient}
                                            className="rounded-circle tableImg"
                                            alt=""
                                          />
                                          Drogo
                                        </td>
                                        <td>
                                          <img
                                            src={Print}
                                            onClick={() =>
                                              setClickedTransaction(true)
                                            }
                                            className="clickableImg"
                                            alt=""
                                          />
                                        </td>
                                        <td>Dog</td>
                                        <td>Sam Billings</td>
                                        <td>₹15(Monthly Sub.)</td>
                                        <td>
                                          {" "}
                                          <img
                                            src={approved}
                                            className="checks"
                                            alt=""
                                          />
                                          Checked
                                        </td>
                                      </tr> */}
                                </tbody>
                              </table>
                            ) : (
                              <NoDataMsg MSG={"No transactions founds"} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="PaymentDetails">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="bankDetailsCard shadow-sm">
                          <div className="heading">
                            <label className="headTxt">Bank Details</label>
                          </div>
                          {vaccinatorProfile?.bank_details &&
                            vaccinatorProfile?.bank_details.map((item, idx) => {
                              return (
                                <div
                                  key={"vaccinatorProfile" + idx}
                                  // style={{
                                  //     borderBottom : "1px solid #c1b8b8",
                                  //     marginBottom : "20px"
                                  // }}
                                >
                                  <div className="heading">
                                    <label className="headTxt">
                                      Account {idx + 1}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Account Holder Name
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {item?.account_holder_name}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Account Number
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {item?.account_number}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      Bank Name
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {item?.vaccinator_bank}
                                    </label>
                                  </div>
                                  <div className="detailsInsider">
                                    <label htmlFor="" className="key">
                                      IFSC/ SWIFT Code
                                    </label>
                                    <label htmlFor="" className="Value">
                                      {item?.ifsc_code}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-12">
                        <div className="bankDetailsCard shadow-sm">
                          <div className="heading">
                            <label className="headTxt">Revenue Summary</label>
                          </div>
                          <div className="graphs">
                            <div className="profitGraph">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  {formatCurrency(
                                    payment[0]?.currentMonthRevenue
                                  )}
                                </label>
                                 <label className="plusPercent">+5%</label> 
                              </div>
                              <div className="graph">
                                <Chart
                                  options={stateChaneg.options}
                                  series={stateChaneg.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  Total Revenue
                                </label>
                              </div>
                            </div>
                            <div className="lossGraph">
                              <div className="numberDet">
                                <label className="dollarAmnt">
                                  {formatCurrency(payment[1]?.totRevenue)}
                                </label>
                              <label className="lossPercent">+5%</label> 
                              </div>
                              <div className="graph">
                                <Chart
                                  options={lossState.options}
                                  series={lossState.series}
                                  type="area"
                                  width="100%"
                                  height="80"
                                />
                              </div>
                              <div className="text-center">
                                <label htmlFor="" className="InsideTxt">
                                  This Month
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="RatingReviews">
                    <div className="ratingBox">
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="totalReviewCard">
                            <h1 className="headTxt">Total Reviews</h1>
                            <p className="amntTxt">
                              {formatTotalReviews(
                                ratingAndReviews?.totalReviews
                              )}
                            </p>
                            <label className="detailTxt">
                              Growth in the review from last month
                            </label>
                          </div>
                        </div>
                        {ratingAndReviews?.averageRatings && (
                          <div className="col-lg-4 col-md-12">
                            <div className="totalReviewCard">
                              <h1 className="headTxt">Average Ratings</h1>
                              <div className="innerStars">
                                <p className="amntTxt">
                                  {ratingAndReviews?.averageRatings.toFixed(1)}
                                </p>
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                              </div>
                              <label className="detailTxt">
                                Average rating from month
                              </label>
                            </div>
                          </div>
                        )}

                        {/* <div className="col-lg-4 col-md-12">
                          <div className="ratingStars">
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">5</label>
                              <ProgressBar now={90} className="greenBar" />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">4</label>
                              <ProgressBar
                                now={70}
                                className="bottlegreenBar"
                              />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">3</label>
                              <ProgressBar now={50} className="liteOrangeBar" />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">2</label>
                              <ProgressBar now={30} className="darkOrangeBar" />
                            </div>
                            <div className="starLevels">
                              <img src={Star} className="starImg" alt="" />
                              <label className="starAmnt">1</label>
                              <ProgressBar now={10} className="redBar" />
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="col-md-12">
                          <div className="belowLine"></div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="petDetailsCard">
                            <img src={DogImg} alt="" />
                            <div className="cardDetails">
                              <label className="headingTx">James Bond</label>
                              <label className="reviewTxt">
                                Total Reviews: <span>10</span>{" "}
                              </label>
                              <label className="spendsTxt">
                                Total Spends: <span>₹1000</span>{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div className="regularCheqCard">
                            <div className="ratingDetailsHead">
                              <div className="nameAndDate">
                                <label className="nameLab">
                                  Regular Checkup
                                </label>
                                <label className="dateLab">24-02-2023</label>
                              </div>
                              <div className="starRate">
                                <img src={Star} className="starIcon" alt="" />
                                <label className="rateNum">4.5</label>
                              </div>
                            </div>
                            <div className="paraFlow">
                              <p className="insideParaDet">
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit . Minima dicta officiis,
                                possimus cumque magni odit, obcaecati, numquam
                                quaerat in eligendi quae provident ratione?
                                Aperiam nam ratione inventore voluptas dolore
                                distinctio deleniti accusamus voluptatibus qui
                                officia !
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="petDetailsCard">
                            <img src={catImg} alt="" />
                            <div className="cardDetails">
                              <label className="headingTx">James Bond</label>
                              <label className="reviewTxt">
                                Total Reviews: <span>10</span>{" "}
                              </label>
                              <label className="spendsTxt">
                                Total Spends: <span>₹1000</span>{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div className="regularCheqCard">
                            <div className="ratingDetailsHead">
                              <div className="nameAndDate">
                                <label className="nameLab">
                                  Regular Checkup
                                </label>
                                <label className="dateLab">24-02-2023</label>
                              </div>
                              <div className="starRate">
                                <img src={Star} className="starIcon" alt="" />
                                <label className="rateNum">4.5</label>
                              </div>
                            </div>
                            <div className="paraFlow">
                              <p className="insideParaDet">
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit . Minima dicta officiis,
                                possimus cumque magni odit, obcaecati, numquam
                                quaerat in eligendi quae provident ratione?
                                Aperiam nam ratione inventore voluptas dolore
                                distinctio deleniti accusamus voluptatibus qui
                                officia !
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Modal For Order */}
      <Modal
        show={clickedOrder}
        onHide={() => setClickedOrder(false)}
        size="lg"
      >
        <Modal.Body>
          <div className="vaccinatorOrderModalInvoice">
            <div className="modalHeading">
              <div className="upperBlue">
                <h1 className="docName">
                  {prescriptionData?.other_details
                    ? prescriptionData?.other_details[0]?.vaccinator_name
                    : ""}
                </h1>
                {/* <label className="domainLab">Orthopedic </label> */}
                <label className="certificateLab">Prescription</label>
              </div>
              <div className="docSymbolBox">
                <img src={DocSYmbol} className="docSymImg" alt="" />
              </div>
            </div>
            <div className="modalBody">
              <div className="detailsFill">
                <div className="labelTxt">
                  <label style={{ width: "26%" }} className="widthFixed">
                    Customer Name:
                  </label>
                </div>
                <input
                  disabled
                  style={{ backgroundColor: "#fff" }}
                  type="text"
                  value={
                    prescriptionData?.other_details
                      ? prescriptionData?.other_details[0]?.customer_name
                      : ""
                  }
                  className="form-control formSolution"
                />
              </div>
              <div className="detailsFill">
                <div className="labelTxt">
                  <label className="widthFixed">Patient Name:</label>
                </div>
                <input
                  type="text"
                  disabled
                  style={{ backgroundColor: "#fff" }}
                  value={
                    prescriptionData?.other_details
                      ? prescriptionData?.other_details[0]?.animal_name
                      : ""
                  }
                  className="form-control formSolution"
                />
              </div>
              <div className="addressFlex">
                <div className="labelTxt">
                  <label className="keyQuestion">Address:</label>
                </div>
                <input
                  disabled
                  style={{ backgroundColor: "#fff" }}
                  value={
                    prescriptionData?.other_details
                      ? prescriptionData?.other_details[0]?.customer_address
                      : ""
                  }
                  type="text"
                  className="form-control formSolution"
                />
              </div>
              <div className="dateNdAgeFlex">
                <div className="ageBox">
                  <label className="ageLab">Age:</label>
                  <input
                    disabled
                    style={{ backgroundColor: "#fff" }}
                    value={
                      prescriptionData?.other_details
                        ? prescriptionData?.other_details[0]?.animal_age
                        : ""
                    }
                    type="text"
                    className="form-control formSolution"
                  />
                </div>
                <div className="dateBox">
                  <label className="ageLab">Date:</label>
                  <input
                    type="text"
                    className="form-control formSolution"
                    disabled
                    style={{ backgroundColor: "#fff" }}
                    value={
                      prescriptionData?.other_details
                        ? moment(
                            prescriptionData?.other_details[0]?.date
                          ).format("DD-MM-YYYY")
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="addressFlex">
                <div className="labelTxt">
                  <label className="keyQuestion">Symptoms:</label>
                </div>
                <input
                  type="text"
                  className="form-control formSolution"
                  disabled
                  style={{ backgroundColor: "#fff" }}
                  value={
                    prescriptionData?.other_details
                      ? prescriptionData?.other_details[0]?.disease
                      : ""
                  }
                />
              </div>
              <div className="tableAndLandingBg">
                <div className="backGroundPic"></div>
                <div className="innerTable">
                  <table className="modalTable">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Vaccine </th>
                        <th>Dose</th>
                        <th>Duration</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescriptionData?.vaccines &&
                        prescriptionData?.vaccines.map((item, idx) => {
                          return (
                            <tr key={"prescriptionData" + idx}>
                              <td>{idx + 1}</td>
                              <td>{item?.vaccine}</td>
                              <td>{item?.dose}</td>
                              <td>{item?.duration}</td>
                              <td>{formatCurrency(item?.price)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="signatureContent">
                {prescriptionData?.other_details && (
                  <div className="sigBox">
                    <label htmlFor="sigNatureFor" className="signaturePad">
                      <img
                        style={{ width: "100px", height: "80px" }}
                        src={
                          prescriptionData?.other_details
                            ? prescriptionData?.other_details[0]
                                ?.vaccinator_signature_path
                            : ""
                        }
                        alt=""
                      />
                      <input type="file" id="sigNatureFor" className="d-none" />
                    </label>
                    <label className="belowTxt">SIGNATURE</label>
                  </div>
                )}
              </div>
            </div>
            <div className="belowDetails">
              <div className="imgWrapper">
                <div className="row heightAuto">
                  <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                    <div className="txt">
                      <div className="innerTxt">Contact</div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-12 p-0">
                    <div className="allCOntact">
                      <div className="emailAndCall">
                        <div className="callNnumber">
                          <img src={CallMaroon} alt="" />
                          <label className="numberInn">
                            +91 9509932234
                            {/* {prescriptionData?.other_details
                              ? prescriptionData?.other_details[0]
                                  ?.vaccinator_contact_number
                              : ""} */}
                          </label>
                        </div>
                        <div className="emialInn">
                          <img src={EmailMaroon} alt="" />
                          <label className="numberInn">
                            info@animalsapp.in
                            {/* {prescriptionData?.other_details
                              ? prescriptionData?.other_details[0]
                                  ?.vaccinator_email
                              : ""} */}
                          </label>
                        </div>
                      </div>
                      <div className="locationInn">
                        <img src={LocatMaroon} alt="" />
                        <label className="locationTxt">
                          Near Ravi steel, kamde, ratu Road, Ranchi, Jharkhand,
                          India 835222
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal For Transaction */}
      <Modal
        show={clickedTransaction}
        onHide={() => setClickedTransaction(false)}
        size="lg"
      >
        <Modal.Body>
          <div className="TransactionPetModalInvoice">
            <div className="modalHeading">
              <div className="upperBlue">
                <h1 className="docName">
                  {prescriptionData?.other_details
                    ? prescriptionData?.other_details[0]?.vaccinator_name
                    : ""}
                </h1>
                {/* <label className="domainLab">Pharmacy </label> */}
                <label className="certificateLab">Invoice Details</label>
              </div>
              <div className="docSymbolBox">
                <img src={MedicalKit} className="docSymImg" alt="" />
              </div>
            </div>
            <div className="modalBody">
              <div className="detailsFill">
                <div className="labelTxt">
                  <label style={{ width: "26%" }} className="widthFixed">
                    Customer Name:
                  </label>
                </div>
                <input
                  disabled
                  style={{ backgroundColor: "#fff" }}
                  type="text"
                  value={
                    prescriptionData?.other_details
                      ? prescriptionData?.other_details[0]?.customer_name
                      : ""
                  }
                  className="form-control formSolution"
                />
              </div>
              <div className="detailsFill">
                <div className="labelTxt">
                  <label className="widthFixed">Patient Name:</label>
                </div>
                <input
                  type="text"
                  className="form-control formSolution"
                  disabled
                  style={{ backgroundColor: "#fff" }}
                  value={
                    prescriptionData?.other_details
                      ? prescriptionData?.other_details[0]?.animal_name
                      : ""
                  }
                />
              </div>
              <div className="addressFlex">
                <div className="labelTxt">
                  <label className="keyQuestion">Address:</label>
                </div>
                <input
                  type="text"
                  className="form-control formSolution"
                  disabled
                  style={{ backgroundColor: "#fff" }}
                  value={
                    prescriptionData?.other_details
                      ? prescriptionData?.other_details[0]?.customer_address
                      : ""
                  }
                />
              </div>
              <div className="dateNdAgeFlex">
                <div className="ageBox">
                  <label className="ageLab">Age:</label>
                  <input
                    type="text"
                    className="form-control formSolution"
                    disabled
                    style={{ backgroundColor: "#fff" }}
                    value={
                      prescriptionData?.other_details
                        ? prescriptionData?.other_details[0]?.animal_age
                        : ""
                    }
                  />
                </div>
                <div className="dateBox">
                  <label className="ageLab">Date:</label>
                  <input
                    type="text"
                    className="form-control formSolution"
                    disabled
                    style={{ backgroundColor: "#fff" }}
                    value={
                      prescriptionData?.other_details
                        ? moment(
                            prescriptionData?.other_details[0]?.date
                          ).format("DD-MM-YYYY")
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="addressFlex">
                <div className="labelTxt">
                  <label className="keyQuestion">Symptoms:</label>
                </div>
                <input
                  type="text"
                  className="form-control formSolution"
                  disabled
                  style={{ backgroundColor: "#fff" }}
                  value={
                    prescriptionData?.other_details
                      ? prescriptionData?.other_details[0]?.disease
                      : ""
                  }
                />
              </div>
              <div className="tableAndLandingBg">
                <div className="backGroundPic"></div>
                <div className="innerTable">
                  <table className="modalTable">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Vaccine </th>
                        <th>Dose</th>
                        <th>Duration</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescriptionData?.vaccines &&
                        prescriptionData?.vaccines.map((item, idx) => {
                          return (
                            <tr key={"prescriptionData" + idx}>
                              <td>{idx + 1}</td>
                              <td>{item?.vaccine}</td>
                              <td>{item?.dose}</td>
                              <td>{item?.duration}</td>
                              <td>{formatCurrency(item?.price)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="belowTableDetails">
                  <div className="paymentTxt">
                    <label className="paymntTxtHead">Payment Method:</label>
                    <label className="paymntOption">
                      {prescriptionData?.vaccines &&
                        prescriptionData?.vaccines[0]?.mode_of_payment}
                    </label>
                  </div>
                  <div className="rightTable">
                    <div className="tableInside">
                      <div className="detailFlexy">
                        <label className="insideLabTxt">Sub Total</label>
                        <label className="insideLab">
                          {formatCurrency(subTotal)}
                        </label>
                      </div>
                      <div className="detailFlexy">
                        <label className="insideLabTxt">Tax</label>
                        <label className="insideLab">-</label>
                      </div>
                      <div className="totalSec">
                        <div className="detailFlexy">
                          <label className="insideLabTxt">Total</label>
                          <label className="insideLab">
                            {formatCurrency(TotalAmt)}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="signatureContent">
                {prescriptionData?.other_details && (
                  <div className="sigBox">
                    <label htmlFor="sigNatureFor" className="signaturePad">
                      <img
                        style={{ width: "100px", height: "80px" }}
                        src={
                          prescriptionData?.other_details
                            ? prescriptionData?.other_details[0]
                                ?.vaccinator_signature_path
                            : ""
                        }
                        alt=""
                      />
                      <input type="file" id="sigNatureFor" className="d-none" />
                    </label>
                    <label className="belowTxt">SIGNATURE</label>
                  </div>
                )}
              </div>
            </div>
            <div className="belowDetails">
              <div className="imgWrapper">
                <div className="row heightAuto">
                  <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                    <div className="txt">
                      <div className="innerTxt">Contact</div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-12 p-0">
                    <div className="allCOntact">
                      <div className="emailAndCall">
                        <div className="callNnumber">
                          <img src={CallMaroon} alt="" />
                          <label className="numberInn">
                            +91 9509932234
                            {/* {prescriptionData?.other_details
                              ? prescriptionData?.other_details[0]
                                  ?.vaccinator_contact_number
                              : ""} */}
                          </label>
                        </div>
                        <div className="emialInn">
                          <img src={EmailMaroon} alt="" />
                          <label className="numberInn">
                            info@animalsapp.in
                            {/* {prescriptionData?.other_details
                              ? prescriptionData?.other_details[0]
                                  ?.vaccinator_email
                              : ""} */}
                          </label>
                        </div>
                      </div>
                      <div className="locationInn">
                        <img src={LocatMaroon} alt="" />
                        <label className="locationTxt">
                          Near Ravi steel, kamde, ratu Road, Ranchi, Jharkhand,
                          India 835222
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VaccinatorDetails;
