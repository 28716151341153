import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { Link, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import PetShop from '../../assets/images/icons/petShopImg.svg'
import {
  PutCallWithErrorResponse,
  simpleGetCallWithErrorResponse
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { notifyError, notifySuccess } from '../../sharedComponent/Notify'

const ViewPetShops = () => {
  // const location = useLocation()
  // const searchParams = new URLSearchParams(location?.search)
  // const pet_shop_id = parseInt(searchParams.get('pet_shop_id'))

  const pet_shop_id = sessionStorage.pet_shop_id
    ? sessionStorage.pet_shop_id
    : ''

  const { sidebar, setBkdropLdr, navigate } = useContext(AppContext)

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }

  const [profileDetails, setprofileDetails] = useState({})

  useEffect(() => {
    fetchProfileDetails()
  }, [])
  // PET_VET_SHOP_PROFILE
  const fetchProfileDetails = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.PET_VET_SHOP_PROFILE}?petShopId=${pet_shop_id}`
      )
      // console.log("res,", res);
      const { success, data } = res?.json
      if (success && data) {
        setprofileDetails(data[0])
      }
    } catch (error) {
      console.log('catch error,', error)
    }
  }

  const approveAndReject = async (petShopId, isVerify) => {
    try {
      const payLoad = {
        petShopUserId: petShopId,
        verify: isVerify
      }
      setBkdropLdr(true)
      const res = await PutCallWithErrorResponse(
        ApiConfig.PETVET_SHOP_VERIFY,
        payLoad
      )
      const { success } = res?.json
      if (success) {
        notifySuccess(
          isVerify ? 'Approved successfully' : 'Rejected successfully'
        )
        navigate('/AddPetShops')
      } else {
        notifyError('Something went wrong')
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  return (
    <>
      <motion.div
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
        id='cx-main'
      >
        <div className='viewPharmacy-wrapper'>
          <div className='mainDetailCard'>
            <div className='topSection'>
              <div className='innerImg'>
                <img
                  src={
                    profileDetails?.pet_shop_image_path
                      ? profileDetails?.pet_shop_image_path
                      : PetShop
                  }
                  className='docImg'
                  alt=''
                />
                <div className='docDetTxt'>
                  <h1 className='docName'>{profileDetails?.pet_shop_name}</h1>
                  <label className='docDomain'>
                    {profileDetails?.type_of_pet_shop}
                  </label>
                </div>
              </div>
            </div>
            <div className='belowDetails'>
              <div className='row'>
                {/* Heading Text */}
                <div className='col-md-12'>
                  <div className='headTittle'>
                    <h1 className='heading'>General Information</h1>
                  </div>
                </div>
                {/* Inner Values */}
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Owner Name</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.owner_name}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Contact Number</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.phone_number}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Email</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.email}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 dispNoneMedia'></div>
                {/* Heading Text */}
                <div className='col-md-12'>
                  <div className='headTittle moreMt'>
                    <h1 className='heading'>Shop Information</h1>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Shop Name</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.pet_shop_name}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Shop Type</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.type_of_pet_shop}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Address</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.pet_shop_address}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>License Number</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.pet_shop_license_number}
                    </label>
                  </div>
                </div>
                {/* Heading Text */}
                <div className='col-md-12'>
                  <div className='headTittle moreMt'>
                    <h1 className='heading'>Bank Details</h1>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Bank Name</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.bank_details &&
                        profileDetails?.bank_details[0]?.pet_shop_bank}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Account Number</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.bank_details &&
                        profileDetails?.bank_details[0]?.account_number}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>Account Holder Name</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.bank_details &&
                        profileDetails?.bank_details[0]?.account_holder_name}
                    </label>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div className='keyValueContent'>
                    <h1 className='Key'>IFSC / SWIFT Code</h1>
                    <label htmlFor='' className='value'>
                      {profileDetails?.bank_details &&
                        profileDetails?.bank_details[0]?.ifsc_code}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-end'>
            <button
              className='rejectBtn me-2'
              type='button'
              onClick={() =>
                approveAndReject(
                  profileDetails?.pet_shop_registration_id,
                  false
                )
              }
            >
              Reject
            </button>
            <button
              className='AcceptBtn'
              type='button'
              onClick={() =>
                approveAndReject(profileDetails?.pet_shop_registration_id, true)
              }
            >
              Accept
            </button>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default ViewPetShops
