import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import percentage from "../../assets/images/icons/percentage.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifySuccess } from "../../sharedComponent/Notify";

const AddDiscount = () => {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const [addDiscountData, setAddDiscountData] = useState({
        coupon_name: "",
        coupon_discount_per: "",
        coupon_use_quantity: null,
        min_amount: null,
        max_amount: null,
        coupon_valid_from: "",
        coupon_valid_upto: "",
        is_public: false,
        coupon_code: ""
    })

    const changeEve = (e) => {
        setAddDiscountData({
            ...addDiscountData,
            [e.target.name]: e.target.value
        })
    };

    const addData = (event) => {
        event.preventDefault();
        simplePostCall(ApiConfig.ADD_DISCOUNT, JSON.stringify(addDiscountData))
            .then((res) => {
                if (res.success) {
                    notifySuccess(res?.message);
                    setAddDiscountData([]);
                    navigate("/DiscountList")
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        addData(event)
    };


    const params = useParams();

    const { sidebar, setSidebar } = useContext(AppContext);

    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };
    return (
        <>
            <motion.div
                variants={aninations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
                className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
                id="cx-main">
                <div className="addEditMain">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row cardRow">
                            <div className="col-md-8">
                                <div className="discountCard">
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <Form.Group controlId="validationCustom01">
                                                <Form.Label>Coupon Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="coupon_name"
                                                    value={addDiscountData?.coupon_name}
                                                    onChange={changeEve}
                                                />
                                                <Form.Control.Feedback type="invalid">Coupon name is required</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group controlId="validationCustom01">
                                                <Form.Label>Coupon Quantity</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    name="coupon_use_quantity"
                                                    value={addDiscountData?.coupon_use_quantity}
                                                    onChange={changeEve}
                                                />
                                                <Form.Control.Feedback type="invalid">Coupon quantity is required</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group controlId="validationCustom01">
                                                <Form.Label>Coupon Percentage</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    name="coupon_discount_per"
                                                    value={addDiscountData?.coupon_discount_per}
                                                    onChange={changeEve}
                                                />
                                                <Form.Control.Feedback type="invalid">Coupon percentage is required</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group controlId="validationCustom01">
                                                <Form.Label>Minimum Amount</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    name="min_amount"
                                                    value={addDiscountData?.min_amount}
                                                    onChange={changeEve}
                                                />
                                                <Form.Control.Feedback type="invalid">Minimum Amount required</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group controlId="validationCustom01">
                                                <Form.Label>Maxmimum Amount</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    name="max_amount"
                                                    value={addDiscountData?.max_amount}
                                                    onChange={changeEve}
                                                />
                                                <Form.Control.Feedback type="invalid">Maxmimum Amount required</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group controlId="validationCustom01">
                                                <Form.Label>Coupon Valid From</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="date"
                                                    name="coupon_valid_from"
                                                    value={addDiscountData?.coupon_valid_from}
                                                    onChange={changeEve}
                                                />
                                                <Form.Control.Feedback type="invalid">Date is required</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group controlId="validationCustom01">
                                                <Form.Label>Coupon Valid Upto</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="date"
                                                    name="coupon_valid_upto"
                                                    value={addDiscountData?.coupon_valid_upto}
                                                    onChange={changeEve}
                                                />
                                                <Form.Control.Feedback type="invalid">Date is required</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <Form.Group controlId="validationCustom01">
                                                <Form.Label>Coupon Code</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="coupon_code"
                                                    value={addDiscountData?.coupon_code}
                                                    onChange={changeEve}
                                                />
                                                <Form.Control.Feedback type="invalid">Coupon Code</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3 d-flex">
                                                <Form.Check
                                                    required
                                                    checked={addDiscountData?.is_public}
                                                    onClick={() => setAddDiscountData({ ...addDiscountData, is_public: !addDiscountData?.is_public })}
                                                    id="checkHere"
                                                    feedbackType="invalid"
                                                    className="formCheck"
                                                />
                                                <label htmlFor="checkHere" className="checkHere">Is Public</label>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12 text-end">
                                            <input type="submit" value="Add Discount" className="submitBtn" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </motion.div>
        </>
    )
}

export default AddDiscount
