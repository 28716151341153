import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import SearchIcon from '../../assets/images/icons/SearchIcon.svg'
import FilterIcon from '../../assets/images/icons/Filter.svg'
import { motion } from 'framer-motion'
import MedicalImg from '../../assets/images/icons/medicalImg.svg'
import { Link } from 'react-router-dom'
import {
  PutCallWithErrorResponse,
  simpleGetCallWithErrorResponse
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import NoDataMsg from '../../sharedComponent/NoDataMsg'
import RotatingLoader from '../../sharedComponent/RotatingLoader'
import { notifySuccess } from '../../sharedComponent/Notify'

const AddPharmacy = () => {
  const { sidebar, setBkdropLdr, slideBelowRef  } = useContext(AppContext)

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }

  // STATES
  const [newShopList, setNewShopList] = useState([])
  const [currentPageData, setCurrentPageData] = useState({ last_page: false })
  const [currentPage, setCurrentPage] = useState(1)
  const [isMoreLoader, setIsMoreLoader] = useState(false)
  const [filterData, setFilterData] = useState({
    page: 1,
    search: ''
  })
  const [isLoading, setIsLoading] = useState(false)

  // STATES

  // STATES
  useEffect(() => {
    fetchShopList()
  }, [filterData])

  const fetchShopList = async () => {
    try {
      setIsLoading(true)
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_NEW_PHARMACY_SHOP_LIST}?page=${currentPage}${
          filterData?.search ? `&search=${filterData?.search}` : ''
        }`
      )
      console.log('res,', res)
      const { success, pharmacyList, last_page } = res?.json
      if (success) {
        pharmacyList && setNewShopList(pharmacyList)
        setCurrentPageData({ last_page })
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchMoreShopList()
    }
  }, [currentPage])

  const fetchMoreShopList = async () => {
    try {
      setIsMoreLoader(true)
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_NEW_PHARMACY_SHOP_LIST}?page=${filterData?.page}${
          filterData?.search ? `&search=${filterData?.search}` : ''
        }`
      )
      console.log('res,', res)
      const { success, pharmacyList, last_page } = res?.json
      if (success) {
        pharmacyList && setNewShopList(prev => [...prev, ...pharmacyList])
        setCurrentPageData({ last_page })
        !last_page && setCurrentPage(currentPage + 1)
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setIsMoreLoader(false)
    }
  }

  // const handleInfiniteScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop + 1 >=
  //     document.documentElement.scrollHeight
  //   ) {
  //     if (!currentPageData.last_page) {
  //       setCurrentPage(currentPage + 1)
  //     }
  //   }
  // }

  const handleInfiniteScroll = () => {
    const slideBelow = slideBelowRef.current;

    if (
      slideBelow &&
      slideBelow.clientHeight + slideBelow.scrollTop + 1 >=
        slideBelow.scrollHeight
    ) {
      if (!currentPageData.last_page) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const slideBelow = slideBelowRef.current;
    if (slideBelow) {
      slideBelow.addEventListener('scroll', handleInfiniteScroll);
    }

    // window.addEventListener('scroll', handleInfiniteScroll)
    // return () => window.removeEventListener('scroll', handleInfiniteScroll)
  }, [])

  const approveAndReject = async (pharmacyId, isVerify) => {
    try {
      const payLoad = {
        pharmacyId: pharmacyId,
        verify: isVerify
      }
      setBkdropLdr(true)
      const res = await PutCallWithErrorResponse(
        ApiConfig.APPROVE_AND_REJECT_SHOP,
        payLoad
      )
      const { success } = res?.json
      if (success) {
        notifySuccess(
          isVerify ? 'Approved successfully' : 'Rejected successfully'
        )
        fetchShopList()
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  return (
    <>
      <motion.div
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
        id='cx-main'
      >
        <div className='addPharmacy-wrapper'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='innerSearchItem'>
                <div className='leftFilterTop'>
                  <div className='topSearch'>
                    <input
                      type='text'
                      className='form-control searchDoc'
                      placeholder='Search by Name, Specialist, Ratings, Visits...'
                      onChange={e => {
                        setFilterData({
                          ...filterData,
                          search: e.target.value
                        })
                      }}
                    />
                    <img src={SearchIcon} className='searchInner' alt='' />
                  </div>
                  {/* <div className='topFilter'>
                    <img src={FilterIcon} className='filterInner' alt='' />
                  </div> */}
                </div>
              </div>
            </div>
            {/* Cards Content */}
            {isLoading ? (
              <RotatingLoader />
            ) : newShopList && newShopList.length > 0 ? (
              <div className='slideBelow row p-0 m-0'
              ref={slideBelowRef}
              >
                {newShopList.map((item, idx) => {
                  return (
                    <div
                      className='col-lg-3 cardsResponsive'
                      key={'newShopList' + idx}
                    >
                      <div className='cardLink'>
                        {/* ViewShopDetails */}
                        <div className='doctorsDetailsCard'>
                          <Link
                            to={'/ViewShopDetails'}
                            className='doctorsDetailsCard'
                            style={{ textDecoration: 'none' }}
                            onClick={() =>
                              sessionStorage.setItem(
                                'pharmacy_shop_id',
                                item?.pharmacy_shop_id
                              )
                            }
                          >
                            <img
                              src={
                                item?.profile_image_path
                                  ? item?.profile_image_path
                                  : MedicalImg
                              }
                              className='docInsideImg'
                              alt=''
                            />
                            <div className='docDetailsTxt'>
                              {item?.pharmacy_shop_name && (
                                <h1>{item?.pharmacy_shop_name}</h1>
                              )}
                              <label>Orthopedic</label>
                            </div>
                          </Link>
                          <div className='innerButton'>
                            <button
                              className='rejectBtn'
                              type='button'
                              onClick={() =>
                                approveAndReject(item?.pharmacy_id, false)
                              }
                            >
                              Reject
                            </button>
                            <button
                              className='AcceptBtn'
                              type='button'
                              onClick={() =>
                                approveAndReject(item?.pharmacy_id, true)
                              }
                            >
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <NoDataMsg />
            )}
            {isMoreLoader && <RotatingLoader />}
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default AddPharmacy
