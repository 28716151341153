import React, { useState } from 'react';
import yourLogo from "../../assets/images/icons/mainLogo.svg";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/landigIcon.svg";
import mailBox from "../../assets/images/icons/emailSvg.svg";
import passBox from "../../assets/images/icons/lock.svg";
import eye from "../../assets/images/icons/eye.svg";
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { simplePostCall } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import { notifyError, notifySuccess } from '../../sharedComponent/Notify';

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [otpReceived, setOtpReceived] = useState(false);
    const [redirectResetPass, setRedirectResetPass] = useState(false);
    const [ForgetPassDetails, setForgetPassDetails] = useState({
        phone_number: "",
        otp: ""
    });

    // State For Reset Password
    const [resetPassword, setResetPassword] = useState({
        email: "",
        new_password: "",
        confirm_password: ""
    })
    const changeVal = (e) => {
        setForgetPassDetails({
            ...ForgetPassDetails,
            [e.target.name]: e.target.value
        })
    };

    const changeResetVal = (e) => {
        setResetPassword({
            ...resetPassword,
            [e.target.name]: e.target.value
        })
    };


    // OTP Request API
    const requestOtp = (e) => {
        let payload = {
            phone_number: ForgetPassDetails?.phone_number
        }
        e.preventDefault();
        if (ForgetPassDetails.phone_number === "") {
            notifyError("Please Enter Phone Number")
        } else {
            simplePostCall(ApiConfig.FORGET_PASSWORD, JSON.stringify(payload))
                .then((res) => {
                    if (res.success === true) {
                        notifySuccess(res?.message)
                        setOtpReceived(true);
                    } else {
                        notifyError(res.message)
                    }
                })
                .catch((err) => {
                    notifyError(err.message)
                })
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        validateOtp(event)
        setValidated(true);
    };

    const validateOtp = (e) => {
        e.preventDefault();
        let payload = {
            phone_number: JSON.parse(ForgetPassDetails?.phone_number),
            otp: JSON.parse(ForgetPassDetails?.otp)
        };
        simplePostCall(ApiConfig.VERIFY_OTP, JSON.stringify(payload))
            .then((res) => {
                if (res.success === true) {
                    notifySuccess(res?.message)
                    setRedirectResetPass(true)
                } else {
                    notifyError(res?.message)
                }
            })
            .catch((err) => {
                notifyError(err?.message);
            })
    }

    const handleReset = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        resetPasswordApi(event)
        setValidated(true);
    };

    const resetPasswordApi = (e) => {
        e.preventDefault();
        let payload = resetPassword;
        simplePostCall(ApiConfig.RESET_PASSWORD, JSON.stringify(payload))
            .then((res) => {
                if (res.success === true) {
                    notifySuccess(res?.message);
                    navigate("/")
                }
            })
            .catch((err) => {
                notifyError(err.message);
            })
    }

    return (
        <>
            {
                redirectResetPass ?
                    <Form noValidate validated={validated} onSubmit={handleReset}>
                        <pre>{JSON.stringify(resetPassword)}</pre>
                        <div className="authMain">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <img src={yourLogo} className='logoImg' alt="" />
                                    <div className="signinForm">
                                        <h1 className='signInTxt'>Reset your password ?</h1>
                                        <p className='forgetLnk'>You Can <Link to="/">Login Here</Link></p>
                                        <Form.Group className="inputBox" controlId="validationCustom01">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Form.Label htmlFor="" className='emailLabel'>Phone Number</Form.Label>
                                            </div>
                                            <img src={mailBox} className='mailImg' alt="" />
                                            <Form.Control
                                                required
                                                type="text"
                                                name='email'
                                                value={resetPassword.email}
                                                onChange={changeResetVal}
                                                className='form-control formInput' placeholder='Enter your Phone / email address' />
                                            <Form.Control.Feedback type="invalid">Please Enter Your Email...</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="inputBox" controlId="validationCustom02">
                                            <Form.Label htmlFor="" className='emailLabel'>Passwords</Form.Label>
                                            <img src={passBox} className='mailImg' alt="" />
                                            <Form.Control
                                                required
                                                type="password"
                                                name='new_password'
                                                value={resetPassword.new_password}
                                                onChange={changeResetVal}
                                                className='form-control formInput' placeholder='Enter your Password' />
                                            <Form.Control.Feedback type="invalid">Please Enter Password...</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="inputBox" controlId="validationCustom02">
                                            <Form.Label htmlFor="" className='emailLabel'>Confirm Password</Form.Label>
                                            <img src={passBox} className='mailImg' alt="" />
                                            <Form.Control
                                                required
                                                type="password"
                                                name='confirm_password'
                                                value={resetPassword.confirm_password}
                                                onChange={changeResetVal}
                                                className='form-control formInput' placeholder='Enter your Confirm Password' />
                                            <Form.Control.Feedback type="invalid">Please Enter Confirm Password...</Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="login">
                                            <button type='submit'>Update</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="landingImg">
                                        <div className="mobileNumber">
                                            <span className='callNumber'><img src={teleCall} height="15" width="15" alt="" /> +94 0116 789 754</span>
                                        </div>
                                        <div className="insiderIcon">
                                            <img src={insiderImg} alt="" />
                                        </div>
                                        <div className="landingTxt">
                                            <div className='d-flex flex-column justify-content-start'>
                                                <h1>Welcome to Admin Panel</h1>
                                                <p>Lets get started !</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    :
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="authMain">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <img src={yourLogo} className='logoImg' alt="" />
                                    <div className="signinForm">
                                        <h1 className='signInTxt'>Forgot your password ?</h1>
                                        <p className='forgetLnk'>You Can <Link to="/">Login Here</Link></p>
                                        <Form.Group className="inputBox" controlId="validationCustom01">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Form.Label htmlFor="" className='emailLabel'>Phone Number</Form.Label>
                                                <button className="otpbtn" onClick={(e) => requestOtp(e)}>
                                                    Request OTP
                                                </button>
                                            </div>
                                            <img src={mailBox} className='mailImg' alt="" />
                                            <Form.Control
                                                required
                                                type="text"
                                                name='phone_number'
                                                value={ForgetPassDetails.phone_number}
                                                onChange={changeVal}
                                                className='form-control formInput' placeholder='Enter your Phone / email address' />
                                            <Form.Control.Feedback type="invalid">Please Enter Your Email...</Form.Control.Feedback>
                                        </Form.Group>
                                        {
                                            otpReceived ?
                                                <>
                                                    <Form.Group className="inputBox" controlId="validationCustom02">
                                                        <Form.Label htmlFor="" className='emailLabel'>OTP</Form.Label>
                                                        <img src={passBox} className='mailImg' alt="" />
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            name='otp'
                                                            value={ForgetPassDetails.otp}
                                                            onChange={changeVal}
                                                            className='form-control formInput' placeholder='Enter your OTP' />
                                                        <Form.Control.Feedback type="invalid">Please Enter Your OTP...</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <div className="login">
                                                        <button type='submit'>Update</button>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="landingImg">
                                        <div className="mobileNumber">
                                            <span className='callNumber'><img src={teleCall} height="15" width="15" alt="" /> +91 9509932234</span>
                                        </div>
                                        <div className="insiderIcon">
                                            <img src={insiderImg} alt="" />
                                        </div>
                                        <div className="landingTxt">
                                            <div className='d-flex flex-column justify-content-start'>
                                                <h1>Welcome to Admin Panel</h1>
                                                <p>Lets get started !</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
            }
        </>
    )
}

export default ForgetPassword;
